import React from 'react'
import { Switch, Route } from 'react-router'
import map from 'lodash/map'
import {
  ACTIVATE_REPORTS,
  ACTIVATE_REPORT,
  LOCAL_SEARCH_REPORTS,
  LOCAL_SEARCH_REPORT,
  VALUE_INTELLIGENCE_REPORTS,
  VALUE_INTELLIGENCE_REPORT
} from '@signal/routes'
import NotFound from '../../components/NotFound'
import ReportView from '../dashboard/Report'
import SignalPage from '../SignalPage'

export function GenericReportsView() {
  return (
    <SignalPage>
      <Switch>
        {map(
          [
            ACTIVATE_REPORTS,
            ACTIVATE_REPORT,
            LOCAL_SEARCH_REPORTS,
            LOCAL_SEARCH_REPORT,
            VALUE_INTELLIGENCE_REPORTS,
            VALUE_INTELLIGENCE_REPORT
          ],
          link => (
            <Route
              key={link.path}
              path={link.path}
              exact
              component={ReportView}
            />
          )
        )}
        <Route component={NotFound} />
      </Switch>
    </SignalPage>
  )
}
