import { createAction } from 'redux-act';
import createRequestActions from '@signal/utils/createRequestActions';
export var fetchAllSpendActions = createRequestActions('SPEND/FETCH_ALL');
export var changeDate = createAction('SPEND/CHANGE_DATE', function (date) {
  return date.toDateString();
});
export var updateSpend = createAction('SPEND/UPDATE_SPEND');
export var updateRepeatMonthly = createAction('SPEND/UPDATE_REPEAT_MONTHLY');
export var updateChannelActions = createRequestActions('SPEND/UPDATE_CHANNEL');
export var updateSpendRequestActions = createRequestActions('SPEND/UPDATE_SPEND_REQUEST');
export var updateRepeatMonthlyRequestActions = createRequestActions('SPEND/UPDATE_REPEAT_MONTHLY_REQUEST');