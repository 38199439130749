import React from 'react'
import { useLocation } from 'react-router'
import { INDUSTRY_REPORT } from '@signal/routes/industry'
import { Nav, NavLink } from '@signal/components/Nav'
import { useRoutePath } from '@signal/core-hooks'
import SignalHeader from '../Header'

export default function Header({ isPageChild }) {
  const location = useLocation()
  const overviewPath = useRoutePath(INDUSTRY_REPORT, {
    reportSlug: 'overview'
  })
  const fixedOpsPath = useRoutePath(INDUSTRY_REPORT, {
    reportSlug: 'fixedOps'
  })
  return (
    <SignalHeader isPageChild={isPageChild} fullWidth>
      <Nav location={location}>
        <NavLink label="Overview" to={overviewPath} />
        <NavLink label="Fixed Ops" to={fixedOpsPath} />
      </Nav>
    </SignalHeader>
  )
}
