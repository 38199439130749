import { createSelector } from 'reselect';
import get from 'lodash/get';
import select from '@signal/utils/select';
import { partialPerformanceShoppersBucketPathSelector } from '../router/selectors';
import { LABELS, DESCRIPTIONS, DAYS_FROM_SALE_LABELS } from './constants';
export var rootStateSelector = select('shoppersBuckets');
export var bucketMatchParamSelector = function bucketMatchParamSelector(state, props) {
  return get(props, 'match.params.shopperBucket');
};
export var activeBucketSelector = createSelector(rootStateSelector, bucketMatchParamSelector, function (buckets, bucket) {
  return get(buckets, bucket, {});
});
export var activeBucketDataSelector = createSelector(activeBucketSelector, select('data'));
export var activeBucketShoppersSelector = createSelector(activeBucketDataSelector, select('shoppers'));
export var activeBucketUsersSelector = createSelector(activeBucketDataSelector, select('users'));
export var activeBucketNameSelector = bucketMatchParamSelector;
export var activeBucketLabelSelector = createSelector(activeBucketNameSelector, function (bucket) {
  return LABELS[bucket];
});
export var activeBucketDaysFromSaleLabelSelector = createSelector(activeBucketNameSelector, function (bucket) {
  return DAYS_FROM_SALE_LABELS[bucket];
});
export var activeBucketDescriptionSelector = createSelector(activeBucketNameSelector, function (bucket) {
  return DESCRIPTIONS[bucket];
});
export var isLoadingSelector = createSelector(activeBucketSelector, select('loading', false));
export var isLoadedSelector = createSelector(activeBucketDataSelector, function (data) {
  return !!data;
});
export var shoppersBucketBreadcrumbLabelSelector = createSelector(activeBucketLabelSelector, activeBucketUsersSelector, function (label, users) {
  return users ? "".concat(label, " (").concat(users, " Results)") : label;
});
export var shoppersBucketBreadcrumbSelector = createSelector(shoppersBucketBreadcrumbLabelSelector, activeBucketNameSelector, partialPerformanceShoppersBucketPathSelector, function (label, shopperBucket, createPath) {
  return {
    label: label,
    to: createPath(shopperBucket)
  };
});