import loadable from '@signal/hoc/loadable'
import combinedError from '@signal/hoc/combinedError'
import ErrorScreenApi from '@signal/containers/ErrorScreenApi'
import ChannelsDetailChartSkeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "channels-detail-chart" */ './component'),
  dataLoader: () =>
    import(
      /* webpackChunkName: "lib-performance-query" */ '@signal/core/performanceQuery'
    ),
  loadData: ({ performanceQueryDataLoader }, loaderInterface) =>
    performanceQueryDataLoader(loaderInterface),
  loading: ChannelsDetailChartSkeleton,
  error: combinedError(ErrorScreenApi)
})
