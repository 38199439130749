import createLoader from '@signal/utils/createLoader';
import { rootLoader } from '../app';
import { loadPerformanceVinData, performanceVinListener } from './sagas';
import reducer from './reducer';
export var performanceVinDataLoader = createLoader('performanceVin', {
  load: loadPerformanceVinData,
  listen: performanceVinListener,
  injectReducers: function injectReducers(injectReducer) {
    return injectReducer('performanceVin', reducer);
  },
  dependencies: [rootLoader]
});
export default performanceVinDataLoader;