import loadable from '@signal/hoc/loadable'
import combinedError from '@signal/hoc/combinedError'
import ErrorScreenApi from '@signal/containers/ErrorScreenApi'
import Skeleton from '../../components/ManageSpendTable/Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "manage-spend-table" */ './component'),
  dataLoader: () =>
    import(/* webpackChunkName: "lib-spend" */ '@signal/core/spend'),
  loading: Skeleton,
  error: combinedError(ErrorScreenApi)
})
