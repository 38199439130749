import styled from 'styled-components'
import { getSpacingSelector } from '@signal/theme/selectors'
import Typography from '@signal/components/Typography'
import SkeletonTypography from '@signal/components/Skeleton/Typography'
import IconForward from '@signal/components/Icons/Forward'

export const Wrapper = styled.div`
  margin-top: ${getSpacingSelector(3)}px;
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'h6',
  color: 'textDark'
}))`
  margin-bottom: ${getSpacingSelector()}px;
`

const rowTitleProps = { variant: 'h6' }
export const RowTitle = styled(Typography).attrs(() => ({
  color: 'textDark',
  ...rowTitleProps
}))``
export const RowTitleSkeleton = styled(SkeletonTypography).attrs(() => ({
  ...rowTitleProps,
  color: 'skeletonDark',
  width: 250
}))``

const rowSubtitleProps = { variant: 'body2' }
export const RowSubtitle = styled(Typography).attrs(rowSubtitleProps)``
export const RowSubtitleSkeleton = styled(SkeletonTypography).attrs(() => ({
  ...rowSubtitleProps,
  color: 'skeletonPrimary',
  width: 160
}))``

export const RowIcon = styled(IconForward).attrs(() => ({
  size: 24
}))``
