var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import PropTypes from 'prop-types';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  &:before {\n    display: block;\n    content: '';\n    width: 100%;\n    padding-top: calc(100% / ", ");\n  }\n\n  & > img {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n  }\n"])), function (props) {
  return props.ratio;
});
Wrapper.propTypes = {
  ratio: PropTypes.number
};
Wrapper.defaultProps = {
  ratio: 4 / 3
};
export var Image = styled.img(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n"])));