function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import propTypeMutuallyExclusiveProperties from './mutuallyExclusiveProperties';
/**
 * Prop-type util that provides a simplified, more limited, abstraction for
 * `utils/propTypes/mutuallyExclusiveProperties`.
 *
 * The simpler interface accepts an object containing prop-type definitions for
 * two or more props, and an `isRequired` flag; it returns an object containing
 * the same props, but with custom prop-type validation that treats each of the
 * provided props as mutually exclusive.
 *
 * @property {Object.<String,Function>} mutuallyExclusivePropTypes - prop-type
 * validators for mutually exclusive props
 * @property {Boolean} [isRequired=false] - whether or not one of the mutually
 * exclusive properties is required
 * @returns {Function} - custom prop-type validator for validating mutually
 * exclusive properties
 *
 * @example
 * // Returns object with same keys as the input `mutuallyExclusivePropTypes`
 * // {
 * //   propA: validateEitherPropAOrPropB,
 * //   propB: validateEitherPropAOrPropB
 * // }
 * mutuallyExclusiveProps({
 *   propA: PropTypes.string,
 *   propB: PropTypes.func
 * })
 *
 * @example
 * // Enabling the `isRequired` flag to require at least one of the props
 * mutuallyExclusiveProps(
 *   { propA: PropTypes.string, propB: PropTypes.func },
 *   // isRequired
 *   true
 * )
 *
 * @example
 * // It just returns an object, so it can be destructured if necessary
 * SomeComponent.propTypes = {
 *   someOtherProp: PropTypes.string,
 *   ...mutuallyExclusiveProps({
 *     propA: PropTypes.string,
 *     propB: PropTypes.func
 *   })
 * }
 */

export default function mutuallyExclusiveProps(mutuallyExclusivePropTypes, required) {
  var propNames = Object.keys(mutuallyExclusivePropTypes);
  var mutuallyExclusivePropType = propTypeMutuallyExclusiveProperties(mutuallyExclusivePropTypes, propNames, required);
  return propNames.reduce(function (propTypes, propName) {
    return _objectSpread(_objectSpread({}, propTypes), {}, _defineProperty({}, propName, mutuallyExclusivePropType));
  }, {});
}