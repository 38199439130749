import React, { memo } from 'react'
import { PAGE_MANAGE_SPEND } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefault from '../../layouts/Default'
import Header from '../../containers/AttributionHeader'
import ManageSpendTable from '../../containers/ManageSpendTable'
import ManageSpendHeader from '../../containers/ManageSpendHeader'
import { Wrapper, ConstrainedWrapper, ContentWrapper } from './styles'

export function ManageSpend() {
  usePageTrack(PAGE_MANAGE_SPEND)

  return (
    <LayoutDefault navbar={Header}>
      <Wrapper>
        <ConstrainedWrapper>
          <ManageSpendHeader />
          <ContentWrapper>
            <ManageSpendTable />
          </ContentWrapper>
        </ConstrainedWrapper>
      </Wrapper>
    </LayoutDefault>
  )
}

export default memo(ManageSpend)
