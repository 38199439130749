/**
 * MANAGED QUERY PARAMETER SUBSTITUTION CODES
 */
export var QP_SUBST_CHAR = ':';
export var SUBST_DATE_RANGE_TYPE = "".concat(QP_SUBST_CHAR, "dateRangeType").concat(QP_SUBST_CHAR);
export var SUBST_WIDGET_ID = "".concat(QP_SUBST_CHAR, "widgetId").concat(QP_SUBST_CHAR);
export var ALL_QUERY_PARAMETER_SUBST_TYPES = [SUBST_DATE_RANGE_TYPE, // which date range (primary/comparison?)
SUBST_WIDGET_ID // which widget (for widget-specific parameters)
]; // date range types

export var DATE_RANGE_TYPE_PRIMARY = 0;
export var DATE_RANGE_TYPE_COMPARISON = 1;
/**
 * MANAGED QUERY PARAMETER TYPES
 */
// global query parameters

export var QP_DATE_START = "d".concat(SUBST_DATE_RANGE_TYPE, "0");
export var QP_DATE_END = "d".concat(SUBST_DATE_RANGE_TYPE, "1");
export var QP_FILTERS = 'filters';
export var GLOBAL_QUERY_PARAMETERS = [QP_DATE_START, QP_DATE_END, QP_FILTERS]; // widget-specific query parameters

export var QP_COLUMN_FILTERS = "column_filters_".concat(SUBST_WIDGET_ID);
export var QP_COLUMN_SET = "columns_".concat(SUBST_WIDGET_ID);
export var QP_FOCUS_TAB = "tab_".concat(SUBST_WIDGET_ID);
export var QP_FOCUS_PANEL = "panel_".concat(SUBST_WIDGET_ID);
export var QP_METRICS = "metrics_".concat(SUBST_WIDGET_ID);
export var QP_START_AT = "start_at_".concat(SUBST_WIDGET_ID);
export var QP_SORT_COLUMN = "sort_".concat(SUBST_WIDGET_ID);
export var ALL_MANAGED_QUERY_PARAMETERS = [QP_COLUMN_FILTERS, QP_COLUMN_SET, QP_DATE_START, QP_DATE_END, QP_FILTERS, QP_FOCUS_TAB, QP_FOCUS_PANEL, QP_METRICS, QP_START_AT, QP_SORT_COLUMN];
export var QP_PRIMARY_DATE_START = QP_DATE_START.replace(SUBST_DATE_RANGE_TYPE, DATE_RANGE_TYPE_PRIMARY);
export var QP_PRIMARY_DATE_END = QP_DATE_END.replace(SUBST_DATE_RANGE_TYPE, DATE_RANGE_TYPE_PRIMARY);
export var QP_COMPARISON_DATE_START = QP_DATE_START.replace(SUBST_DATE_RANGE_TYPE, DATE_RANGE_TYPE_COMPARISON);
export var QP_COMPARISON_DATE_END = QP_DATE_END.replace(SUBST_DATE_RANGE_TYPE, DATE_RANGE_TYPE_COMPARISON);
export var ALL_MANAGED_DATE_PARAMETERS = [[QP_PRIMARY_DATE_START, QP_PRIMARY_DATE_END], [QP_COMPARISON_DATE_START, QP_COMPARISON_DATE_END]];
/**
 * MISC
 */

export var QP_DATE_FORMAT = 'MM-dd-yyyy';