function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createSelector } from 'reselect';
import get from 'lodash/get';
import { typographySelector, getColorPrimarySelector, getConstantSelector, getSignalFontVariantName, getSpacingSelector } from '@signal/theme';

var getFontVariantSelector = function getFontVariantSelector(variant) {
  return createSelector(typographySelector, function (typography) {
    return get(typography, getSignalFontVariantName(variant));
  });
};

var body4VariantSelector = getFontVariantSelector('body4');
var body1VariantSelector = getFontVariantSelector('body1');
var colorPrimarySelector = getColorPrimarySelector();
var colorPrimaryDarkSelector = getColorPrimarySelector('dark');
var buttonMinWidthSelector = getConstantSelector('BUTTON_MIN_WIDTH');
var buttonMinHeightSelector = getConstantSelector('BUTTON_MIN_HEIGHT');
var buttonSmallMinWidthSelector = getConstantSelector('BUTTON_SMALL_MIN_WIDTH');
var buttonSmallMinHeightSelector = getConstantSelector('BUTTON_SMALL_MIN_HEIGHT');
var buttonLargeMinWidthSelector = getConstantSelector('BUTTON_LARGE_MIN_WIDTH');
var buttonLargeMinHeightSelector = getConstantSelector('BUTTON_LARGE_MIN_HEIGHT');
var horizontalSpacingSelector = getSpacingSelector(2);
var verticalSpacingSelector = getSpacingSelector();
export var NEW_DESIGN_OVERRIDES = {
  // a-k-a "primary"
  contained: {
    minWidth: '120px',
    borderRadius: '25px',
    fontSize: '19px',
    padding: '16px 20px',
    boxShadow: 'none',
    '&$focusVisible': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&$disabled': {
      boxShadow: 'none'
    }
  },
  // a-k-a "secondary"
  outlined: {
    minWidth: '120px',
    borderColor: '#0d304c',
    borderStyle: 'double',
    borderWidth: '7px',
    borderRadius: '25px',
    fontSize: '19px',
    padding: '9px 20px',
    boxShadow: 'none',
    '&$focusVisible': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&$disabled': {
      borderStyle: 'double',
      borderWidth: '8px',
      borderRadius: '25px',
      boxShadow: 'none'
    }
  },
  text: {
    minWidth: '120px',
    borderRadius: '25px',
    fontSize: '19px',
    padding: '16px 20px',
    boxShadow: 'none',
    '&$focusVisible': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&$disabled': {
      boxShadow: 'none'
    }
  }
};
export default (function () {
  var ruleOverrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
  return function (theme) {
    return _objectSpread({
      root: _objectSpread({
        textTransform: 'none',
        minWidth: buttonMinWidthSelector(theme),
        minHeight: buttonMinHeightSelector(theme),
        padding: "".concat(verticalSpacingSelector(theme), "px ").concat(horizontalSpacingSelector(theme), "px")
      }, body4VariantSelector(theme)),
      sizeSmall: {
        minWidth: buttonSmallMinWidthSelector(theme),
        minHeight: buttonSmallMinHeightSelector(theme)
      },
      sizeLarge: _objectSpread({
        minWidth: buttonLargeMinWidthSelector(theme),
        minHeight: buttonLargeMinHeightSelector(theme)
      }, body1VariantSelector(theme)),
      contained: {
        boxShadow: 'none',
        '&$focusVisible': {
          boxShadow: 'none'
        },
        '&:active': {
          boxShadow: 'none'
        },
        '&$disabled': {
          boxShadow: 'none'
        }
      },
      containedPrimary: {
        backgroundColor: colorPrimarySelector(theme),
        '&:hover': {
          backgroundColor: colorPrimaryDarkSelector(theme),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: colorPrimarySelector(theme)
          }
        }
      },

      /* Styles applied to the ButtonBase root element if the button is keyboard focused. */
      focusVisible: {},

      /* Styles applied to the root element if `disabled={true}`. */
      disabled: {}
    }, ruleOverrides(theme));
  };
});