import orderBy from 'lodash/orderBy'
import {
  SORT_ASCENDING,
  SORT_DESCENDING
} from '@signal/components/SortableTable'

export const sortRows = ({ rows, activeSortKey, sortDirection }) => {
  if (!activeSortKey) {
    return rows
  }

  return orderBy(
    rows,
    [activeSortKey],
    [sortDirection === SORT_DESCENDING ? 'desc' : 'asc']
  )
}

export const reverseSortDirection = sortDirection =>
  sortDirection === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING
