var _templateObject;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import { createGlobalStyle } from 'styled-components';
import { getColorBackgroundSelector } from './selectors';
export var SIGNAL_HIDE = 'signal-hide';
export default createGlobalStyle(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  body {\n    background-color: ", ";\n  }\n\n  .", " {\n    display: none !important;\n  }\n"])), getColorBackgroundSelector(), SIGNAL_HIDE);