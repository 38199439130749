var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getColorBackgroundSelector, paletteDividerSelector } from '@signal/theme';
import Typography from '../Typography';
import TypographySkeleton from '../Skeleton/Typography';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 20px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 3px;\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n"])), getColorBackgroundSelector('paper'), paletteDividerSelector);
export var DetailsWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var MetricTitle = styled(Typography).attrs(function () {
  return {
    variant: 'h3'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-bottom: 10px;\n  grid-area: metric;\n"])));
export var MetricTitleSkeleton = MetricTitle.withComponent(TypographySkeleton);
export var Title = styled(Typography).attrs(function () {
  return {
    variant: 'body4'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  margin-bottom: 2px;\n  grid-area: title;\n"])));
export var TitleSkeleton = Title.withComponent(TypographySkeleton);
export var Subtitle = styled(Typography).attrs(function () {
  return {
    variant: 'body2'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  grid-area: subtitle;\n"])));
export var SubtitleSkeleton = Subtitle.withComponent(TypographySkeleton);
export var IconWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  grid-area: icon;\n  align-self: center;\n  justify-self: flex-end;\n"])));