import select from '@signal/utils/select';
import { createThemeSelector, createVariableThemeSelectorCreator } from './utils';
export var zIndexSelector = createThemeSelector(function (theme) {
  return theme.zIndex;
});
export var getElevationLayerSelector = createVariableThemeSelectorCreator(function (layerName) {
  return createThemeSelector(zIndexSelector, select(layerName));
});
export var mobileStepperZIndexSelector = getElevationLayerSelector('mobileStepper'); // 1000

export var pageToolbarZIndexSelector = mobileStepperZIndexSelector;
export var appBarZIndexSelector = getElevationLayerSelector('appBar'); // 1100

export var drawerZIndexSelector = getElevationLayerSelector('drawer'); // 1200

export var modalZIndexSelector = getElevationLayerSelector('modal'); // 1300

export var snackbarZIndexSelector = getElevationLayerSelector('snackbar'); // 1400

export var tooltipZIndexSelector = getElevationLayerSelector('tooltip'); // 1500