import styled from 'styled-components'
import { getSpacingSelector } from '@signal/theme/selectors'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'

export const Wrapper = styled.div``

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${getSpacingSelector(2)}px;
`

export const Timestamp = styled(Typography).attrs(() => ({
  variant: 'body2'
}))``

export const TimestampSkeleton = styled(
  Timestamp.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 90
}))``
