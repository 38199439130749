import React, { memo, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { PERFORMANCE_TAB_CHANNELS } from '@signal/routes'
import { PAGE_CHANNELS_LIST, APP_ATTRIBUTION } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import Header from '../../containers/AttributionHeader'
import ChannelsSummaryHeader from '../../containers/ChannelsSummaryHeader'
import ChannelsOverview from '../../containers/ChannelsOverview'
import ChannelsOverviewToolbar from '../../containers/ChannelsOverviewToolbar'
import { PageWrapper } from '../styles'
import { initialSelectedGroupSelector } from './selectors'

export const ChannelsOverviewPage = memo(() => {
  const initialSelectedGroup = useSelector(initialSelectedGroupSelector)
  const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroup)

  const initialSelectedGroupRef = useRef(initialSelectedGroup)
  useEffect(() => {
    if (initialSelectedGroup !== initialSelectedGroupRef.current) {
      setSelectedGroup(initialSelectedGroup)
      initialSelectedGroupRef.current = initialSelectedGroup
    }
  }, [initialSelectedGroup])

  usePageTrack(PAGE_CHANNELS_LIST, APP_ATTRIBUTION)

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      headerComponent={ChannelsSummaryHeader}
    >
      <ChannelsOverviewToolbar
        selectedGroup={selectedGroup}
        onSelectGroup={setSelectedGroup}
      />
      <PageWrapper>
        <ChannelsOverview
          selectedGroup={selectedGroup}
          activeTab={PERFORMANCE_TAB_CHANNELS}
        />
      </PageWrapper>
    </LayoutDefaultWithPageHeader>
  )
})

ChannelsOverviewPage.displayName = 'ChannelsOverviewPage'

export default ChannelsOverviewPage
