import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@signal/components/Typography'
import { setPriorAd, NO_AD } from '@signal/core/ad'
import { AUDIENCES_ARTICLE_URL } from '@signal/core/helpdesk'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import ExternalLink from '@signal/components/ExternalLink'
import { DESCRIPTION } from './constants'

export const AudiencesSummary = memo(function AudiencesSummary() {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => dispatch(setPriorAd(NO_AD))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <ContentSectionHeading
      title="Audiences"
      titleProps={{ variant: 'h3' }}
      description={() => (
        <Typography variant="subtitle2">
          {DESCRIPTION}{' '}
          <ExternalLink to={AUDIENCES_ARTICLE_URL}>
            Learn more about audiences.
          </ExternalLink>
        </Typography>
      )}
    />
  )
})

export default AudiencesSummary
