import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import { lighten } from '@material-ui/core/styles/colorManipulator';
export var COLOR_COMMON_BLACK = '#0a1c33';
export var COLOR_COMMON_WHITE = common.white;
export var COLOR_PRIMARY_LIGHTEST = '#e9f4f8';
export var COLOR_PRIMARY_LIGHT = '#b4e1ea';
export var COLOR_PRIMARY = '#1d8fb9';
export var COLOR_PRIMARY_DARK = '#153a59';
export var COLOR_PRIMARY_DEEP = '#eb4d3b'; // orange/red

export var COLOR_SECONDARY_LIGHT = '#e1e0e0';
export var COLOR_SECONDARY = '#59b8ce';
export var COLOR_SECONDARY_DARK = '#5b6c76';
export var COLOR_SECONDARY_DEEP = '#fbb330'; // deep yellow

export var COLOR_BACKGROUND_LIGHT = grey[400];
export var COLOR_BACKGROUND = grey[100];
export var COLOR_BACKGROUND_HEADER = lighten(COLOR_PRIMARY, 0.95);
export var COLOR_TABLE_ROW_HOVER = 'rgba(225, 224, 224, 0.25)';
export var COLOR_GREY_LIGHTEST = '#e1e0e0';
export var COLOR_GREY_ACCENT = grey[50];
export var COLOR_GREY_LIGHT = 'rgba(10, 28, 51, 0.3)';
export var COLOR_GREY = 'rgba(10, 28, 51, 0.5)';
export var COLOR_GREY_DARK = 'rgba(10, 28, 51, 0.7)';
export var COLOR_GREY_DEEP = 'rgba(10, 28, 51, 0.8)';
export var COLOR_SUCCESS = '#12b890';
export var COLOR_SUCCESS_LIGHTEST = lighten(COLOR_SUCCESS, 0.75);
export var COLOR_SUCCESS_LIGHT = '#16d1a4';
export var COLOR_SUCCESS_DARK = '#14ab87';
export var COLOR_ERROR = '#eb4d3b';
export var COLOR_ERROR_LIGHTEST = lighten(COLOR_ERROR, 0.75);
export var COLOR_ERROR_LIGHT = '#f3786a';
export var COLOR_ERROR_DARK = '#d94231';
export var COLOR_WARN_LIGHT = '#ffc459';
export var COLOR_WARN = '#fbb330';