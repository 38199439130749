import React from 'react'
import { ExportIcon } from './styles'
import IconButton from '@signal/components/IconButton'

export function ReportExporterSkeleton(): JSX.Element {
  return (
    <IconButton>
      <ExportIcon disabled />
    </IconButton>
  )
}

export default ReportExporterSkeleton
