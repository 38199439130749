function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
var initialState = {
  isLoading: false,
  error: null,
  data: null
};
/**
 * This util function creates a reducer given the request actions and manages the error states,
 * loading states, and data states.
 */

export default function createRequestReducer(actions) {
  var _reducer;

  var reducer = (_reducer = {}, _defineProperty(_reducer, actions.request, function (state) {
    return _objectSpread(_objectSpread({}, state), {}, {
      isLoading: true
    });
  }), _defineProperty(_reducer, actions.success, function (state, data) {
    return _objectSpread(_objectSpread({}, state), {}, {
      data: data,
      isLoading: false
    });
  }), _defineProperty(_reducer, actions.failure, function (state, error) {
    return _objectSpread(_objectSpread({}, initialState), {}, {
      isLoading: false,
      error: error
    });
  }), _reducer);
  return createReducer(reducer, initialState);
}
var initialStateV2 = {
  isLoaded: false,
  isLoading: false,
  error: null,
  data: null
};
/**
 * This util function creates a reducer given the request actions and manages the error states,
 * loading states, and data states.
 */

export function createRequestReducerV2(actions) {
  var _objectSpread2;

  var customReducerFunctions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var reducer = _objectSpread((_objectSpread2 = {}, _defineProperty(_objectSpread2, actions.request, function (state) {
    return _objectSpread(_objectSpread({}, state), {}, {
      error: null,
      isLoaded: false,
      isLoading: true
    });
  }), _defineProperty(_objectSpread2, actions.success, function (state, data) {
    return _objectSpread(_objectSpread({}, state), {}, {
      data: data,
      isLoaded: true,
      isLoading: false
    });
  }), _defineProperty(_objectSpread2, actions.failure, function (state, error) {
    return _objectSpread(_objectSpread({}, initialState), {}, {
      error: error
    });
  }), _objectSpread2), customReducerFunctions);

  return createReducer(reducer, initialStateV2);
}