var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { fadeInUp } from '@signal/utils/animations';
import { getColorBackgroundSelector } from '@signal/theme/selectors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: ", ";\n  &.animateIn {\n    ", "\n  }\n  // background: ", ";\n"])), function (_ref) {
  var hasAsideContent = _ref.hasAsideContent;
  return hasAsideContent ? 'normal' : 'center';
}, fadeInUp, getColorBackgroundSelector('paper'));
export var LeftWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex: 1;\n"])));
export var RightWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: flex-end;\n  flex: 1;\n"])));