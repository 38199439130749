import React, { memo } from 'react'
import { ToolbarWrapper } from './styles'

export function Toolbar({ style, className, children }) {
  return (
    <ToolbarWrapper style={style} className={className}>
      {children}
    </ToolbarWrapper>
  )
}

export default memo(Toolbar)
