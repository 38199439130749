import React from 'react'
import { Wrapper as FormControlBaseWrapper } from '@signal/components/FormControl/styles'
import FormControlTextFieldSkeleton from '@signal/components/FormControlTextField/Skeleton'
import FormControlSelectSkeleton from '@signal/components/FormControlSelect/Skeleton'
import FormControlSwitch from '@signal/components/FormControlSwitch'
import FormControlDateRange from '@signal/components/FormControlDateRange'
import ValidationIcon from '../FormControlValidationIcon'
import {
  Wrapper,
  AudienceFormControlWrapper,
  CreateAudienceButton,
  AudienceIcon
} from './styles'

import renderIf from '@signal/utils/renderIf'

const AdFormSkeleton = ({ isUniversal, ...props }) => {
  return (
    <Wrapper {...props}>
      <FormControlBaseWrapper>
        <FormControlTextFieldSkeleton
          label="What do you want to call this offer?"
          leftAdornment={<ValidationIcon />}
          placeholder="Enter a name for your offer"
          margin="dense"
        />
      </FormControlBaseWrapper>

      <FormControlBaseWrapper>
        <FormControlSelectSkeleton
          label="What kind of offer would you like to run?"
          leftAdornment={<ValidationIcon />}
          margin="dense"
        />
      </FormControlBaseWrapper>

      <FormControlBaseWrapper>
        <FormControlSelectSkeleton
          label="What type of lead should we send?"
          leftAdornment={<ValidationIcon />}
          margin="dense"
        />
      </FormControlBaseWrapper>

      <FormControlBaseWrapper>
        <FormControlSelectSkeleton
          label="Where on the site would you like the offer to run?"
          leftAdornment={<ValidationIcon />}
          margin="dense"
        />
      </FormControlBaseWrapper>

      <FormControlBaseWrapper>
        <FormControlSwitch
          label="Should this offer repeat?"
          leftAdornment={<ValidationIcon />}
          margin="dense"
        />
      </FormControlBaseWrapper>

      {renderIf(
        !isUniversal,
        <FormControlBaseWrapper>
          <AudienceFormControlWrapper>
            <FormControlSelectSkeleton
              label="Who should see this offer?"
              leftAdornment={<ValidationIcon />}
              margin="dense"
            />

            <CreateAudienceButton disabled>
              <AudienceIcon />
              Create Audience
            </CreateAudienceButton>
          </AudienceFormControlWrapper>
        </FormControlBaseWrapper>
      )}

      <FormControlBaseWrapper>
        <FormControlDateRange
          label="How long should this run?"
          leftAdornment={<ValidationIcon />}
          margin="dense"
          onDatesChange={() => {}}
        />
      </FormControlBaseWrapper>
    </Wrapper>
  )
}

export default AdFormSkeleton
