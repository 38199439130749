import React from 'react'
import DrilldownTableSkeleton from '../DrilldownTable/Skeleton'
import { TableContributionChartSkeleton } from './styles'

export class ChannelsDrilldownTableSkeleton extends React.Component {
  getColumnInfo = (detailsColumn, primaryColumn, secondaryColumns) => {
    const { showContributionChart } = this.props

    let contributionChartColumn
    if (showContributionChart) {
      contributionChartColumn = {
        key: 'contributionPercentage',
        component: TableContributionChartSkeleton,
        headerProps: {
          width: 120
        }
      }
    }

    return [
      detailsColumn,
      contributionChartColumn,
      secondaryColumns,
      primaryColumn
    ]
      .filter(Boolean)
      .reduce((columnInfo, column) => columnInfo.concat(column), [])
  }

  render() {
    return (
      <DrilldownTableSkeleton
        getColumnInfo={this.getColumnInfo}
        {...this.props}
      />
    )
  }
}

export default ChannelsDrilldownTableSkeleton
