import styled from 'styled-components'
import {
  getSpacingSelector,
  constrainToMaxPageWidth,
  navigationBarOuterHeightTabletSelector
} from '@signal/theme'

export const Wrapper = styled.div`
  background: #fff;
  height: calc(100vh - ${navigationBarOuterHeightTabletSelector}px);
  display: flex;
  flex-direction: column;
`

export const ConstrainedWrapper = styled.div`
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${getSpacingSelector(3)}px;
  > *:not(:last-of-type) {
    margin-bottom: ${getSpacingSelector(3)}px;
  }

  ${constrainToMaxPageWidth}
`

export const ContentWrapper = styled.div`
  overflow: auto;
  flex: 1;
`
