import Consumer from './Consumer';
import Context from './Context';
import Provider from './Provider';
import Root from './Root';
export var ModalManager = {
  Consumer: Consumer,
  Context: Context,
  Provider: Provider,
  Root: Root
};
export default ModalManager;
export { default as ModalManagerConsumer } from './Consumer';
export { default as ModalManagerContext } from './Context';
export { default as ModalManagerProvider } from './Provider';
export { default as ModalManagerRoot } from './Root';
export * from './hooks';