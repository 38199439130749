import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  activeBucketLabelSelector,
  activeBucketDescriptionSelector
} from '@signal/core/shoppersBucket/selectors'
import ShopperBucketDetailHeader from '../../components/ShopperBucketDetailHeader'
import ContextualPageHeader from '../../components/ContextualPageHeader'
import { crumbsSelector, bucketMetricSelector } from './selectors'

export function ShoppersBucketPageHeader({
  crumbs,
  alertMessage,
  bucket,
  bucketDescription,
  bucketMetric,
  ...rest
}) {
  return (
    <ContextualPageHeader crumbs={crumbs}>
      <ShopperBucketDetailHeader
        bucket={bucket}
        description={bucketDescription}
        metric={bucketMetric}
      />
    </ContextualPageHeader>
  )
}

const mapStateToProps = createStructuredSelector({
  crumbs: crumbsSelector,
  bucket: activeBucketLabelSelector,
  bucketDescription: activeBucketDescriptionSelector,
  bucketMetric: bucketMetricSelector
})

export default connect(mapStateToProps)(ShoppersBucketPageHeader)
