import React, { memo } from 'react'
import DatePickerSkeleton from '@signal/components/DatePicker/Skeleton'
import { DatePickerNewSkeleton } from '@signal/components/DateRangePickerNew/styles'
import renderIf from '@signal/utils/renderIf'
import { isOldDatePicker } from '../../../ab-tests'
import ReportFilterSkeleton from '../ReportFilter/Skeleton'
import { Wrapper, Content, Controls } from './styles'
import IconButton from '@signal/components/IconButton'
import ReportExporterSkeleton from '../ReportExporter/Skeleton'
import ReportPrinterSkeleton from '../ReportPrinter/Skeleton'

export const ReportHeaderSkeleton = function ReportHeaderSkeleton() {
  return (
    <Wrapper constrainWidth={false} fixed>
      <Content>
        <Controls>
          <ReportExporterSkeleton />
          <ReportPrinterSkeleton />
          <ReportFilterSkeleton />
          {renderIf(
            !isOldDatePicker(),
            () => (
              <IconButton>
                <DatePickerNewSkeleton />
              </IconButton>
            ),
            () => (
              <DatePickerSkeleton />
            )
          )}
        </Controls>
      </Content>
    </Wrapper>
  )
}

export default memo(ReportHeaderSkeleton)
