import React, { Fragment } from 'react'
import { FeaturedMetricSkeleton, DataGridSkeleton } from './styles'

export const OverviewSnapshotSkeleton = () => (
  <Fragment>
    <FeaturedMetricSkeleton />
    <DataGridSkeleton items={3} />
  </Fragment>
)

export default OverviewSnapshotSkeleton
