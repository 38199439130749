import styled from 'styled-components'
import Typography from '@signal/components/Typography'

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`

export const Description = styled(Typography).attrs(() => ({
  variant: 'subtitle2',
  paragraph: true
}))``
