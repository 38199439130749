import styled from 'styled-components'
import AddIcon from '@signal/components/Icons/Add'
import ButtonMenu from '@signal/components/ButtonMenu'
import { getSpacingSelector } from '@signal/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const FormControlWrapper = styled.div`
  min-width: 300px;
`

export const AddSegmentButton = styled(ButtonMenu).attrs(() => ({
  color: 'primary',
  variant: 'contained'
}))`
  margin-left: ${getSpacingSelector(2)}px;
`

export const AddSegmentIcon = styled(AddIcon)`
  margin-right: ${getSpacingSelector(2.5)}px;
`
