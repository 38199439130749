import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
/**
 * The options propType is an array of complex propTypes that are flexible
 * and can be different from option to option.
 *
 * At a high level, there are two basic types that an `option` can be.
 * 1) a string
 * 2) an object
 *
 * Examples:
 * legal: ['test1', 'test2']
 * legal: ['test1', { label: 'test2', key: 2 }]
 * legal: [{ label: 'test1', key: 1 }, { label: 'test2', key: 2 }]
 * Everything else is illegal.
 *
 * In the case that an option is an object, there is flexibility in the
 * properties that it has. Below is a list of possible properties.
 *
 * {
 *   [labelBy] - required - {String} must contain a property keyed by the `labelBy` prop
 *   [keyBy] - required - {String|Number} must contain a property keyed by the `keyBy` prop
 *   component - optional - {Function|Object} can optionally define a component to pass to the ListItem
 *   componentProps - optional - {Object} can contain an object of props that is spread to the ListItem
 * }
 */

export var optionsPropType = function optionsPropType(props, propName, componentName) {
  var labelBy = props.labelBy,
      keyBy = props.keyBy,
      options = props.options;
  if (!options) return;
  var error;
  options.some(function (option, i) {
    // 1) an option as a string is legal
    if (isString(option)) return false; // 2) non objects are illegal

    if (!isObject(option)) {
      error = "Invalid prop `".concat(propName, "[").concat(i, "]` is supposed to be a string or object, instead got `").concat(typeof option, ".`");
      return true;
    } // all options should now be objects
    // 3) the option should contain the labelBy property


    var label = option[labelBy];

    if (!isString(label)) {
      error = "Invalid prop `".concat(propName, "[").concat(i, "].").concat(labelBy, "` is supposed to be a string, instead got `").concat(typeof label, ".`");
      return true;
    } // 4) the option should contain the keyBy property


    var key = option[keyBy];

    if (!isString(key) && !isNumber(key)) {
      error = "Invalid prop `".concat(propName, "[").concat(i, "].").concat(keyBy, "` is supposed to be a string or number, instead got `").concat(typeof key, ".`");
      return true;
    } // an option can specify a component to use


    var component = option.component;
    if (!component) return false; // 5) can contain a component prop that is a function or object

    if (!isFunction(component) && !isObject(component)) {
      error = "Invalid prop `".concat(propName, "[").concat(i, "].component` is supposed to be a function or object, instead got `").concat(typeof component, ".`");
      return true;
    } // 6) can contain an object of componentProps that will be spread to the component


    var componentProps = option.componentProps;

    if (componentProps && !isObject(componentProps)) {
      error = "Invalid prop `".concat(propName, "[").concat(i, "].componentProps` is supposed to be an object, instead got `").concat(typeof componentProps, ".`");
      return true;
    }

    return false;
  });
  if (error) return new Error(error);
};