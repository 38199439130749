import styled from 'styled-components'
import {
  paletteDividerSelector,
  getColorBackgroundSelector,
  getSpacingSelector,
  pageToolbarZIndexSelector
} from '@signal/theme/selectors'

export const ToolbarWrapper = styled.div`
  border-top: solid 1px ${paletteDividerSelector};
  border-bottom: solid 1px ${paletteDividerSelector};
  display: flex;
  justify-content: center;
  padding: ${getSpacingSelector()}px;
  background: ${getColorBackgroundSelector('paper')};
  position: sticky;
  z-index: ${pageToolbarZIndexSelector};
`
