var _createReducer;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import omit from 'lodash/omit';
import get from 'lodash/get';
import parseDate from '@signal/utils/parseDate';
import { fetchAllSpendActions, changeDate, updateSpend, updateRepeatMonthly, updateChannelActions } from './actions';
var now = parseDate();
export var initialState = {
  loading: false,
  loaded: false,
  error: null,

  /**
   * channelsUpdating is an object that will contain a key value pair represented by a channel's
   * spend key and a counting semaphore (respectively) that increments as requests for that channel are made and decrements
   * as they resolve.
   */
  channelsUpdating: {},
  channels: null,

  /**
   * The date that the data corresponds to - the day is ignored - only the month and year matter
   */
  dateContext: now.toDateString()
};

function incrementChannelSemaphore(channelsUpdating, key) {
  return _objectSpread(_objectSpread({}, channelsUpdating), {}, _defineProperty({}, key, get(channelsUpdating, key, 0) + 1));
}

function decrementChannelSemaphore(channelsUpdating, key) {
  var count = channelsUpdating[key];
  return count === 1 ? omit(channelsUpdating, key) : _objectSpread(_objectSpread({}, channelsUpdating), {}, _defineProperty({}, key, count - 1));
}

export default createReducer((_createReducer = {}, _defineProperty(_createReducer, changeDate, function (state, dateContext) {
  return _objectSpread(_objectSpread({}, state), {}, {
    dateContext: dateContext
  });
}), _defineProperty(_createReducer, fetchAllSpendActions.request, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: true,
    loaded: false,
    error: null
  });
}), _defineProperty(_createReducer, fetchAllSpendActions.success, function (state, channels) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    loaded: true,
    error: null,
    channels: channels
  });
}), _defineProperty(_createReducer, fetchAllSpendActions.failure, function (state, error) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    loaded: false,
    error: error
  });
}), _defineProperty(_createReducer, updateSpend, function (state, _ref) {
  var key = _ref.key,
      value = _ref.value;
  return _objectSpread(_objectSpread({}, state), {}, {
    channels: state.channels.map(function (channel) {
      return channel.key !== key ? channel : _objectSpread(_objectSpread({}, channel), {}, {
        spend: value
      });
    })
  });
}), _defineProperty(_createReducer, updateRepeatMonthly, function (state, _ref2) {
  var key = _ref2.key,
      repeat = _ref2.repeat;
  return _objectSpread(_objectSpread({}, state), {}, {
    channels: state.channels.map(function (channel) {
      return channel.key !== key ? channel : _objectSpread(_objectSpread({}, channel), {}, {
        repeat: repeat
      });
    })
  });
}), _defineProperty(_createReducer, updateChannelActions.request, function (state, key) {
  return _objectSpread(_objectSpread({}, state), {}, {
    channelsUpdating: incrementChannelSemaphore(state.channelsUpdating, key)
  });
}), _defineProperty(_createReducer, updateChannelActions.success, function (state, key) {
  return _objectSpread(_objectSpread({}, state), {}, {
    channelsUpdating: decrementChannelSemaphore(state.channelsUpdating, key)
  });
}), _defineProperty(_createReducer, updateChannelActions.failure, function (state, _ref3) {
  var key = _ref3.key;
  return _objectSpread(_objectSpread({}, state), {}, {
    channelsUpdating: decrementChannelSemaphore(state.channelsUpdating, key)
  });
}), _createReducer), initialState);