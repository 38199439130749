export var BOTTOM_NAVIGATION_HEIGHT = 56;
export var BOTTOM_NAVIGATION_HEIGHT_LANDSCAPE = 42;
export var MAX_PAGE_WIDTH = 1024;
/* NavigationBar */

export var NAVIGATION_BAR_BORDER_WIDTH = 1;
export var NAVIGATION_BAR_HEIGHT = 56;
export var NAVIGATION_BAR_HEIGHT_TABLET = 64;
export var NAVIGATION_BAR_HEIGHT_LANDSCAPE = 48;
/* ContentSectionHeading */

export var CONTENT_SECTION_HEADING_TITLE_TYPOGRAPHY_VARIANT = 'h5';
export var CONTENT_SECTION_HEADING_TITLE_SPACING = [2.5, 0, 2, 0];
export var CONTENT_SECTION_HEADING_TITLE_SPACING_BOTTOM = 2;