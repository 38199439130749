import React from 'react'
import AreaChartSkeleton from '../../components/AreaChart/Skeleton'
import { Wrapper, ChartWrapper, LegendSkeleton } from './styles'

export function ChannelsDetailChartSkeleton() {
  return (
    <Wrapper>
      <LegendSkeleton />
      <ChartWrapper>
        <AreaChartSkeleton />
      </ChartWrapper>
    </Wrapper>
  )
}

export default ChannelsDetailChartSkeleton
