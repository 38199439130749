import styled from 'styled-components'
import Typography from '@signal/components/Typography'
import { getSpacingSelector } from '@signal/theme/selectors'

export const ToolbarControlLabel = styled(Typography).attrs(() => ({
  variant: 'body1'
}))`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-right: ${getSpacingSelector(1)}px;
`

export const ToolbarControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${getSpacingSelector(3)}px;

  &:last-of-type {
    margin-right: 0;
  }
`
