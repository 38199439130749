import { useCallback } from 'react';
/**
 * remember the 2020 PureCars front-end team interview question about breaking out of ref closures?
 * This is a convenient hook to get a callback that is updated via a React ref, so it is 100% guaranteed to render with
 * the latest data from the component in which this callback was bound.
 *
 * Example usage:
 *
 * import React, { useRef } from 'react'
 * import { useCallbackRef } from '@signal/core-hooks'
 *
 * const myHandlerRef = useRef()
 * const myHandler = useCallbackRef(myHandlerRef, () => { ... your code here, which is freed from the closure ... })

 * @param {React.Ref} ref - a React reference created by calling React's useRef
 * @param {Function} func - the callback handling function you would like to unbound from any closure
 * @return {Function} - a function that you can pass as a handler to another component.  It will automatically call
 *   the most recent version of the function created
 */

export function useCallbackRef(ref, func) {
  ref.current = func;
  return useCallback(function () {
    return ref.current.apply(ref, arguments);
  }, [ref]);
}
export default useCallbackRef;