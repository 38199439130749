import React, { memo } from 'react'
import get from 'lodash/get'
import { PAGE_AUDIENCES_LIST_EDIT, APP_AUDIENCES } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import { useBodyBackgroundColor } from '@signal/theme'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import Header from '../../containers/AudiencesHeader'
import AudienceEditSummary from '../../containers/AudienceEditSummary'
import AudienceDetailForm from '../../containers/AudienceDetailForm'
import AudienceDetailHeader from '../../containers/AudienceDetailHeader'
import { PageWrapper } from '../styles'

export const AudienceDetailPage = memo(props => {
  const id = get(props, 'match.params.audienceId')
  usePageTrack(PAGE_AUDIENCES_LIST_EDIT, APP_AUDIENCES)
  useBodyBackgroundColor('light')

  return (
    <LayoutDefaultWithPageHeader
      headerComponent={AudienceDetailHeader}
      navbar={Header}
    >
      <PageWrapper>
        <AudienceEditSummary id={id} />
        <AudienceDetailForm id={id} />
      </PageWrapper>
    </LayoutDefaultWithPageHeader>
  )
})

AudienceDetailPage.displayName = 'AudienceDetailPage'

export default AudienceDetailPage
