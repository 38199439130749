var _createReducer;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import without from 'lodash/without';
import isObject from 'lodash/isObject';
import { showModal, dismissModal, setActiveModal } from './actions';
export var initialState = {
  requests: [],
  active: null
};
/**
 *
 * @param {Array} requests
 * @param {Object} showModalRequest
 *
 * This function prevents duplicate requests by checking the supplied name. If no name is provided,
 * duplicate prevention will not happen.
 */

function getRequests(requests, showModalRequest) {
  var hasModalRequest = isObject(requests.find(function (_ref) {
    var name = _ref.name;
    return name === showModalRequest.name;
  }));
  return hasModalRequest ? requests : requests.concat(showModalRequest);
}

export default createReducer((_createReducer = {}, _defineProperty(_createReducer, showModal, function (state, showModalRequest) {
  return _objectSpread(_objectSpread({}, state), {}, {
    requests: getRequests(state.requests, showModalRequest)
  });
}), _defineProperty(_createReducer, dismissModal, function (_ref2) {
  var requests = _ref2.requests,
      active = _ref2.active;
  return {
    active: null,
    requests: without(requests, active)
  };
}), _defineProperty(_createReducer, setActiveModal, function (state, active) {
  return _objectSpread(_objectSpread({}, state), {}, {
    active: active
  });
}), _createReducer), initialState);