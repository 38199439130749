import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () => import(/* webpackChunkName: "ad-detail-form" */ './component'),
  dataLoader: {
    audience: () =>
      import(/* webpackChunkName: "lib-audience" */ '@signal/core/audience'),
    ad: () => import(/* webpackChunkName: "lib-ad" */ '@signal/core/ad')
  },
  loadData: (
    { audience: { audiencesLoader }, ad: { adDetailsLoader, adPhotosLoader } },
    loaderInterface
  ) =>
    Promise.all([
      audiencesLoader(loaderInterface),
      adDetailsLoader(loaderInterface),
      adPhotosLoader(loaderInterface)
    ]),
  loading: Skeleton
})
