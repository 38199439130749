import { createSelector } from 'reselect';
/**
 * Selector that takes in a (styled-component) component's props and returns
 * it's root theme object.
 *
 * @param {Object} props - component props
 * @return {Object} - the component's theme
 */

export function themeRootSelector(props) {
  // If used directly in a styled component, assume that props.theme is the
  // styled components theme, if it doesn't exist, assume props is the theme.
  return props.theme || props;
}
/**
 * Takes one or more selectors, or an array of selectors, computes their values
 * and passes them as arguments to `resultFunc`.
 *
 * When used in a (styled-component) component's style block, the input to the
 * selector will be the theme applied to the component. This allows you to create
 * reusable (and composable) selectors that retrieve values from the theme for
 * use in the component's style definitions.
 *
 * @param {...Function|Function[]} inputSelectors - one or more functions that
 * are passed a theme as their only argument and return a value from, or based
 * on, that theme
 * @param {Function} resultFunc - accepts inputSelector computed values as
 * arguments and uses them to return a single, final result value
 * @return {Function} - selector that derives a value from a (styled-component)
 * component's theme
 */

export function createThemeSelector() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  if (args.length === 1) {
    // no input selectors were provided, default to selecting the whole theme
    args.unshift(function (theme) {
      return theme;
    });
  }

  var themeValueSelector = createSelector.apply(void 0, args);
  return createSelector(themeRootSelector, // the input of the "themeValueSelector" will be the MUI theme
  themeValueSelector);
}
/**
 * A memoized selector creator that caches created selector variations
 * @typedef {Function} VariableThemeSelectorCreator
 */

/**
 * Creates an optimized theme selector creator that can be used to create
 * variable/dynamic selectors that require a fixed, limited number of potential
 * input variables.
 *
 * The selectors are cached based on the input variables and
 * recycled where possible to take full advantage of the memoization provided
 * by reselect.
 *
 * @param {Function} createSelectorVariation
 * Returns a selector variation for the given input variables
 * @param {Function} generateCacheKey
 * Function that generates the cache key for a selector variation based on the
 * input variables. The default cache key generator creates a cache key that
 * starts with the string "default-" and is followed by all input variable
 * values, each separated by a "-".
 *
 * Examples:
 * generateCacheKey(400) // returns "default-400"
 * generateCacheKey('test', 'test2') // returns "default-test-test2"
 * @return {VariableThemeSelectorCreator}
 */

export function createVariableThemeSelectorCreator(createSelectorVariation) {
  var generateCacheKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
    for (var _len2 = arguments.length, inputVars = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      inputVars[_key2] = arguments[_key2];
    }

    return inputVars.reduce(function (_cacheKey, inputVar) {
      return "".concat(_cacheKey, "-").concat(inputVar);
    }, 'default');
  }; // Cache for selector variations

  var variableThemeSelectorsCache = {}; // Function that returns a cached selector variation for the input variable(s)
  // provided, if that variation already exists, or returns (and caches) a new
  // selector variation for the given input variable(s).

  return function () {
    var cacheKey = generateCacheKey.apply(void 0, arguments);
    var selectorVariation = variableThemeSelectorsCache[cacheKey];

    if (!selectorVariation) {
      // if variation is not already cached, create it and add it to the cache
      selectorVariation = createSelectorVariation.apply(void 0, arguments);
      variableThemeSelectorsCache[cacheKey] = selectorVariation;
    }

    return selectorVariation;
  };
}