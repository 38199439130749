import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "overview-snapshot" */ './component'),
  dataLoader: () =>
    import(/* webpackChunkName: "lib-auth" */ '@signal/core/auth'),
  loadData: ({ authLoader }, loaderInterface) => authLoader(loaderInterface),
  loading: Skeleton
})
