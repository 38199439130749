import React from 'react'
import componentPropType from '@signal/prop-types/component'
import DrilldownTableSkeleton from '../DrilldownTable/Skeleton'
import { Wrapper, SectionHeadingSkeleton } from './styles'

export const DrilldownSectionSkeleton = ({
  drilldownTableComponent: DrilldownTableSkeletonComponent,
  rightContent,
  className,
  style,
  ...rest
}) => (
  <Wrapper className={className} style={style}>
    <SectionHeadingSkeleton
      subtitle={false}
      description={false}
      rightContent={rightContent}
    />
    <DrilldownTableSkeletonComponent {...rest} />
  </Wrapper>
)

DrilldownSectionSkeleton.propTypes = {
  drilldownTableComponent: componentPropType
}

DrilldownSectionSkeleton.defaultProps = {
  drilldownTableComponent: DrilldownTableSkeleton
}

export default DrilldownSectionSkeleton
