import { createSelector } from 'reselect'
import {
  getColorSecondarySelector,
  getColorBackgroundSelector,
  getSpacingSelector
} from '@signal/theme/selectors'

export const LIGHT = {
  background: getColorBackgroundSelector('paper'),
  text: {
    color: 'textPrimary'
  }
}

export const DARK = {
  background: getColorBackgroundSelector(),
  text: {
    color: 'textSecondary',
    background: getColorSecondarySelector()
  },
  image: {
    align: 'flex-end',
    offset: {
      bottom: createSelector(getSpacingSelector(4), offset => -offset)
    }
  }
}
