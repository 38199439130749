var createStorage = function createStorage(provider) {
  return {
    get: function get(key, defaultValue) {
      var json = provider.getItem(key); // eslint-disable-next-line no-nested-ternary

      return json === null ? typeof defaultValue === 'function' ? defaultValue() : defaultValue : JSON.parse(json);
    },
    set: function set(key, value) {
      provider.setItem(key, JSON.stringify(value));
    }
  };
};

export default createStorage;