var _PERMISSION_LEVEL_MAS;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import find from 'lodash/find';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
export var DASHBOARD_FEATURE_KEY = 'meridianDashboard';
export var INDUSTRY_REPORTS_FEATURE_KEY = 'industryReports';
export var PERFORMANCE_FEATURE_KEY = 'performance';
export var ACTIVATE_FEATURE_KEY = 'activate';
export var LOCAL_SEARCH_FEATURE_KEY = 'meridianDashboard';
export var VALUE_INTELLIGENCE_FEATURE_KEY = 'meridianDashboard';
export var DEALER_PRO_FEATURE_KEY = 'dealerPro';
export var PERMISSION_LEVEL_READ = 'read';
export var PERMISSION_LEVEL_WRITE = 'write';
export var PERMISSION_LEVEL_ADMIN = 'admin';
export var PERMISSION_LEVEL_DENY = 'deny';
var PERMISSION_LEVEL_MASKS = (_PERMISSION_LEVEL_MAS = {}, _defineProperty(_PERMISSION_LEVEL_MAS, PERMISSION_LEVEL_DENY, 0x0), _defineProperty(_PERMISSION_LEVEL_MAS, PERMISSION_LEVEL_READ, 0x1), _defineProperty(_PERMISSION_LEVEL_MAS, PERMISSION_LEVEL_WRITE, 0x3), _defineProperty(_PERMISSION_LEVEL_MAS, PERMISSION_LEVEL_ADMIN, 0x7), _PERMISSION_LEVEL_MAS);
export var hasPermission = function hasPermission(accessibleFeatures, _ref) {
  var neededFeature = _ref.feature,
      neededLevel = _ref.level;
  var neededLevelMask = PERMISSION_LEVEL_MASKS[neededLevel];

  if (isUndefined(neededLevelMask)) {
    throw new Error("hasPermission requested invalid level type '".concat(neededLevel, "' for '").concat(neededFeature, "'"));
  }

  var accessibleFeature = find(accessibleFeatures, function (featureCur) {
    return featureCur.feature === neededFeature;
  });
  var accessibleLevel = get(accessibleFeature, 'level');
  var accessibleLevelMask = PERMISSION_LEVEL_MASKS[accessibleLevel] || 0;

  if (isUndefined(accessibleLevelMask)) {
    throw new Error("hasPermission saw invalid level type '".concat(accessibleLevel, "' for '").concat(neededFeature, "' in accessibleFeatures"));
  }

  return (accessibleLevelMask & neededLevelMask) === neededLevelMask;
};
/**
 * This function checks the input features to see if it contains all the required permissions passed
 * in as the second argument
 *
 * @param {Array} accessibleFeatures the account features, taken from the active account object
 * @param {Array} neededPermissions an array of permission objects in the form of {feature, permission}
 * for example, { feature: 'performance', permission: 'write' }. When possible, use the constants
 * defined at the top of this file
 */

export var hasAllPermissions = function hasAllPermissions(accessibleFeatures, neededPermissions) {
  return !neededPermissions || neededPermissions.reduce(function (hasAllPermissions, permissionCur) {
    return hasAllPermissions && hasPermission(accessibleFeatures, permissionCur);
  }, true);
};