import styled from 'styled-components'
import { getSpacingSelector } from '@signal/theme/selectors'
import ShopperIcon from '@signal/components/Icons/Shopper'
import Button from '@signal/components/Button'

export const Wrapper = styled.div``

export const AudienceFormControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const CreateAudienceButton = styled(Button).attrs(() => ({
  variant: 'contained',
  color: 'primary'
}))`
  margin-left: ${getSpacingSelector(2)}px;
`

export const AudienceIcon = styled(ShopperIcon)`
  margin-right: ${getSpacingSelector(2)}px;
`
