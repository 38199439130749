var _orientationMap, _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import styled from 'styled-components';
import Typography from '../Typography';
import { getSpacingSelector } from '@signal/theme/selectors';
export var ORIENTATIONS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical'
};
var orientationMap = (_orientationMap = {}, _defineProperty(_orientationMap, ORIENTATIONS.HORIZONTAL, 'row'), _defineProperty(_orientationMap, ORIENTATIONS.VERTICAL, 'column'), _orientationMap);

var getFlexDirection = function getFlexDirection(orientation) {
  return orientationMap[orientation] || ORIENTATIONS.HORIZONTAL;
};

var getMarginSpacing = function getMarginSpacing(marginProp) {
  return function (props) {
    return getSpacingSelector(props[marginProp]);
  };
};

export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n  flex-direction: ", ";\n  flex-wrap: wrap;\n  margin-bottom: -", "px;\n"])), function (_ref) {
  var orientation = _ref.orientation;
  return getFlexDirection(orientation);
}, getSpacingSelector());
export var Series = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: inline-flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-right: ", "px;\n  margin-bottom: ", "px;\n"])), getMarginSpacing('seriesMarginSpacing'), getSpacingSelector());
export var BadgeWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: center;\n  display: inline-flex;\n"])));
export var RightContent = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var Color = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  flex-shrink: 0;\n  height: 18px;\n  width: 18px;\n  border-radius: 2px;\n  background: ", ";\n  margin-right: ", "px;\n  ", "\n"])), function (props) {
  return props.disabled ? 'transparent' : props.color;
}, getMarginSpacing('marginSpacing'), function (props) {
  return props.disabled ? "border: 1px solid ".concat(props.theme.palette.grey[400], ";") : '';
});
export var Label = styled(Typography)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));