export var LOCAL_STORAGE_TOKEN_KEY = 'pc_authToken'; // this is the name used by redux-localstorage-simple for the local redux store.

export var LOCAL_STORAGE_SIGNAL_DATA = 'signal-local_local';
export var REFRESH_WINDOW = 1000 * 60 * 15; // Refresh 15 min before token expires

export var CMD_CREATE_SESSION = 'createAuthSession';
export var CMD_DESTROY_SESSION = 'destroyAuthSession';
export var AUTH_POLICY = 'B2C_1_Meridian';
/**
 * Action Types
 */

export var AUTH_REQUEST = 'AUTH/REQUEST';
export var AUTH_SUCCESS = 'AUTH/SUCCESSFUL';
export var AUTH_FAILURE = 'AUTH/FAILURE';
export var AUTH_LOGOUT = 'AUTH/LOGOUT';
export var SET_ACTIVE_ACCOUNT = 'AUTH/SET_ACTIVE_ACCOUNT';
export var PC_AUTH_COOKIE = 'pc-auth';
export var PC_AUTH_COOKIE_QA = 'pc-auth-qa';