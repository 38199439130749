function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import cloneDeep from 'lodash/cloneDeep';
import createRequestActions, { REQUEST_ACTION_TYPES } from '@signal/utils/createRequestActions';
export var performanceVinDataActions = createRequestActions('PERFORMANCE_CHANNELS_VIN/DATA', _defineProperty({}, REQUEST_ACTION_TYPES.SUCCESS, function (defineActionReducers) {
  return defineActionReducers(function (payload) {
    var payloadClone = cloneDeep(payload); // TODO: Sorry..... this can be removed once the api is updated and returns percentage values as decimals
    // that should be multiplied by 100 instead of as whole numbers already multiplied by 100.

    payloadClone.data.channelAttribution.channels = payloadClone.data.channelAttribution.channels.map(function (_ref) {
      var values = _ref.values,
          rest = _objectWithoutProperties(_ref, ["values"]);

      return _objectSpread(_objectSpread({}, rest), {}, {
        values: values.map(function (value) {
          return value.format === 'Percent' ? _objectSpread(_objectSpread({}, value), {}, {
            value: value.value / 100
          }) : value;
        })
      });
    });
    return payloadClone;
  });
}));
var performanceVinDataLoad = performanceVinDataActions.load,
    performanceVinDataRequest = performanceVinDataActions.request,
    performanceVinDataSuccess = performanceVinDataActions.success,
    performanceVinDataFailure = performanceVinDataActions.failure;
export { performanceVinDataLoad, performanceVinDataRequest, performanceVinDataSuccess, performanceVinDataFailure };