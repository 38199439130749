import { createSelector, createStructuredSelector } from 'reselect'
import { matchPath } from 'react-router-dom'
import isObject from 'lodash/isObject'
import { performancePathSelector } from '@signal/core/router/selectors'
import { locationPathnameSelector } from '@signal/core/selectors'
import { SHOW_ALL } from '../../core/channels/constants'

export const createPerformanceSubPathSelector = subPath =>
  createSelector(
    performancePathSelector,
    performancePath => `${performancePath}${subPath}`
  )

export const performanceChannelsPathSelector = createSelector(
  createPerformanceSubPathSelector('/channels'),
  pathname => ({
    pathname,
    state: {
      selectedGroup: SHOW_ALL
    }
  })
)
export const performanceInventoryPathSelector = createPerformanceSubPathSelector(
  '/inventory'
)
export const performanceShoppersPathSelector = createPerformanceSubPathSelector(
  '/shoppers'
)

export const navigationTabsSelector = createStructuredSelector({
  Overview: performancePathSelector,
  Channels: performanceChannelsPathSelector,
  Inventory: performanceInventoryPathSelector,
  Shoppers: performanceShoppersPathSelector
})

export const createTab = ([label, to]) => ({ to, label })

export const tabsSelector = createSelector(
  navigationTabsSelector,
  navigationTabs => Object.entries(navigationTabs).map(createTab)
)

export const activeTabIndexSelector = createSelector(
  locationPathnameSelector,
  tabsSelector,
  (path, tabs) => {
    const [firstTab, ...otherTabs] = tabs
    /**
     * This is a bit of a hack currently, the first tab is technically
     * a parent route to all of the other tabs, but we don't want to highlight
     * it when the subroutes are active. However, for the other routes, we do
     * want the tab to be active when a subroute is active.
     */
    const firstTabMatch = matchPath(path, { path: firstTab.to })
    if (firstTabMatch && firstTabMatch.isExact) return 0 // the first tab index
    const isOtherTabIndex = otherTabs.findIndex(({ to }) =>
      matchPath(path, { path: isObject(to) ? to.pathname : to })
    )
    /**
     * False denotes that no tabs path's matched. Add one to the index account
     * for the removal of the first element
     */
    return isOtherTabIndex >= 0 ? isOtherTabIndex + 1 : false
  }
)
