var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getSpacingSelector } from '@signal/theme/selectors';
import { clearfix } from '@signal/theme/mixins';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), clearfix);
export var Summary = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin: ", "px 0;\n"])), getSpacingSelector(2));
export var Details = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding-top: ", "px;\n  padding-bottom: ", "px;\n"])), getSpacingSelector(1), getSpacingSelector(3));