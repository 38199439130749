import { createSelector } from 'reselect';
import get from 'lodash/get';
import select from '@signal/utils/select';
import parseDate from '@signal/utils/parseDate';
import { partialPerformanceShoppersBucketPathSelector, performanceShoppersPathSelector } from '../router/selectors';
import { DAYS_FROM_SALE_DESCRIPTIONS, BUCKETS, LABELS } from '../shoppersBucket/constants';
import { SHOPPER_FOCUS_TYPE_COLORS, SHOPPER_FOCUS_TYPE_LABELS, FOCUS_TYPES, DEFAULT_TIME_RANGE_LABEL } from './constants';
export var rootStateSelector = function rootStateSelector(state) {
  return state.shoppers;
};
export var dataSelector = createSelector(rootStateSelector, select('data'));
export var isLoadedSelector = createSelector(dataSelector, function (data) {
  return !!data;
});
export var isLoadingSelector = createSelector(rootStateSelector, select('loading'));
export var lastUpdatedSelector = createSelector(dataSelector, function (_ref) {
  var lastUpdate = _ref.lastUpdate;
  return parseDate(lastUpdate);
});
export var totalUsersSelector = createSelector(dataSelector, select('totalUsers')); // We've already been burned from the api returning the buckets
// in an undesireable order, this logic will enforce that order.

export var orderBuckets = function orderBuckets(buckets) {
  return BUCKETS.map(function (orderedBucket) {
    var bucketDetails = buckets.find(function (_ref2) {
      var bucket = _ref2.class;
      return bucket.toLowerCase() === orderedBucket;
    });
    if (bucketDetails) return bucketDetails; // If bucket not found, assume 0 users in said bucket.

    return {
      class: LABELS[orderedBucket],
      users: 0
    };
  });
};
/**
 * This selector returns the buckets in a specific order drive by the
 * BUCKETS constants. This prevents anything from breaking if the
 * api ever changes the order of the data. Which I have already experienced.
 */

export var bucketsSelector = createSelector(dataSelector, function (data) {
  var buckets = get(data, 'buckets', []);
  return buckets.length === 0 ? buckets : orderBuckets(buckets);
});
export var bucketLabelsSelector = function bucketLabelsSelector() {
  return DAYS_FROM_SALE_DESCRIPTIONS;
};
export var focusTypeKeysSelector = function focusTypeKeysSelector() {
  return FOCUS_TYPES;
};
export var focusTypeColorsSelector = function focusTypeColorsSelector() {
  return SHOPPER_FOCUS_TYPE_COLORS;
};
export var focusTypeLabelsSelector = function focusTypeLabelsSelector() {
  return SHOPPER_FOCUS_TYPE_LABELS;
};
export var focusTypesSelector = createSelector(focusTypeKeysSelector, focusTypeColorsSelector, focusTypeLabelsSelector, function (types, colors, labels) {
  return types.map(function (type) {
    return {
      label: labels[type],
      color: colors[type]
    };
  });
});
export var getBucketDetailsSelector = function getBucketDetailsSelector(bucketsBreakdownSelector) {
  return createSelector(bucketsBreakdownSelector, bucketLabelsSelector, partialPerformanceShoppersBucketPathSelector, function (bucketsBreakdown, labelsMap, createPath) {
    return bucketsBreakdown.map(function (_ref3) {
      var bucketName = _ref3.class,
          users = _ref3.users;
      return {
        metric: users,
        title: bucketName,
        subtitle: labelsMap[bucketName.toLowerCase()],
        bucket: bucketName.toLowerCase(),
        to: createPath(bucketName.toLowerCase())
      };
    });
  });
};
export var bucketDetailsSelector = getBucketDetailsSelector(bucketsSelector);
export var shoppersBreadcrumbSelector = createSelector(performanceShoppersPathSelector, function (to) {
  return {
    label: 'Shoppers',
    to: to
  };
});
export var timeRangeSelector = function timeRangeSelector() {
  return DEFAULT_TIME_RANGE_LABEL;
};