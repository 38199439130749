import loadable from '@signal/hoc/loadable'
import Skeleton from '../../components/AudienceListGroup/Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "audience-list-group" */ './component'),
  dataLoader: () =>
    import(/* webpackChunkName: "lib-audience" */ '@signal/core/audience'),
  loadData: ({ audiencesLoader }, loaderInterface) =>
    audiencesLoader(loaderInterface),
  loading: Skeleton
})
