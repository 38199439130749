import React, { Fragment } from 'react'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import Button from '@signal/components/Button'
import Typography from '@signal/components/Typography'
import InventoryDrilldownTableSkeleton from '../../components/InventoryDrilldownTable/Skeleton'
import { ColumnsWrapper, Column, TimestampSkeleton, Footer } from './styles'

export class OverviewTopInventory extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <ContentSectionHeading
          title={
            <Typography variant="h3" color="textDark">
              Current Snapshot
            </Typography>
          }
          description={
            <Typography variant="subtitle2">
              The data below represents a current snapshot of your inventory
              health and shopper activity today. Makes and models are given an
              engagement score to represent your heaviest stocked makes and
              models.
            </Typography>
          }
        />
        <div>
          <ColumnsWrapper>
            <Column>
              <ContentSectionHeading title="Top Makes" />
              <InventoryDrilldownTableSkeleton
                countSecondaryValues={0}
                disableHeader
                showAvatar
              />
            </Column>
            <Column>
              <ContentSectionHeading title="Top Models" />
              <InventoryDrilldownTableSkeleton
                countSecondaryValues={0}
                disableHeader
                showImage
              />
            </Column>
          </ColumnsWrapper>
          <Footer>
            <TimestampSkeleton />
            <Button variant="contained" color="primary" disabled>
              View Inventory
            </Button>
          </Footer>
        </div>
      </Fragment>
    )
  }
}

export default OverviewTopInventory
