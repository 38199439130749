import React from 'react'
import ChannelsVinSummarySkeleton from '../ChannelsVinSummary/Skeleton'
import ChannelsVinAppearancesSkeleton from '../ChannelsVinAppearances/Skeleton'
import { WrapperSkeleton } from './styles'

export function ChannelsVinHeaderSkeleton() {
  return (
    <WrapperSkeleton showCrumbs>
      <ChannelsVinSummarySkeleton />
      <ChannelsVinAppearancesSkeleton />
    </WrapperSkeleton>
  )
}

export default ChannelsVinHeaderSkeleton
