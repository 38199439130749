import { createSelector } from 'reselect';
import select from '@signal/utils/select';
import parseDate from '@signal/utils/parseDate';
import { REDUCER_KEY, TOP_CHANNELS_DATE_RANGE_LABEL, TOP_INVENTORY_DATE_RANGE_LABEL, SHOPPER_ACTIVITY_DATE_RANGE_LABEL } from './constants';
import { manageSpendPathSelector } from '../router/selectors';
export var rootSelector = select(REDUCER_KEY);
export var isLoadingSelector = createSelector(rootSelector, select('loading'));
export var isLoadedSelector = createSelector(rootSelector, select('loaded'));
export var dataSelector = createSelector(rootSelector, select('data'));
export var startDateRawSelector = createSelector(dataSelector, select('startDate'));
export var startDateSelector = createSelector(startDateRawSelector, parseDate);
export var endDateRawSelector = createSelector(dataSelector, select('endDate'));
export var endDateSelector = createSelector(startDateSelector, parseDate);
export var topChannelsSelector = createSelector(dataSelector, select('topChannels', []));
export var topMakesSelector = createSelector(dataSelector, select('topMakes', []));
export var topModelsSelector = createSelector(dataSelector, select('topModels', []));
export var salesSelector = createSelector(dataSelector, select('sales'));
export var shoppersSelector = createSelector(dataSelector, select('shoppers', []));
export var labelSelector = createSelector(dataSelector, select('label'));
export var primaryValueSelector = createSelector(dataSelector, select('primaryValue'));
export var primaryValueFormatSelector = createSelector(dataSelector, select('primaryValueFormat'));
export var primaryValueLabelSelector = createSelector(dataSelector, select('primaryValueLabel'));
export var secondaryValuesSelector = createSelector(dataSelector, select('secondaryValues', []));
export var topChannelsDateRangeLabelSelector = function topChannelsDateRangeLabelSelector() {
  return TOP_CHANNELS_DATE_RANGE_LABEL;
};
export var topInventoryDateRangeLabelSelector = function topInventoryDateRangeLabelSelector() {
  return TOP_INVENTORY_DATE_RANGE_LABEL;
};
export var shopperActivityDateRangeLabelSelector = function shopperActivityDateRangeLabelSelector() {
  return SHOPPER_ACTIVITY_DATE_RANGE_LABEL;
};
export var overviewTooltipSelector = createSelector(manageSpendPathSelector, function (manageSpendPath) {
  return {
    'total spent': {
      text: "This total represents the average spend of channels whose spend you've entered",
      linkText: 'Add or edit your spend for channels',
      to: manageSpendPath
    },
    roas: {
      text: 'Also known as Return on Ad Spend, is the amount of revenue earned from all channels per dollar of',
      linkText: 'Ad Spend',
      to: manageSpendPath,
      inlineLink: true
    },
    'total influenced vins': {
      text: 'The total number of vehicles whose sales were influenced by ad spend',
      linkText: null,
      to: null
    }
  };
});
export var isMissingDataSelector = createSelector(primaryValueSelector, topChannelsSelector, isLoadedSelector, function (value, topChannels, isLoaded) {
  return isLoaded && (!value || !topChannels.length);
});