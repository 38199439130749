import loadable from '@signal/hoc/loadable'
import AdStatsListSkeleton from '../../components/AdStatsList/Skeleton'

export default loadable({
  loader: () => import(/* webpackChunkName: "ad-stats" */ './Component'),
  dataLoader: () => import(/* webpackChunkName: "lib-ad" */ '@signal/core/ad'),
  loadData: ({ adStatsLoader }, loaderInterface) =>
    adStatsLoader(loaderInterface),
  loading: AdStatsListSkeleton
})
