function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
/**
 * Severity Levels Enum
 */

export var LEVELS = {
  DEBUG: 'debug',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  FATAL: 'fatal'
};
/**
 * Tags Enum
 */

export var TAGS = {
  CATEGORY: 'category',
  COMPONENT: 'component'
};
/**
 * Initializes the Sentry browser SDK.
 * @param {Object} config - Sentry SDK configuration object
 */

export function initialize(config) {
  Sentry.init(config);
}
/**
 * Creates redux middleware using redux-sentry-middleware.
 * @param {Object} options - options provided to redux-sentry-middleware
 */

export function createMiddleware(options) {
  return createSentryMiddleware(Sentry, options);
}
/**
 * Sets the user context for Sentry
 * @param {Object} userData - User data
 */

export function setUserContext(userData) {
  var id = userData.id,
      email = userData.email;
  Sentry.setUser({
    id: id,
    email: email
  });
}
/**
 * Captures an exception, and optionally some supporting metadata, and reports it to Sentry.
 * @param {Error} error - exception to capture
 * @param {Object} options - capture options
 * @param {string} options.level - severity level of the exception
 * @param {Object.<string, string>} options.tags - tags to apply to the exception
 * @param {Object.<string, *>} options.extra - extra data to send with the exception
 *
 * @example
 * captureException(error, {
 *   level: LEVEL.FATAL,
 *   tags: {
 *     [TAG.CATEGORY]: 'Render',
 *     [TAG.COMPONENT]: 'ErrorBoundary'
 *   },
 *   extra: {
 *     componentStack
 *   }
 * })
 */

export function captureException(error) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var level = options.level,
      tags = options.tags,
      extra = options.extra;
  Sentry.withScope(function (scope) {
    if (level) {
      scope.setLevel(level);
    }

    if (tags) {
      Object.entries(tags).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            val = _ref2[1];

        scope.setTag(key, val);
      });
    }

    if (extra) {
      Object.entries(extra).forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            key = _ref4[0],
            val = _ref4[1];

        scope.setExtra(key, val);
      });
    }

    Sentry.captureException(error);
  });
}
/**
 * Shows a dialog that allows a user to report a bug to Sentry.
 * @param {Object} options - report dialog options
 */

export function reportBug(options) {
  Sentry.showReportDialog(options);
}