var _templateObject;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabelBase from '../FormControlLabel';
import MuiSwitch from '@material-ui/core/Switch';
import { colorWhiteSelector, getColorPrimarySelector, getColorGreySelector, getSpacingSelector } from '@signal/theme';
var leftSpacingSelector = getSpacingSelector(2);
var bottomSpacingSelector = getSpacingSelector(1);
var colorPrimarySelector = getColorPrimarySelector();
var backgroundColorSelector = getColorGreySelector(300);
export var FormControlLabel = styled(FormControlLabelBase)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 12px;\n"])));
export var switchStyles = function switchStyles(theme) {
  return {
    root: {
      marginLeft: leftSpacingSelector(theme),
      marginBottom: bottomSpacingSelector(theme),
      padding: 0,
      height: 18,
      width: 30,
      borderRadius: 9
    },
    switchBase: {
      padding: 0,
      bottom: 0,
      left: 1,
      '&$checked': {
        color: colorWhiteSelector(theme),
        transform: 'translateX(12px)',
        '& + $track': {
          opacity: 1,
          backgroundColor: colorPrimarySelector(theme)
        }
      },
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.sharp
      })
    },
    track: {
      opacity: 1,
      backgroundColor: backgroundColorSelector(theme)
    },
    thumb: {
      width: 16,
      height: 16
    },
    checked: {}
  };
};
export var Switch = withStyles(switchStyles)(MuiSwitch);