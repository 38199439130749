import { formats } from '@signal/utils/getStringFormatter';
import createChainableTypeChecker from './createChainableTypeChecker';
/**
 * Checks the given prop to make sure it is one of the formatters formats.
 */

export function formatPropType(props, propName, componentName) {
  var formatKeys = Object.keys(formats);

  if (!formatKeys.some(function (key) {
    return key.toLowerCase() === props[propName].toLowerCase();
  })) {
    return new Error("Invalid prop `".concat(propName, "` supplied to `").concat(componentName, "`. Received `").concat(props[propName], "` but expected one of the following: [").concat(formatKeys.map(function (key) {
      return "'".concat(key, "'");
    }).join(', '), "]"));
  }
}
export default createChainableTypeChecker(formatPropType);