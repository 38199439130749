import styled from 'styled-components'
import Button from '@signal/components/Button'
import Panel from '@signal/components/Panel'
import TimelineView from '@signal/components/TimelineView'
import TimelineMarker from '@signal/components/TimelineMarker'
import { getSpacingSelector } from '@signal/theme/selectors'
import { COLOR_GREY_LIGHT } from '@signal/theme/variables'

export const vars = {
  DATE_WIDTH: 141,
  MARKER_SIZE: 20
}

export const Timeline = styled(TimelineView)`
  > *::after {
    top: 6px;
  }
`

export const Wrapper = styled.div`
  display: flex;
`

export const GroupWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 60px;
`

export const GroupContent = styled(Panel)`
  > * > * {
    margin: ${getSpacingSelector(2)}px 0;
  }
`

export const GroupBase = styled.div`
  padding-bottom: 25px;
`

export const DateGroup = styled(GroupBase)``

export const EventGroup = styled(GroupBase)``

export const GroupHeading = styled.div`
  display: flex;
  align-items: center;
`

export const GroupTitle = styled.div`
  width: ${vars.DATE_WIDTH}px;
`

export const DateTitle = styled(GroupTitle)`
  margin-bottom: 15px;
  // The line height is adjusted here so that the dates are perfectly
  // vertically aligned with the date title.
  * {
    line-height: ${vars.MARKER_SIZE};
  }
`

export const Marker = styled(TimelineMarker)`
  margin-top: 6px;
`

export const MarkerSkeleton = styled(Marker).attrs(() => ({
  innerProps: {
    style: {
      backgroundColor: COLOR_GREY_LIGHT
    }
  }
}))``

export const MoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${getSpacingSelector(3.5)}px;
`

export const MoreButton = styled(Button).attrs(() => ({
  color: 'primary',
  variant: 'contained'
}))``
