import styled from 'styled-components'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import { getSpacingSelector } from '@signal/theme/selectors'

export const SaleDetailTypography = styled(Typography).attrs(() => ({
  variant: 'body2'
}))`
  margin-top: ${getSpacingSelector(2)}px !important;
  padding-bottom: ${getSpacingSelector(0.75)}px;
`

export const SaleDetailTypographySkeleton = SaleDetailTypography.withComponent(
  TypographySkeleton
)
