import subDays from 'date-fns/subDays';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subMonths from 'date-fns/subMonths';
import parseDate from '@signal/utils/parseDate';
export var REDUCER_KEY = 'local';
/**
 * Top-level Reducer State Keys
 */

export var DATE_RANGE = 'dateRange';
export var SUMMARY_EXPANDED = 'summaryExpanded';
/**
 * Channels Filter Toolbar Keys
 */

export var CHANNELS_FILTER_TOOLBAR = 'channelsFilterToolbar';
/**
 * Action Types
 */

export var LOCAL_SET_SUMMARY_EXPANDED = 'LOCAL/SET_SUMMARY_EXPANDED';
export var LOCAL_SET_CHANNELS_FILTERS = 'LOCAL/SET_CHANNELS_FILTERS';
/**
 * Summary Expanded Constants
 */

export var SUMMARY_TYPE_CHANNELS = 'channels';
export var SUMMARY_TYPE_INVENTORY = 'inventory';
export var SUMMARY_TYPE_SHOPPERS = 'shoppers';
/**
 * Date Range Constants
 */

export var DATE_FORMAT = 'yyyy-MM-dd';
export var START_DATE = subDays(parseDate(), 29);
export var END_DATE = parseDate();
export var createPreset = function createPreset(text, start, end) {
  return {
    text: text,
    start: format(start, DATE_FORMAT),
    end: format(end, DATE_FORMAT)
  };
};
export var DATE_TODAY = parseDate();
export var DATE_30_DAYS_AGO = subDays(DATE_TODAY, 29);
export var DATE_START_OF_MONTH = startOfMonth(DATE_TODAY);
export var DATE_LAST_MONTH = subMonths(DATE_TODAY, 1);
export var DATE_START_OF_LAST_MONTH = startOfMonth(DATE_LAST_MONTH);
export var DATE_END_OF_LAST_MONTH = endOfMonth(DATE_LAST_MONTH);
export var PRESET_PAST_30_DAYS_TEXT = 'Past 30 Days';
export var PRESET_PAST_30_DAYS_START = DATE_30_DAYS_AGO;
export var PRESET_PAST_30_DAYS_END = DATE_TODAY;
export var PRESET_PAST_30_DAYS = createPreset(PRESET_PAST_30_DAYS_TEXT, PRESET_PAST_30_DAYS_START, PRESET_PAST_30_DAYS_END);
export var PRESET_MONTH_TO_DATE_TEXT = 'Month to Date';
export var PRESET_MONTH_TO_DATE_START = DATE_START_OF_MONTH;
export var PRESET_MONTH_TO_DATE_END = DATE_TODAY;
export var PRESET_MONTH_TO_DATE = createPreset(PRESET_MONTH_TO_DATE_TEXT, PRESET_MONTH_TO_DATE_START, PRESET_MONTH_TO_DATE_END);
export var PRESET_LAST_MONTH_TEXT = 'Last Month';
export var PRESET_LAST_MONTH_START = DATE_START_OF_LAST_MONTH;
export var PRESET_LAST_MONTH_END = DATE_END_OF_LAST_MONTH;
export var PRESET_LAST_MONTH = createPreset(PRESET_LAST_MONTH_TEXT, PRESET_LAST_MONTH_START, PRESET_LAST_MONTH_END);
export var DATE_RANGE_PRESETS = [PRESET_PAST_30_DAYS, PRESET_MONTH_TO_DATE, PRESET_LAST_MONTH];
/**
 * Recently viewed accounts
 */

export var LOCAL_SET_RECENTLY_VIEWED_ACCOUNTS = 'LOCAL/RECENTLY_VIEWED_ACCOUNTS';
export var RECENTLY_VIEWED_ACCOUNTS_KEY = 'recentlyViewedAccounts';
/**
 * Onboarding tour
 */

export var LOCAL_BEGIN_ONBOARDING_TOUR = 'LOCAL/BEGIN_ONBOARDING_TOUR';
export var LOCAL_DISMISS_ONBOARDING_TOUR = 'LOCAL/DISMISS_ONBOARDING_TOUR';
export var SHOW_ONBOARDING_TOUR = 'showOnboardingTour';
/**
 * Activate tour
 */

export var LOCAL_BEGIN_ACTIVATE_TOUR = 'LOCAL/BEGIN_ACTIVATE_TOUR';
export var LOCAL_DISMISS_ACTIVATE_TOUR = 'LOCAL/DISMISS_ACTIVATE_TOUR';
export var SHOW_ACTIVATE_TOUR = 'showActivateTour';