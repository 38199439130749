var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Check from '@material-ui/icons/Check';
import FuzzySearchInput from '../FuzzySearchInput';
import Search from '../Icons/Search';
import { getColorBackgroundSelector, getSpacingSelector, paletteDividerSelector } from '@signal/theme/selectors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  background: ", ";\n"])), getColorBackgroundSelector('paper'));
export var AccountSearch = styled(FuzzySearchInput)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  flex-grow: 0;\n  flex-shrink: 0;\n"])), getSpacingSelector(2), getSpacingSelector(2));
export var Adornment = styled(InputAdornment)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-height: initial;\n"])));
export var AccountSearchAdornment = styled(Adornment)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-left: ", "px;\n  margin-right: 0;\n"])), getSpacingSelector());
export var AccountSearchIcon = styled(Search)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 28px;\n  height: 28px;\n"])));
export var AccountSelectedIcon = styled(Check)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  width: 28px;\n  height: 28px;\n  color: ", ";\n"])), function (props) {
  return props.theme.palette.grey[700];
});
export var AccountListItem = styled(ListItem).attrs({
  className: 'sig-account-list-item'
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  padding: ", "px ", "px;\n  border-bottom: 1px solid ", ";\n"])), getSpacingSelector(3), getSpacingSelector(4), paletteDividerSelector);
export var AccountListItemText = styled(ListItemText)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral([""])));
export var AccountList = styled(List)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  height: 100%;\n  padding: 0;\n\n  .sig-account-list-item:last-child {\n    border-bottom: 0;\n  }\n"])));
export var AccountListSkeleton = styled(AccountList)(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  overflow-y: auto;\n"])));
export var NoRows = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #bdbdbd;\n  font-size: 16px;\n"])));