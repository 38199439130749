import styled from 'styled-components'
import { getColorBackgroundSelector, getSpacingSelector } from '@signal/theme'
import Paper from '@material-ui/core/Paper'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: center;
  align-items: center;
`

export const ComponentWrapper = styled(Paper).attrs(() => ({
  elevation: 1
}))`
  background-color: ${getColorBackgroundSelector('paper')};
  padding: ${getSpacingSelector(5)}px;
  margin: auto;
  max-width: 600px;
`
