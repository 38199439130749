import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import renderIf from '@signal/utils/renderIf'
import AlertBannerSkeleton from '@signal/components/AlertBanner/Skeleton'
import {
  ContentWrapper,
  ConstrainWidth,
  BreadcrumbsWrapper,
  BreadcrumbsContent,
  BreadcrumbsSkeleton
} from './styles'

export default function ContextualPageHeaderSkeleton({
  crumbProps,
  showAlertMessage,
  showCrumbs,
  children,
  className,
  style
}) {
  return (
    <Fragment>
      {renderIf(showCrumbs, () => (
        <BreadcrumbsWrapper>
          <BreadcrumbsContent>
            {renderIf(showCrumbs, () => (
              <BreadcrumbsSkeleton {...crumbProps} />
            ))}
          </BreadcrumbsContent>
        </BreadcrumbsWrapper>
      ))}
      {renderIf(showAlertMessage, () => (
        <AlertBannerSkeleton />
      ))}
      {renderIf(children, () => (
        <ContentWrapper>
          <ConstrainWidth className={className} style={style}>
            {children}
          </ConstrainWidth>
        </ContentWrapper>
      ))}
    </Fragment>
  )
}

ContextualPageHeaderSkeleton.propTypes = {
  crumbProps: PropTypes.object,
  showAlertMessage: PropTypes.bool,
  showCrumbs: PropTypes.bool
}

ContextualPageHeaderSkeleton.defaultProps = {
  crumbProps: {},
  showAlertMessage: false,
  showCrumbs: false
}
