var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { createSelector } from 'reselect';
import { getSpacingSelector } from '@signal/theme/selectors';
import ButtonBase from '../Button';
import ArrowDownIcon from '../Icons/ArrowDown';
import ArrowUpIcon from '../Icons/ArrowUp';
export var propOpenSelector = function propOpenSelector(props) {
  return props.open;
};
export var opacitySelector = createSelector(propOpenSelector, function (open) {
  return open ? 0.85 : 1;
});
export var Button = styled(ButtonBase)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  opacity: ", ";\n  padding: 0 ", "px;\n"])), opacitySelector, getSpacingSelector());
export var ArrowDown = styled(ArrowDownIcon).attrs(function () {
  return {
    size: 12
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-left: ", "px;\n"])), getSpacingSelector(2));
export var ArrowUp = ArrowDown.withComponent(ArrowUpIcon);