var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import { keyframes, css } from 'styled-components';
export var fadeInUpKeyframe = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    opacity:0;\n    transform: translateY(20px);\n   }\n\n  100% {\n    opacity: 1;\n  }\n"])));
var fadeInUpAnimation = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  animation: ", " 300ms ease-out;\n"])), fadeInUpKeyframe);
export default fadeInUpAnimation;