import React from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import TableListView from '../../components/TableListView'
import {
  Wrapper,
  Title,
  RowTitleSkeleton,
  RowSubtitleSkeleton,
  RowIcon
} from './styles'

const columnInfo = [
  {
    key: 'title',
    component: RowTitleSkeleton
  },
  {
    key: 'subtitle',
    component: RowSubtitleSkeleton
  },
  {
    key: 'arrowIcon',
    component: RowIcon,
    style: { textAlign: 'right' }
  }
]

const rows = times(3, i => ({ key: i }))

const cellRenderer = (row, { component: Component }) => <Component {...row} />

export function MostActiveShopperSkeleton(props) {
  return (
    <Wrapper {...props}>
      <Title>Most Active Shoppers</Title>
      <TableListView
        rows={rows}
        columnInfo={columnInfo}
        renderRowCell={cellRenderer}
      />
    </Wrapper>
  )
}

MostActiveShopperSkeleton.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
}

export default MostActiveShopperSkeleton
