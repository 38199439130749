/**
 * This function returns a wrapped prop type validate function with the native
 * prop-type `isRequired` functionality.
 *
 * Inspired by: https://github.com/facebook/prop-types/blob/master/factoryWithTypeCheckers.js#L167
 *
 * @param {Function} validate - the prop type function
 * @returns {Function} - wrapped in custom logic to check for `null` or `undefined`
 * if `isRequired` is called
 */
export default function createChainableTypeChecker(validate) {
  function checkType(isRequired, props, propName, componentName, location, propFullName) {
    function createErrorMessage(unexpectedType) {
      return "The ".concat(location, " `").concat(propFullName || propName, "` is marked as required in `").concat(componentName, "`, but its value is `").concat(unexpectedType, "`.");
    }

    if (props[propName] == null) {
      if (isRequired) {
        if (props[propName] === null) {
          return new Error(createErrorMessage('null'));
        }

        return new Error(createErrorMessage('undefined'));
      }

      return null;
    } else {
      return validate(props, propName, componentName, location, propFullName);
    }
  }

  var chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);
  return chainedCheckType;
}