export var REDUCER_KEY = 'audience';
/**
 * Action Types
 */

export var AUDIENCE_AUDIENCES = 'AUDIENCE/AUDIENCES';
export var AUDIENCE_SEGMENTS = 'AUDIENCE/SEGMENTS';
export var AUDIENCE_DETAILS = 'AUDIENCE/DETAILS';
export var AUDIENCE_SAVE = 'AUDIENCE/SAVE';
export var AUDIENCE_DELETE = 'AUDIENCE/DELETE';
export var AUDIENCE_RESET_FORM = 'AUDIENCE/RESET_FORM';
/**
 Audience created modal
 */

export var INVOKE_AUDIENCE_CREATED = 'AUDIENCE/INVOKE_CREATED';
export var DISMISS_AUDIENCE_CREATED = 'AUDIENCE/DISMISS_CREATED';
export var SHOW_AUDIENCE_CREATED = 'showAudienceCreated';
export var NO_AUDIENCE_CREATED = -1;