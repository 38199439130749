import getAPIDomain from '@signal/utils/env/apiDomain';
import { API_VERSION } from '../api/constants';
import useSWRWithCache from '../contextFetch/hooks';
export function getUserAccountsPath() {
  return "".concat(getAPIDomain('/signal'), "/performance/").concat(API_VERSION, "/me/accounts");
}
export function getUserDetailsPath() {
  return "".concat(getAPIDomain('/signal'), "/performance/").concat(API_VERSION, "/me/details");
}
/**
 * this is a wrapper around useSWRWithCache that can be used to fetch the user accounts
 * @return {Promise} - a promise to receive the IUserDetails
 */

export function useUserAccounts() {
  return useSWRWithCache({
    cacheBust: true,
    context: 'useUserAccounts',
    url: getUserAccountsPath()
  });
}
/**
 * this is a wrapper around useSWRWithCache that can be used to fetch the user details
 * @return {Promise} - a promise to receive the IUserDetails
 */

export function useUserDetails() {
  return useSWRWithCache({
    cacheBust: true,
    context: 'useUserDetails',
    url: getUserDetailsPath()
  });
}