import React, { memo } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { PAGE_CHANNEL_VIN, APP_ATTRIBUTION } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import Header from '../../containers/AttributionHeader'
import ChannelsVinAttribution from '../../containers/ChannelsVinAttribution'
import ChannelsVinContribution from '../../containers/ChannelsVinContribution'
import ChannelsVinSaleDetail from '../../containers/ChannelsVinSaleDetail'
import ChannelsVinUserActivity from '../../containers/ChannelsVinUserActivity'
import ChannelsVinHeader from '../../containers/ChannelsVinHeader'
import { ChannelContributions, TopChannels, BuyerActivity } from './styles'
import { selectSalesStatement } from './selectors'

export const ChannelsVinPage = memo(({ match, salesStatement }) => {
  usePageTrack(PAGE_CHANNEL_VIN, APP_ATTRIBUTION)

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      headerComponent={ChannelsVinHeader}
      match={match}
    >
      <TopChannels subtitle={salesStatement}>
        <ChannelsVinAttribution match={match} />
      </TopChannels>
      <ChannelContributions>
        <ChannelsVinContribution match={match} />
      </ChannelContributions>
      <BuyerActivity>
        <ChannelsVinSaleDetail match={match} />
        <ChannelsVinUserActivity match={match} />
      </BuyerActivity>
    </LayoutDefaultWithPageHeader>
  )
})

ChannelsVinPage.displayName = 'ChannelsVinPage'

const mapStateToProps = createStructuredSelector({
  salesStatement: selectSalesStatement
})

export default connect(mapStateToProps)(ChannelsVinPage)
