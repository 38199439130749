function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createSelector } from 'reselect';
import { getLocation } from 'connected-react-router';
import queryString from 'query-string';
import get from 'lodash/get';
import select from '@signal/utils/select';
import { ACCOUNT, ACTIVATE_REPORT, LOCAL_SEARCH_REPORT, VALUE_INTELLIGENCE_REPORT, PROFILE, ACCOUNTS, LOGOUT, MANAGE_SPEND, PERFORMANCE_TABS, PERFORMANCE, PERFORMANCE_OVERVIEW, PERFORMANCE_CHANNELS, PERFORMANCE_CHANNELS_DETAIL, PERFORMANCE_CHANNELS_VIN, PERFORMANCE_INVENTORY, PERFORMANCE_INVENTORY_DETAIL, PERFORMANCE_INVENTORY_DETAIL_VIN, PERFORMANCE_INVENTORY_VIN, PERFORMANCE_SHOPPERS, PERFORMANCE_SHOPPERS_BUCKET, OFFERS_OVERVIEW, OFFERS_PERSONALIZED_EDIT, OFFERS_PERSONALIZED_STATS, OFFERS_PERSONALIZED_CREATE, OFFERS_UNIVERSAL_EDIT, OFFERS_UNIVERSAL_CREATE, AUDIENCES, AUDIENCE_DETAIL, AUDIENCE_CREATE, SHOPPER_DETAIL, REPORT, CUSTOM_REPORT, INDUSTRY_REPORT, SUB_REPORT } from '@signal/routes';
import { activeAccountIdSelector } from '../auth';
import { REDUCER_KEY } from './constants';
export var locationSelector = getLocation;
export var searchSelector = createSelector(locationSelector, select('search'));
export var pathnameSelector = createSelector(locationSelector, select('pathname'));
export var stateSelector = createSelector(locationSelector, select('state'));
export var searchObjSelector = createSelector(searchSelector, function (search) {
  return queryString.parse(search);
});
export var createRouteParamsSelector = function createRouteParamsSelector(route) {
  return createSelector(pathnameSelector, function (pathname) {
    var match = route.match(pathname, {
      exact: true
    });
    return get(match, 'params', {});
  });
};
export function getPathForRoute(route, props) {
  return Object.values(route.params).every(function (param) {
    return !!props[param];
  }) ? route.buildPath(props) : route.path;
}
export var createSearchParamSelector = function createSearchParamSelector(param, defaultValue) {
  return createSelector(searchObjSelector, select(param, defaultValue));
};
export var logoutPathSelector = function logoutPathSelector() {
  return LOGOUT.path;
};
export var profilePathSelector = function profilePathSelector() {
  return PROFILE.path;
};
export var accountsPathSelector = function accountsPathSelector() {
  return ACCOUNTS.path;
};
export var getPerformanceOverviewPathByAccountId = function getPerformanceOverviewPathByAccountId(accountId) {
  return getPathForRoute(PERFORMANCE, {
    accountId: accountId
  });
};
export var accountPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(ACCOUNT, {
    accountId: accountId
  });
});
export var manageSpendPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(MANAGE_SPEND, {
    accountId: accountId
  });
});
export var signalRouterSelector = function signalRouterSelector(state) {
  return get(state, REDUCER_KEY);
};
export var activePerformanceTabSelector = createSelector(signalRouterSelector, select('activePerformanceTab', PERFORMANCE_TABS[0].key));
export var performancePathSelector = createSelector(activeAccountIdSelector, activePerformanceTabSelector, function (accountId, activeTab) {
  return getPathForRoute(PERFORMANCE, {
    accountId: accountId,
    activeTab: activeTab
  });
});
export var performanceTabPathSelector = createSelector(activeAccountIdSelector, activePerformanceTabSelector, function (accountId, activeTab) {
  return getPathForRoute(PERFORMANCE_OVERVIEW, {
    accountId: accountId,
    activeTab: activeTab
  });
});
export var performanceChannelsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_CHANNELS, {
    accountId: accountId
  });
});
export var performanceChannelsDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_CHANNELS_DETAIL, {
    accountId: accountId
  });
}); // TODO improve dryness of partial selectors

export var partialChannelsVinSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (vin) {
    return getPathForRoute(PERFORMANCE_CHANNELS_VIN, {
      accountId: accountId,
      vin: vin
    });
  };
}); // TODO improve dryness of partial selectors

export var partialInventoryVinModalSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (vin) {
    return getPathForRoute(PERFORMANCE_INVENTORY_DETAIL_VIN, {
      accountId: accountId,
      vin: vin
    });
  };
}); // TODO improve dryness of partial selectors

export var partialInventoryVinSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (vin) {
    return getPathForRoute(PERFORMANCE_INVENTORY_VIN, {
      accountId: accountId,
      vin: vin
    });
  };
});
export var performanceInventoryPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_INVENTORY, {
    accountId: accountId
  });
});
export var performanceInventoryDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_INVENTORY_DETAIL, {
    accountId: accountId
  });
});
export var performanceShoppersPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(PERFORMANCE_SHOPPERS, {
    accountId: accountId
  });
});
export var partialPerformanceShopperPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (other) {
    return getPathForRoute(SHOPPER_DETAIL, _objectSpread({
      accountId: accountId
    }, other));
  };
});
export var partialPerformanceShoppersBucketPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (shopperBucket) {
    return getPathForRoute(PERFORMANCE_SHOPPERS_BUCKET, {
      accountId: accountId,
      shopperBucket: shopperBucket
    });
  };
});
export var adsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(OFFERS_OVERVIEW, {
    accountId: accountId
  });
});
export var partialAdDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (offerId) {
    if (!offerId) {
      return getPathForRoute(OFFERS_PERSONALIZED_CREATE, {
        accountId: accountId
      });
    }

    return getPathForRoute(OFFERS_PERSONALIZED_EDIT, {
      accountId: accountId,
      offerId: offerId
    });
  };
});
export var partialUniversalAdDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (offerId) {
    if (!offerId) {
      return getPathForRoute(OFFERS_UNIVERSAL_CREATE, {
        accountId: accountId
      });
    }

    return getPathForRoute(OFFERS_UNIVERSAL_EDIT, {
      accountId: accountId,
      offerId: offerId
    });
  };
});
export var partialAdStatsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (offerId) {
    return getPathForRoute(OFFERS_PERSONALIZED_STATS, {
      accountId: accountId,
      offerId: offerId
    });
  };
});
export var adCreatePathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(OFFERS_PERSONALIZED_CREATE, {
    accountId: accountId
  });
});
export var audiencesPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(AUDIENCES, {
    accountId: accountId
  });
});
export var partialAudienceDetailPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return function (audienceId) {
    return getPathForRoute(AUDIENCE_DETAIL, {
      accountId: accountId,
      audienceId: audienceId
    });
  };
});
export var audienceCreatePathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(AUDIENCE_CREATE, {
    accountId: accountId
  });
});
export var dashboardDefaultSlugSelector = function dashboardDefaultSlugSelector() {
  return 'overview-v2';
};
export var dashboardPathSelector = createSelector(activeAccountIdSelector, dashboardDefaultSlugSelector, function (accountId, reportSlug) {
  return getPathForRoute(REPORT, {
    accountId: accountId,
    reportSlug: reportSlug
  });
});
export var industryReportsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(INDUSTRY_REPORT, {
    accountId: accountId,
    reportSlug: 'overview'
  });
});
export var forecastingReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(REPORT, {
    accountId: accountId,
    reportSlug: 'forecasting'
  });
});
export var inventoryReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(REPORT, {
    accountId: accountId,
    reportSlug: 'inventory'
  });
});
export var opportunityReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(REPORT, {
    accountId: accountId,
    reportSlug: 'opportunities'
  });
});
export var performanceReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(REPORT, {
    accountId: accountId,
    reportSlug: 'performance'
  });
});
export var performanceDisplayReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(SUB_REPORT, {
    accountId: accountId,
    reportSlug: 'performance',
    subReportSlug: 'display'
  });
});
export var performanceFixedOpsReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(SUB_REPORT, {
    accountId: accountId,
    reportSlug: 'performance',
    subReportSlug: 'fixed-ops'
  });
});
export var performanceSearchReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(SUB_REPORT, {
    accountId: accountId,
    reportSlug: 'performance',
    subReportSlug: 'search'
  });
});
export var performanceSocialReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(SUB_REPORT, {
    accountId: accountId,
    reportSlug: 'performance',
    subReportSlug: 'social'
  });
});
export var performanceVideoReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(SUB_REPORT, {
    accountId: accountId,
    reportSlug: 'performance',
    subReportSlug: 'video'
  });
});
export var pureCarsTVReportPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(REPORT, {
    accountId: accountId,
    reportSlug: 'purecars-tv'
  });
});
export var activateReportsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(ACTIVATE_REPORT, {
    accountId: accountId,
    reportSlug: 'overview'
  });
});
export var localSearchReportsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(LOCAL_SEARCH_REPORT, {
    accountId: accountId,
    reportSlug: 'overview'
  });
});
export var valueIntelligenceReportsPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(VALUE_INTELLIGENCE_REPORT, {
    accountId: accountId,
    reportSlug: 'overview'
  });
});
export var myDashboardPathSelector = createSelector(activeAccountIdSelector, function (accountId) {
  return getPathForRoute(REPORT, {
    accountId: accountId,
    reportSlug: CUSTOM_REPORT
  });
});