import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { audienceCreatePathSelector } from '@signal/core/router/selectors'
import Button from '@signal/components/Button'
import { Icon } from './styles'

export const CreateAudienceButton = memo(function({
  createAudienceHref,
  label
}) {
  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to={createAudienceHref}
    >
      <Icon />
      {label}
    </Button>
  )
})

CreateAudienceButton.propTypes = {
  createAudienceHref: PropTypes.string.isRequired,
  label: PropTypes.string
}

CreateAudienceButton.defaultProps = {
  label: 'Create a new audience'
}

const mapStateToProps = createStructuredSelector({
  createAudienceHref: audienceCreatePathSelector
})

export default connect(mapStateToProps)(CreateAudienceButton)
