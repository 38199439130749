import React, { Fragment } from 'react'
import DrilldownSectionSkeleton from '../../components/DrilldownSection/Skeleton'
import InventoryDrilldownTableSkeleton from '../../components/InventoryDrilldownTable/Skeleton'
import TimeRangeSkeleton from '@signal/components/TimeRanges/Skeleton'

export const InventoryDrilldownTablesSkeleton = () => (
  <Fragment>
    <TimeRangeSkeleton />
    <DrilldownSectionSkeleton
      drilldownTableComponent={InventoryDrilldownTableSkeleton}
      showImage
    />
  </Fragment>
)

export default InventoryDrilldownTablesSkeleton
