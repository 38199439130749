import React from 'react'
import PerformanceSummaryPanel from '../../components/PerformanceSummaryPanel'

const InventorySummaryHeader = () => (
  <PerformanceSummaryPanel
    title="Inventory Engagement"
    description="See what makes and models are getting noticed based off a score of 0 to 100, with 100 representing maximum engagement."
    image={{
      srcSet: [
        {
          dpr: 1,
          src:
            'https://purecars-signal.imgix.net/app/performance/inventory/inventory-header-image.png'
        },
        {
          dpr: 2,
          src:
            'https://purecars-signal.imgix.net/app/performance/inventory/inventory-header-image@2x.png'
        },
        {
          dpr: 3,
          src:
            'https://purecars-signal.imgix.net/app/performance/inventory/inventory-header-image@3x.png'
        }
      ],
      alt: 'Inventory engagement score',
      width: 913,
      height: 255
    }}
  />
)

export default InventorySummaryHeader
