function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import set from 'lodash/set';
import { UI_DESIGN_V2, GRADIENT } from './constants';
/**
 * Takes in a filter array of structure [{ name: 'Condition', value: 'New' }] and returns
 * { Condition: 'New' }
 * @param {Array} filtersArray
 */

export function mapFiltersToArray(filtersArray) {
  return filtersArray.reduce(function (result, filter) {
    var filterName = filter.filter,
        name = filter.name,
        value = filter.value,
        valueDisplay = filter.valueDisplay,
        display = filter.display;
    result.push({
      filter: filterName || name,
      value: value,
      display: valueDisplay || display
    });
    return result;
  }, []);
}
/**
 * Takes an array of widget children, a widget title, and the default display variant value (defined in signal/desktop)
 * and adjusts the children for being in a custom report, which mainly means making them unpinnable
 * @param {Array} children
 * @param {String} title
 * @param {Number} defaultDisplayVariant
 * @return {Array} adjusted children
 */

export function adjustWidgetChildrenForCustomReport(children, title, defaultDisplayVariant) {
  // TODO: were changes to layout intentional?
  return map(children, function (child) {
    var adjustedChild = _objectSpread(_objectSpread({}, child), {}, {
      pinnable: false,
      elevation: 0,
      layout: {
        props: _objectSpread({
          displayVariant: defaultDisplayVariant
        }, omit(child.layout.props, ['width', 'height']))
      }
    });

    if (title && !isEmpty(title)) {
      adjustedChild['title'] = title;
    }

    return adjustedChild;
  });
}
/**
 * This function removes any filters the user wants to apply that are not available
 * @param {Array} filters - the filers the user wants to apply
 * @param {Array} widgetFilterOptions - the filers that are available to apply (from getWidgetFilterOptions)
 * @return {Array} the filters the user wanted to apply, minus those which could not be applied
 */

export function stripUnavailableFilters(filters, widgetFilterOptions) {
  var optionsMap = widgetFilterOptions.reduce(function (_optionsMap, optionCur) {
    return set(_optionsMap, optionCur.name || optionCur.filter, optionCur);
  }, {});
  return filters.filter(function (filterCur) {
    return optionsMap[filterCur.name || filterCur.filter];
  });
}
/**
 * This function determines whether to render a widget using the new V2 design or not based on whether the design V2
 * flag is sent from BE.  This doesn't check QP because that functionality is specific to the desktop app
 * @param {Object} layout - the layout object from the BE
 * @return {Boolean} whether to use the new v2 dashboard design for this widget or not
 */

export function hasDesignV2LayoutFlag(layout) {
  return get(layout, 'props.uiDesign') === UI_DESIGN_V2;
}
/**
 * This function foces the new V2 design layout flag into a layout payload
 * @param {Object} layout - the layout object from the BE
 * @return {Object} the newly munged layout object
 */

export function forceDesignV2LayoutFlag(layout) {
  return set(layout, 'props.uiDesign', UI_DESIGN_V2);
}
/**
 * This function determines whether to render a widget using the new gradient
 * style or not based on whether the gradient flag is sent from BE. This doesn't
 * check QP because that functionality is specific to the desktop app
 * @param {Object} layout - the layout object from the BE
 * @return {Boolean} whether to use the new gradient design or not
 */

export function hasGradientLayoutFlag(layout) {
  return get(layout, 'props.gradient') === GRADIENT;
}
/**
 * This function foces the new gradient layout flag into a layout payload
 * @param {Object} layout - the layout object from the BE
 * @return {Object} the newly munged layout object
 */

export function forceGradientLayoutFlag(layout) {
  return set(layout, 'props.gradient', GRADIENT);
}
/**
 * This function scans the set of dashboard widgets to determine if a given widgetId is an ancestor of another widgetId
 * in the widget hierarchy
 * @param {Object} widgets - widgets from the dashboard returned from useReport()
 * @param {String} widgetId - a widget id
 * @param {String} widgetIdAncestor - the widget id
 * @return {Boolean} returns true if widgetIdAncestor is an ancestor of widgetId
 */

export function isAncestorWidget(widgets, widgetId, widgetIdAncestor) {
  var findWidget = function findWidget(widgetsInHierarchy, sawAncestor) {
    return widgetsInHierarchy.find(function (_ref) {
      var id = _ref.id,
          children = _ref.children;

      if (id === widgetId) {
        return sawAncestor;
      }

      return findWidget(children || [], sawAncestor || id === widgetIdAncestor);
    });
  };

  return findWidget(widgets, false
  /*sawAncestor*/
  );
}