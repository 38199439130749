/**
 * Started when it is injected and cancelled with `task.cancel()` when ejected
 * for improved performance.
 * @type {String}
 */
export var RESTART = '@@saga-injector/restart';
/**
 * Starts the saga upon being injected and never cancels it or starts again.
 * @type {String}
 */

export var DAEMON = '@@saga-injector/daemon';
/**
 * Behaves like {@link RESTART} but runs only once and never again.
 * @type {String}
 */

export var ONCE = '@@saga-injector/once';
/**
 * The mode with which to run a saga.
 * @global
 * @typedef {(RESTART|ONCE|DAEMON)} SAGA_MODE
 * @see {@link SagaDescriptor} for more information about usage.
 */

/**
 * Enum for the different saga modes.
 * @readonly
 * @global
 * @enum {SAGA_MODE}
 */

export var SAGA_MODES = {
  RESTART: RESTART,
  DAEMON: DAEMON,
  ONCE: ONCE
};