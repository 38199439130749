import forEach from 'lodash/forEach';
import set from 'lodash/set';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';
import invertColor from '@signal/utils/invertColor';
var INVERT = 'invert'; // this mapping is based on the spreadsheet at
// https://docs.google.com/spreadsheets/d/1-_tiR3t873tkhs8UohrhPQ6eZgBo4UnnbarUj3yofHo/edit#gid=0

var TENANT_PALETTE_MAPPING = {
  primary1Color: {
    'background.header': 0.95,
    'primary.dark': -0.33,
    'primary.light': 0.33,
    'primary.lightest': 0.75,
    'primary.main': 0
  },
  primary2Color: {
    'secondary.dark': -0.33,
    'secondary.light': 0.33,
    'secondary.main': 0
  },
  primary3Color: {
    'grey.dark': -0.33,
    'grey.deep': -0.75,
    'grey.light': 0.33,
    'grey.main': 0
  },
  accent1Color: {
    'primary.deep': 0
  },
  accent2Color: {
    'secondary.deep': 0
  },
  textColor: {
    'text.dark': 0,
    'text.deep': 0,
    'text.primary': 0
  },
  alternateTextColor: {
    'text.hint': 0,
    'text.secondary': 0
  },
  canvasColor: {
    'background.default': -0.1,
    'background.paper': 0,
    'common.black': INVERT,
    'common.white': 0,
    'primary.contrastText': 0
  },
  // note that borderColor from the tenant theme is not used
  disabledColor: {
    'action.disabled': 0,
    'text.disabled': 0
  }
};
/**
 * this function replaces colors in the Signal color theme with colors from the tenant theme, using a static mapping
 * to control how the colors are mapped
 * @param {MUIThemeType} - theme - the MUI theme (containing Signal colors) that should have colors replaced from the
 *   tenant theme
 * @param {TenantThemeType} - the tenant theme data obtained form the useTenantTheme hook
 * @return {MUIThemeType} - the Signal color theme altered to reflect tenant theme colors
 */

export function replaceThemeColors(theme, tenantTheme) {
  if (!tenantTheme || !tenantTheme.palette) {
    return theme;
  }

  forEach(TENANT_PALETTE_MAPPING, function (mappings, tenantKey) {
    var color = tenantTheme.palette[tenantKey];
    forEach(mappings, function (adj, paletteKey) {
      var adjustedColor = color;

      if (adj === INVERT) {
        adjustedColor = invertColor(color);
      } else if (adj > 0) {
        adjustedColor = lighten(color, adj);
      } else if (adj < 0) {
        adjustedColor = darken(color, -adj);
      }

      set(theme.palette, paletteKey, adjustedColor);
    });
  });
  return theme;
}