export const TITLE_KEY = 'title'
export const LAST_EDITED_KEY = 'lastEdited'
export const DELETE_KEY = 'delete'
export const STATUS_KEY = 'status'

const AD_LIST_COLUMNS = [
  {
    key: TITLE_KEY,
    label: 'Offer',
    width: '55%',
    sortKey: 'title',
    inverse: true
  },
  {
    key: LAST_EDITED_KEY,
    label: 'Last Edited',
    sortKey: 'lastEditedDate',
    sortReverse: true,
    inverse: true
  },
  {
    key: STATUS_KEY,
    label: 'Status',
    sortKey: 'status',
    sortReverse: true,
    inverse: true
  },
  {
    key: DELETE_KEY
  }
]

export const generateAdListColumns = columnInfo =>
  AD_LIST_COLUMNS.map(columnCur => ({
    ...columnCur,
    ...columnInfo[columnCur.key]
  }))
