var _templateObject;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getSpacingSelector, getColorGreySelector } from '@signal/theme/selectors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: 1px solid ", ";\n  padding: ", "px;\n  border-radius: 3px;\n"])), getColorGreySelector(100), getColorGreySelector('light'), getSpacingSelector(2.5));