import React from 'react'
import {
  Content,
  Details,
  EventSkeleton,
  IconSkeleton,
  TimestampSkeleton,
  TitleSkeleton,
  Wrapper
} from './styles'

export const TimelineGroupSkeleton = () => (
  <Wrapper>
    <TimestampSkeleton />
    <Content>
      <IconSkeleton />
      <Details>
        <TitleSkeleton />
        <EventSkeleton />
      </Details>
    </Content>
  </Wrapper>
)

export default TimelineGroupSkeleton
