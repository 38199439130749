import React from 'react'
import { PureCarsBrand } from '@signal/components/CommonHeader/styles'
import Unauthorized from '@signal/components/Unauthorized'
import LayoutDefault from '../../layouts/Default'
import Header from '../../containers/Header'

function NavHeader() {
  return <Header title={''} icon={PureCarsBrand} alignIcon="left" />
}

export function UnauthorizedPage() {
  return (
    <LayoutDefault navbar={NavHeader}>
      <Unauthorized />
    </LayoutDefault>
  )
}

export default UnauthorizedPage
