import React from 'react'
import { SUMMARY_TYPE_SHOPPERS } from '@signal/core/local'
import PerformanceSummaryPanel from '../../components/PerformanceSummaryPanel'
import withExpandable from '../../hoc/withExpandable'

export const ShoppersSummaryHeader = props => (
  <PerformanceSummaryPanel
    title="Shopper Engagement"
    description="View customer activity from first click all the way up to point of sale."
    image={{
      srcSet: [
        {
          dpr: 1,
          src:
            'https://purecars-signal.imgix.net/app/performance/shoppers/shoppers-header-image.png'
        },
        {
          dpr: 2,
          src:
            'https://purecars-signal.imgix.net/app/performance/shoppers/shoppers-header-image@2x.png'
        },
        {
          dpr: 3,
          src:
            'https://purecars-signal.imgix.net/app/performance/shoppers/shoppers-header-image@3x.png'
        }
      ],
      alt: 'Shopper stages and focus-level',
      width: 960,
      height: 200
    }}
    {...props}
  />
)

export default withExpandable(SUMMARY_TYPE_SHOPPERS)(ShoppersSummaryHeader)
