var _createReducer, _createReducer2;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import { combineReducers } from 'redux';
import { setDateRange } from '../local';
import { performanceDataRequest, performanceDataSuccess, performanceDataFailure, inventoryPerformanceDataRequest, inventoryPerformanceDataSuccess, inventoryPerformanceDataFailure } from './actions';
/**
 * @global
 * @name performanceChannelsState
 * The state tree for all performance channels-related application state.
 *
 * @property {Object} data - Data that populates the highest level performance channels page.
 * @property {Boolean} loading - Loading state for the performance channels data.
 * @property {(Error|Boolean)} error - Denotes whether the performance channels request was successful or not.
 */

export var channelsInitialState = {
  data: null,
  loading: false,
  loaded: false,
  error: false
};
/**
 * @global
 * @name performanceChannelsReducer
 * Reducer for the performance channels-related application state.
 *
 * @type {ReduxReducer}
 */

export var performanceChannelsReducer = createReducer((_createReducer = {}, _defineProperty(_createReducer, performanceDataRequest, function () {
  return _objectSpread(_objectSpread({}, channelsInitialState), {}, {
    loading: true
  });
}), _defineProperty(_createReducer, performanceDataFailure, function (state, error) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    loaded: true,
    error: error
  });
}), _defineProperty(_createReducer, performanceDataSuccess, function (state, data) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    loaded: true,
    data: data
  });
}), _defineProperty(_createReducer, setDateRange, function (state, data) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loaded: false
  });
}), _createReducer), channelsInitialState);
/**
 * @global
 * @name performanceInventoryState
 * The state tree for all performance inventory-related application state.
 *
 * @property {Object} data - Data that populates the highest level performance inventory page.
 * @property {Boolean} loading - Loading state for the performance inventory data.
 * @property {(Error|Boolean)} error - Denotes whether the performance inventory request was successful or not.
 */

export var inventoryInitialState = {
  data: null,
  loading: false,
  error: false
};
/**
 * @global
 * @name performanceInventoryReducer
 * Reducer for the performance inventory-related application state.
 *
 * @type {ReduxReducer}
 */

export var performanceInventoryReducer = createReducer((_createReducer2 = {}, _defineProperty(_createReducer2, inventoryPerformanceDataRequest, function () {
  return _objectSpread(_objectSpread({}, inventoryInitialState), {}, {
    loading: true
  });
}), _defineProperty(_createReducer2, inventoryPerformanceDataSuccess, function (state, data) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    data: data
  });
}), _defineProperty(_createReducer2, inventoryPerformanceDataFailure, function (state, error) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    error: error
  });
}), _createReducer2), inventoryInitialState);
/**
 * @global
 * @namespace performanceState
 */

export default combineReducers({
  channels: performanceChannelsReducer,
  inventory: performanceInventoryReducer
});