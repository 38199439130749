import React, { PureComponent } from 'react'
import trim from 'lodash/trim'
import DesktopEmptyScreen from '@signal/components/DesktopEmptyScreen'
import IconCar from '@signal/components/Icons/Car'
import IconPerformance from '@signal/components/Icons/Performance'
import IconShopper from '@signal/components/Icons/Shopper'

const ICONS_FROM_TYPE = {
  channel: IconPerformance,
  inventory: IconCar,
  shopper: IconShopper
}

export class MissingDataBoundary extends PureComponent {
  static defaultProps = {
    isMissingData: false,
    dataType: null
  }

  render() {
    const { dataType, isMissingData, children } = this.props
    // by default we simply render children within the boundary
    if (!isMissingData) return children

    const type = dataType.toLowerCase()
    // if a child component throws because it's missing data, we render an
    // `EmptyScreen` in place of the children within the boundary
    return (
      <DesktopEmptyScreen
        icon={ICONS_FROM_TYPE[type]}
        title={trim(`No ${type} data found`)}
        description={trim(
          `We're currently processing your ${type} data. Check back soon!`
        )}
      />
    )
  }
}

export default MissingDataBoundary
