var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import HeaderBase from '@signal/layouts/Header';
import { getSpacingSelector, paletteDividerSelector, getColorBackgroundSelector } from '@signal/theme/selectors';
import Typography from '../Typography';
export var Header = styled(HeaderBase)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n\n  @media print {\n    display: none;\n  }\n"])), getColorBackgroundSelector('paper'), paletteDividerSelector);
export var AppIconWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  margin-left: ", "px;\n"])), getSpacingSelector(3));
export var Left = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: ", ";\n  align-items: center;\n  height: 100%;\n  padding-left: ", "px;\n  flex: 1;\n"])), function (props) {
  return props.alignIcon === 'right' ? 'space-between' : 'flex-start';
}, getSpacingSelector(3));
export var AppTitle = styled(Typography).attrs(function () {
  return {
    variant: 'h4',
    color: 'textDark'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-right: ", "px;\n  align-self: center;\n"])), getSpacingSelector(9));
export var Middle = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  align-items: center;\n"])));