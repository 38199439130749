import omit from 'lodash/omit';
import { withStyles } from '@material-ui/core/styles';
import MuiInput from '@material-ui/core/Input';
import MuiFilledInput from '@material-ui/core/FilledInput';
import MuiOutlinedInput from '@material-ui/core/OutlinedInput';
import { getSpacingSelector, getPxToRemSelector, getColorErrorSelector, getColorBackgroundSelector, getColorTextSelector } from '@signal/theme';
export var fontSizeSelector = getPxToRemSelector(16);
export var labelSpacingSelector = getSpacingSelector(2.5);
export var spacingSelector = getSpacingSelector(2);
export var spacingDenseSelector = getSpacingSelector();
export var colorErrorSelector = getColorErrorSelector();
export var backgroundColorSelector = getColorBackgroundSelector();
export var textColorSelector = getColorTextSelector();
export var inputStyles = function inputStyles(theme) {
  return {
    root: {
      padding: 0,
      borderRadius: 2,
      border: '1px solid #e0e0e0',
      boxSizing: 'border-box',
      background: backgroundColorSelector(theme),
      color: textColorSelector(theme)
    },
    formControl: {
      'label + &': {
        marginTop: labelSpacingSelector(theme)
      }
    },
    input: {
      fontSize: fontSizeSelector(theme),
      padding: spacingSelector(theme),
      boxSizing: 'border-box',
      height: 'auto'
    },
    inputMultiline: {
      minHeight: 100
    },
    inputMarginDense: {
      padding: spacingDenseSelector(theme)
    },
    error: {
      borderColor: colorErrorSelector(theme)
    }
  };
};
export var withInputStyles = withStyles(inputStyles);
export var StandardInput = withInputStyles(MuiInput);
export var FilledInput = withInputStyles(MuiFilledInput);
export var OutlinedInput = withStyles(function (theme) {
  var styles = inputStyles(theme);
  return omit(styles, ['formControl']);
})(MuiOutlinedInput);