import { createSelector } from 'reselect'
import {
  showOnboardingTourSelector,
  showActivateTourSelector
} from '@signal/core/local'
import { hasActiveAccountSelector } from '@signal/core/auth'
import { LIGHT, DARK } from './themes'

export const isVisibleSelector = createSelector(
  hasActiveAccountSelector,
  showOnboardingTourSelector,
  (hasActiveAccount, showOnboardingTour) =>
    hasActiveAccount && showOnboardingTour
)

export const isActivateTourVisibleSelector = createSelector(
  hasActiveAccountSelector,
  showActivateTourSelector,
  (hasActiveAccount, showActivateTour) => {
    return hasActiveAccount && showActivateTour
  }
)

const getImgProps = ({ name, width, height }) => ({
  srcSet: [
    {
      dpr: 1,
      src: `https://purecars-signal.imgix.net/onboarding-tour/${name}.png`
    },
    {
      dpr: 2,
      src: `https://purecars-signal.imgix.net/onboarding-tour/${name}@2x.png`
    },
    {
      dpr: 3,
      src: `https://purecars-signal.imgix.net/onboarding-tour/${name}@3x.png`
    }
  ],
  width,
  height
})

export const tourPagesSelector = () => [
  {
    theme: LIGHT,
    title: 'Welcome to Signal!',
    text:
      'Signal connects advertising, sales and inventory data to provide the most accurate, holistic view of sales attribution all in one place.',
    img: getImgProps({
      name: 'tour-page-1',
      width: 360,
      height: 295
    })
  },
  {
    theme: LIGHT,
    title: 'Attribution is a team effort',
    text:
      'Unlike traditional models that give all the credit to the first or last click, Signal recognizes the many touchpoints along the customer journey.',
    img: getImgProps({
      name: 'tour-page-2',
      width: 218,
      height: 286
    })
  },
  {
    theme: LIGHT,
    title: 'A forecast of the future',
    text:
      'Utilizing a unique algorithm, each channel is assigned a value linked to the probability that it will convert.  We analyze this with other sets of data...',
    img: getImgProps({
      name: 'tour-page-3',
      width: 315,
      height: 270
    })
  },
  {
    theme: DARK,
    title: 'See the big picture',
    text:
      'The Overview page is an at-a-glance look at your overall performance health. See sales, top channels, trending inventory, and a glimpse into shopper activity.',
    img: getImgProps({
      name: 'tour-page-4',
      width: 669,
      height: 433
    })
  },
  {
    theme: DARK,
    title: 'View channel contributions',
    text:
      'Explore Channels to see ad channel contributions at make, model, and VIN levels.  Discover what channels and activities led to a sale.',
    img: getImgProps({
      name: 'tour-page-5',
      width: 669,
      height: 433
    })
  },
  {
    theme: DARK,
    title: 'Unlock your return on ad spend',
    text:
      'Want to see the return that a channel is bringing?  Simply enter your ad spend for channels on the Manage Spend page, accessible from the account menu.',
    img: getImgProps({
      name: 'tour-page-6',
      width: 669,
      height: 433
    })
  },
  {
    theme: DARK,
    title: "See what's trending",
    text:
      'Checkout inventory to see what makes and models are getting noticed with our score based ranking of 0 to 100, with 100 representing maximum engagement.',
    img: getImgProps({
      name: 'tour-page-7',
      width: 669,
      height: 433
    })
  },
  {
    theme: DARK,
    title: 'Checkout shopper activity',
    text:
      "Visit Shoppers to see where your shoppers are along the path to purchase, what they're looking at and an estimate for how far away from sale they are.",
    img: getImgProps({
      name: 'tour-page-8',
      width: 669,
      height: 433
    })
  }
]

export const activateTourPagesSelector = () => [
  {
    theme: LIGHT,
    title: 'Welcome to Activate!',
    text:
      'Instantly and easily create relevant offers & messages for your shoppers.',
    img: getImgProps({
      name: 'activate-tour-page-1',
      width: 600,
      height: 350
    })
  },
  {
    theme: LIGHT,
    title: 'The Right Message',
    text:
      'Use Activate to create broad messaging through a Universal Offer or a hyper-targeted message using Personalized Offers.',
    img: getImgProps({
      name: 'activate-tour-page-2',
      width: 600,
      height: 350
    })
  },
  {
    theme: LIGHT,
    title: 'Universal Offers',
    text:
      'Use Universal Offers to reach all new and returning shoppers to your website. This is a great medium to broadcast a general message.',
    img: getImgProps({
      name: 'activate-tour-page-3',
      width: 600,
      height: 350
    })
  },
  {
    theme: LIGHT,
    title: 'Personalized Offers',
    text:
      'Use Personalized Offers to reach targeted audiences. This is great for messaging to shoppers who are focused on specific vehicles.',
    img: getImgProps({
      name: 'activate-tour-page-4',
      width: 600,
      height: 350
    })
  },
  {
    theme: LIGHT,
    title: 'Audiences',
    text:
      'Use Activate to build audiences depending on your business goals. Activate automatically understands where all shoppers are in the buyer journey and what vehicles they are focused on.',
    img: getImgProps({
      name: 'activate-tour-page-5',
      width: 669,
      height: 350
    })
  },
  {
    theme: LIGHT,
    title: 'Offer Types',
    text:
      'Select either a cash offer or a custom offer. Cash offers allow you designate a specific dollar amount incentive off while custom offers give you the flexibility to create any message you want.',
    img: getImgProps({
      name: 'activate-tour-page-6',
      width: 669,
      height: 350
    })
  },
  {
    theme: LIGHT,
    title: 'Offer Settings',
    text:
      'You can run recurring offers or schedule your offer to run for a specific date range. Easily add a relevant photo depending on your offer. Don’t worry about specific vehicle photos, we got you covered. Activate uses dynamic display technology to automatically create relevant photos for model audiences. Make sure to include any Terms & Conditions when appropriate.',
    img: getImgProps({
      name: 'activate-tour-page-7',
      width: 669,
      height: 350
    })
  },
  {
    theme: LIGHT,
    title: 'All Set!',
    text:
      'In today’s world, shoppers demand relevant experiences. Set yourself apart from the competition by giving them just that! You can find other helpful articles in the Knowledge Base section in the account dropdown.',
    img: getImgProps({
      name: 'activate-tour-page-8',
      width: 669,
      height: 433
    })
  }
]
