import React, { memo } from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import { useTheme } from '@material-ui/core/styles'
import ProgressiveImage from '@signal/components/ProgressiveImage'
import ResponsiveImage from '@signal/components/ResponsiveImage'
import {
  Wrapper,
  ImageWrapper,
  ImageOverlay,
  Image,
  Content,
  Title,
  Text
} from './styles'

export const TourPage = memo(props => {
  const { img, title, text, theme } = props
  const _theme = useTheme()
  const themeBackground = isFunction(theme.background)
    ? theme.background(_theme)
    : theme.background
  const textBackground = isFunction(theme.text.background)
    ? theme.text.background(_theme)
    : theme.text.background
  return (
    <Wrapper backgroundColor={themeBackground}>
      <ResponsiveImage {...img} alt={title}>
        {({ src, srcSet, ...imgProps }) => (
          <ProgressiveImage
            key={src}
            src={src}
            srcSetData={{
              srcSet
            }}
          >
            {(src, loading, srcSetData) => (
              <ImageWrapper>
                <ImageOverlay
                  loading={loading}
                  backgroundColor={themeBackground}
                />
                <Image
                  src={src}
                  srcSet={srcSetData.srcSet}
                  {...theme.image}
                  {...imgProps}
                />
              </ImageWrapper>
            )}
          </ProgressiveImage>
        )}
      </ResponsiveImage>
      <Content backgroundColor={textBackground}>
        <Title color={theme.text.color}>{title}</Title>
        <Text color={theme.text.color}>{text}</Text>
      </Content>
    </Wrapper>
  )
})

TourPage.propTypes = {
  img: PropTypes.shape({
    srcSet: PropTypes.arrayOf(
      PropTypes.shape({
        dpr: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired
      })
    ),
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    text: PropTypes.shape({
      color: PropTypes.string,
      background: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    }),
    image: PropTypes.shape({
      align: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      offset: PropTypes.shape({
        top: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
        right: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
        bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
        left: PropTypes.oneOfType([PropTypes.number, PropTypes.func])
      })
    })
  })
}

export default TourPage
