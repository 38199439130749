import _times from 'lodash/times';
import { SORT_DESCENDING } from './constants';

function desc(a, b, orderBy, valueGetter) {
  if (valueGetter(b, orderBy) < valueGetter(a, orderBy)) {
    return -1;
  }

  if (valueGetter(b, orderBy) > valueGetter(a, orderBy)) {
    return 1;
  }

  return 0;
}

export function stableSort(array, cmp) {
  var stabilizedThis = array.map(function (el, index) {
    return [el, index];
  });
  stabilizedThis.sort(function (a, b) {
    var order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(function (el) {
    return el[0];
  });
}
export function getSorting(order, orderBy, valueGetter) {
  return order === SORT_DESCENDING ? function (a, b) {
    return desc(a, b, orderBy, valueGetter);
  } : function (a, b) {
    return -desc(a, b, orderBy, valueGetter);
  };
} // limit the max pagination according to data set

export var rowsPerPageOptions = function rowsPerPageOptions(numItems, itemsPerPage) {
  var res = _times(Math.floor(numItems / itemsPerPage), function (i) {
    return (i + 1) * itemsPerPage;
  });

  if (numItems > itemsPerPage) {
    res.push(numItems);
  }

  return res;
};