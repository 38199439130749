import { createSelector } from 'reselect'
import styled from 'styled-components'
import MuiBackdrop from '@material-ui/core/Backdrop'
import { fade } from '@material-ui/core/styles/colorManipulator'
import IconButton from '@signal/components/IconButton'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import IconCancel from '@signal/components/Icons/Cancel'
import {
  getColorGreySelector,
  getSpacingSelector
} from '@signal/theme/selectors'

export const backdropColorSelector = createSelector(
  getColorGreySelector('900'),
  color => fade(color, 0.2)
)
export const Backdrop = styled(MuiBackdrop)`
  background-color: ${backdropColorSelector};
`

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid ${getColorGreySelector('300')};
  align-items: flex-start;
`

export const TitleWrapper = styled.div`
  flex: 1;
  padding-left: ${getSpacingSelector(3.5)}px;
  padding-bottom: ${getSpacingSelector(2)}px;
  padding-top: ${getSpacingSelector(2)}px;
`

export const TitlePlaceholder = styled.div`
  flex: 1;
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'h3'
}))``

export const TitleSkeleton = styled(
  Title.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 270
}))``

export const DismissButton = styled(IconButton)`
  margin: ${getSpacingSelector(1)}px;
`

export const DismissIcon = styled(IconCancel).attrs(() => ({
  size: 20
}))``
