/**
 * App Names
 */
export var APP_ATTRIBUTION = 'Attribution';
export var APP_OFFERS = 'Offers';
export var APP_AUDIENCES = 'Audiences';
/**
 * Page Name Constants
 */

function createPageName(app, page) {
  return "".concat(app, "_").concat(page);
} // This constant is used in some places to as a catch for unknown pages. Ideally, it will never be used.
// However, in cases like desktop/src/pages/InventoryDrilldowns/index.js this component represents several
// different "pages" and if any new ones are added the current logic will not support it. At that point
// the page name would be "Page_Unkown" and we can see that in the analytics and then fix the problem.


export var PAGE_UNKNOWN = 'Page_Unknown';
export var PAGE_ACCOUNTS = 'Accounts';
export var PAGE_PROFILE = 'Profile';
export var PAGE_MANAGE_SPEND = 'Manage_Spend';
export var PAGE_OVERVIEW = createPageName(APP_ATTRIBUTION, 'Overview');
export var PAGE_OFFERS_OVERVIEW = createPageName(APP_OFFERS, 'Overview');
export var PAGE_OFFERS_PERSONALIZED = createPageName(APP_OFFERS, 'Personalized');
export var PAGE_OFFERS_PERSONALIZED_CREATE = createPageName(APP_OFFERS, 'Personalized_Create');
export var PAGE_OFFERS_PERSONALIZED_EDIT = createPageName(APP_OFFERS, 'Personalized_Edit');
export var PAGE_OFFERS_UNIVERSAL = createPageName(APP_OFFERS, 'Universal');
export var PAGE_OFFERS_UNIVERSAL_CREATE = createPageName(APP_OFFERS, 'Universal_Create');
export var PAGE_OFFERS_UNIVERSAL_EDIT = createPageName(APP_OFFERS, 'Universal_Edit');
export var PAGE_AUDIENCES_LIST_CREATE = createPageName(APP_AUDIENCES, 'Audiences_List_Create');
export var PAGE_AUDIENCES_LIST_EDIT = createPageName(APP_AUDIENCES, 'Audiences_List_Edit');
export var PAGE_AUDIENCES_LIST = createPageName(APP_AUDIENCES, 'Audiences_List');
export var PAGE_CHANNELS_LIST = createPageName(APP_ATTRIBUTION, 'Channels');
export var PAGE_CHANNEL = createPageName(APP_ATTRIBUTION, 'Channels_Channel');
export var PAGE_CHANNEL_MAKE = createPageName(APP_ATTRIBUTION, 'Channels_Channel_Make');
export var PAGE_CHANNEL_MODEL = createPageName(APP_ATTRIBUTION, 'Channels_Channel_Make_Model');
export var PAGE_CHANNEL_VIN = createPageName(APP_ATTRIBUTION, 'Channels_Channel_Make_Model_VIN');
export var PAGE_INVENTORY_LIST = createPageName(APP_ATTRIBUTION, 'Inventory');
export var PAGE_INVENTORY_MAKE = createPageName(APP_ATTRIBUTION, 'Inventory_Make');
export var PAGE_INVENTORY_MODEL = createPageName(APP_ATTRIBUTION, 'Inventory_Make_Model');
export var PAGE_INVENTORY_VIN = createPageName(APP_ATTRIBUTION, 'Inventory_Make_Model_VIN');
export var PAGE_SHOPPERS = createPageName(APP_ATTRIBUTION, 'Shoppers');
export var PAGE_SHOPPERS_BUCKET = createPageName(APP_ATTRIBUTION, 'Shoppers_UserList');
export var PAGE_SHOPPER = createPageName(APP_ATTRIBUTION, 'Shoppers_UserList_User');