import React from 'react'
import DirectionsCar from '@signal/components/Icons/Car'
import Search from '@signal/components/Icons/Search'
import Visibility from '@signal/components/Icons/Eye'

export const CHANNEL = 'Channel'
export const SALE = 'Sale'
export const SRP_VIEW = 'SrpView'
export const VDP_VIEW = 'VdpView'

export const ICONS_FROM_TYPE = {
  [CHANNEL]: <Search />,
  [SALE]: <DirectionsCar />,
  [SRP_VIEW]: <Visibility />,
  [VDP_VIEW]: <Visibility />
}

export const TITLE = 'User Activity'
export const DESCRIPTION =
  'View a timeline of activity associated with this user'
