import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import select from '@signal/utils/select';
import createDeepEqualSelector from '@signal/utils/createDeepEqualSelector';
import { AUDIENCE_DETAIL } from '@signal/routes';
import { createRouteParamsSelector, audiencesPathSelector } from '../router/selectors';
import { REDUCER_KEY, SHOW_AUDIENCE_CREATED } from './constants';
export var rootSelector = select(REDUCER_KEY);
export var audienceCreateModalRootSelector = createSelector(rootSelector, select('createModal'));
export var formsSelector = createSelector(rootSelector, select('forms'));
export var formSelector = createSelector(formsSelector, function (state, _ref) {
  var id = _ref.id;
  return id;
}, function (forms) {
  var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'create';
  return get(forms, id);
});
export var formTargetSelector = createSelector(formSelector, select('target'));
export var formSegmentsSelector = createSelector(formSelector, select('segments'));
export var formSegmentsLoadingSelector = createSelector(formSegmentsSelector, select('isLoading', false));
export var formSegmentsDataSelector = createSelector(formSegmentsSelector, select('data'));
export var formSegmentsPotentialReachSelector = createSelector(formSegmentsDataSelector, select('currentReach', 0));
export var formSegmentsErrorSelector = createSelector(formSegmentsSelector, select('error'));
export var formAudienceSelector = createSelector(formSelector, select('audience'));
export var formAudienceDataSelector = createSelector(formAudienceSelector, select('data'));
var ENABLE_MOCK_DATA = false;

var mockListReducer = function mockListReducer() {
  return {
    isLoading: false,
    isLoaded: true,
    data: [{
      id: '1',
      audienceName: 'Test Audience 1',
      status: 'active',
      uniqueCustomers: 6294,
      updatedAt: '2019-01-14T20:12:54.588Z'
    }, {
      id: '2',
      audienceName: 'Test Audience 2',
      status: 'active',
      uniqueCustomers: 2112,
      updatedAt: '2019-01-17T20:12:54.588Z'
    }]
  };
};

export var audienceListSelector = ENABLE_MOCK_DATA ? mockListReducer : createDeepEqualSelector(rootSelector, select('list'));
export var audienceListLoadingSelector = createDeepEqualSelector(audienceListSelector, select('loading'));
export var audienceListLoadedSelector = createDeepEqualSelector(audienceListSelector, select('loaded'));
export var audienceListDataSelector = createDeepEqualSelector(audienceListSelector, select('data'));
export var audiencesBreadcrumbSelector = createSelector(audiencesPathSelector, function (to) {
  return {
    label: 'Audiences',
    to: to
  };
});
export var audienceDetailsRouteParamsSelector = createRouteParamsSelector(AUDIENCE_DETAIL);
export var audienceDetailsIdParamSelector = createSelector(audienceDetailsRouteParamsSelector, function (_ref2) {
  var audienceId = _ref2.audienceId;
  if (isNil(audienceId) || audienceId === 'create') return null;
  return audienceId;
});
export var showAudienceCreatedSelector = createSelector(audienceCreateModalRootSelector, select(SHOW_AUDIENCE_CREATED));