import { stringify } from 'query-string';
import getAPIDomain from '@signal/utils/env/apiDomain';
import { AUTH_POLICY } from '../constants';
/**
 * this function yields the URL to redirect to after logout
 * @return {String} the URL to redirect to after logout
 */

export var getLogoutRedirectUrl = function getLogoutRedirectUrl() {
  var _window$location = window.location,
      origin = _window$location.origin,
      pathname = _window$location.pathname,
      search = _window$location.search;
  var loginRedirectQueryString = stringify({
    policy: AUTH_POLICY,
    redirect: "".concat(origin).concat(pathname).concat(search)
  });
  var sheriffRoot = getAPIDomain('/sheriff/external');
  var logoutQueryString = stringify({
    redirect: "".concat(sheriffRoot, "/v1/user/auth?").concat(loginRedirectQueryString)
  });
  return "".concat(sheriffRoot, "/v1/user/logout?").concat(logoutQueryString);
};
/**
 * this function yields the URL to redirect to after auth failure
 * @return {String} the URL to redirect to after auth failure
 */

export var getAuthFailureRedirectUrl = function getAuthFailureRedirectUrl() {
  var _window$location2 = window.location,
      origin = _window$location2.origin,
      pathname = _window$location2.pathname,
      search = _window$location2.search;
  var qs = stringify({
    policy: AUTH_POLICY,
    redirect: "".concat(origin).concat(pathname).concat(search)
  });
  return "".concat(getAPIDomain('/sheriff/external'), "/v1/user/auth?").concat(qs);
};