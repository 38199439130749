import styled, { css } from 'styled-components'
import IconButton from '@signal/components/IconButton'
import { PictureAsPdf } from '@material-ui/icons'
import { getColorGreySelector } from '@signal/theme/selectors'

export const Button = styled(IconButton)`
  @media print {
    :hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`

export const ExportIcon = styled(PictureAsPdf)`
  ${({ disabled }) =>
    disabled
      ? css`
          fill: ${getColorGreySelector('lightest')};
        `
      : ''};
`
