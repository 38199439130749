import { createRoute } from '@signal/router';
import { ACCOUNT } from './core';
export var OFFERS = createRoute(ACCOUNT, 'offers');
export var OFFERS_OVERVIEW = createRoute(OFFERS, 'overview');
export var OFFERS_PERSONALIZED = createRoute(OFFERS, 'personalized');
export var OFFERS_PERSONALIZED_CREATE = createRoute(OFFERS_PERSONALIZED, 'create');
export var OFFERS_PERSONALIZED_EDIT = createRoute(OFFERS_PERSONALIZED, ':offerId/edit');
export var OFFERS_PERSONALIZED_STATS = createRoute(OFFERS_PERSONALIZED, ':offerId/stats');
export var OFFERS_UNIVERSAL = createRoute(OFFERS, 'universal');
export var OFFERS_UNIVERSAL_CREATE = createRoute(OFFERS_UNIVERSAL, 'create');
export var OFFERS_UNIVERSAL_EDIT = createRoute(OFFERS_UNIVERSAL, ':offerId/edit');
export var OFFERS_UNIVERSAL_STATS = createRoute(OFFERS_UNIVERSAL, ':offerId/stats');