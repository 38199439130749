var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import { css } from 'styled-components';
import { createSelector } from 'reselect';
import { maxPageWidthSelector, breakpointTabletSelector, breakpointMobileLandscapeSelector, navigationBarHeightSelector, navigationBarHeightTabletSelector, navigationBarHeightLandscapeSelector, navigationBarOuterHeightSelector, navigationBarOuterHeightTabletSelector, navigationBarOuterHeightLandscapeSelector, getSpacingSelector } from '../selectors';
export * from './utils';
/**
 * CSS mixins that can be re-used across multiple `styled-components` components
 * @typedef {Function} StyledComponentMixin
 * @global
 */

/**
 * Mixin used to prevent margins of a component from collapsing, which can
 * sometimes cause unexpected behavior.
 * @type {StyledComponentMixin}
 */

export var clearfix = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:before {\n    content: '';\n    display: table;\n    clear: both;\n  }\n\n  &:after {\n    content: '';\n    display: table;\n    clear: both;\n  }\n"])));
/**
 * Mixin to constrain a component to the max page width of the app and center
 * it (horizontally) on the page.
 * @type {StyledComponentMixin}
 */

export var constrainToMaxPageWidth = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: ", "px;\n  padding: 0 ", "px;\n  margin-left: auto;\n  margin-right: auto;\n"])), maxPageWidthSelector, getSpacingSelector(3));
/**
 * Mixin intended for use in components that need to match the height of the
 * navigation bar.
 * @type {StyledComponentMixin}
 */

export var navigationBarHeight = css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: ", "px;\n\n  ", " {\n    height: ", "px;\n  }\n\n  ", " {\n    height: ", "px;\n  }\n\n  &&& {\n    min-height: initial;\n  }\n"])), navigationBarHeightSelector, breakpointTabletSelector, navigationBarHeightTabletSelector, breakpointMobileLandscapeSelector, navigationBarHeightLandscapeSelector);

var declareTopStyles = function declareTopStyles(top) {
  return "\n  top: ".concat(top, "px;\n");
};

var declareTopStylesWithSafeAreas = function declareTopStylesWithSafeAreas(top) {
  return "\n  top: ".concat(top, "px;\n  /* iOS < 11.2 */\n  top: calc(").concat(top, "px + constant(safe-area-inset-top));\n  /* iOS > 11.2*/\n  top: calc(").concat(top, "px + env(safe-area-inset-top));\n");
};

export var stickyHeaderTop = css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n\n  ", " {\n    ", "\n  }\n\n  ", " {\n    ", "\n  }\n"])), createSelector(navigationBarOuterHeightSelector, declareTopStylesWithSafeAreas), breakpointTabletSelector, createSelector(navigationBarOuterHeightTabletSelector, declareTopStylesWithSafeAreas), breakpointMobileLandscapeSelector, createSelector(navigationBarOuterHeightLandscapeSelector, declareTopStyles));