var _templateObject, _templateObject2;

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import { css } from 'styled-components';
import { createSelector } from 'reselect';
import flow from 'lodash/flow';
import reduce from 'lodash/reduce';
import add from 'lodash/add';
import isNumber from 'lodash/isNumber';
import { breakpointTabletSelector, breakpointMobileLandscapeSelector } from '../selectors';
/**
 * Creates a mixin that's commonly used to set a component's height/min-height
 * to the viewport height minus the heights of any persistant layout elements.
 * @param {string} heightProperty - height or min-height
 * @param {Object} heightSelectors - height selectors for different breakpoints
 * @param {[Function]} heightSelectors.default - height selectors
 * @param {[Function]} heightSelectors.tablet - height selectors for tablet breakpoint
 * @param {[Function]} heightSelectors.landscape - height selectors for landscape breakpoint
 * @return {StyledComponentMixin}
 */

export function createViewportHeightMixin(heightProperty, heightSelectors) {
  var combineHeights = function combineHeights() {
    for (var _len = arguments.length, heights = new Array(_len), _key = 0; _key < _len; _key++) {
      heights[_key] = arguments[_key];
    }

    return reduce(heights, add, 0);
  };

  var declareStyles = function declareStyles(height) {
    return "\n    ".concat(heightProperty, ": calc(100vh - ").concat(height, "px);\n  ");
  };

  var declareStylesWithSafeAreas = function declareStylesWithSafeAreas(height) {
    return withSafeAreas(heightProperty, "calc(100vh - ".concat(height, "px)"), ['top', 'bottom'], '-');
  };

  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    /* min-height should be 100vh minus header/footer height minus 1px (border on header) */\n    ", "\n\n    ", " {\n      ", "\n    }\n\n    ", " {\n      ", "\n    }\n  "])), createSelector.apply(void 0, _toConsumableArray(heightSelectors.default).concat([flow([combineHeights, declareStylesWithSafeAreas])])), breakpointTabletSelector, createSelector.apply(void 0, _toConsumableArray(heightSelectors.tablet).concat([flow([combineHeights, declareStylesWithSafeAreas])])), breakpointMobileLandscapeSelector, createSelector.apply(void 0, _toConsumableArray(heightSelectors.landscape).concat([flow([combineHeights, declareStyles])])));
}
export function withSafeAreas(property, value, safeAreas) {
  var separator = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '+';
  if (isNumber(value)) value = "".concat(value, "px");

  var wrapWith = function wrapWith(wrapper) {
    return function (variable) {
      return "".concat(wrapper, "(").concat(variable, ")");
    };
  };

  var safeAreaVariables = safeAreas.map(function (safeArea) {
    return "safe-area-inset-".concat(safeArea);
  });
  var safeAreaConstants = safeAreaVariables.map(wrapWith('constant'));
  var safeAreaEnvs = safeAreaVariables.map(wrapWith('env'));
  var separatorStr = " ".concat(separator, " ");
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    ", ": ", ";\n    /* iOS < 11.2 */\n    ", ": calc(\n      ", "\n    );\n    /* iOS > 11.2*/\n    ", ": calc(\n      ", "\n    );\n  "])), property, value, property, [value.replace('calc', '')].concat(_toConsumableArray(safeAreaConstants)).join(separatorStr), property, [value.replace('calc', '')].concat(_toConsumableArray(safeAreaEnvs)).join(separatorStr));
}