var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { createSelector } from 'reselect';
import MuiAvatar from '@material-ui/core/Avatar';
import { getColorBackgroundSelector, getColorTextSelector, typographySelector } from '@signal/theme/selectors';
import { BASE_SIZE, BASE_FONT_SIZE, FONT_MULTIPLIER } from './constants';
export var propSizeSelector = function propSizeSelector(props) {
  return props.size;
};
export var iconSizeSelector = createSelector(propSizeSelector, function (size) {
  return size + 4;
});
export var fontSizeSelector = createSelector(propSizeSelector, typographySelector, function (size, typography) {
  var sizeDiff = size - BASE_SIZE;
  var scaleAmount = sizeDiff ? Math.round(sizeDiff / FONT_MULTIPLIER) : 0; // font size must scale based on size

  var scaledFontSize = BASE_FONT_SIZE + scaleAmount;
  return typography.pxToRem(scaledFontSize);
});
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Avatar = styled(MuiAvatar).attrs(function () {
  return {
    className: 'sig-avatar'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  font-size: ", ";\n  color: ", ";\n"])), getColorBackgroundSelector(), fontSizeSelector, getColorTextSelector());
export var TitleAvatar = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: relative;\n  width: ", "px;\n  height: ", "px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .sig-avatar {\n    width: ", "px;\n    height: ", "px;\n  }\n"])), iconSizeSelector, iconSizeSelector, propSizeSelector, propSizeSelector);
export var Icon = styled.img(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  z-index: 1;\n"])));