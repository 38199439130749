import React, { memo } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { PAGE_OVERVIEW, APP_ATTRIBUTION } from '@signal/analytics'
import { isMissingDataSelector } from '@signal/core/overview/selectors'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefault from '../../layouts/Default'
import Header from '../../containers/AttributionHeader'
import OverviewHeading from '../../containers/OverviewHeading'
import OverviewSnapshot from '../../containers/OverviewSnapshot'
import OverviewTopChannels from '../../containers/OverviewTopChannels'
import OverviewTopInventory from '../../containers/OverviewTopInventory'
import OverviewShopperActivity from '../../containers/OverviewShopperActivity'
import EnterSpendPrompt from '../../containers/EnterSpendPrompt'
import MissingDataBoundary from '../../components/MissingDataBoundary'
import { PageWrapper } from '../styles'

export const OverviewPage = memo(props => {
  const { isMissingData } = props
  usePageTrack(PAGE_OVERVIEW, APP_ATTRIBUTION)

  return (
    <LayoutDefault navbar={Header}>
      <PageWrapper>
        <OverviewHeading />
        <MissingDataBoundary isMissingData={isMissingData} dataType="Channel">
          <OverviewSnapshot {...props} />
          <EnterSpendPrompt />
          <OverviewTopChannels {...props} />
        </MissingDataBoundary>
        <OverviewTopInventory {...props} />
        <OverviewShopperActivity {...props} />
      </PageWrapper>
    </LayoutDefault>
  )
})

const mapStateToProps = createStructuredSelector({
  isMissingData: isMissingDataSelector
})

export default connect(mapStateToProps)(OverviewPage)
