import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import componentPropType from '@signal/prop-types/component'
import renderIf from '@signal/utils/renderIf'
import {
  RecedeIconBack,
  AdvanceIconForward,
  AdvanceIconFinish,
  AdvanceTourButton,
  AdvanceTourOuterWrapper,
  RecedeTourButton,
  RecedeTourOuterWrapper,
  CancelTourButton,
  CancelIcon,
  RestartButton,
  SkipButton,
  TourDialog,
  TourInnerWrapper,
  TourStepper,
  TourStepperOuterWrapper,
  TourStepperInnerWrapper
} from './styles'

export class Tour extends PureComponent {
  static getDerivedStateFromProps({ showTour }) {
    if (!showTour) {
      // reset active tour to beginning when hidden
      return {
        activeTourStep: 0
      }
    }
    return null
  }

  static propTypes = {
    /**
     * Tour pages are passed in as an array of children.
     */
    children: PropTypes.arrayOf(componentPropType).isRequired,
    showTour: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired
  }

  state = {
    activeTourStep: 0
  }

  onAdvanceTour = () => {
    const { children: tourPages } = this.props
    const { activeTourStep } = this.state
    if (activeTourStep < tourPages.length - 1) {
      this.setState(prevState => ({
        activeTourStep: prevState.activeTourStep + 1
      }))
    }
  }

  onFinishTour = () => {
    const { onDismiss } = this.props
    onDismiss()
  }

  onKeyDown = evt => {
    switch (evt.key) {
      case 'ArrowLeft':
        this.onRecedeTour()
        break
      case 'ArrowRight':
        this.onAdvanceTour()
        break
      default:
        break
    }
  }

  onRecedeTour = () => {
    const { activeTourStep } = this.state
    if (activeTourStep > 0) {
      this.setState(prevState => ({
        activeTourStep: prevState.activeTourStep - 1
      }))
    }
  }

  onRestartTour = () => {
    this.setState({
      activeTourStep: 0
    })
  }

  onTourStepChange = activeStep => {
    this.setState({
      activeTourStep: activeStep
    })
  }

  render() {
    const { showTour, onDismiss, children: tourPages, ...rest } = this.props
    const { activeTourStep } = this.state
    return (
      <TourDialog
        open={showTour}
        onKeyDown={this.onKeyDown}
        onClose={onDismiss}
        maxWidth={false}
        PaperProps={{
          elevation: 0
        }}
        {...rest}
      >
        {tourPages[activeTourStep]}
        {renderIf(activeTourStep === tourPages.length - 1, () => (
          <RestartButton onClick={this.onRestartTour}>Restart</RestartButton>
        ))}
        <SkipButton onClick={this.onFinishTour}>Skip</SkipButton>
        {renderIf(activeTourStep > 0, () => (
          <RecedeTourOuterWrapper>
            <TourInnerWrapper>
              <RecedeTourButton onClick={this.onRecedeTour}>
                <RecedeIconBack />
              </RecedeTourButton>
            </TourInnerWrapper>
          </RecedeTourOuterWrapper>
        ))}
        <AdvanceTourOuterWrapper>
          <TourInnerWrapper>
            {renderIf(
              activeTourStep < tourPages.length - 1,
              () => (
                <AdvanceTourButton onClick={this.onAdvanceTour}>
                  <AdvanceIconForward />
                </AdvanceTourButton>
              ),
              () => (
                <AdvanceTourButton onClick={this.onFinishTour}>
                  <AdvanceIconFinish />
                </AdvanceTourButton>
              )
            )}
          </TourInnerWrapper>
        </AdvanceTourOuterWrapper>
        <CancelTourButton onClick={this.onFinishTour}>
          <CancelIcon />
        </CancelTourButton>
        <TourStepperOuterWrapper>
          <TourStepperInnerWrapper>
            <TourStepper
              activeStep={activeTourStep}
              stepCount={tourPages.length}
              onStepChange={this.onTourStepChange}
            />
          </TourStepperInnerWrapper>
        </TourStepperOuterWrapper>
      </TourDialog>
    )
  }
}

export default Tour
