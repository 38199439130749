import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userIdSelector } from '@signal/core/user'
import Layout from '@signal/layouts/Layout'
import { CUSTOM_REPORT } from '@signal/routes'
import { ReportLoadable } from '../../../loadables/dashboard'
import { CustomReportLoadable } from '../../../loadables/dashboard'

function ReportView({ isShared }) {
  const userId = useSelector(userIdSelector)
  const { reportSlug } = useParams()

  const Loadable =
    isShared || reportSlug === CUSTOM_REPORT || reportSlug === userId
      ? CustomReportLoadable
      : ReportLoadable
  return (
    <Layout constrainWidth={false} disableGutter>
      <Loadable readOnly={isShared} />
    </Layout>
  )
}

export default memo(ReportView)
