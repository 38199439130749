import styled from 'styled-components'
import { getSpacingSelector } from '@signal/theme/selectors'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'

export const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  grid-column-gap: ${getSpacingSelector(2.5)}px;
`

export const Column = styled.div``

export const Wrapper = styled.div``

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Timestamp = styled(Typography).attrs(() => ({
  variant: 'body2'
}))``

export const TimestampSkeleton = styled(
  Timestamp.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 90
}))``
