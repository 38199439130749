import React from 'react'
import AudienceDetailsFormSkeleton from '../../components/AudienceDetailsForm/Skeleton'
import LiveSummarySkeleton from '../../components/LiveSummary/Skeleton'
import {
  Wrapper,
  FormDetailsWrapper,
  ControlsWrapper,
  ControlsSeparator,
  SummaryWrapper,
  ClearButton,
  SaveButton,
  ActionsWrapper
} from './styles'

export default function AudienceDetailFormContainerSkeleton() {
  return (
    <Wrapper>
      <FormDetailsWrapper>
        <ControlsWrapper>
          <AudienceDetailsFormSkeleton />
        </ControlsWrapper>
        <ControlsSeparator />
        <SummaryWrapper>
          <LiveSummarySkeleton numGroups={2} numLabels={[2, 2]} />
        </SummaryWrapper>
      </FormDetailsWrapper>
      <ActionsWrapper>
        <ClearButton disabled>Cancel</ClearButton>
        <SaveButton disabled>Save Audience</SaveButton>
      </ActionsWrapper>
    </Wrapper>
  )
}
