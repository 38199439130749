import styled, { css } from 'styled-components'
import { getSpacingSelector } from '@signal/theme/selectors'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100vw;
  height: 60vh;
  justify-content: center;
  align-items: center;
`

export const GridWrapper = styled.div`
  height: 100%;

  @media print {
    padding: 0 ${getSpacingSelector(2)}px;
  }

  ${({ printMode }) =>
    printMode &&
    css`
      @media print {
        @page {
          size: landscape;
        }
      }

      width: 1480px;
    `}
`
