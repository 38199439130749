var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import MuiLinearProgress from '@material-ui/core/LinearProgress';
import Typography from '../Typography';
import TypographySkeleton from '../Skeleton/Typography';
import { getColorBackgroundSelector } from '@signal/theme/selectors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
export var PercentTypography = styled(Typography).attrs(function () {
  return {
    variant: 'body3'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-right: 10px;\n  min-width: 36px;\n"])));
export var PercentTypographySkeleton = styled(PercentTypography.withComponent(TypographySkeleton)).attrs(function () {
  return {
    width: 30
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var ContributionProgress = styled(MuiLinearProgress).attrs(function () {
  return {
    variant: 'determinate'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  width: 100%;\n"])), getColorBackgroundSelector());
export var ContributionProgressSkeleton = styled(ContributionProgress).attrs(function () {
  return {
    value: 0
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));