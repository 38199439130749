import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import {
  getSpacingSelector,
  getColorPrimarySelector,
  getColorBackgroundSelector
} from '@signal/theme/selectors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacingSelector(1)}px 0;
`

export const Actions = styled(List)`
  display: flex;
  flex-direction: column;
  padding: 0;
`

export const Action = styled(ListItem)`
  padding: ${getSpacingSelector(1)}px ${getSpacingSelector(2)}px;
  cursor: pointer;
  &:hover span {
    color: ${getColorPrimarySelector()};
    background-color: ${getColorBackgroundSelector('paper')};
  }
`
