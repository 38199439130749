import React from 'react'
import { compose } from 'redux'
import withAuth from '@signal/hoc/withAuth'
import withStore from '@signal/hoc/withStore'

// TODO: get rid of this once we port all user/account fetching to the context fetcher
export function ReduxCrutchLoaderComponent() {
  return <></>
}

export const foo = compose(withAuth(), withStore)(ReduxCrutchLoaderComponent)

export default ReduxCrutchLoaderComponent
