var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getColorBackgroundSelector, getSpacingSelector } from '@signal/theme/selectors';
import Search from '../Icons/Search';
import TextField from '../TextField';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Input = styled(TextField)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n\n  > div {\n    border: 0;\n    border-radius: 6px;\n    background: ", ";\n  }\n"])), getColorBackgroundSelector('paper'));
export var EndAdornment = styled(InputAdornment)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-height: initial;\n"])));
export var StartAdornment = styled(EndAdornment)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-left: ", "px;\n  margin-right: 0;\n"])), getSpacingSelector());
export var SearchIcon = styled(Search)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 28px;\n  height: 28px;\n"])));