import queryString from 'query-string';
import { getLocation } from 'connected-react-router';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import createDeepEqualSelector from '@signal/utils/createDeepEqualSelector';
import select from '@signal/utils/select';
import { searchSelector, performanceChannelsPathSelector, performanceInventoryPathSelector, performanceChannelsDetailPathSelector, performanceInventoryDetailPathSelector, partialChannelsVinSelector, partialInventoryVinSelector, manageSpendPathSelector } from '../router/selectors';
import { getBackPathParams, parsePerformanceParams } from './utils';
import { INVENTORY_IDS_AT_LVL, CHANNELS_IDS_AT_LVL } from './constants';
export var stackSelector = createSelector(searchSelector, function (search) {
  return get(parsePerformanceParams(search), 'stack', []);
});
export var performanceSelector = select('performance');
export var performanceChannelsSelector = createSelector(performanceSelector, select('channels'));
export var isLoadingSelector = createDeepEqualSelector(performanceChannelsSelector, select('loading'));
export var isLoadedSelector = createDeepEqualSelector(performanceChannelsSelector, select('loaded'));
export var dataSelector = createDeepEqualSelector(performanceChannelsSelector, select('data'));
export var dateDisplaySelector = createSelector(dataSelector, select('dateDisplay'));
export var channelPerfSelector = createSelector(dataSelector, select('channelPerf'));
export var inventoryPerfSelector = createSelector(dataSelector, select('inventoryPerf'));
export var channelsBreadcrumbSelector = createSelector(performanceChannelsPathSelector, function (to) {
  return {
    label: 'Channels',
    to: to
  };
});
export var inventoryBreadcrumbSelector = createSelector(performanceInventoryPathSelector, function (to) {
  return {
    label: 'Inventory',
    to: to
  };
});
export var channelsTooltipSelector = createSelector(manageSpendPathSelector, function (manageSpendPath) {
  return {
    'attributed sales': {
      text: 'Dollar value of sales revenue generated by channel',
      linkText: null,
      to: null
    },
    sales: {
      text: 'Dollar value of sales revenue generated by channel',
      linkText: null,
      to: null
    },
    'frontend margin': {
      text: 'Dollar value of frontend profit margin generated by channel',
      linkText: null,
      to: null
    },
    'backend margin': {
      text: 'Dollar value of backend profit margin generated by channel',
      linkText: null,
      to: null
    },
    'total margin': {
      text: 'Dollar value of total profit margin generated by channel',
      linkText: null,
      to: null
    },
    'contribution to sold vins': {
      text: 'Percentage that this channel contributed towards VINs whose sales they influenced',
      linkText: null,
      to: null
    },
    'influenced vins': {
      text: 'The total number of vehicles whose sales were influenced by ad spend',
      linkText: null,
      to: null
    },
    roas: {
      text: 'Also known as Return on Ad Spend, is the amount of revenue earned from this channel per dollar of',
      linkText: 'Ad Spend',
      to: manageSpendPath,
      inlineLink: true
    },
    roi: {
      text: 'Also known as Return on Investment, is the value returned from the amount spent relative to the current margin value'
    },
    spent: {
      text: 'for channels to get more out of your data',
      linkText: 'Add or Edit your ad spend',
      to: manageSpendPath,
      prependLink: true,
      inlineLink: true
    }
  };
});
/**
 * Inventory Data Selectors
 */

export var inventoryPerformanceStateSelector = createSelector(performanceSelector, select('inventory'));
export var inventoryPerformanceDataSelector = createSelector(inventoryPerformanceStateSelector, select('data'));
export var inventoryPerformanceDateDisplaySelector = createSelector(inventoryPerformanceDataSelector, select('dateDisplay'));
export var inventoryPerformanceDrilldownsSelector = createSelector(inventoryPerformanceDataSelector, select('drilldowns'));
export var isInventoryPerformanceDataLoadingSelector = createSelector(inventoryPerformanceStateSelector, select('loading'));
export var isInventoryPerformanceDataLoadedSelector = createSelector(inventoryPerformanceDataSelector, function (data) {
  return !!data;
});
export var inventoryTooltipSelector = function inventoryTooltipSelector() {
  return {
    'avg days on lot': {
      text: 'Average amount of time vehicles have spent on your lot',
      linkText: null,
      to: null
    },
    vins: {
      text: 'The number of vehicles whose sales were influenced by this channel',
      linkText: null,
      to: null
    },
    engagement: {
      text: 'Assessment of engagement level based off engagement score',
      linkText: null,
      to: null
    },
    'engagement score': {
      text: 'Score based off a scale of 0 - 100, with 100 representing maximum engagement',
      linkText: null,
      to: null
    }
  };
};
export var createPerformanceQueryBackPathSelector = function createPerformanceQueryBackPathSelector(overviewPathSelector, detailPathSelector, idsAtLvl) {
  return createSelector(getLocation, overviewPathSelector, detailPathSelector, function (location, overviewPath, detailPath) {
    var search = getBackPathParams(location, idsAtLvl);
    if (isEmpty(search)) return overviewPath;
    return {
      search: queryString.stringify(search),
      pathname: detailPath
    };
  });
};
export var inventoryBackPathSelector = createPerformanceQueryBackPathSelector(performanceInventoryPathSelector, performanceInventoryDetailPathSelector, INVENTORY_IDS_AT_LVL);
export var channelBackPathSelector = createPerformanceQueryBackPathSelector(performanceChannelsPathSelector, performanceChannelsDetailPathSelector, CHANNELS_IDS_AT_LVL);
export function createPerformanceItemPathSelector(detailPathSelector, partialVinPathSelector) {
  return createSelector(detailPathSelector, partialVinPathSelector, function (detailPath, createVinPath) {
    return function (drilldownPath) {
      // There are currently two potential types of drilldowns. A VIN related drilldown
      // and a non-VIN related drilldown. So the logic below determines whether a drilldown
      // is or is not VIN related and returns the correct path.
      if (drilldownPath.includes('vin')) {
        var vin = drilldownPath.split('/').pop();
        return createVinPath(vin);
      }

      return detailPath;
    };
  });
} // This selector returns the path prefix for the formatted drilldowns
// based on the current breakdown. If the breakdown is by VIN
// then it will return the vin path selector.

export var performanceChannelDrilldownPathSelector = createPerformanceItemPathSelector(performanceChannelsDetailPathSelector, partialChannelsVinSelector);
export var performanceChannelGroupDrilldownPathSelector = createPerformanceItemPathSelector(performanceChannelsPathSelector, partialChannelsVinSelector);
export var performanceInventoryDrilldownPathSelector = createPerformanceItemPathSelector(performanceInventoryDetailPathSelector, partialInventoryVinSelector);
/**
 * This selector creator returns a selector that will reduce a list of performance items through the
 * provided list of reducers.
 *
 * @param {*} itemsSelector - performance items selector
 * @param {*} pathSelector - a selector that should return either a `Function` or `String` that
 * will be used as the pathname for the items link
 * @param {*} reducers - an array of functions that the items will be reduced through (from left to right)
 *
 * A reducer is a function thar receives the previously reduced items, the path to prefix the item with,
 * and the current location as parameters. It should return a list of items that will be passed to the
 * next reducer.
 *
 * @returns {Selector} - a reselect selector
 */

export function createFormattedPerformanceItemSelector(itemsSelector, pathSelector, reducers) {
  return createSelector(itemsSelector, pathSelector, getLocation, function (items, path, location) {
    return reducers.reduce(function (reducedItems, reducer) {
      return reducer(reducedItems, path, location);
    }, items);
  });
}