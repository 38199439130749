import React from 'react'
import {
  OFFERS_OVERVIEW,
  OFFERS_PERSONALIZED,
  OFFERS_PERSONALIZED_CREATE,
  OFFERS_PERSONALIZED_EDIT,
  OFFERS_UNIVERSAL,
  OFFERS_UNIVERSAL_CREATE,
  OFFERS_UNIVERSAL_EDIT
} from '@signal/routes'
import { useSelector } from 'react-redux'
import Unauthorized from '@signal/components/Unauthorized'
import { hasActivateWriteSelector } from '@signal/core/auth'
import { Switch, Route } from 'react-router'
import Personalized from './Personalized'
import Universal from './Universal'
import UniversalCreate from './UniversalCreate'
import UniversalEdit from './UniversalEdit'
import Overview from './Overview'
import PersonalizedCreate from './PersonalizedCreate'
import PersonalizedEdit from './PersonalizedEdit'
import NotFound from './../NotFound'
import Header from '../../containers/OffersHeader'
import SignalPage from '../SignalPage'

export default function OffersView() {
  const hasWriteAccess = useSelector(hasActivateWriteSelector)

  function getProtectedComponent(component) {
    return hasWriteAccess ? component : Unauthorized
  }

  return (
    <SignalPage header={Header}>
      <Switch>
        <Route path={OFFERS_OVERVIEW.path} component={Overview} exact />
        <Route
          path={OFFERS_PERSONALIZED_CREATE.path}
          component={getProtectedComponent(PersonalizedCreate)}
          exact
        />
        <Route
          path={OFFERS_PERSONALIZED_EDIT.path}
          component={getProtectedComponent(PersonalizedEdit)}
          exact
        />
        <Route path={OFFERS_PERSONALIZED.path} component={Personalized} />
        <Route
          path={OFFERS_UNIVERSAL_CREATE.path}
          component={getProtectedComponent(UniversalCreate)}
          exact
        />
        <Route
          path={OFFERS_UNIVERSAL_EDIT.path}
          component={getProtectedComponent(UniversalEdit)}
          exact
        />
        <Route path={OFFERS_UNIVERSAL.path} component={Universal} />
        <Route component={NotFound} />
      </Switch>
    </SignalPage>
  )
}
