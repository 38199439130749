function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

import differenceInDays from 'date-fns/differenceInDays';
import isArray from 'lodash/isArray';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import { parse, stringify } from 'query-string';
import parseDate from '@signal/utils/parseDate';
import { ALL_MANAGED_DATE_PARAMETERS, ALL_MANAGED_QUERY_PARAMETERS, GLOBAL_QUERY_PARAMETERS, QP_COMPARISON_DATE_START, QP_COMPARISON_DATE_END, QP_COLUMN_SET, QP_DATE_START, QP_DATE_END, QP_FILTERS, QP_METRICS, QP_SUBST_CHAR } from './constants';
var QP_SUBST_REGEX = new RegExp("".concat(QP_SUBST_CHAR, ".+").concat(QP_SUBST_CHAR), 'g');
/**
 * this function converts legacy route filter formats to the new format for compatibility with old bookmarks
 * @param {Array/Object} routeFilters - an array of route filter objects what may be a non-array object (old style)
 * @return {Array} - the modern array format for filters
 */

function ensureRouteFilterIsArray(routeFilters) {
  return isArray(routeFilters) ? routeFilters : // convert from legacy array format for filters in route
  map(routeFilters, function (value, name) {
    return {
      name: name,
      value: value
    };
  });
}
/**
 * validation functions
 */

/**
 * this function validates that a date range is valid (has a end date that comes after a start date
 * @param {Object} - an object with a startDate and endDate, which should both be Javascript Date() objects
 * @return {Boolean} - true if this date range was valid
 */


export function validateDateRange() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      startDate = _ref.startDate,
      endDate = _ref.endDate;

  return isDate(startDate) && isDate(endDate) && differenceInDays(endDate, startDate) >= 0;
}
/**
 * this function validates that the given date range is valid
 * @param {Object} - an object with these members:
 *   {Date} startDate - the start date, as a JS date
 *   {Date} endDate - the end date, as a JS date
 * @return {Boolean} - true if this date range was valid
 */

export function validateDates(_ref2) {
  var startDate = _ref2.startDate,
      endDate = _ref2.endDate;
  return startDate && endDate && validateDateRange({
    startDate: startDate,
    endDate: endDate
  });
}
/**
 * this function validates filters
 * @param {Array} filters - an array of route filter objects
 * @return {Boolean} - whether the given filters were valid
 */

export function validateFilters(filters) {
  return isArray(filters) && filters.every(function (filter) {
    var filterKeys = Object.keys(filter);
    return filterKeys.includes('filter') || filterKeys.includes('name');
  });
}
/**
 * this function validates metrics
 * @param {Array} metrics - an array of metrics
 * @return {Boolean} - whether the given metrics were valid
 */

export function validateMetrics(metrics) {
  return isArray(metrics) && metrics.every(function (metric) {
    return isString(metric);
  });
}
/**
 * this function validates table column sets when they are in their JSON (non-string) form
 * they are also valid as a string which refers to a named column set, but that form is not validated here
 * @param {Array} columnSet - an array of indexes (a column set)
 * @return {Boolean} - whether the given column set was valid JSON
 */

export function validateTableColumnSetJSON(columnSet) {
  return isArray(columnSet) && columnSet.every(function (index) {
    return isNumber(index);
  });
}
/**
 * this function "cooks" a query parameter string value into a non-string type
 * @param {String} qpType - the type of query parameter being requested (one of the QP_ constants)
 * @param {String} value - the string value found for this query parameter
 * @return {Varies} - the resulting cooked value, which varies by query parameter, or undefined if not found
 */

export function cookManagedQPValue(qpType, value) {
  switch (qpType) {
    case QP_DATE_START:
    case QP_DATE_END:
      // dates must be parsed, and if they don't parse, return undefined
      var date = value && parseDate(value); // detect missing or failed-to-parse dates

      return !value || isNaN(date.getTime()) ? undefined : date;

    case QP_FILTERS:
      try {
        // ensure the filter is an array containing the expected kinds of values, or return undefined
        var filters = ensureRouteFilterIsArray(JSON.parse(value));
        return validateFilters(filters) ? filters : undefined;
      } catch (e) {
        return undefined;
      }

    case QP_METRICS:
      try {
        // ensure the metrics are an array of strings, or return undefined
        var metrics = JSON.parse(value);
        return validateMetrics(metrics) ? metrics : undefined;
      } catch (e) {
        return undefined;
      }

    case QP_COLUMN_SET:
      try {
        // ensure the metrics are an array of numbers, or return the original string (might be a named column set)
        var columnSet = JSON.parse(value);
        return validateTableColumnSetJSON(columnSet) ? columnSet : value;
      } catch (e) {
        return value;
      }

    default:
      return value;
  }
}
/**
 * this function reduces all managed query parameters
 * @param {Object} an object with these members -
 *   {Object} reducerCallback - the reducer callback.  It will be provided with an object containing the found
 *     key in the query parameters (foundKey), the full set of query parameters being reduced (params) and the
 *     type of query parameter that was found (qpType),  It must return the accumulated params
 *   {Object} queryParams - query params that were parsed by query-string module - this is preferred
 *   {Object} search - the search query string (unparsed) - queryParams is preferred instead of this
 *   {Object} initialReducerValue - the initial value for the reducer
 * @return {Object} - the resulting set of valid managed query parameters, with all values "cooked"
 */

function reduceManagedQPs(_ref3) {
  var callback = _ref3.callback,
      search = _ref3.search,
      queryParams = _ref3.queryParams,
      _ref3$initialReducerV = _ref3.initialReducerValue,
      initialReducerValue = _ref3$initialReducerV === void 0 ? {} : _ref3$initialReducerV; // providing queryParams is preferred, but we can parse a search string

  if (!queryParams) {
    queryParams = parse(search);
  }

  var queryParamKeys = Object.keys(queryParams); // reduce all the managed query parameters

  return ALL_MANAGED_QUERY_PARAMETERS.reduce(function (params, qpType) {
    var qpMatchRegex = new RegExp("(\\?|^)".concat(qpType.replace(QP_SUBST_REGEX, '.+'), "(=|$)")); // attempt to find query parameter keys which are a match for this managed parameter (ignoring substitution codes)

    var foundKeys = queryParamKeys.filter(function (keyCur) {
      return qpMatchRegex.test(keyCur);
    });
    foundKeys.forEach(function (foundKey) {
      params = callback({
        foundKey: foundKey,
        params: params,
        qpType: qpType,
        queryParams: queryParams
      });
    });
    return params;
  }, initialReducerValue);
}
/**
 * this function obtains all valid query parameters managed by this service
 * @param {Object} paramObj - an object with these members -
 *   {Object} queryParams - query params that were parsed by query-string module - this is preferred
 *   {Object} search - the search query string (unparsed) - queryParams is preferred instead of this
 *   {Boolean} includeGlobals - if true, include global query parameters
 *   {Boolean} includeNonGlobals - if true, include non-global query parameters
 * @return {Object} - the resulting set of valid managed query parameters, with all values "cooked"
 */


export function getAllManagedQPs(_ref4) {
  var search = _ref4.search,
      queryParams = _ref4.queryParams,
      _ref4$excludeGlobals = _ref4.excludeGlobals,
      excludeGlobals = _ref4$excludeGlobals === void 0 ? false : _ref4$excludeGlobals,
      _ref4$excludeNonGloba = _ref4.excludeNonGlobals,
      excludeNonGlobals = _ref4$excludeNonGloba === void 0 ? false : _ref4$excludeNonGloba;
  var managedQPs = reduceManagedQPs({
    callback: function callback(_ref5) {
      var foundKey = _ref5.foundKey,
          params = _ref5.params,
          queryParams = _ref5.queryParams,
          qpType = _ref5.qpType;
      var cookedValue = foundKey && cookManagedQPValue(qpType, queryParams[foundKey]); // ensure the value will cook before including it, but keep the uncooked value

      if (!isUndefined(cookedValue)) {
        if (GLOBAL_QUERY_PARAMETERS.includes(qpType) ? !excludeGlobals : !excludeNonGlobals) {
          params[foundKey] = queryParams[foundKey];
        }
      }

      return params;
    },
    search: search,
    queryParams: queryParams
  }); // extra date validation

  ALL_MANAGED_DATE_PARAMETERS.forEach(function (_ref6) {
    var _ref7 = _slicedToArray(_ref6, 2),
        startDateKey = _ref7[0],
        endDateKey = _ref7[1]; // must have valid start/end date (both must exist, end date must be after start date)


    if (!validateDates({
      startDate: cookManagedQPValue(QP_DATE_START, managedQPs[startDateKey]),
      endDate: cookManagedQPValue(QP_DATE_END, managedQPs[endDateKey])
    })) {
      delete managedQPs[startDateKey];
      delete managedQPs[endDateKey]; // also delete comparison dates if any date fails

      delete managedQPs[QP_COMPARISON_DATE_START];
      delete managedQPs[QP_COMPARISON_DATE_END];
    }
  });
  return managedQPs;
}
/**
 * this function strips all query parameters managed by this service (all not unknown) from the given query parameter
 * object (parsed by query-string)
 * @param {Object} queryParams - query params that were parsed by query-string module
 * @return {Object} - the query parameters provided, with all managed parameters stripped
 */

export function stripAllManagedQPs(queryParams) {
  return reduceManagedQPs({
    callback: function callback(_ref8) {
      var foundKey = _ref8.foundKey,
          params = _ref8.params;

      if (foundKey) {
        delete params[foundKey];
      }

      return params;
    },
    queryParams: queryParams,
    initialReducerValue: _objectSpread({}, queryParams)
  });
}
/**
 * this function builds a query parameter string from the given query params
 * @param {Object} queryParams - query params to stringify
 * @return {String} - a string ready to append to a URL to supply all its query params
 */

export function buildManagedQPString(queryParams) {
  return "?".concat(stringify(queryParams));
}