import createLoader from '@signal/utils/createLoader';
import { rootLoader } from '../app/loaders';
import { loadAds, loadAdDetails, loadAdPhotos, loadAdStats, adStatsListener, adsListener, adDetailsListener, adPhotosListener, loadUniversalOffers, universalOffersListener } from './sagas';
import reducer from './reducer';
import { REDUCER_KEY } from './constants';

var injectReducers = function injectReducers(injectReducer) {
  return injectReducer(REDUCER_KEY, reducer);
};

var dependencies = [rootLoader];
export var adsLoader = createLoader('ads', {
  load: loadAds,
  listen: adsListener,
  injectReducers: injectReducers,
  dependencies: dependencies
});
export var adDetailsLoader = createLoader('adDetails', {
  load: loadAdDetails,
  listen: adDetailsListener,
  injectReducers: injectReducers,
  dependencies: dependencies
});
export var adPhotosLoader = createLoader('adPhotos', {
  load: loadAdPhotos,
  listen: adPhotosListener,
  injectReducers: injectReducers,
  dependencies: dependencies
});
export var adStatsLoader = createLoader('adStats', {
  load: loadAdStats,
  listen: adStatsListener,
  injectReducers: injectReducers,
  dependencies: dependencies
});
export var universalOffersLoader = createLoader('universalOffers', {
  load: loadUniversalOffers,
  listen: universalOffersListener,
  injectReducers: injectReducers,
  dependencies: dependencies
});