var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Button from '../Button';
import Typography from '../Typography';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { getColorGreySelector } from '@signal/theme/selectors';
export var Wrapper = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-block;\n"])));
export var Target = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  border-bottom: 1px dashed ", ";\n"])), getColorGreySelector());
export var Content = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  background: ", ";\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);\n  width: ", ";\n  padding: 10px 15px;\n  opacity: 0.8;\n  margin-top: 10px;\n  border-radius: 5px;\n\n  &:before {\n    content: '';\n    width: 0;\n    height: 0;\n    position: absolute;\n    top: 0;\n    left: calc(50% - 5px);\n    border-left: 8px solid transparent;\n    border-right: 8px solid transparent;\n    border-bottom: 10px solid ", ";\n  }\n"])), function (props) {
  return props.bgColor;
}, function (props) {
  return props.size === 'small' ? '233px' : '325px';
}, function (props) {
  return props.bgColor;
});
export var ContentText = styled(Typography).attrs(function () {
  return {
    variant: 'body2',
    color: 'inverse'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: inline;\n"])));
export var LinkButton = styled(Button)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: ", ";\n  cursor: pointer;\n  font-weight: inherit;\n  display: ", ";\n  padding: 0 5px;\n  padding-left: ", ";\n  margin-top: ", "\n\n  &:hover {\n    text-decoration: underline;\n  }\n"])), lightBlue[600], function (props) {
  return props.inline ? 'inline' : 'block';
}, function (props) {
  return props.noLeftPadding ? '0' : '5px';
}, function (props) {
  return props.topMargin ? '10px' : '0';
});