import { createSelector } from 'reselect'
import styled from 'styled-components'
import Backdrop from '@material-ui/core/Backdrop'
import Paper from '@material-ui/core/Paper'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Button from '@signal/components/Button'
import IconButton from '@signal/components/IconButton'
import Typography from '@signal/components/Typography'
import IconCancel from '@signal/components/Icons/Cancel'
import {
  colorWhiteSelector,
  getColorGreySelector,
  getSpacingSelector
} from '@signal/theme/selectors'

export const backdropColorSelector = createSelector(
  colorWhiteSelector,
  // sets alpha transparency of `colorWhite` to 0.8 to match mockup
  colorWhite => fade(colorWhite, 0.8)
)

export const DialogBackdrop = styled(Backdrop)`
  background-color: ${backdropColorSelector};
`

export const DialogPaper = styled(Paper)`
  border: 1px solid ${getColorGreySelector('light')};
`

const spacingSelector = getSpacingSelector()

export const DismissButton = styled(IconButton)`
  position: absolute;
  right: ${spacingSelector}px;
  top: ${spacingSelector}px;
`

export const DismissIcon = styled(IconCancel).attrs(() => ({
  size: 24,
  grey: 'dark'
}))``

export const MessageTypography = styled(Typography).attrs(() => ({
  variant: 'h4',
  align: 'center'
}))``

const DialogButton = styled(Button)`
  margin: 0 ${spacingSelector}px;
`

export const ActionButton = styled(DialogButton).attrs(() => ({
  variant: 'contained',
  color: 'primary'
}))``

export const CancelButton = styled(DialogButton).attrs(() => ({
  variant: 'outlined'
}))``

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const DialogWrapper = styled.div`
  padding: 0px ${getSpacingSelector(6)}px;

  > * {
    margin: ${getSpacingSelector(3)}px 0;
  }
`
