function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { REPORT, SUB_REPORT } from '@signal/routes';
import isTest from '@signal/utils/env/isTest';
import isStorybook from '@signal/utils/env/isStorybook';
import { activeAccountIdSelector } from '../auth';
import { userIdSelector } from '../user';
import useSlugService from './slugService';
import { getSlugsFromReportId } from './slugService/utils';
import { applyGlobalQPs } from './queryParamManager';
export function useReportLinks(deeplinks) {
  var _useLocation = useLocation(),
      search = _useLocation.search;

  var accountIdFromSelector = useSelector(activeAccountIdSelector);
  var accountId = (isTest() || isStorybook() ? 'dummy' : accountIdFromSelector) || 'dummy';
  var userId = useSelector(userIdSelector);
  var serviceSlugs = useSlugService({
    accountId: accountId,
    userId: userId
  });
  return useMemo(function () {
    return deeplinks.map(function (deeplink) {
      var drilldownReportSlug = deeplink.drilldownReportSlug,
          _deeplink$filters = deeplink.filters,
          filters = _deeplink$filters === void 0 ? [] : _deeplink$filters,
          reportId = deeplink.reportId;
      var foundSlug;

      if (drilldownReportSlug) {
        foundSlug = drilldownReportSlug.split('/');
      } else {
        foundSlug = getSlugsFromReportId({
          serviceSlugs: serviceSlugs,
          reportId: reportId
        });
      }

      var reportSlug;
      var subReportSlug;

      if (foundSlug.length) {
        ;
        var _foundSlug = foundSlug;

        var _foundSlug2 = _slicedToArray(_foundSlug, 2);

        reportSlug = _foundSlug2[0];
        subReportSlug = _foundSlug2[1];
      } else {
        reportSlug = reportId;
      }

      var route = subReportSlug ? SUB_REPORT : REPORT;
      var pathname = route.buildPath({
        accountId: accountId,
        reportSlug: reportSlug,
        subReportSlug: subReportSlug
      });
      var searchFinal = applyGlobalQPs({
        clearIfNotSet: false,
        filters: filters,
        search: search
      });
      return "".concat(pathname).concat(searchFinal);
    });
  }, [accountId, deeplinks, search, serviceSlugs]);
}
export function useReportLink(deeplink) {
  return useReportLinks([deeplink])[0];
}
export default useReportLink;