var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import MuiIconButton from '@material-ui/core/IconButton';
import { getColorPrimarySelector, getSpacingSelector } from '@signal/theme/selectors';
export var ActiveDot = styled.span(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: ", ";\n  position: absolute;\n  bottom: ", "px;\n  height: 4px;\n  width: 4px;\n  background-color: ", ";\n  border-radius: 50%;\n"])), function (props) {
  return props.active ? 'block' : 'none';
}, function (props) {
  return props.bottom || 5;
}, getColorPrimarySelector('main')); // This is really (obviously) a super simple wrapper.
// The main MuiIconButton, however, has too much padding
// to match our designs so this was created to reduce that
// and be easily used across our apps.

export default styled(MuiIconButton)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: ", "px;\n"])), getSpacingSelector());