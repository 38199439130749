import loadable from '@signal/hoc/loadable'
import combinedError from '@signal/hoc/combinedError'
import ErrorScreenApi from '@signal/containers/ErrorScreenApi'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "ad-detail-header" */ './component'),
  dataLoader: {
    ad: () => import(/* webpackChunkName: "lib-ad" */ '@signal/core/ad')
  },
  loadData: ({ ad: { adsLoader, universalOffersLoader } }, loaderInterface) =>
    Promise.all([
      adsLoader(loaderInterface),
      universalOffersLoader(loaderInterface)
    ]),
  loading: Skeleton,
  error: combinedError(ErrorScreenApi)
})
