import React, { memo } from 'react'
import { PAGE_SHOPPERS, APP_ATTRIBUTION } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import Header from '../../containers/AttributionHeader'
import ShoppersHeading from '../../containers/ShoppersHeading'
import ShoppersBucketsDetails from '../../containers/ShoppersBucketsDetails'
import ShoppersFunnelChart from '../../containers/ShoppersFunnelChart'
import ShoppersSummaryHeader from '../../containers/ShoppersSummaryHeader'
import { PageWrapper } from '../styles'

export const PerformanceShoppersPage = memo(() => {
  usePageTrack(PAGE_SHOPPERS, APP_ATTRIBUTION)

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      headerComponent={ShoppersSummaryHeader}
    >
      <PageWrapper>
        <ShoppersHeading />
        <ShoppersBucketsDetails />
        <ShoppersFunnelChart />
      </PageWrapper>
    </LayoutDefaultWithPageHeader>
  )
})

PerformanceShoppersPage.displayName = 'PerformanceShoppersPage'

export default PerformanceShoppersPage
