import styled from 'styled-components'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import FeaturedMetricsList from '@signal/components/FeaturedMetricsList'
import FeaturedMetricsListSkeleton from '@signal/components/FeaturedMetricsList/Skeleton'
import { getSpacingSelector } from '@signal/theme/selectors'

export const Wrapper = styled.div`
  padding-top: ${getSpacingSelector(3)}px;
  padding-bottom: ${getSpacingSelector(4)}px;
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'h3',
  color: 'textDark'
}))``
export const TitleSkeleton = styled(
  Title.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonDark',
  width: 90
}))``

export const Subtitle = styled(Typography).attrs(() => ({
  variant: 'body2'
}))`
  margin-top: ${getSpacingSelector()}px;
  margin-bottom: ${getSpacingSelector(2)}px;
`
export const SubtitleSkeleton = styled(
  Subtitle.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 130,
  color: 'skeletonDark'
}))``

export const Metrics = styled(FeaturedMetricsList)``
export const MetricsSkeleton = Metrics.withComponent(
  FeaturedMetricsListSkeleton
)
