import styled, { css } from 'styled-components'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import select from '@signal/utils/select'
import { getSpacingSelector } from '@signal/theme/selectors'
import Typography from '@signal/components/Typography'

const backgroundColorSelector = select('backgroundColor')

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 600px;
  background-color: ${backgroundColorSelector};
`

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 450px;
`

export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s;
  opacity: ${props => (props.loading ? 1 : 0)};
  background-color: ${backgroundColorSelector};
`

export const Image = styled.img`
  user-select: none;
  object-fit: contain;
  align-self: ${select('align', 'center')};
  margin: ${createSelector(
    select('offset', {}),
    offset =>
      css`${get(offset, 'top', 0)}px ${get(offset, 'right', 0)}px ${get(
        offset,
        'bottom',
        0
      )}px ${get(offset, 'left', 0)}px;`
  )};
`

export const Content = styled.div`
  background-color: ${backgroundColorSelector};
  flex-basis: 150px;
  z-index: 1;
  padding: ${getSpacingSelector(2)}px ${getSpacingSelector(4)}px;
`

export const CenteredTypography = styled(Typography).attrs(() => ({
  align: 'center'
}))``

export const Title = styled(CenteredTypography).attrs(() => ({
  variant: 'h3'
}))`
  margin-bottom: ${getSpacingSelector(2)}px;
`

export const Text = styled(CenteredTypography).attrs(() => ({
  variant: 'subtitle2'
}))``
