/**
 * Creates the action type for a request action by prefixing the request type
 * with the baseType.
 *
 * @param {String} baseType - The base action type
 * @param {RequestActionType} type - The request type
 * @return {String} - The type to use for the request action creator
 */
export var getActionType = function getActionType(baseType, type) {
  return "".concat(baseType, "_").concat(type);
};