import styled from 'styled-components'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import { getSpacingSelector } from '@signal/theme/selectors'

export const Header = styled(ContentSectionHeading)`
  background: #fff;
  position: sticky;
  top: 0;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: ${getSpacingSelector()}px;
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'h3',
  color: 'textDark'
}))``
export const TitleSkeleton = styled(
  Title.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonDark',
  width: 150
}))``

export const AutoSaveStatus = styled(Typography).attrs(() => ({
  variant: 'subtitle2'
}))``
