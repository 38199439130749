var _templateObject;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getSpacingSelector } from '@signal/theme/selectors';
export var Image = styled.img(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  object-fit: contain;\n  margin-top: 1px;\n  padding: ", "px 0;\n"])), getSpacingSelector(2));