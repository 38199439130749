import styled from 'styled-components'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import { getSpacingSelector } from '@signal/theme/selectors'

export const Timestamp = styled(Typography).attrs(() => ({
  variant: 'body2'
}))`
  margin-bottom: ${getSpacingSelector(5.5)}px;
`
export const TimestampSkeleton = styled(
  Timestamp.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 130
}))``
