import React from 'react'
import { createSelector, createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { createSearchParamSelector } from '@signal/core/router/selectors'
import renderIf from '@signal/utils/renderIf'
import ProgressiveImage from '@signal/components/ProgressiveImage'
import { CHANNEL, MAKE } from './constants'
import {
  Wrapper,
  AttributedSalesMetricSkeleton,
  AvatarSkeleton,
  ChannelLegendSkeleton,
  CentralWrapper,
  SummaryContributionChartSkeleton,
  TitleSkeleton,
  TypographyContributionSkeleton,
  Image
} from './styles'

export function ChannelsDetailSummarySkeleton({ showAvatar }) {
  return (
    <Wrapper
      title={<TitleSkeleton width={120} />}
      leftContent={renderIf(
        showAvatar,
        () => (
          <AvatarSkeleton />
        ),
        () => (
          <ProgressiveImage>
            {src => (
              <Image
                imgStyle={{ objectFit: 'cover' }}
                src={src}
                alt="loading..."
              />
            )}
          </ProgressiveImage>
        )
      )}
      rightContent={<AttributedSalesMetricSkeleton />}
    >
      <CentralWrapper>
        <SummaryContributionChartSkeleton />
        <TypographyContributionSkeleton />
        <ChannelLegendSkeleton />
      </CentralWrapper>
    </Wrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  showAvatar: createSelector(createSearchParamSelector('lvl'), lvl =>
    [CHANNEL, MAKE].includes(lvl)
  )
})

export default connect(mapStateToProps)(ChannelsDetailSummarySkeleton)
