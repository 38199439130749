import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { PAGE_AUDIENCES_LIST, APP_AUDIENCES } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import { hasActivateWriteSelector } from '@signal/core/auth'
import Typography from '@signal/components/Typography'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import LayoutDefault from '../../layouts/Default'
import Header from '../../containers/AudiencesHeader'
import AudienceCreatedModal from '../../containers/AudienceCreatedModal'
import AudienceListGroup from '../../containers/AudienceListGroup'
import GeneratedAudienceListGroup from '../../containers/GeneratedAudienceList'
import AudiencesSummary from '../../containers/AudiencesSummary'
import CreateAudienceButton from '../../containers/CreateAudienceButton'
import { PageWrapper } from '../styles'
import { ButtonWrapper } from './styles'

export default memo(function AudiencesPage() {
  usePageTrack(PAGE_AUDIENCES_LIST, APP_AUDIENCES)
  const hasWriteAccess = useSelector(hasActivateWriteSelector)

  return (
    <LayoutDefault navbar={Header}>
      <PageWrapper>
        {hasWriteAccess && (
          <ButtonWrapper>
            <CreateAudienceButton />
          </ButtonWrapper>
        )}
        <AudiencesSummary />
        <AudienceListGroup hasWriteAccess={hasWriteAccess} />
        <ContentSectionHeading
          title="Generated Audiences"
          titleProps={{ variant: 'h3' }}
          description={() => (
            <Typography variant="subtitle2">
              Automatically created audiences base on the franchise and
              audiences that have shown the greatest success in generating
              leads. These audiences are managed by PureCars and are not
              editable.
            </Typography>
          )}
        />
        <GeneratedAudienceListGroup />
        {hasWriteAccess && <AudienceCreatedModal />}
      </PageWrapper>
    </LayoutDefault>
  )
})
