var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import OpenInNew from '@material-ui/icons/OpenInNew';
export var Link = styled.a(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n  align-items: center;\n"])));
export var Icon = styled(OpenInNew).attrs(function () {
  return {
    fontSize: 'inherit'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-left: 2px;\n"])));