var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { getSpacingSelector, paletteDividerSelector } from '@signal/theme/selectors';
import { clearfix } from '@signal/theme/mixins';
import Typography from '../Typography';
export var Wrapper = styled(Paper).attrs(function () {
  return {
    elevation: 1
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: ", "px;\n  ", "\n"])), getSpacingSelector(2), clearfix);
export var Title = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: ", "px;\n  border-bottom: 1px solid ", ";\n  font-weight: 600;\n"])), getSpacingSelector(2), paletteDividerSelector);
export var TitleText = styled(Typography).attrs(function () {
  return {
    variant: 'body1',
    style: 'padding: 0'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var SubTitleWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-left: auto\n  display: flex;\n  justify-content: right;\n"])));
export var SubTitle = styled(Typography).attrs(function () {
  return {
    variant: 'subheading'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var Content = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin: ", "px;\n"])), getSpacingSelector(2));