import { createSelector } from 'reselect'
import {
  showAudienceCreatedSelector,
  NO_AUDIENCE_CREATED
} from '@signal/core/audience'
import { partialAdDetailPathSelector } from '@signal/core/router/selectors'

export const audienceCreatedModalOpenSelector = createSelector(
  showAudienceCreatedSelector,
  createdId => createdId !== NO_AUDIENCE_CREATED
)

export const adDetailHrefSelector = createSelector(
  showAudienceCreatedSelector,
  partialAdDetailPathSelector,
  (createdId, createAdDetailPath) =>
    createdId ? createAdDetailPath(createdId) : ''
)
