import partial from 'lodash/partial';
import every from 'lodash/every';
import isLoader from './isLoader';
/**
 * Checks if every function/object in provided array is a data loader.
 * @param  {Array<Function|Object>} maybeLoaders - An array of unknown functions/objects
 * @returns {Boolean}
 */

export var isLoaders = partial(every, partial.placeholder, isLoader);
export default isLoaders;