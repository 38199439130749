function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import * as constantsModule from '../variables';
import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import muiTypography from './typography.mui';
import muiOverrides from './overrides.mui';
import muiProps from './props.mui';

var constants = _objectSpread({}, constantsModule);
/**
 * An object comprised of one or more {@link ThemeOverride} objects that is
 * provided to `createTheme` to override default theme values.
 * @typedef {Object.<string, ThemeOverride>} ThemeOverrides
 * @global
 */

/**
 * An object that is used to override default theme values.
 * @typedef {Object} ThemeOverride
 * @global
 */

/**
 * Material UI Theme Overrides
 * @type {ThemeOverrides}
 */


export var overrides = {
  breakpoints: breakpoints,
  constants: constants,
  palette: palette,
  typography: typography,
  muiTypography: muiTypography,
  overrides: muiOverrides,
  props: muiProps
};
export { breakpoints, constants, palette };
export default overrides;