import { lazy } from 'react'

/*
 * for use with @signal/test-utils/testableLazy
 * Add any new lazy-loaded components here, and also add a way to import it directly in __mocks__/lazy-map.js
 */
export function getLazyMap() {
  return {
    comparisonBars: lazy(() => import('./components/dashboard/ComparisonBars')),
    complexTable: lazy(() =>
      import('./components/dashboard/ComplexTableWidget')
    ),
    customReport: lazy(() =>
      import('./loadables/dashboard/CustomReport/CustomReport')
    ),
    deepLink: lazy(() => import('./components/dashboard/DeepLinkWidget')),
    donut: lazy(() => import('./components/dashboard/DonutWidget')),
    filtersLoadable: lazy(() => import('./loadables/dashboard/FilterList')),
    funnelGraph: lazy(() => import('./components/dashboard/FunnelGraphWidget')),
    gauge: lazy(() => import('./components/dashboard/GaugeWidget')),
    kpiGauge: lazy(() => import('./components/dashboard/KPIGaugeWidget')),
    heroCard: lazy(() => import('./components/dashboard/HeroCardWidget')),
    kpi: lazy(() => import('./components/dashboard/KPIWidget')),
    chart: lazy(() => import('./components/dashboard/ChartWidget')),
    multiVariateChart: lazy(() =>
      import('./components/dashboard/ComparisonChartWidget')
    ),
    pieChart: lazy(() => import('./components/dashboard/PieChartWidget')),
    report: lazy(() => import('./loadables/dashboard/Report/Report')),
    scoreCard: lazy(() => import('./components/dashboard/ScoreCardWidget')),
    searchableList: lazy(() => import('./components/dashboard/SearchableList')),
    sparkGauge: lazy(() => import('./components/dashboard/SparkGaugeWidget')),
    speedometer: lazy(() => import('./components/dashboard/SpeedometerWidget')),
    table: lazy(() => import('./components/dashboard/TableWidget')),
    tabGroup: lazy(() => import('./components/dashboard/TabGroupWidget')),
    upsell: lazy(() => import('./components/dashboard/UpsellWidget'))
  }
}
