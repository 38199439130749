import isString from 'lodash/isString';
import isTest from './env/isTest'; // unit tests were failing on Sundays due to inability to discriminate between "start of last week" and "last 7 days"

var TIME_NOW_FOR_UNIT_TEST = '6/16/2020'; // Parse the date as UTC

export default function parseDate() {
  var dateVal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : isTest() ? TIME_NOW_FOR_UNIT_TEST : Date.now(); // non-Chrome cannot accept non-UTC dates with dashes, e.g. '10-31-2020'

  if (isString(dateVal) && dateVal.match(/\d{2}-\d{2}-\d{4}/)) {
    dateVal = dateVal.replace(/-/g, '/');
  } // always use UTC to avoid timezone issues, particularly on Safari


  return new Date(new Date(dateVal).toUTCString());
}