import React, { memo } from 'react'
import Typography from '@signal/components/Typography'
import { AUDIENCES_ARTICLE_URL } from '@signal/core/helpdesk'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import ExternalLink from '@signal/components/ExternalLink'
import { DESCRIPTION } from './constants'

export const AudienceCreateSummary = memo(function() {
  return (
    <ContentSectionHeading
      title="Edit your Audience"
      titleProps={{ variant: 'h3' }}
      description={
        <Typography variant="subtitle2">
          {DESCRIPTION}{' '}
          <ExternalLink to={AUDIENCES_ARTICLE_URL}>
            Learn more about segments.
          </ExternalLink>
        </Typography>
      }
    />
  )
})

export default AudienceCreateSummary
