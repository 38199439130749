import styled from 'styled-components'
import { Link } from 'react-router-dom'
import OptionsIcon from '@material-ui/icons/MoreHoriz'
import IconButton from '@material-ui/core/IconButton'
import { getSpacingSelector } from '@signal/theme/selectors'
import Typography from '@signal/components/Typography'
import SkeletonTypography from '@signal/components/Skeleton/Typography'
import { RowBase, CellBase, HeaderCellBase } from '../TableListView/styles'
import {
  getColorGreySelector,
  getColorPrimarySelector
} from '@signal/theme/selectors'
import SortableTableListView from '../SortableTableListView'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const OptionsMenuWrapper = styled.div`
  text-align: right;
  padding-right: ${getSpacingSelector()}px;
`

export const OptionsMenu = styled(IconButton).attrs(() => ({
  className: 'sig-ad-list-group-options-menu'
}))`
  border-radius: 3px;
  border: 1px solid transparent;
  padding: ${getSpacingSelector()}px;

  // Adds the active border color state to the button when the popover is shown.
  ${props =>
    props['aria-owns'] && `border-color: ${getColorPrimarySelector()(props)};`}

  &:hover {
    background: none;
  }
`

export const OptionsMenuIcon = styled(OptionsIcon).attrs(() => ({
  size: 19
}))`
  color: ${getColorGreySelector()};
`

export const Cell = styled(CellBase).attrs(() => ({
  elevation: 0
}))``

export const HeaderCell = styled(HeaderCellBase).attrs(() => ({
  elevation: 0
}))``

export const Row = styled(RowBase).attrs(({ to }) => ({
  elevation: 0,
  component: to ? Link : Cell
}))`
  &:hover {
    .sig-ad-list-group-options-menu {
      border-color: ${getColorGreySelector('light')};
    }
  }
`

export const RowSkeleton = styled.div``

export const Table = styled(SortableTableListView).attrs(props => ({
  rowSpacing: getSpacingSelector(2)(props)
}))``

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'h6',
  color: 'textDark'
}))`
  margin-bottom: ${getSpacingSelector(0.5)}px;
`

export const TitleSkeleton = styled(
  Title.withComponent(SkeletonTypography)
).attrs(() => ({
  width: 100,
  color: 'skeletonDark'
}))``

export const CellText = styled(Typography).attrs(() => ({
  variant: 'body3'
}))``

export const StatusCellText = styled(CellText).attrs(() => ({
  color: 'textSecondary'
}))``

export const CellTextSkeleton = CellText.withComponent(SkeletonTypography)

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
