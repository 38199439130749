import { createThemeSelector, createVariableThemeSelectorCreator } from './utils';
export var spacingSelector = createThemeSelector(function (theme) {
  return theme.spacing;
});
export var getSpacingSelector = createVariableThemeSelectorCreator( // selector creator
function () {
  var multiplier = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return createThemeSelector(spacingSelector, function (spacing) {
    return spacing(multiplier);
  });
}, // generate cache key (must account for default value)
function () {
  var multiplier = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return multiplier;
});