function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import map from 'lodash/map';
import uniq from 'lodash/uniq';
import capitalize from 'lodash/capitalize';
import { SAGA_TYPES } from './constants';
/**
 * Helper util to create consistent saga keys based on the key and type
 * @param {String} key - Data loader key
 * @param {String} type - Saga type
 *   - ex: 'load'
 * @returns {String} - String representing the provided key/type
 *   - ex: 'userDataLoad'
 */

export var getSagaKey = function getSagaKey(key, type) {
  return "".concat(key).concat(capitalize(type));
};
/**
 * Helper util to create consistent cancel saga keys based on the key and type,
 * while accounting for replacements. When a data loader is created using the
 * `replace` method, the new loader must be assigned a unique cancel saga key
 * because the cancel saga for the original loader has no knowledge of the
 * sagas that were replaced.
 * @param {String} key - Data loader key
 * @param {String} replacements - Data loader replacements
 * @returns {String} - Key for the cancel saga
 *   - ex: 'userDataWithAccounts1Cancel'
 */

export var getCancelSagaKey = function getCancelSagaKey(key, replacements) {
  var replacementKeys = map(replacements, 'key');
  var replacementCount = replacementKeys.length; // If there are no replacements, use default behavior saga key

  if (!replacementCount) return getSagaKey(key, 'cancel'); // To generate a unique hash to use for the cancel saga key for replacements,
  // both the replacement key(s) and the count of replacements must be used.

  var uniqReplacementKeys = uniq(replacementKeys);
  var replacementSagaKey = "".concat(key).concat(uniqReplacementKeys.map(capitalize).join()).concat(replacementCount);
  return getSagaKey(replacementSagaKey, 'cancel');
};
/**
 * Options for the loader that are created with the input key and loader definition.
 * @global
 * @typedef {Object} LoaderOptions
 * @property {Symbol} LISTEN - Symbol used for the listener saga key
 * @property {Symbol} LOAD - Symbol used for the loader saga key
 * @property {Symbol} CANCEL - Symbol used for the canceller (INTERNAL ONLY) saga key
 * @property {Saga} load - Saga that completes after data is loaded
 * @property {Saga} listen - Saga that sets up one or more action listeners
 * @property {Function} injectReducers - Function that takes in the `injectReducer`
 * @property {Array<Symbol>} sagaKeys - List of saga keys to eject on `LOCATION_CHANGE`
 * @property {Array<DataLoader>} dependencies - Loaders that are dependencies
 */

/**
 * Converts the arguments provided to {@link createLoader} function into options
 * required by the {@link DataLoader}.
 * @param {String} key - Unique identifier for the loader
 *   - Used to generate keys for the `load`/`listen` sagas
 *   - Allows data loader to run sagas only once per route change
 * @param {LoaderDefinition} definition - Loader definition that defines the
 * behavior of the loader
 * @param  {Object} [replacements={}] - INTERNAL ONLY &mdash; used by `dataLoader.replace`
 * @return {LoaderOptions} - The options required by the {@link DataLoader}
 */

export function getOptions(key, definition) {
  var replacements = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var injectReducers = replacements.injectReducers || definition.injectReducers;
  var dependencies = replacements.dependencies || definition.dependencies;
  var CANCEL = getCancelSagaKey(key, replacements);
  var options = {
    CANCEL: CANCEL,
    injectReducers: injectReducers,
    dependencies: dependencies,
    sagaKeys: [CANCEL]
  };
  return SAGA_TYPES.reduce(function (opts, TYPE) {
    var _Object$assign; // Check if this saga type has been replaced


    var replacement = replacements[TYPE];
    var sagaKey = getSagaKey(replacement ? replacement.key : key, TYPE);
    var saga = replacement ? replacement.saga : definition[TYPE];
    var sagaKeys = opts.sagaKeys.concat(sagaKey);

    if (replacement) {
      // If this type was replaced, push the original key in as well. This is
      // important in order to properly eject each saga
      sagaKeys.push(getSagaKey(key, TYPE));
    }

    return Object.assign({}, opts, (_Object$assign = {}, _defineProperty(_Object$assign, TYPE.toUpperCase(), sagaKey), _defineProperty(_Object$assign, TYPE, saga), _defineProperty(_Object$assign, "sagaKeys", sagaKeys), _Object$assign));
  }, options);
}