import { palette } from '@signal/theme/overrides'
import * as constantsModule from '../constants'

const constants = { ...constantsModule }
/**
 * Material UI Theme Overrides
 */
export const overrides = {
  constants,
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: palette.primary.main
      }
    },
    MuiTab: {
      root: {
        textTransform: 'uppercase',
        '@media (min-width: 960px)': {
          minWidth: 100
        }
      }
    }
  },
  palette: {
    type: 'dark',
    text: {
      deep: '#ffffff',
      dark: '#ffffff',
      primary: '#ffffff',
      secondary: '#c5c6c5',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
      icon: 'rgba(255, 255, 255, 0.5)'
    },
    background: {
      light: '#666666',
      paper: '#424242',
      default: '#303030'
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    action: {
      active: '#ffffff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)'
    }
  }
}

export default overrides
