var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Typography from '../Typography';
export var LabelWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n"])));
export var SubLabel = styled(Typography).attrs(function () {
  return {
    variant: 'caption'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  text-transform: none;\n  position: absolute;\n  bottom: 3px;\n  right: 5px;\n"])));