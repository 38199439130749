/**
 * all ab-testing parameters
 *
 * parameters added here will appear in the query parameters as ab_<name>, e.g. ab_design
 */

export const AB_DESIGN = 'design'
export const AB_CHART_GRADIENT = 'charts'
export const AB_DATE_PICKER = 'datePicker'
export const AB_THEME = 'theme'

// options related to parameter "design" follow
export const DESIGN_NEW = 'new'

// options related to parameter "datePicker" follow
export const DATE_PICKER_OLD = 'old'

// options related to parameter "charts" follow
export const ALL_CHART_GRADIENT = 'all'
export const MV_CHART_GRADIENT = 'mv'

// options related to parameter "theme" follow
export const THEME_SERVER = 'server'
