import styled from 'styled-components'
import ConstrainedImage from '@signal/components/ConstrainedImage'
import FeaturedMetric from '@signal/components/FeaturedMetric'
import FeaturedMetricSkeleton from '@signal/components/FeaturedMetric/Skeleton'
import PerformanceDetailView from '@signal/components/PerformanceDetailView'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import {
  getSpacingSelector,
  getColorGreySelector
} from '@signal/theme/selectors'

export const Layout = styled(PerformanceDetailView).attrs(() => ({
  centerColumnProps: {
    style: {
      alignSelf: 'center'
    }
  }
}))``

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const Image = styled(ConstrainedImage)`
  width: 100%;
  height: auto;
  justify-self: center;
  min-width: 50px;
  max-width: 97px;
  margin-right: ${getSpacingSelector(5)}px;
`

export const ImageSkeleton = styled(Image).attrs(() => ({
  imgStyle: { objectFit: 'cover' }
}))`
  background-color: ${getColorGreySelector('accent')};
`

export const SalePriceTitle = styled(Typography).attrs(() => ({
  variant: 'body4',
  color: 'textDark'
}))``

export const SalePriceTitleSkeleton = styled(
  SalePriceTitle.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonDark'
}))``

export const SalePriceMetric = styled(FeaturedMetric)`
  justify-self: center;
`

export const SalePriceMetricSkeleton = styled(FeaturedMetricSkeleton).attrs(
  () => ({
    noname: true,
    metricWidth: 200
  })
)`
  justify-self: center;
`

export const TitleSkeleton = styled(TypographySkeleton).attrs(() => ({
  variant: 'h3',
  color: 'skeletonDark'
}))``

export const Subtitle = styled(Typography).attrs(() => ({
  variant: 'body2'
}))`
  margin-top: ${getSpacingSelector(0.5)}px;
`
export const SubtitleSkeleton = Subtitle.withComponent(TypographySkeleton)
