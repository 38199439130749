import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { dismissAudienceCreatedModal } from '@signal/core/audience'
import ResponseDialog from '../../components/ResponseDialog'
import { audienceCreatedModalOpenSelector } from './selectors'

export class AudienceCreatedModal extends PureComponent {
  static propTypes = {
    dismissAudienceCreatedModal: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  }

  onConfirm = () => this.props.dismissAudienceCreatedModal()

  render() {
    const { open } = this.props
    return (
      <ResponseDialog
        action={{
          label: 'OK',
          onClick: this.onConfirm
        }}
        message="Your audience has been saved."
        open={open}
        onClose={this.onConfirm}
      />
    )
  }
}

const mapStateToProps = createStructuredSelector({
  open: audienceCreatedModalOpenSelector
})

const mapDispatchToProps = {
  dismissAudienceCreatedModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AudienceCreatedModal)
