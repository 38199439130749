var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getColorGreySelector, getSpacingSelector } from '@signal/theme/selectors';
import Typography from '../Typography';
import TypographySkeleton from '../Skeleton/Typography';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n\n  & > :last-child {\n    border: none;\n    margin-right: 0;\n    padding-right: 0;\n  }\n"])));
export var MetricLabel = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n"])));
export var MetricSeparator = styled.span(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: 6px;\n"])));
export var MetricValue = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n"])));
export var MetricBase = styled.div.attrs(function () {
  return {
    variant: 'body3',
    color: 'textSecondary'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  border-right: 1px solid ", ";\n  padding-right: ", "px;\n  margin-right: ", "px;\n"])), getColorGreySelector(), getSpacingSelector(1), getSpacingSelector(1));
export var Metric = styled(MetricBase.withComponent(Typography)).attrs(function () {
  return {
    noWrap: true,
    component: 'span'
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: inline-flex;\n"])));
export var MetricSkeleton = styled(MetricBase.withComponent(TypographySkeleton)).attrs(function () {
  return {
    color: 'skeletonPrimary'
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: inline-block;\n"])));