var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Typography from '../Typography';
import { getSpacingSelector, getColorGreySelector } from '@signal/theme/selectors';
var colorGreyAccentSelector = getColorGreySelector('accent');
var colorGreySelector = getColorGreySelector();
var gridSpacingSelector = getSpacingSelector(2);
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n"])), colorGreyAccentSelector);
export var Grid = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr;\n  justify-content: center;\n  align-items: center;\n  grid-gap: ", "px;\n  margin: ", "px;\n  max-width: 375px;\n"])), gridSpacingSelector, gridSpacingSelector);
export var Icon = styled.img(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  justify-self: center;\n  color: ", ";\n  font-size: 60px;\n"])), colorGreySelector);
export var Description = styled(Typography).attrs(function () {
  return {
    variant: 'subtitle1'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  text-align: center;\n  color: ", ";\n"])), colorGreySelector);
export var Title = styled(Description)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.typography.fontWeightMedium;
});