import React from 'react'
import DrilldownSectionSkeleton from '../../components/DrilldownSection/Skeleton'
import ChannelsDrilldownTableSkeleton from '../../components/ChannelsDrilldownTable/Skeleton'

export const ChannelsDetailDrilldownTablesSkeleton = () => (
  <DrilldownSectionSkeleton
    drilldownTableComponent={ChannelsDrilldownTableSkeleton}
    showImage
  />
)

export default ChannelsDetailDrilldownTablesSkeleton
