var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { colorWhiteSelector, getColorGreySelector, getColorPrimarySelector, getSpacingSelector } from '@signal/theme/selectors';
import { Button } from '../Button';
import buttonStyles from '../Button/styles';
import Typography from '../Typography';
var colorVeryLightGraySelector = getColorGreySelector(100);
var colorDarkGraySelector = getColorGreySelector('dark');
var colorPrimarySelector = getColorPrimarySelector();
export var ButtonWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & > button {\n    padding: 0px ", "px;\n    border-radius: 0;\n  }\n\n  &:not(:first-of-type) > button {\n    border-left: 0;\n  }\n\n  &:first-of-type > button {\n    border-top-left-radius: 2px;\n    border-bottom-left-radius: 2px;\n  }\n\n  &:last-of-type > button {\n    border-top-right-radius: 2px;\n    border-bottom-right-radius: 2px;\n  }\n"])), getSpacingSelector(1.5));
export var LabelTypography = styled(Typography).attrs(function () {
  return {
    variant: 'subtitle1'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));

var buttonStyleOverrides = function buttonStyleOverrides(theme) {
  return {
    containedPrimary: {
      backgroundColor: colorPrimarySelector(theme),
      border: '1px solid transparent',
      color: colorWhiteSelector(theme),
      '&:hover': {
        borderColor: colorPrimarySelector(theme)
      },
      '&:disabled': {
        backgroundColor: getColorGreySelector(200)(theme),
        borderColor: colorPrimarySelector(theme)
      }
    },
    outlinedPrimary: {
      color: colorDarkGraySelector(theme),
      borderColor: colorPrimarySelector(theme),
      '&:hover': {
        backgroundColor: colorVeryLightGraySelector(theme)
      },
      '&:disabled': {
        backgroundColor: getColorGreySelector(200)(theme),
        borderColor: colorPrimarySelector(theme)
      }
    }
  };
};

export var RadioButton = styled(withStyles(buttonStyles(buttonStyleOverrides))(Button)).attrs(function () {
  return {
    size: 'medium'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Wrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: inline-flex;\n"])));