import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () => import(/* webpackChunkName: "ad-detail-title" */ './component'),
  dataLoader: () => import(/* webpackChunkName: "lib-ad" */ '@signal/core/ad'),
  loadData: ({ adDetailsLoader }, loaderInterface) =>
    adDetailsLoader(loaderInterface),
  loading: Skeleton
})
