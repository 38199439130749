import * as constantsModule from '../constants'

const constants = { ...constantsModule }
/**
 * Material UI Theme Overrides
 */
export const overrides = {
  constants,
  overrides: {
    MuiTab: {
      root: {
        textTransform: 'uppercase',
        '@media (min-width: 960px)': {
          minWidth: 100
        }
      }
    }
  }
}

export default overrides
