import { applyAPIDomainToEnvBase } from '@signal/utils/env/apiDomain';
var API_BASE = applyAPIDomainToEnvBase('REACT_APP_SIGNAL_API_BASE'); // const API_ENVIRONMENT = process.env.REACT_APP_SIGNAL_API_ENVIRONMENT

export var API_VERSION = process.env.REACT_APP_SIGNAL_API_VERSION || 'v1.2'; // export const API_PARTS = [API_BASE, API_ENVIRONMENT, API_VERSION]

export var API_PARTS = [API_BASE, API_VERSION];
export var API_URL = API_PARTS.filter(Boolean).join('/');
export var API_METHODS = {
  GET: 'GET',
  DELETE: 'DELETE',
  POST: 'POST',
  PUT: 'PUT'
};
/**
 * Audience Microservice
 */

var AUDIENCE_MICROSERVICE_BASE = applyAPIDomainToEnvBase('REACT_APP_SIGNAL_AUDIENCE_MICROSERVICE_BASE');
var AUDIENCE_MICROSERVICE_VERSION = process.env.REACT_APP_SIGNAL_AUDIENCE_MICROSERVICE_VERSION;
export var AUDIENCE_MICROSERVICE_PARTS = [AUDIENCE_MICROSERVICE_BASE, AUDIENCE_MICROSERVICE_VERSION];
export var AUDIENCE_MICROSERVICE_URL = AUDIENCE_MICROSERVICE_PARTS.join('/');
/**
 * Native Microservice
 */

var NATIVE_MICROSERVICE_BASE = applyAPIDomainToEnvBase('REACT_APP_SIGNAL_NATIVE_MICROSERVICE_BASE');
var NATIVE_MICROSERVICE_VERSION = process.env.REACT_APP_SIGNAL_NATIVE_MICROSERVICE_VERSION;
export var NATIVE_MICROSERVICE_PARTS = [NATIVE_MICROSERVICE_BASE, NATIVE_MICROSERVICE_VERSION];
export var NATIVE_MICROSERVICE_URL = NATIVE_MICROSERVICE_PARTS.join('/');