import React from 'react'
import ReportLoadingState from '@signal/components/ReportLoadingState'

/**
 * This component determines the route to load when someone visits the root url for the app
 */
export function Home() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f1f1f1'
      }}
    >
      <ReportLoadingState />
    </div>
  )
}

export default Home
