var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { paletteDividerSelector } from '@signal/theme';
import { withSafeAreas } from '@signal/theme/mixins';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""]))); // The line that connects the markers is created using ::after

export var Timeline = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  ", "\n\n  &::after {\n    content: '';\n    display: inline-block;\n    width: 2px;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: ", "px;\n    background-color: ", ";\n  }\n"])), withSafeAreas('padding-bottom', 0, ['bottom']), function (props) {
  return props.verticalLineOffset;
}, paletteDividerSelector);