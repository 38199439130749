import createLoader from '@signal/utils/createLoader';
import { authLoader } from '../auth/loaders';
import { loadUserDetails, userDetailsListener, loadUserAccounts, userAccountsListener, loadUsers, usersListener, syncActiveAccountSaga } from './sagas';
export var userDetailsLoader = createLoader('userDetails', {
  load: loadUserDetails,
  listen: userDetailsListener,
  dependencies: [authLoader]
});
export var userAccountsLoader = createLoader('userAccounts', {
  load: loadUserAccounts,
  listen: userAccountsListener,
  dependencies: [authLoader]
});
export var usersLoader = createLoader('users', {
  load: loadUsers,
  listen: usersListener,
  dependencies: [authLoader]
});
export var syncActiveAccountLoader = userAccountsLoader.replace('load', {
  key: 'syncActiveAccount',
  saga: syncActiveAccountSaga
});