var _templateObject;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { Select } from '../MonthPicker/styles';
import { getColorBackgroundSelector } from '@signal/theme';
import { getSpacingSelector } from '@signal/theme/selectors';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
export var StyledSelectComponent = styled(Select)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: ", ";\n  border-radius: 4px;\n  // margin-right: ", "px;\n  padding: 0;\n"])), getColorBackgroundSelector('paper'), function (props) {
  return props['data-border'] ? '1px solid rgba(0, 0, 0, 0.23)' : 'null';
}, getSpacingSelector(2));
export var StyledInput = withStyles(function (theme) {
  return {
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      fontSize: '0.875rem',
      minWidth: '185px',
      padding: '10px 26px 10px 12px'
    }
  };
})(InputBase);