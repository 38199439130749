import React from 'react'
import ContextualPageHeaderSkeleton from '../../components/ContextualPageHeader/Skeleton'
import {
  Wrapper,
  TitleSkeleton,
  SubtitleSkeleton,
  MetricsSkeleton
} from './styles'

export function ShopperDetailHeaderSkeleton({
  crumbs,
  subtitle,
  metrics,
  ...rest
}) {
  return (
    <ContextualPageHeaderSkeleton
      showCrumbs
      crumbProps={{
        loadingCount: 3
      }}
    >
      <Wrapper>
        <TitleSkeleton />
        <SubtitleSkeleton />
        <MetricsSkeleton count={2} />
      </Wrapper>
    </ContextualPageHeaderSkeleton>
  )
}

export default ShopperDetailHeaderSkeleton
