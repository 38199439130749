import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

export const ValueInput = styled(TextField)`
  width: 124px;
  & input {
    text-align: right;
    padding: 8px;
  }
`
