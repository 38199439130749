import { createAction } from 'redux-act';
import createRequestActions from '@signal/utils/createRequestActions';
import { adTemplateActionReducers } from './utils';
import { AD_ADS, AD_AD_DETAILS, AD_CREATE, AD_STATS, AD_DISABLE, AD_PHOTOS, ACCOUNT_AD_PHOTOS, ACCOUNT_AD_PHOTO_UPLOAD, ACCOUNT_AD_PHOTO_DELETE, SAVE_AD_DETAILS, DISMISS_AD_CREATED, SET_PRIOR_AD, OFFERS_UNIVERSAL_LIST, OFFERS_UNIVERSAL_DISABLE } from './constants';
/**
 * Ads
 */

/**
 * Actions for the ads API lifecycle.
 * @type {RequestActions}
 */

export var adsActions = createRequestActions(AD_ADS);
var adsLoad = adsActions.load,
    adsRequest = adsActions.request,
    adsSuccess = adsActions.success,
    adsFailure = adsActions.failure;
export { adsLoad, adsRequest, adsSuccess, adsFailure };
export var adDetailsActions = createRequestActions(AD_AD_DETAILS);
var adDetailsLoad = adDetailsActions.load,
    adDetailsRequest = adDetailsActions.request,
    adDetailsSuccess = adDetailsActions.success,
    adDetailsFailure = adDetailsActions.failure;
export { adDetailsLoad, adDetailsRequest, adDetailsSuccess, adDetailsFailure };
export var adCreateActions = createRequestActions(AD_CREATE);
var adCreateLoad = adCreateActions.load,
    adCreateRequest = adCreateActions.request,
    adCreateSuccess = adCreateActions.success,
    adCreateFailure = adCreateActions.failure;
export { adCreateLoad, adCreateRequest, adCreateSuccess, adCreateFailure };
export var adDisableActions = createRequestActions(AD_DISABLE);
var adDisableLoad = adDisableActions.load,
    adDisableRequest = adDisableActions.request,
    adDisableSuccess = adDisableActions.success,
    adDisableFailure = adDisableActions.failure;
export { adDisableLoad, adDisableRequest, adDisableSuccess, adDisableFailure };
export var adPhotosActions = createRequestActions(AD_PHOTOS);
var adPhotosLoad = adPhotosActions.load,
    adPhotosRequest = adPhotosActions.request,
    adPhotosSuccess = adPhotosActions.success,
    adPhotosFailure = adPhotosActions.failure;
export { adPhotosLoad, adPhotosRequest, adPhotosSuccess, adPhotosFailure };
export var accountAdPhotosActions = createRequestActions(ACCOUNT_AD_PHOTOS);
var accountAdPhotosLoad = accountAdPhotosActions.load,
    accountAdPhotosRequest = accountAdPhotosActions.request,
    accountAdPhotosSuccess = accountAdPhotosActions.success,
    accountAdPhotosFailure = accountAdPhotosActions.failure;
export { accountAdPhotosLoad, accountAdPhotosRequest, accountAdPhotosSuccess, accountAdPhotosFailure };
export var accountAdPhotoUploadActions = createRequestActions(ACCOUNT_AD_PHOTO_UPLOAD);
var accountAdPhotoUpload = accountAdPhotosActions.load;
export { accountAdPhotoUpload };
export var accountAdPhotoDeleteActions = createRequestActions(ACCOUNT_AD_PHOTO_DELETE);
var accountAdPhotoDelete = accountAdPhotoDeleteActions.load;
export { accountAdPhotoDelete };
export var adStatsActions = createRequestActions(AD_STATS);
/**
 * Actions for managing Ad created dialog
 */

export var saveOfferDetails = createAction(SAVE_AD_DETAILS, function (offerDetails, _ref) {
  var isUniversal = _ref.isUniversal;
  var adTemplateId = offerDetails.adTemplateId;
  var offerDetailsReducer = adTemplateActionReducers[adTemplateId];
  return offerDetailsReducer(offerDetails, isUniversal);
}, function (_, _ref2) {
  var offerId = _ref2.offerId,
      isUniversal = _ref2.isUniversal,
      setErrors = _ref2.setErrors,
      setSubmitting = _ref2.setSubmitting;
  return {
    offerId: offerId,
    isUniversal: isUniversal,
    setErrors: setErrors,
    setSubmitting: setSubmitting
  };
});
export var dismissAdCreatedModal = createAction(DISMISS_AD_CREATED);
/*
 * Actions for setting the 'prior ad' before creating an audience
 */

export var setPriorAd = createAction(SET_PRIOR_AD);
export var universalOffersActions = createRequestActions(OFFERS_UNIVERSAL_LIST);
var universalOffersLoad = universalOffersActions.load,
    universalOffersRequest = universalOffersActions.request,
    universalOffersSuccess = universalOffersActions.success,
    universalOffersFailure = universalOffersActions.failure;
export { universalOffersLoad, universalOffersRequest, universalOffersSuccess, universalOffersFailure };
export var universalOfferDisableActions = createRequestActions(OFFERS_UNIVERSAL_DISABLE);
var universalOfferDisableLoad = universalOfferDisableActions.load,
    universalOfferDisableRequest = universalOfferDisableActions.request,
    universalOfferDisableSuccess = universalOfferDisableActions.success,
    universalOfferDisableFailure = universalOfferDisableActions.failure;
export { universalOfferDisableLoad, universalOfferDisableRequest, universalOfferDisableSuccess, universalOfferDisableFailure };