export var REDUCER_KEY = 'ad';
export var NO_AD = undefined;
/**
 * Reducer Subtypes
 */

export var AD_LIST_REDUCERS = 'adList';
export var AD_DETAILS_REDUCERS = 'adDetails';
export var AD_PHOTOS_REDUCERS = 'adPhotos';
export var ACCOUNT_AD_PHOTOS_REDUCERS = 'accountAdPhotos';
export var NON_REQUEST_REDUCERS = 'nonRequestReducers';
export var UNIVERSAL_OFFERS_LIST_REDUCER = 'universalOffersList';
/**
 * Action Types
 */

export var AD_ADS = 'AD/ADS';
export var AD_AD_DETAILS = 'AD/AD_DETAILS';
export var AD_DISABLE = 'AD/DISABLE';
export var AD_CREATE = 'AD/CREATE';
export var AD_STATS = 'AD/STATS';
export var AD_PHOTOS = 'AD/PHOTO';
export var ACCOUNT_AD_PHOTOS = 'AD/ACCOUNT_PHOTO';
export var ACCOUNT_AD_PHOTO_UPLOAD = 'AD/ACCOUNT_PHOTO_UPLOAD';
export var ACCOUNT_AD_PHOTO_DELETE = 'AD/ACCOUNT_PHOTO_DELETE';
/**
 Ad created modal
 */

export var SAVE_AD_DETAILS = 'AD/SAVE_AD_DETAILS';
export var INVOKE_AD_CREATED = 'AD/INVOKE_CREATED';
export var DISMISS_AD_CREATED = 'AD/DISMISS_CREATED';
export var SHOW_AD_CREATED = 'showAdCreated';
/**
 Prior ad before creating audience
 */

export var SET_PRIOR_AD = 'AD/SET_PRIOR_AD';
export var PRIOR_AD = 'priorAd';
/**
 * Template IDs
 */

export var CASH_OFFER_TEMPLATE_ID = '8667ab67-d742-4fc0-96a5-03ecb9881401';
export var CUSTOM_OFFER_TEMPLATE_ID = 'a1f7dd11-8635-4f95-9fcd-145f5778712b';
export var FULL_CUSTOM_OFFER_TEMPLATE_ID = '220adf4a-8cbd-496f-a213-72b5182769e3';
export var OFFERS_UNIVERSAL_LIST = 'OFFERS/UNIVERSAL_LIST';
export var OFFERS_UNIVERSAL_DISABLE = 'OFFERS/DISABLE';
/**
 * Error enum values
 */

export var ERROR_OVERLAPPING_TIME_WINDOW = 'overlappingTimeWindow';
/**
 * Content
 */

export var CONTENT_ENUMS = {
  CASH: 'cash',
  CUSTOM: 'custom',
  CUSTOM_COUNTDOWN: 'customCountdown'
};
/**
 * Positioning
 */

export var POSITION_ENUMS = {
  BOTTOM: 'bottom',
  MIDDLE: 'middle',
  TOP: 'top',
  RIGHT: 'right',
  CENTER: 'center',
  LEFT: 'left'
};
/**
 * Types
 */

export var TYPE_ENUMS = {
  TEASER: 'teaser',
  INTERSTITIAL: 'interstitial'
};