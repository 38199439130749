import React from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import {
  Table,
  RowSkeleton,
  Cell,
  TitleWrapper,
  TitleSkeleton,
  CellTextSkeleton,
  OptionsMenuWrapper,
  OptionsMenu,
  OptionsMenuIcon
} from './styles'
import {
  TITLE_KEY,
  LAST_EDITED_KEY,
  STATUS_KEY,
  DELETE_KEY,
  generateAdListColumns
} from './utils'

export default function AdListGroupSkeleton({ rowCount, ...rest }) {
  const columnInfo = generateAdListColumns({
    [TITLE_KEY]: {
      isSortable: false,
      renderCell: () => (
        <TitleWrapper>
          <TitleSkeleton />
          <CellTextSkeleton width={150} />
        </TitleWrapper>
      )
    },
    [LAST_EDITED_KEY]: {
      isSortable: false,
      component: () => <CellTextSkeleton width={75} />
    },
    [STATUS_KEY]: {
      component: ({ status }) => {
        return <CellTextSkeleton width={75} />
      }
    },
    [DELETE_KEY]: {
      isSortable: false,
      component: () => (
        <OptionsMenuWrapper>
          <OptionsMenu disabled>
            <OptionsMenuIcon />
          </OptionsMenu>
        </OptionsMenuWrapper>
      )
    }
  })

  return (
    <Table
      allowSort={false}
      columnInfo={columnInfo}
      rowComponent={RowSkeleton}
      cellComponent={Cell}
      rows={times(rowCount, rowCur => ({
        id: rowCur,
        key: rowCur
      }))}
      initialSortKey="lastEdited"
      {...rest}
    />
  )
}

AdListGroupSkeleton.propTypes = {
  rowCount: PropTypes.number
}

AdListGroupSkeleton.defaultProps = {
  rowCount: 3
}
