var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import MuiAvatar from '@material-ui/core/Avatar';
import { paletteDividerSelector, getColorBackgroundSelector, getColorTextSelector, getSpacingSelector, getPxToRemSelector } from '@signal/theme/selectors';
import Button from '../Button';
import PopoverBase from '../Popover';
import PopoverTrigger from '../PopoverTrigger';
import TypographySkeleton from '../Skeleton/Typography';
import Typography from '../Typography';
var avatarColorSelector = getColorBackgroundSelector();
export var Popover = styled(PopoverBase)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n  & > span {\n    height: 100%;\n  }\n"])));
export var ButtonWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
export var Trigger = styled(PopoverTrigger)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 0;\n  height: 100%;\n  padding: 0 ", "px;\n  margin-right: -", "px;\n  border-radius: 0;\n  &:hover {\n    background: none;\n  }\n"])), getSpacingSelector(1), getSpacingSelector(1));
export var Avatar = styled(MuiAvatar)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 36px;\n  height: 36px;\n  font-size: ", ";\n  color: ", ";\n  background-color: ", ";\n"])), getPxToRemSelector(16), getColorTextSelector(), avatarColorSelector);
export var AvatarLg = styled(MuiAvatar)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  width: 56px;\n  height: 56px;\n  font-size: ", ";\n"])), getPxToRemSelector(20));
export var ButtonSkeleton = styled(Avatar)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  background-color: ", ";\n"])), avatarColorSelector);
export var ButtonTitle = styled(Typography).attrs(function () {
  return {
    variant: 'body4',
    noWrap: true
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin-right: ", "px;\n"])), getSpacingSelector(2));
export var TitleSkeleton = styled(ButtonTitle).attrs(function () {
  return {
    as: TypographySkeleton,
    width: 100,
    color: 'skeletonDark'
  };
})(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral([""])));
export var Divider = styled.hr(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  margin: 0;\n  border: none;\n  border-bottom: 1px solid ", ";\n"])), paletteDividerSelector);
export var AccountMenu = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  min-width: 385px;\n"])));
export var LogoutButtonWrapper = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 ", "px ", "px\n    ", "px;\n"])), getSpacingSelector(2), getSpacingSelector(2), getSpacingSelector(2));
export var LogoutButton = styled(Button).attrs(function () {
  return {
    variant: 'outlined'
  };
})(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral([""])));
export var FontSlider = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  width: 70px;\n"])));
export var IconWrapper = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n"])));