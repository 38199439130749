import React, { memo } from 'react'
import { ToolbarControlWrapper, ToolbarControlLabel } from './styles'

export function ToolbarControl({ label, style, className, children }) {
  return (
    <ToolbarControlWrapper style={style} className={className}>
      <ToolbarControlLabel>{label}</ToolbarControlLabel>
      {children}
    </ToolbarControlWrapper>
  )
}

export default memo(ToolbarControl)
