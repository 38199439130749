import React from 'react'
import styled from 'styled-components'
import { createSelector } from 'reselect'
import set from 'lodash/set'
import Panel from '@signal/components/Panel'
import Typography from '@signal/components/Typography'
import { constrainToMaxPageWidth } from '@signal/theme/mixins'
import { getSpacingSelector } from '@signal/theme/selectors'

const verticalSpacingSelector = getSpacingSelector(2)
const horizontalSpacingSelector = getSpacingSelector(3)
const getPanelStyleSelector = spacingProperty =>
  createSelector(
    verticalSpacingSelector,
    horizontalSpacingSelector,
    (verticalSpacing, horizontalSpacing) => ({
      [spacingProperty]: `${verticalSpacing}px ${horizontalSpacing}px`
    })
  )
const titleStyleSelector = getPanelStyleSelector('padding')
const contentStyleSelector = getPanelStyleSelector('margin')

export const Title = styled(Typography).attrs(() => ({
  variant: 'h3',
  color: 'textDark'
}))``

export const VehicleActivity = styled(Panel).attrs(props => ({
  title: <Title>Vehicle Activity</Title>,
  titleStyle: titleStyleSelector(props),
  contentStyle: contentStyleSelector(props)
}))`
  ${constrainToMaxPageWidth}
  padding: 0;
  margin: ${getSpacingSelector(4)}px auto;

  > *:last-child > * {
    margin: ${getSpacingSelector(3)}px 0;
  }
`

export const ChannelContributions = styled(Panel).attrs(props => ({
  contentStyle: contentStyleSelector(props)
}))`
  ${constrainToMaxPageWidth}
  padding: 0;
  margin: ${getSpacingSelector(4)}px auto;

  > *:last-child > * {
    margin: ${getSpacingSelector(3)}px 0;
  }
`

export const TopChannels = styled(Panel).attrs(props => ({
  title: <Title>Top Channels</Title>,
  titleStyle: titleStyleSelector(props),
  contentStyle: contentStyleSelector(props)
}))`
  ${constrainToMaxPageWidth}
  padding: 0;
  margin: ${getSpacingSelector(4)}px auto;

  > *:last-child > * {
    margin: ${getSpacingSelector(3)}px 0;
  }
`
const contentStyleSelectorBuyer = createSelector(contentStyleSelector, style =>
  set(style, 'marginBottom', 0)
)
export const BuyerActivity = styled(VehicleActivity).attrs(props => ({
  title: <Title>Buyer Activity</Title>,
  contentStyle: contentStyleSelectorBuyer(props)
}))`
  > *:last-child > *:last-child {
    margin-bottom: 0;
  }
`
