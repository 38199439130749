import styled from 'styled-components'
import Header from '@signal/layouts/Header'
import { HEADER_CONTENT_HEIGHT } from '@signal/layouts/constants'
import {
  getSpacingSelector,
  paletteDividerSelector,
  getColorBackgroundSelector,
  appBarZIndexSelector
} from '@signal/theme/selectors'

export const Wrapper = styled(Header)`
  top: ${HEADER_CONTENT_HEIGHT};
  background-color: ${getColorBackgroundSelector('paper')};
  border-bottom: 1px solid ${paletteDividerSelector};
  z-index: calc(${appBarZIndexSelector} - 1);

  @media print {
    display: none;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const Controls = styled.div`
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: ${getSpacingSelector()}px;
  }
`
