import styled from 'styled-components'
import { clearfix } from '@signal/theme/mixins'

export const Header = styled.div`
  z-index: 100;
`

export const Content = styled.div`
  width: 100%;
  ${clearfix}
`
