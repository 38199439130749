import React, { memo, useMemo, useState } from 'react'
import {
  PAGE_CHANNEL,
  PAGE_CHANNEL_MAKE,
  PAGE_CHANNEL_MODEL,
  APP_ATTRIBUTION
} from '@signal/analytics'
import { parsePerformanceParams, MAKE, MODEL } from '@signal/core/performance'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import Header from '../../containers/AttributionHeader'
import ChannelsDetailDataGrid from '../../containers/ChannelsDetailDataGrid'
import ChannelsDetailChart from '../../containers/ChannelsDetailChart'
import ChannelsDetailDrilldownTables from '../../containers/ChannelsDetailDrilldownTables'
import ChannelsDetailHeader from '../../containers/ChannelsDetailHeader'
import ChannelsDetailToolbar from '../../containers/ChannelsDetailToolbar'
import { SHOW_ALL } from '../../core/channels/constants'
import { PageWrapper } from '../styles'

export const ChannelsDetailPage = memo(({ location }) => {
  const [selectedGroup, setSelectedGroup] = useState(SHOW_ALL)

  const pageName = useMemo(() => {
    const { lvl } = parsePerformanceParams(location.search)
    let pageName
    switch (lvl) {
      case MAKE:
        pageName = PAGE_CHANNEL_MAKE
        break
      case MODEL:
        pageName = PAGE_CHANNEL_MODEL
        break
      default:
        pageName = PAGE_CHANNEL
    }
    return pageName
  }, [location.search])

  usePageTrack(pageName, APP_ATTRIBUTION)

  // TODO: Remove this hack - hopefully when loadables are refactored. This is because
  // the current implementation of the channels detail loadable requires the component to
  // be unmounted and remounted. So adding the key here forces React to unmount and remount
  // the Layout component (and thereby all of the child components as well).
  const key = location.pathname + location.search

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      location={location}
      headerComponent={ChannelsDetailHeader}
      key={key}
    >
      <ChannelsDetailToolbar
        location={location}
        selectedGroup={selectedGroup}
        onSelectGroup={setSelectedGroup}
      />
      <PageWrapper>
        <ChannelsDetailDataGrid location={location} />
        <ChannelsDetailChart location={location} />
        <ChannelsDetailDrilldownTables
          location={location}
          selectedGroup={selectedGroup}
        />
      </PageWrapper>
    </LayoutDefaultWithPageHeader>
  )
})

ChannelsDetailPage.displayName = 'ChannelsDetailPage'

export default ChannelsDetailPage
