import React from 'react'
import ContextualPageHeaderSkeleton from '../../components/ContextualPageHeader/Skeleton'
import InventoryPerformancePanelSummarySkeleton from '../../components/InventoryPerformancePanelSummary/Skeleton'

export function Skeleton() {
  return (
    <ContextualPageHeaderSkeleton showCrumbs showAlertMessage>
      <InventoryPerformancePanelSummarySkeleton />
    </ContextualPageHeaderSkeleton>
  )
}

export default Skeleton
