var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { createSelector } from 'reselect';
import Typography from '../Typography';
import { getSpacingSelector, contentSectionHeadingTitleTypographyVariantSelector, contentSectionHeadingTitleSpacingBottomSelector } from '@signal/theme/selectors';
import TypographySkeleton from '../Skeleton/Typography';
var marginBottomSelector = createSelector(contentSectionHeadingTitleSpacingBottomSelector, getSpacingSelector);
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));

var createTitleAttrs = function createTitleAttrs(attrs) {
  return function (props) {
    return _objectSpread({
      variant: contentSectionHeadingTitleTypographyVariantSelector(props)
    }, attrs);
  };
};

export var Title = styled(Typography).attrs(createTitleAttrs({
  color: 'textDark'
}))(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var TitleSkeleton = styled(TypographySkeleton).attrs(createTitleAttrs({
  color: 'skeletonDark',
  width: 90
}))(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));

var subtitleSharedAttrs = function subtitleSharedAttrs() {
  return {
    variant: 'body2'
  };
};

export var Subtitle = styled(Typography).attrs(subtitleSharedAttrs)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var SubtitleSkeleton = styled(TypographySkeleton).attrs(function (props) {
  return _objectSpread(_objectSpread({}, subtitleSharedAttrs(props)), {}, {
    width: 90
  });
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var Description = styled(Typography).attrs(function () {
  return {
    variant: 'subtitle2'
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top: ", "px;\n"])), getSpacingSelector(2));
export var DescriptionSkeleton = styled(Description.withComponent(TypographySkeleton)).attrs(function () {
  return {
    lines: 2
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral([""])));
export var TitleLine = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: flex-end;\n  justify-content: space-between;\n\n  > * {\n    margin-bottom: ", "px;\n  }\n"])), marginBottomSelector);