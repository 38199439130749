import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const HelpButton = styled(IconButton)`
  margin-left: 6px;
`
