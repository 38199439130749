import checkStore from '../checkStore';
import { injectSagaFactory, ejectSagaFactory } from './utils';
export { SAGA_MODES } from './constants';
/**
 * Saga-related helper functions bound to a redux store.
 * @global
 * @typedef {Object} SagaHelpers
 * @property {injectSaga} injectSaga - injectSaga helper function
 * @property {ejectSaga} ejectSaga - ejectSaga helper function
 */

/**
 * Creates saga-related helpers for the provided store.
 *
 * @param {Store} store - A redux store to pass to helper factories
 * @returns {SagaHelpers} - Saga-related helper functions
 */

export function createSagaHelpers(store) {
  checkStore(store);
  return {
    injectSaga: injectSagaFactory(store, true),
    ejectSaga: ejectSagaFactory(store, true)
  };
}
export default createSagaHelpers;