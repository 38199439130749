import select from '@signal/utils/select';
import { getSignalFontVariantName } from '../utils';
import { createThemeSelector, createVariableThemeSelectorCreator } from './utils';
export var typographySelector = createThemeSelector(function (theme) {
  return theme.typography;
});
export var getPxToRemSelector = createVariableThemeSelectorCreator(function (fontSize) {
  return createThemeSelector(typographySelector, function (typography) {
    return typography.pxToRem(fontSize);
  });
});
export var getTypographyVariantSelector = createVariableThemeSelectorCreator(function (variant) {
  return createThemeSelector(typographySelector, select(getSignalFontVariantName(variant)));
});