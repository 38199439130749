import React, { memo } from 'react'
import { PAGE_INVENTORY_VIN, APP_ATTRIBUTION } from '@signal/analytics'
import Header from '../../containers/AttributionHeader'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import InventoryVinDetailsList from '../../containers/InventoryVinDetailsList'
import InventoryVinHeader from '../../containers/InventoryVinHeader'
import { PageWrapper } from '../styles'

export const InventoryVinPage = memo(props => {
  const { match } = props
  usePageTrack(PAGE_INVENTORY_VIN, APP_ATTRIBUTION)

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      headerComponent={InventoryVinHeader}
      match={match}
    >
      <PageWrapper>
        <InventoryVinDetailsList match={match} />
      </PageWrapper>
    </LayoutDefaultWithPageHeader>
  )
})

InventoryVinPage.displayName = 'InventoryVinPage'

export default InventoryVinPage
