import { createSelector } from 'reselect';
import { activeAccountIdSelector } from '@signal/core/auth';
import { recentlyViewedAccountIdsSelector } from '@signal/core/local/selectors';
import { accountsByIdSelector } from '@signal/core/user/selectors';
export var recentlyViewAccountsSelector = createSelector(recentlyViewedAccountIdsSelector, accountsByIdSelector, activeAccountIdSelector, function (ids, accountDict, activeAccountId) {
  if (!accountDict) {
    return [];
  }

  return ids.filter(function (id) {
    return id !== activeAccountId;
  }).slice(0, 3).map(function (id) {
    return accountDict[id];
  }).filter(Boolean);
});