import styled from 'styled-components'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import StackedBarChartWithLegend from '@signal/components/StackedBarChartWithLegend'
import SortableTableSekelton from '@signal/components/SortableTable/Skeleton'
import StackedBarChartWithLegendSkeleton from '@signal/components/StackedBarChartWithLegend/Skeleton'
import SortableTable from '@signal/components/SortableTable'
import {
  getSpacingSelector,
  getColorScalePrimarySelector,
  paletteDividerSelector
} from '@signal/theme/selectors'

export const chartColors = [
  getColorScalePrimarySelector(7),
  getColorScalePrimarySelector(6),
  getColorScalePrimarySelector(5),
  getColorScalePrimarySelector(4),
  getColorScalePrimarySelector(3),
  getColorScalePrimarySelector(2),
  getColorScalePrimarySelector(1),
  getColorScalePrimarySelector(0)
]

export const Wrapper = styled.div`
  padding-bottom: 0;
`

export const AttributionBarChart = styled(StackedBarChartWithLegend).attrs(
  () => ({
    chartWrapperProps: {
      style: {
        height: '10px'
      }
    }
  })
)`
  margin-bottom: 20px;
`
export const AttributionBarChartSkeleton = AttributionBarChart.withComponent(
  StackedBarChartWithLegendSkeleton
)

export const SectionHeading = styled(ContentSectionHeading).attrs(() => ({
  description:
    'Below are the top channels that contributed to the sale of this VIN'
}))`
  margin-bottom: ${getSpacingSelector(3)}px;
`

export const ChannelsTable = styled(SortableTable)`
  margin-left: -${getSpacingSelector(3)}px;
  margin-right: -${getSpacingSelector(3)}px;
  // Setting padding-bottom is kind of a hack because the position sticky td and th cover the horizontal scrollbar.
  // The padding pushes the table components up slightly and gives some breathing room to the scrollbar.
  // I'm not sure if there is a better way to solve this problem.
  padding-bottom: ${getSpacingSelector(1.25)}px;

  thead tr th:first-of-type,
  tbody tr td:first-of-type {
    position: sticky;
    left: 0;
    z-index: 2;
    // Position sticky will leave behind the border and it won't stick with the element.
    // So instead, I'm using box-shadow to act as a border to the right.
    box-shadow: 1px 0px 0px 0px ${paletteDividerSelector};
  }
`

export const ChannelTableSkeleton = ChannelsTable.withComponent(
  SortableTableSekelton
)
