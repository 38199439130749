import isStorybook from '@signal/utils/env/isStorybook';
import isTest from '@signal/utils/env/isTest';
import useSWRWithCache from '../contextFetch/hooks';
import { replaceCacheURL } from '../contextFetch/utils';
import { API_VERSION, API_URL } from './constants';
import mockData from './mockData.json';
var MOCK_THEME = 'mock';
export var NO_THEME = 'none';
var TENANT_THEME_URL = "".concat(API_URL, "/v").concat(API_VERSION, "/theme"); // use this hook from the main app to load tenant theme colors

export function useTenantTheme(_ref) {
  var mockTheme = _ref.mockTheme;

  if (!mockTheme && (isStorybook() || isTest())) {
    mockTheme = NO_THEME;
  }

  var url = TENANT_THEME_URL;

  if (mockTheme) {
    // NOTE: replace {} with mockData to test the actual mock data
    replaceCacheURL(url, '', mockTheme === MOCK_THEME ? mockData : {});
  }

  return useSWRWithCache({
    url: url,
    context: 'useTenantTheme'
  });
}
export default useTenantTheme;