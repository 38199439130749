var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { getSpacingSelector, getColorGreySelector } from '@signal/theme/selectors';
import ButtonComponent from '../Button';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var DefaultButton = styled(ButtonComponent)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  box-shadow: none;\n"])));
export var List = styled(MenuList)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 0;\n"])));
export var ListItemDivider = styled(Divider)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background-color: ", ";\n"])), getColorGreySelector(100));
export var ListItem = styled(MenuItem)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: ", "px;\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), getSpacingSelector(1.5), getColorGreySelector(100));
export var ListItemContent = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n"])));
export var ListPaper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  border-radius: 2px;\n  border: 1px solid ", ";\n"])), getColorGreySelector(300));