import React from 'react'
import times from 'lodash/times'
import ChartSkeleton from '@signal/components/HorizontalFunnelChart/Skeleton'
import {
  Wrapper,
  Labels,
  LabelSkeletonWrapper,
  LabelSkeleton,
  Descriptions,
  DescriptionSkeleton,
  VerticalGridLine,
  ChartWrapper
} from './styles'

export default function ShoppersFunnelChartSkeleton({
  hasDescriptions = false,
  labelSkeleton,
  className
}) {
  return (
    <Wrapper className={className}>
      <Labels>
        {times(3, i => (
          <LabelSkeletonWrapper key={i}>
            {labelSkeleton ? labelSkeleton : <LabelSkeleton />}
          </LabelSkeletonWrapper>
        ))}
      </Labels>
      <ChartWrapper>
        <ChartSkeleton />
      </ChartWrapper>
      {hasDescriptions && (
        <Descriptions>
          {times(3, i => (
            <LabelSkeletonWrapper key={i}>
              <DescriptionSkeleton />
            </LabelSkeletonWrapper>
          ))}
        </Descriptions>
      )}
      <VerticalGridLine y={1 / 3} />
      <VerticalGridLine y={2 / 3} />
    </Wrapper>
  )
}
