import React from 'react'
import Skeleton from '@signal/components/ContentSectionHeading/Skeleton'
import TimeRangesSkeleton from '@signal/components/TimeRanges/Skeleton'
import LegendSkeleton from '../ShoppersChartLegend/Skeleton'

export default function ShoppersHeadingSkeleton() {
  return (
    <Skeleton
      description={false}
      subtitle={<TimeRangesSkeleton />}
      rightContent={LegendSkeleton}
    />
  )
}
