import React from 'react'
import { PureCarsBrand } from '@signal/components/CommonHeader/styles'
import NotFound from '../../components/NotFound'
import LayoutDefault from '../../layouts/Default'
import Header from '../../containers/Header'

function NavHeader() {
  return <Header title={''} icon={PureCarsBrand} alignIcon="left" />
}

export default function NotFoundPage() {
  return (
    <LayoutDefault navbar={NavHeader}>
      <NotFound />
    </LayoutDefault>
  )
}
