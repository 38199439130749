import { createSelector } from 'reselect'
import {
  accountsPathSelector,
  pathnameSelector,
  searchSelector,
  manageSpendPathSelector
} from '@signal/core/router/selectors'
import { activeAccountFeaturesSelector } from '@signal/core/auth'
import {
  hasAllPermissions,
  PERMISSION_LEVEL_WRITE,
  PERFORMANCE_FEATURE_KEY
} from '@signal/utils/permissions'

export const actionsSelector = createSelector(
  accountsPathSelector,
  manageSpendPathSelector,
  activeAccountFeaturesSelector,
  pathnameSelector,
  searchSelector,
  (accountsPath, manageSpendPath, features, pathname, search) => {
    const qs = search ? `?${search}` : ''
    const actions = [
      {
        to: {
          pathname: accountsPath,
          state: {
            referrer: `${pathname}${qs}`
          }
        },
        label: 'Accounts'
      },
      {
        to: manageSpendPath,
        label: 'Manage Spend',
        neededPermissions: [
          {
            feature: PERFORMANCE_FEATURE_KEY,
            level: PERMISSION_LEVEL_WRITE
          }
        ]
      }
    ]

    return actions.filter(action =>
      hasAllPermissions(features, action.neededPermissions)
    )
  }
)
