import merge from 'lodash/merge';
import reduce from 'lodash/reduce'; // Middleware exports

export { default as typographyMiddleware } from './typography';
export { default as muiOverridesMiddleware } from './muiOverrides';
/**
 * A function that can perform custom logic on custom overrides before they are
 * merged into a final {@link Theme} object by {@link createTheme}.
 * @typedef {Function} ThemeMiddleware
 * @param {ThemeOverrides} baseOverrides - base theme overrides
 * @param {ThemeOverrides} customOverrides - custom theme overrides
 * @global
 */

/**
 * Combines a list of theme middleware functions into a single "middleware"
 * function that passes custom overrides through each of the middleware functions
 * provided before merging the resulting {@link ThemeOverrides} object with the
 * base theme overrides.
 * @param {Array.<ThemeMiddleware>} arrThemeMiddleware - ordered list of theme middleware
 * @return {Function}
 */

export var combineMiddleware = function combineMiddleware(arrThemeMiddleware) {
  /**
   * Passes the supplied {@link ThemeOverrides} object through a series of
   * {@link ThemeMiddleware} functions before merging the final result into the
   * base overrides object.
   * @param {ThemeOverrides} baseOverrides - base theme overrides
   * @param {ThemeOverrides} customOverrides - custom theme overrides
   * @returns {ThemeOverrides}
   * @global
   */
  return function combinedMiddleware(baseOverrides, customOverrides) {
    var resultOverrides = reduce(arrThemeMiddleware, // Each middleware function is called with the base theme overrides and
    // the overrides object returned from the previous middleware
    function (prevCustomOverrides, middleware) {
      return middleware(baseOverrides, prevCustomOverrides);
    }, // The custom overrides object provided the function is used as the
    // initial value for  `reduce()`
    customOverrides); // Merge the resulting overrides object that has passed through each of the
    // middleware functions into the base overrides object

    return merge({}, baseOverrides, resultOverrides);
  };
};