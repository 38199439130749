import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { SUMMARY_TYPE_CHANNELS } from '@signal/core/local'
import { manageSpendPathSelector } from '@signal/core/router/selectors'
import PerformanceSummaryPanel from '../../components/PerformanceSummaryPanel'
import withExpandable from '../../hoc/withExpandable'

export const ChannelsSummaryHeader = ({ manageSpendLink, ...rest }) => (
  <PerformanceSummaryPanel
    title="Channel Attribution"
    description={
      <Fragment>
        See which channels are bringing the greatest return on your investment
        at Make, Model and VIN levels. The sale of each VIN can be attributed to
        multiple channels. Below are the sum of attributed sales from each
        channel and the percentage they contributed towards VINs whose sales
        they influenced.{' '}
        <Link to={manageSpendLink}>
          Enter your spend to get the most out of your data
        </Link>
      </Fragment>
    }
    image={{
      srcSet: [
        {
          dpr: 1,
          src:
            'https://purecars-signal.imgix.net/app/performance/channels/channel-header-image.png'
        },
        {
          dpr: 2,
          src:
            'https://purecars-signal.imgix.net/app/performance/channels/channel-header-image@2x.png'
        },
        {
          dpr: 3,
          src:
            'https://purecars-signal.imgix.net/app/performance/channels/channel-header-image@3x.png'
        }
      ],
      alt: 'Channel performance broken down by Make, Model and VIN',
      width: 1032,
      height: 198
    }}
    {...rest}
  />
)

const mapStateToProps = createStructuredSelector({
  manageSpendLink: manageSpendPathSelector
})

export default connect(mapStateToProps)(
  withExpandable(SUMMARY_TYPE_CHANNELS)(ChannelsSummaryHeader)
)
