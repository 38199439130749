import React from 'react'
import styled from 'styled-components'
import Header from '../../containers/Header'
import LayoutDefault from '../../layouts/Default'
import LogOut from '../../containers/LogOut'

import { getSpacingSelector } from '@signal/theme/selectors'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${getSpacingSelector(2)}px;
`
export default () => (
  <LayoutDefault navbar={Header}>
    <CenterContainer>
      <LogOut />
    </CenterContainer>
  </LayoutDefault>
)
