import styled from 'styled-components'
import DataGridBase from '@signal/components/DataGrid'
import DataGridSkeletonBase from '@signal/components/DataGrid/Skeleton'
import FeaturedMetricBase from '@signal/components/FeaturedMetric'
import FeaturedMetricSkeletonBase from '@signal/components/FeaturedMetric/Skeleton'
import { getSpacingSelector } from '@signal/theme/selectors'

export const FeaturedMetric = styled(FeaturedMetricBase)`
  align-items: flex-start;
  margin: ${getSpacingSelector(4)}px 0;
`

export const FeaturedMetricSkeleton = FeaturedMetric.withComponent(
  FeaturedMetricSkeletonBase
)

export const DataGrid = styled(DataGridBase)`
  margin: ${getSpacingSelector(4)}px 0;
`

export const DataGridSkeleton = DataGrid.withComponent(DataGridSkeletonBase)
