import { createRoute } from '@signal/router';
import { ACCOUNT } from './core';
export var PERFORMANCE = createRoute(ACCOUNT, 'performance');
export var PERFORMANCE_OVERVIEW = createRoute(PERFORMANCE, ':activeTab');
export var PERFORMANCE_CHANNELS = createRoute(PERFORMANCE, 'channels');
export var PERFORMANCE_CHANNELS_DETAIL = createRoute(PERFORMANCE_CHANNELS, 'detail');
export var PERFORMANCE_CHANNELS_VIN = createRoute(PERFORMANCE_CHANNELS, 'vin/:vin');
export var PERFORMANCE_INVENTORY = createRoute(PERFORMANCE, 'inventory');
export var PERFORMANCE_INVENTORY_DETAIL = createRoute(PERFORMANCE_INVENTORY, 'detail');
export var PERFORMANCE_INVENTORY_DETAIL_VIN = createRoute(PERFORMANCE_INVENTORY_DETAIL, 'vin/:vin');
export var PERFORMANCE_INVENTORY_VIN = createRoute(PERFORMANCE_INVENTORY, 'vin/:vin');
export var PERFORMANCE_SHOPPERS = createRoute(PERFORMANCE, 'shoppers');
export var PERFORMANCE_SHOPPERS_BUCKET = createRoute(PERFORMANCE_SHOPPERS, ':shopperBucket');
export var SHOPPER_DETAIL = createRoute(PERFORMANCE_SHOPPERS_BUCKET, ':shopperId');
export var MANAGE_SPEND = createRoute(ACCOUNT, 'manage-spend');