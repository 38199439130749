import { LOADER } from './createLoader/constants';
/**
 * Checks if a function/object is a data loader.
 * @param  {Function|Object} maybeLoader - An unknown function or object
 * @returns {Boolean}
 */

export default function isLoader() {
  var maybeLoader = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return maybeLoader[LOADER] === true;
}