var _SUMMARY_EXPANDED, _initialState, _createReducer;

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import format from 'date-fns/format';
import { SUMMARY_EXPANDED, SUMMARY_TYPE_CHANNELS, SUMMARY_TYPE_INVENTORY, SUMMARY_TYPE_SHOPPERS, DATE_RANGE, DATE_RANGE_PRESETS, DATE_FORMAT, RECENTLY_VIEWED_ACCOUNTS_KEY, SHOW_ONBOARDING_TOUR, SHOW_ACTIVATE_TOUR, CHANNELS_FILTER_TOOLBAR } from './constants';
import { setSummaryExpanded, setDateRange, setRecentlyViewedAccount, beginOnboardingTour, dismissOnboardingTour, beginActivateTour, dismissActivateTour, setChannelsFilter } from './actions';
export var initialState = (_initialState = {}, _defineProperty(_initialState, SUMMARY_EXPANDED, (_SUMMARY_EXPANDED = {}, _defineProperty(_SUMMARY_EXPANDED, SUMMARY_TYPE_CHANNELS, true), _defineProperty(_SUMMARY_EXPANDED, SUMMARY_TYPE_INVENTORY, true), _defineProperty(_SUMMARY_EXPANDED, SUMMARY_TYPE_SHOPPERS, true), _SUMMARY_EXPANDED)), _defineProperty(_initialState, DATE_RANGE, DATE_RANGE_PRESETS[0]), _defineProperty(_initialState, RECENTLY_VIEWED_ACCOUNTS_KEY, []), _defineProperty(_initialState, SHOW_ONBOARDING_TOUR, true), _defineProperty(_initialState, SHOW_ACTIVATE_TOUR, true), _defineProperty(_initialState, CHANNELS_FILTER_TOOLBAR, {}), _initialState);
export default createReducer((_createReducer = {}, _defineProperty(_createReducer, setSummaryExpanded, function (state, payload) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SUMMARY_EXPANDED, _objectSpread(_objectSpread({}, state[SUMMARY_EXPANDED]), payload)));
}), _defineProperty(_createReducer, setDateRange, function (state, _ref) {
  var startDate = _ref.startDate,
      endDate = _ref.endDate;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, DATE_RANGE, {
    start: startDate ? format(startDate.toDate(), DATE_FORMAT) : null,
    end: endDate ? format(endDate.toDate(), DATE_FORMAT) : null
  }));
}), _defineProperty(_createReducer, setRecentlyViewedAccount, function (state, accountId) {
  var prevAccountList = get(state, RECENTLY_VIEWED_ACCOUNTS_KEY, []);
  var accountList = [accountId].concat(_toConsumableArray(prevAccountList));
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, RECENTLY_VIEWED_ACCOUNTS_KEY, uniq(accountList)));
}), _defineProperty(_createReducer, beginOnboardingTour, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_ONBOARDING_TOUR, true));
}), _defineProperty(_createReducer, dismissOnboardingTour, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_ONBOARDING_TOUR, false));
}), _defineProperty(_createReducer, beginActivateTour, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_ACTIVATE_TOUR, true));
}), _defineProperty(_createReducer, dismissActivateTour, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_ACTIVATE_TOUR, false));
}), _defineProperty(_createReducer, setChannelsFilter, function (state, payload) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, CHANNELS_FILTER_TOOLBAR, _objectSpread(_objectSpread({}, state[CHANNELS_FILTER_TOOLBAR]), payload)));
}), _createReducer), initialState);