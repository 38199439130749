import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import { SIGNAL_FONT_VARIANT } from './constants';
/**
 * Combines a list of theme middleware functions into a single "middleware"
 * function that passes custom overrides through each of the middleware functions
 * provided before merging the resulting {@link ThemeOverrides} object with the
 * base theme overrides.
 * @param {Array.<ThemeMiddleware>} arrThemeMiddleware - ordered list of theme middleware
 * @return {Function}
 */

export var combineThemeMiddleware = function combineThemeMiddleware(arrThemeMiddleware) {
  /**
   * Passes the supplied {@link ThemeOverrides} object through a series of
   * {@link ThemeMiddleware} functions before merging the final result into the
   * base overrides object.
   * @param {ThemeOverrides} baseOverrides - base theme overrides
   * @param {ThemeOverrides} customOverrides - custom theme overrides
   * @returns {ThemeOverrides}
   * @global
   */
  return function combinedThemeMiddleware(baseOverrides, customOverrides) {
    var resultOverrides = reduce(arrThemeMiddleware, // Each middleware function is called with the base theme overrides and
    // the overrides object returned from the previous middleware
    function (prevCustomOverrides, middleware) {
      return middleware(baseOverrides, prevCustomOverrides);
    }, // The custom overrides object provided the function is used as the
    // initial value for  `reduce()`
    customOverrides); // Merge the resulting overrides object that has passed through each of the
    // middleware functions into the base overrides object

    return merge({}, baseOverrides, resultOverrides);
  };
};
/**
 * @param {String} maybeSignalFontVariantKey
 * @return {Boolean}
 */

export function isSignalFontVariant(maybeSignalFontVariantKey) {
  return maybeSignalFontVariantKey.startsWith(SIGNAL_FONT_VARIANT);
}
/**
 * Create and return a prefixed name for a custom font variant
 * @param {String} name
 * @return {String} name plus the prefix constant
 */

export function getSignalFontVariantName(name) {
  return "".concat(SIGNAL_FONT_VARIANT).concat(name);
}