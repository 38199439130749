var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import { createSelector } from 'reselect';
import styled from 'styled-components';
import MuiAvatar from '@material-ui/core/Avatar';
import Typography from '../Typography';
import { getSpacingSelector, getPxToRemSelector, getColorPrimarySelector, getColorGreySelector, getColorTextSelector } from '@signal/theme/selectors';
import Button from '../Button';
import SkeletonTypography from '../Skeleton/Typography';
var horizontalSpacingSelector = getSpacingSelector();
var verticalSpacingSelector = getSpacingSelector(1 / 4);
var textSpacingSelector = createSelector(horizontalSpacingSelector, function (horizontalSpacing) {
  return "0 ".concat(horizontalSpacing, "px");
});
var buttonSpacingSelector = createSelector(horizontalSpacingSelector, verticalSpacingSelector, function (horizontalSpacing, verticalSpacing) {
  return "".concat(verticalSpacing, "px ").concat(horizontalSpacing, "px");
});
export var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
export var Avatar = styled(MuiAvatar)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  margin-right: ", "px;\n  width: 50px;\n  height: 50px;\n"])), getColorPrimarySelector(), horizontalSpacingSelector);
export var AvatarSkeleton = styled(Avatar)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  background-color: ", ";\n"])), getColorGreySelector(300));
export var Name = styled(Typography).attrs(function () {
  return {
    variant: 'subtitle2'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding: ", ";\n  line-height: ", ";\n"])), textSpacingSelector, getPxToRemSelector(18));
export var NameSkeleton = styled(SkeletonTypography).attrs(function () {
  return {
    variant: 'caption'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  margin: ", ";\n  padding: 0;\n"])), textSpacingSelector);
export var Subtitle = styled(Typography)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top: 5px;\n  padding: ", ";\n  color: ", ";\n  font-size: ", ";\n  line-height: ", ";\n"])), textSpacingSelector, getColorGreySelector(500), getPxToRemSelector(14), getPxToRemSelector(16));
export var SubtitleSkeleton = styled(SkeletonTypography)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin: ", ";\n  margin-top: 5px;\n"])), textSpacingSelector);
export var LogoutButton = styled(Button)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  padding: ", ";\n  margin-top: 5px;\n  color: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  min-width: ", "px;\n  min-height: ", "px;\n"])), buttonSpacingSelector, getColorTextSelector('secondary'), getPxToRemSelector(14), getPxToRemSelector(16), getSpacingSelector(6), getSpacingSelector(2));