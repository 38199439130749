var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getSpacingSelector } from '@signal/theme/selectors';
import FormLabel from '../FormLabel';
export var LabelWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  grid-area: label;\n"])));
export var ErrorLabel = styled(FormLabel)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  grid-area: error;\n  padding-top: ", "px;\n"])), getSpacingSelector(1));
export var LeftAdornmentWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-right: ", "px;\n  grid-area: leftAdornment;\n"])), getSpacingSelector(1));
export var ControlWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-area: control;\n"])));
export var styles = function styles(theme) {
  return {
    /* Styles applied to the root element. */
    root: {
      display: 'grid',
      gridTemplateAreas: "'leftAdornment control label'  'leftAdornmentPadding error error'",
      gridTemplateColumns: 'min-content',
      alignItems: 'center',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      // For correct alignment with the text.
      verticalAlign: 'middle',
      // Remove grey highlight
      WebkitTapHighlightColor: 'transparent',
      '&$disabled': {
        cursor: 'default'
      }
    },

    /* Styles applied to the root element if `labelPlacement="start"`. */
    labelPlacementStart: {
      gridTemplateAreas: "'leftAdornment label control' 'leftAdornmentPadding error error'",
      gridTemplateColumns: 'min-content max-content',
      marginLeft: 0
    },

    /* Styles applied to the root element if `labelPlacement="top"`. */
    labelPlacementTop: {
      gridTemplateAreas: "'leftAdornment label' 'leftAdornmentPadding control' 'leftAdornmentPadding error'",
      marginLeft: 0
    },

    /* Styles applied to the root element if `labelPlacement="bottom"`. */
    labelPlacementBottom: {
      gridTemplateAreas: "'leftAdornmentPadding error' 'leftAdornmentPadding control' 'leftAdornment label'",
      marginLeft: 0
    },

    /* Styles applied to the root element if `disabled={true}`. */
    disabled: {}
  };
};