import contextLoadable from '@signal/core/contextFetch/Loadable';
import { UserAccountsContext, UserDetailsContext } from '@signal/core/user/Context';
import Component from './Component';
import Loader from './Loader';
import Skeleton from './Skeleton';
export default contextLoadable({
  fetchContexts: [UserAccountsContext, UserDetailsContext],
  Component: Component,
  Loader: Loader,
  Skeleton: Skeleton
});