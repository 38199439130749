import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withScrollFix } from '@signal/components/ScrollToTop'
import componentPropType from '@signal/prop-types/component'
import renderIf from '@signal/utils/renderIf'
import Header from '../../containers/Header'
import { Content } from './styles'
import SignalPage from '../../views/SignalPage'

const ContentWithScrollFix = withScrollFix(Content)

export const LayoutDefault = memo(props => {
  const {
    wrapperComponent: WrapperComponent,
    navbar: Navbar,
    navbarInWrapper,
    contentProps,
    children,
    match
  } = props

  return (
    <WrapperComponent match={match} header={Navbar}>
      {renderIf(navbarInWrapper, () => (
        <Navbar match={match} />
      ))}
      <ContentWithScrollFix {...contentProps}>{children}</ContentWithScrollFix>
    </WrapperComponent>
  )
})

LayoutDefault.propTypes = {
  /**
   * The component used for the wrapper node.
   * Either a string to use a DOM element or a component.
   */
  wrapperComponent: componentPropType,
  navbar: componentPropType,
  navbarInWrapper: PropTypes.bool,
  /**
   * These props will be forwarded to the page content component.
   */
  contentProps: PropTypes.object
}

LayoutDefault.defaultProps = {
  wrapperComponent: SignalPage,
  background: 'default',
  navbar: Header,
  navbarInWrapper: false
}

export default LayoutDefault
