import React, { memo } from 'react'
import PropTypes from 'prop-types'
import range from 'lodash/range'
import {
  TitleSkeleton,
  Group,
  LabelSkeleton,
  ContentSkeleton,
  Definition
} from './styles'

const LiveSummary = ({ numGroups, numLabels, ...rest }) => {
  return (
    <div {...rest}>
      <TitleSkeleton />
      {range(numGroups).map(i => (
        <Group key={i}>
          {range(numLabels[i]).map(i => (
            <Definition key={i}>
              <LabelSkeleton />
              <ContentSkeleton />
            </Definition>
          ))}
        </Group>
      ))}
    </div>
  )
}

LiveSummary.propTypes = {
  /** the number of groups to display */
  numGroups: PropTypes.number,
  /** the number of sub groups to display for each group
   * array index corresponds numGroups iteration index
   */
  numLabels: PropTypes.arrayOf(PropTypes.number)
}

LiveSummary.defaultProps = {
  numGroups: 2,
  numLabels: [2, 3]
}

export default memo(LiveSummary)
