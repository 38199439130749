import loadable from '@signal/hoc/loadable'
import Skeleton from '../ChannelsToolbar/Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "channels-overview-toolbar" */ './component'),
  dataLoader: () =>
    import(
      /* webpackChunkName: "lib-performance" */ '@signal/core/performance'
    ),
  loadData: ({ performanceDataLoader }, loaderInterface) =>
    performanceDataLoader(loaderInterface),
  loading: Skeleton
})
