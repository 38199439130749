function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { useCallback, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import ModalManagerContext from './Context';
/**
 *
 * @param {Component|Function|Object} Modal
 * @param {Object} options
 *
 * @param {Number} options.priority - The priority of the modal to show over other modals.
 * @param {Function} options.onDismiss
 * @param {Boolean} options.force
 * @param {String} options.name - (Optional) if provided this name will be used to determine
 * if a previous modal request has already been made to prevent duplicate requests.
 */

export function useModal(Modal, options) {
  var _useContext = useContext(ModalManagerContext),
      showModal = _useContext.showModal;

  return useCallback(function () {
    if (Modal && !isEmpty(options)) {
      showModal(_objectSpread({
        modal: Modal
      }, options));
    }
  }, [Modal, options, showModal]);
}