import { createSelector } from 'reselect'
import add from 'lodash/add'
import { getConstantSelector } from '@signal/theme/selectors'

export const maxPageWidthSelector = getConstantSelector('MAX_PAGE_WIDTH')

export const breadcrumbHeightSelector = getConstantSelector('BREADCRUMB_HEIGHT')
export const breadcrumbBorderWidthSelector = getConstantSelector(
  'BREADCRUMB_BORDER_WIDTH'
)
export const breadcrumbOuterHeightSelector = createSelector(
  breadcrumbHeightSelector,
  breadcrumbBorderWidthSelector,
  add
)
