import React, { memo } from 'react'
import { PAGE_AUDIENCES_LIST_CREATE, APP_AUDIENCES } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import { useBodyBackgroundColor } from '@signal/theme'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import Header from '../../containers/AudiencesHeader'
import AudienceCreateSummary from '../../containers/AudienceCreateSummary'
import AudienceDetailForm from '../../containers/AudienceDetailForm'
import AudienceDetailHeader from '../../containers/AudienceDetailHeader'
import { Wrapper } from './styles'

export const AudienceCreatePage = memo(() => {
  usePageTrack(PAGE_AUDIENCES_LIST_CREATE, APP_AUDIENCES)
  useBodyBackgroundColor('light')
  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      headerComponent={AudienceDetailHeader}
    >
      <Wrapper>
        <AudienceCreateSummary />
        <AudienceDetailForm />
      </Wrapper>
    </LayoutDefaultWithPageHeader>
  )
})

AudienceCreatePage.displayName = 'AudienceCreatePage'

export default AudienceCreatePage
