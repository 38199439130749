import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { usePrevious } from 'react-hanger'
import { dismissOnboardingTour, dismissActivateTour } from '@signal/core/local'
import { useModal } from '@signal/components/ModalManager'
import Tour from '../../components/Tour'
import TourPage from '../../components/TourPage'
import {
  isVisibleSelector,
  tourPagesSelector,
  isActivateTourVisibleSelector,
  activateTourPagesSelector
} from './selectors'

const OnboardingTour = memo(props => {
  const { dismiss, isVisible, pages } = props

  const TourModal = useMemo(
    () => ({ dismissModal }) => (
      <Tour showTour onDismiss={dismissModal}>
        {pages.map(page => (
          <TourPage key={page.title} {...page} />
        ))}
      </Tour>
    ),
    [pages]
  )

  const showModal = useModal(TourModal, {
    priority: 1,
    onDismiss: dismiss,
    force: true,
    name: 'onBoardingTour'
  })

  if (!usePrevious(isVisible) && isVisible) {
    showModal()
  }

  return null
})

OnboardingTour.propTypes = {
  dismiss: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      img: PropTypes.shape({
        srcSet: PropTypes.arrayOf(
          PropTypes.shape({
            dpr: PropTypes.number.isRequired,
            src: PropTypes.string.isRequired
          })
        ),
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired
      }).isRequired,
      theme: PropTypes.shape({
        background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        text: PropTypes.shape({
          color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
          background: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        }),
        image: PropTypes.shape({
          align: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
          offset: PropTypes.shape({
            top: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
            right: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
            bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
            left: PropTypes.oneOfType([PropTypes.number, PropTypes.func])
          })
        })
      })
    })
  ).isRequired
}

const mapStateToProps = createStructuredSelector({
  isVisible: isVisibleSelector,
  pages: tourPagesSelector
})

const mapDispatchToProps = {
  dismiss: dismissOnboardingTour
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTour)

/**
 * ACTIVATE TOUR
 */
const mapActivateTourStateToProps = createStructuredSelector({
  isVisible: isActivateTourVisibleSelector,
  pages: activateTourPagesSelector
})

const mapActivateTourDispatchToProps = {
  dismiss: dismissActivateTour
}
export const ActivateTour = connect(
  mapActivateTourStateToProps,
  mapActivateTourDispatchToProps
)(OnboardingTour)
