import { createSelector } from 'reselect';
import isThisMonth from 'date-fns/isThisMonth';
import parseDate from '@signal/utils/parseDate';
import select from '@signal/utils/select';
import { REDUCER_KEY } from './constants';
export var rootSelector = select(REDUCER_KEY);
export var isLoadingSelector = createSelector(rootSelector, select('loading', false));
export var isLoadedSelector = createSelector(rootSelector, select('loaded', false));
export var channelsSelector = createSelector(rootSelector, select('channels', []));
export var channelSelector = createSelector(channelsSelector, function (state, _ref) {
  var key = _ref.key;
  return key;
}, function (channels, key) {
  return channels.find(function (channel) {
    return channel.key === key;
  });
});
export var dateContextSelector = createSelector(rootSelector, select('dateContext'));
export var dateSelector = createSelector(dateContextSelector, function (dateContext) {
  return parseDate(dateContext);
});
export var canEditRepeatMonthlySelector = createSelector(dateSelector, isThisMonth);
export var channelsUpdatingSelector = createSelector(rootSelector, select('channelsUpdating'));
export var isSavingSelector = createSelector(channelsUpdatingSelector, function (channelsUpdating) {
  return Object.keys(channelsUpdating).length > 0;
});