import { createSelector } from 'reselect'
import { dateContextSelector } from '@signal/core/spend'
import parseDate from '@signal/utils/parseDate'
import { DESCRIPTION } from './constants'

export const descriptionSelector = () => DESCRIPTION
export const activeDateSelector = createSelector(
  dateContextSelector,
  dateContext => parseDate(dateContext)
)
