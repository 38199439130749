export const LATEST_APP_DOMAIN = 'apps.purecars.com'
export const LATEST_APP_DOMAIN_QA = 'apps-qa.purecars.com'

/**
 * This function automatically redirects to the latest app domain if it finds the current route is on an old
 * domain (such as signal or meridian).
 * @returns {Boolean} true if the current URL is being redirected
 */
export function redirectToLatestDomain(): boolean {
  const host = window.location.host
  if (
    !host.includes('localhost') &&
    !host.includes('dev.local') &&
    host !== LATEST_APP_DOMAIN &&
    host !== LATEST_APP_DOMAIN_QA
  ) {
    const isQA = host.includes('-alpha') || host.includes('-qa')
    const latestDomain = isQA ? LATEST_APP_DOMAIN_QA : LATEST_APP_DOMAIN
    const redirectUrl = `${window.location.protocol}//${latestDomain}${window.location.pathname}${window.location.search}`
    console.log('redirecting to latest domain', redirectUrl)
    window.location.href = redirectUrl
    return true
  }
  return false
}

export default redirectToLatestDomain
