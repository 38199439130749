var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import { getColorBackgroundSelector, paletteDividerSelector } from '@signal/theme/selectors';
import EnhancedTableHead from './EnhancedTableHead';
export var TableWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  overflow-x: auto;\n\n  .MuiTableCell-root {\n    border-bottom-color: ", ";\n  }\n"])), paletteDividerSelector);
export var StyledTable = styled(Table)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var TableHead = styled(EnhancedTableHead)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  > tr > th {\n    z-index: 2;\n    background-color: ", ";\n  }\n"])), getColorBackgroundSelector('paper'));
export var StyledRow = styled(TableRow)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  > td {\n    background-color: ", ";\n    white-space: nowrap;\n  }\n"])), function (props) {
  return props.highlight === 'true' ? getColorBackgroundSelector('light') : getColorBackgroundSelector('paper');
});
export var TableCell = styled(MuiTableCell)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  > * {\n    vertical-align: middle;\n  }\n"])), function (props) {
  return props.size === 'small' && 'padding: 6px 4px 6px 16px';
});