import styled, { css } from 'styled-components'
import Typography from '@signal/components/Typography'
import {
  getSpacingSelector,
  getColorGreySelector
} from '@signal/theme/selectors'

const wrapperMixin = css`
  display: flex;
  padding: ${getSpacingSelector(3)}px;
  border-radius: 3px;
  border: 1px solid ${getColorGreySelector('light')};
`

export const Wrapper = styled.div`
  ${wrapperMixin}

  flex-direction: column-reverse;

  > *:first-child {
    width: 100%;
  }
`

export const SkeletonWrapper = styled.div`
  ${wrapperMixin}

  > *:first-child {
    flex: 1;
  }
`
export const SkeletonDescription = styled(Typography).attrs(() => ({
  color: 'textSecondary'
}))`
  height: 150px;
  width: 100%;
  padding: ${getSpacingSelector(2)}px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
