import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "overview-shopper-activity" */ './component'),
  dataLoader: () =>
    import(/* webpackChunkName: "lib-overview" */ '@signal/core/overview'),
  loading: Skeleton
})
