var _createReducer, _createReducer2;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import { createRequestReducerV2 } from '@signal/utils/createRequestReducer';
import { SHOW_AUDIENCE_CREATED, NO_AUDIENCE_CREATED } from './constants';
import { audiencesActions, audienceSegmentsActions, invokeAudienceCreatedModal, dismissAudienceCreatedModal, audienceSaveActions, audienceDetailsActions, audienceResetForm } from './actions';
/**
 * Audience create reducer and state
 */

var formInitialState = {
  segments: {
    isLoading: false,
    data: null,
    error: null
  },
  audience: {
    isLoading: false,
    data: null,
    error: null
  }
};
var formsInitialState = {};
var forms = createReducer((_createReducer = {}, _defineProperty(_createReducer, audienceSegmentsActions.load, function (state, _ref) {
  var _ref$id = _ref.id,
      id = _ref$id === void 0 ? 'create' : _ref$id;
  return state[id] ? state : _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, formInitialState));
}), _defineProperty(_createReducer, audienceSegmentsActions.request, function (state, _ref2) {
  var _ref2$id = _ref2.id,
      id = _ref2$id === void 0 ? 'create' : _ref2$id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, _objectSpread(_objectSpread({}, state[id]), {}, {
    segments: _objectSpread(_objectSpread({}, state[id].segments), {}, {
      isLoading: true,
      error: null
    })
  })));
}), _defineProperty(_createReducer, audienceSegmentsActions.success, function (state, _ref3) {
  var data = _ref3.data,
      _ref3$id = _ref3.id,
      id = _ref3$id === void 0 ? 'create' : _ref3$id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, _objectSpread(_objectSpread({}, state[id]), {}, {
    segments: {
      isLoading: false,
      error: null,
      data: data
    }
  })));
}), _defineProperty(_createReducer, audienceSegmentsActions.failure, function (state, _ref4) {
  var error = _ref4.error,
      _ref4$id = _ref4.id,
      id = _ref4$id === void 0 ? 'create' : _ref4$id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, _objectSpread(_objectSpread({}, state[id]), {}, {
    segments: {
      isLoading: false,
      data: null,
      error: error
    }
  })));
}), _defineProperty(_createReducer, audienceDetailsActions.request, function (state, id) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, _objectSpread(_objectSpread({}, state[id]), {}, {
    audience: {
      data: null,
      error: null,
      isLoading: true
    }
  })));
}), _defineProperty(_createReducer, audienceDetailsActions.success, function (state, payload) {
  var id = payload.id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, _objectSpread(_objectSpread({}, state[id]), {}, {
    audience: {
      data: payload,
      error: null,
      isLoading: false
    }
  })));
}), _defineProperty(_createReducer, audienceDetailsActions.failure, function (state, _ref5) {
  var id = _ref5.id; // TODO

  return state;
}), _defineProperty(_createReducer, audienceResetForm, function (state, _ref6) {
  var _ref6$id = _ref6.id,
      id = _ref6$id === void 0 ? 'create' : _ref6$id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, formInitialState));
}), _createReducer), formsInitialState);
export var list = createRequestReducerV2(audiencesActions);
export var createModalInitialState = _defineProperty({}, SHOW_AUDIENCE_CREATED, NO_AUDIENCE_CREATED);
export var createModal = createReducer((_createReducer2 = {}, _defineProperty(_createReducer2, audienceSaveActions.success, function (state, _ref7) {
  var id = _ref7.id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_AUDIENCE_CREATED, id));
}), _defineProperty(_createReducer2, invokeAudienceCreatedModal, function (state, audienceId) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_AUDIENCE_CREATED, audienceId));
}), _defineProperty(_createReducer2, dismissAudienceCreatedModal, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_AUDIENCE_CREATED, NO_AUDIENCE_CREATED));
}), _createReducer2), createModalInitialState);
export default combineReducers({
  list: list,
  forms: forms,
  createModal: createModal
});