import { createSelector } from 'reselect'
import get from 'lodash/get'
import select from '@signal/utils/select'
import {
  adStatsSelector,
  adListDataSelector,
  universalOffersListDataSelector
} from '@signal/core/ad'

export const modalAdIdPathSelector = (state, props) => get(props, 'adId')

export const modalAdSelector = createSelector(
  modalAdIdPathSelector,
  (state, props) =>
    props.isUniversal
      ? universalOffersListDataSelector(state)
      : adListDataSelector(state),
  (activeId, ads) => {
    return ads && ads.length > 0 ? ads.find(({ id }) => id === activeId) : null
  }
)

export const titleSelector = createSelector(
  modalAdSelector,
  (state, props) => props.isUniversal,
  (ad, isUniversal) => (isUniversal ? get(ad, 'name') : get(ad, 'outputName'))
)

export const reportStartSelector = createSelector(
  modalAdSelector,
  select('startDate')
)

export const reportEndSelector = createSelector(
  modalAdSelector,
  select('endDate')
)

const statsList = [
  'uniqueImpressions',
  'uniqueExpands',
  'expandRate',
  'uniqueLeads',
  'conversionRate'
]

const statLabelsByKey = {
  uniqueImpressions: 'Reach',
  uniqueExpands: 'Engagements',
  expandRate: 'Engagement Rate',
  uniqueLeads: 'Leads',
  conversionRate: 'Lead Rate'
}

const percentageKeys = ['conversionRate', 'expandRate']

export const statsSelector = createSelector(adStatsSelector, stats =>
  statsList.map(statKey => ({
    label: statLabelsByKey[statKey],
    metric: stats[statKey],
    format: percentageKeys.indexOf(statKey) < 0 ? 'Number' : 'Percent'
  }))
)
