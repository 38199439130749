import React from 'react'
import times from 'lodash/times'
import ProgressiveImage from '@signal/components/ProgressiveImage'

import {
  Wrapper,
  TitleSkeleton,
  SubtitleSkeleton,
  Image,
  EngagementScoreDonutSkeleton,
  MetricsWrapper,
  MetricItemSkeleton
} from './styles'

export const InventoryPerformanceDetailsSummarySkeleton = ({ data }) => {
  return (
    <Wrapper
      title={<TitleSkeleton />}
      leftContent={
        <ProgressiveImage>
          {(src, loading) => (
            <Image
              imgStyle={{ objectFit: loading ? 'cover' : 'contain' }}
              src={src}
            />
          )}
        </ProgressiveImage>
      }
      rightContent={<EngagementScoreDonutSkeleton />}
    >
      <SubtitleSkeleton />
      <MetricsWrapper>
        {times(3, i => (
          <MetricItemSkeleton key={i} />
        ))}
      </MetricsWrapper>
    </Wrapper>
  )
}

export default InventoryPerformanceDetailsSummarySkeleton
