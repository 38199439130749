import React, { memo } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import get from 'lodash/get'
import FormControl from '@signal/components/FormControl'
import Link from '@signal/components/Link'
import ValidationIcon from '../FormControlValidationIcon'
import {
  Wrapper,
  AudienceFormControlWrapper,
  CreateAudienceButton,
  AudienceIcon
} from './styles'

const audiencePropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string
})

/**
 * The standard render function for form controls in this component
 * @param {object} control - the form control to render
 */
const renderFormControl = ({ attrs: incomingAttrs, ...rest }) => {
  const isValid = incomingAttrs.touched && !incomingAttrs.error
  const attrs = {
    ...omit(incomingAttrs, 'touched'),
    leftAdornment: <ValidationIcon valid={isValid} />
  }

  return <FormControl key={rest.name} attrs={attrs} {...rest} />
}

/**
 * This is a custom render function to add the Create Audience button
 * beside the audience template select field.
 * @param {object} control - the form control to render
 */
const audienceControlRenderFunction = (control, { createAudiencePath }) => {
  return (
    <AudienceFormControlWrapper key={control.name}>
      {renderFormControl(control)}
      <CreateAudienceButton component={Link} to={createAudiencePath}>
        <AudienceIcon />
        Create Audience
      </CreateAudienceButton>
    </AudienceFormControlWrapper>
  )
}

/**
 * A map of form control names to their custom render functions.
 */
const customControlRenderFunctions = {
  audience: audienceControlRenderFunction
}
/**
 * A function that gets the default renderer or custom renderer for each control
 * @param {object} control
 */
const formControlRenderMapper = (control, props) => {
  return get(
    customControlRenderFunctions,
    control.name,
    renderFormControl
  )(control, props)
}

const AdForm = ({ formControls, className, ...rest }) => (
  <Wrapper className={className}>
    {formControls.map(control => formControlRenderMapper(control, rest))}
  </Wrapper>
)

AdForm.propTypes = {
  values: PropTypes.shape({
    adName: PropTypes.string,
    audience: audiencePropType
  }),
  touched: PropTypes.shape({
    adName: PropTypes.bool,
    audience: PropTypes.bool
  }),
  errors: PropTypes.shape({
    adName: PropTypes.string,
    audience: PropTypes.string
  }),
  audiences: PropTypes.arrayOf(audiencePropType),
  createAudiencePath: PropTypes.string.isRequired
}

AdForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
  audiences: []
}

export default memo(AdForm)
