function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

import times from 'lodash/times';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';
export function getColorScale(color) {
  var countLight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;
  var countDark = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
  var coefficient = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0.15;
  return [].concat(_toConsumableArray(times(countLight, function (i) {
    return lighten(color, (countLight - i) * coefficient);
  })), [color], _toConsumableArray(times(countDark, function (i) {
    return darken(color, (i + 1) * coefficient);
  })));
}
export default getColorScale;