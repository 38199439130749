import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import times from 'lodash/times'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Checkbox from '@material-ui/core/Checkbox'
import SpendInputSkeleton from '../SpendInput/Skeleton'
import ChannelAvatarSkeleton from './ChannelAvatarSkeleton'
import {
  TableHeaderCell,
  TableHeaderCellFixed,
  TableHeaderCellText,
  TableRow,
  TableCell,
  TableCellFixed
} from './styles'

export class ManageSpendTableSkeleton extends React.PureComponent {
  static propTypes = {
    rowCount: PropTypes.number
  }

  static defaultProps = {
    rowCount: 5
  }

  renderTableRows = () => {
    const { rowCount } = this.props
    const widths = [110, 130]
    return times(rowCount, i => (
      <TableRow key={i}>
        <TableCell>
          <ChannelAvatarSkeleton TitleProps={{ width: get(widths, i % 2) }} />
        </TableCell>
        <TableCellFixed align="right">
          <SpendInputSkeleton value="$0" disabled />
        </TableCellFixed>
        <TableCellFixed align="right">
          <Checkbox color="primary" disabled />
        </TableCellFixed>
      </TableRow>
    ))
  }

  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>
              <TableHeaderCellText>Paid Channels</TableHeaderCellText>
            </TableHeaderCell>
            <TableHeaderCellFixed align="right">
              <TableHeaderCellText>Spend Amount</TableHeaderCellText>
            </TableHeaderCellFixed>
            <TableHeaderCellFixed align="right">
              <TableHeaderCellText>Repeat Monthly</TableHeaderCellText>
            </TableHeaderCellFixed>
          </TableRow>
        </TableHead>
        <TableBody>{this.renderTableRows()}</TableBody>
      </Table>
    )
  }
}

export default ManageSpendTableSkeleton
