import styled from 'styled-components'
import ContributionChart from '@signal/components/ContributionChart'
import ContributionChartSkeleton from '@signal/components/ContributionChart/Skeleton'

export const TableContributionChart = styled(ContributionChart)`
  width: 100%;
`

export const TableContributionChartSkeleton = styled(ContributionChartSkeleton)`
  width: 150px;
`
