function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';
import createDeepEqualSelector from '@signal/utils/createDeepEqualSelector';
import select from '@signal/utils/select';
export var rootSelector = function rootSelector(state) {
  return get(state, 'user');
};
export var featureFlagsSelector = createSelector(rootSelector, select('featureFlags'));
export var getFeatureFlagSelector = function getFeatureFlagSelector(featureKey) {
  return createSelector(featureFlagsSelector, select(featureKey, false));
};
/**
 * User Details Selectors
 */

export var detailsRootSelector = createDeepEqualSelector(rootSelector, function (user) {
  return get(user, 'me');
});
export var detailsLoadedSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'id') !== null;
});
export var detailsLoadingSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'loading');
});
export var userIdSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'id');
});
export var firstNameSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'firstName');
});
export var lastNameSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'lastName');
});
export var nameSelector = createSelector(firstNameSelector, lastNameSelector, function (firstName, lastName) {
  return [firstName, lastName].filter(Boolean).join(' ');
});
export var initialsSelector = createSelector(firstNameSelector, lastNameSelector, function (firstName, lastName) {
  return [firstName, lastName].filter(Boolean).map(function (name) {
    return name[0];
  }).join('');
});
export var emailSelector = createSelector(detailsRootSelector, function (details) {
  return get(details, 'email');
});
/**
 * User Accounts Selectors
 */

export var accountsRootSelector = createDeepEqualSelector(rootSelector, function (user) {
  return get(user, 'accounts');
});
export var accountsListSelector = createSelector(accountsRootSelector, function (accounts) {
  return get(accounts, 'list');
});
export var accountsByIdSelector = createSelector(accountsRootSelector, function (accounts) {
  return get(accounts, 'byId');
});
export var accountsLoadedSelector = createSelector(accountsListSelector, function (accountsList) {
  return accountsList !== null;
});
export var accountsLoadingSelector = createSelector(accountsRootSelector, function (accounts) {
  return get(accounts, 'loading');
});
export var accountsSelector = createSelector(accountsListSelector, accountsByIdSelector, function (accountsList, accountsById) {
  if (!accountsList) return null;
  var accounts = accountsList.map(function (id) {
    return get(accountsById, id);
  });
  return sortBy(accounts, 'name');
});
export var accountDetailsSelector = createSelector( // eslint-disable-next-line no-unused-vars
function (state, _ref) {
  var accountId = _ref.accountId;
  return accountId;
}, accountsByIdSelector, function (id, accounts) {
  return get(accounts, id);
});
/**
 * Users Selectors
 */

export var usersRootSelector = createDeepEqualSelector(rootSelector, function (user) {
  return get(user, 'users');
});
export var usersLoadedSelector = createSelector(usersRootSelector, function (users) {
  return get(users, 'list') !== null;
});
export var usersLoadingSelector = createSelector(usersRootSelector, function (users) {
  return get(users, 'loading');
});
export var usersListSelector = createSelector(usersRootSelector, function (users) {
  return get(users, 'list');
});
export var usersByIdSelector = createSelector(usersRootSelector, function (users) {
  return get(users, 'byId');
});
export var usersSelector = createSelector(usersListSelector, usersByIdSelector, function (usersList, usersById) {
  if (!usersList) return null;
  var users = usersList.map(function (id) {
    return get(usersById, id);
  });
  return sortBy(users, 'name');
}); // functions to create simulated redux state for data obtained by context fetcher for use with selectors

export function cookUserAccountsForSelectors(_ref2) {
  var response = _ref2.response;

  if (!response) {
    return {
      user: {
        accounts: {
          loading: true
        }
      }
    };
  }

  return {
    user: {
      accounts: {
        byId: response.reduce(function (byId, _ref3) {
          var accountId = _ref3.accountId,
              accountName = _ref3.accountName,
              rest = _objectWithoutProperties(_ref3, ["accountId", "accountName"]);

          byId[accountId] = _objectSpread({
            accountId: accountId,
            id: accountId,
            name: accountName
          }, rest);
          return byId;
        }, {}),
        list: response.map(function (_ref4) {
          var accountId = _ref4.accountId;
          return accountId;
        }),
        loading: false
      }
    }
  };
}
export function cookUserDetailsForSelectors(_ref5) {
  var response = _ref5.response;

  if (!response) {
    return {
      user: {
        loading: true
      }
    };
  }

  return {
    user: {
      loading: false,
      me: response
    }
  };
}