import differenceInMinutes from 'date-fns/differenceInMinutes';
import parseDate from './parseDate';
/**
 * This selector creator returns a selector and creates a cache for the
 * current time. The ultimate goal of the returned selector is
 * to update the now time only if one minute has passed.
 * @returns {Function} the now date selector
 */

export default function createNowDateSelector() {
  var previousNow = parseDate();
  return function () {
    var now = parseDate();

    if (differenceInMinutes(now, previousNow) < 1) {
      return previousNow;
    }

    previousNow = now;
    return now;
  };
}