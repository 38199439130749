var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getColorPrimarySelector, getColorBackgroundSelector, getSpacingSelector } from '@signal/theme';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n  border-radius: ", "px;\n  padding: ", "px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])), getColorBackgroundSelector('paper'), getSpacingSelector(), getSpacingSelector(2));
export var WidgetIcons = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-rows: auto auto auto;\n  grid-template-columns: auto auto auto;\n  grid-column-gap: 4px;\n  grid-row-gap: 4px;\n"])));
var WIDGET_SIZE = 16;
export var WidgetIcon = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: ", "px;\n  width: ", "px;\n  border-radius: 2px;\n  background: ", ";\n"])), WIDGET_SIZE, WIDGET_SIZE, getColorPrimarySelector());