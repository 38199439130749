import React from 'react'
import { LegendSkeleton, BadgeComponent, LabelSkeleton } from './styles'

export default function ShoppersChartLegendSkeleton() {
  return (
    <LegendSkeleton
      badgeComponent={BadgeComponent}
      labelComponent={LabelSkeleton}
      numOfSeries={3}
    />
  )
}
