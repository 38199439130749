import styled, { css } from 'styled-components'
import Paper from '@material-ui/core/Paper'
import {
  getSpacingSelector,
  paletteDividerSelector
} from '@signal/theme/selectors'
import TableListView from '@signal/components/TableListView'

const spacingSelector = getSpacingSelector(2)
const outerHorizontalSpacingSelector = getSpacingSelector(2.5)

export const Table = styled(TableListView).attrs(props => ({
  rowSpacing: spacingSelector(props)
}))``

export const RowBase = styled(Paper)``

const rowBorder = css`solid 1px ${paletteDividerSelector}`

export const CellBase = styled(Paper)`
  && {
    padding: ${spacingSelector}px ${getSpacingSelector()}px;
    border-top: ${rowBorder};
    border-bottom: ${rowBorder};
    border-radius: 3px;
    /* remove border-radius for middle cells */
    &:not(:first-of-type):not(:last-of-type) {
      border-radius: 0;
    }
    /* style first and last cells properly */
    &:first-of-type {
      padding-left: ${outerHorizontalSpacingSelector}px;
      border-left: ${rowBorder};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-of-type {
      padding-right: ${outerHorizontalSpacingSelector}px;
      border-right: ${rowBorder};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`

export const HeaderCellBase = styled(Paper).attrs(() => ({
  elevation: 0,
  square: true
}))`
  && {
    background: transparent;
    padding: 0 ${getSpacingSelector()}px;
    /* style first and last cells properly */
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: ${outerHorizontalSpacingSelector}px;
    }
  }
`
