import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "audience-detail-form" */ './Component'),
  dataLoader: () =>
    import(/* webpackChunkName: "lib-audience" */ '@signal/core/audience'),
  loadData: ({ audienceDetailsLoader }, loaderInterface) =>
    audienceDetailsLoader(loaderInterface),
  loading: Skeleton
})
