var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
} // @ts-ignore


import styled from 'styled-components';
import { getColorGreySelector, getSpacingSelector } from '@signal/theme/selectors';
import { clearfix } from '@signal/theme/mixins';
import IconCalendar from '../Icons/CalendarNew';
export var PickerWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: ", "px;\n  width: 328px;\n"])), clearfix, getSpacingSelector(2));
export var ButtonCalendarIcon = styled(IconCalendar)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var DatePickerNewSkeleton = styled(IconCalendar)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  fill: ", ";\n"])), getColorGreySelector('lightest'));