function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import numeral from 'numeral';
import { addSeconds, formatDuration, intervalToDuration } from 'date-fns';
import upperFirst from 'lodash/upperFirst';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isValidNumber from './isValidNumber';
import parseDate from './parseDate';
/**
 * Update the numeral abbreviations to use capital letters
 */

var numeralLocaleOverrides = {
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  }
};
numeral.register('locale', 'en-US', _objectSpread(_objectSpread({}, numeral.localeData('en')), numeralLocaleOverrides));
numeral.locale('en-US');
export var STRING_FORMATS = {
  CURRENCY: 'currency',
  INTEGER: 'integer',
  PERCENT: 'percent'
};
export var formats = {
  Currency: new Intl.NumberFormat('en-US', {
    style: STRING_FORMATS.CURRENCY,
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  CurrencyDecimal: new Intl.NumberFormat('en-US', {
    style: STRING_FORMATS.CURRENCY,
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }),
  Number: new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  Integer: new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  Decimal: new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }),
  Percent: new Intl.NumberFormat('en-US', {
    style: STRING_FORMATS.PERCENT,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  Percentage: new Intl.NumberFormat('en-US', {
    style: STRING_FORMATS.PERCENT,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  PercentageDecimal: new Intl.NumberFormat('en-US', {
    style: STRING_FORMATS.PERCENT,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }),
  String: {
    format: function format(str) {
      return "".concat(str);
    }
  }
};
export function isCurrency(format) {
  return format && ['Currency', 'CurrencyDecimal'].includes(upperFirst(format));
}
export function isPercent(format) {
  return format && ['Percent', 'Percentage', 'PercentageDecimal'].includes(upperFirst(format));
}
export function isTimespan(format) {
  return format && ['Timespan', 'TimespanDecimal'].includes(upperFirst(format));
}
export function isWholePercent(format) {
  return format && ['Percent', 'Percentage'].includes(upperFirst(format));
}

function formatInvalidNumber(type) {
  var result = '—';

  if (isCurrency(type)) {
    result = '$ —';
  }

  if (isPercent(type)) {
    result = '— %';
  }

  if (isTimespan(type)) {
    result = '— s';
  }

  return result;
}
/**
 * This function formats a timespan value into a value like 20m 15s
 * @param {Number} value - the value (in seconds) of the timespan
 * @param {Boolean} allowDecimal - allow a demical to be added to the timespan seconds
 * @returns {String} - the value, formatted as a timespan
 */


export function formatTimespan(value) {
  var allowDecimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var dateNow = parseDate();
  var dateSpan = addSeconds(dateNow, value);
  var duration = intervalToDuration({
    start: dateNow,
    end: dateSpan
  });
  var remainder = allowDecimal ? value % 1 : 0;

  if (remainder > 0.00001) {
    // truncate to 2 decimal places and remove the leading zero
    remainder = (Math.round(remainder * 100) / 100).toString().substr(1);
  } else {
    remainder = '';
  }

  var formatted = formatDuration(duration) || '0s';
  var appendRemainder = Boolean(remainder && !formatted.includes('second'));
  formatted = formatted.replace(/ years?/, 'y').replace(/ months?/, 'mo').replace(/ weeks?/, 'w').replace(/ days?/, 'd').replace(/ hours?/, 'h').replace(/ minutes?/, 'm').replace(/ seconds?/, "".concat(remainder, "s"));

  if (appendRemainder) {
    formatted += " 0".concat(remainder, "s");
  }

  return formatted;
}
/**
 * This closure returns a function that accepts a string
 * and options. The modifier function is called on the
 * input value after the formatter has returned it's value.
 *
 * @param {String} type the formatter type (see Formats above)
 * @returns {Function} a function that accepts the value to format and
 * optionally, a FormattedStringModifier function
 */

export function formatString() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Number';
  var formatter;

  if (isObject(type)) {
    formatter = new Intl.NumberFormat('en-US', type);
  } else {
    type = upperFirst(type) || 'Number';
    formatter = formats[type] || formats.Number;
  }
  /**
   * [This function accepts a number to format and a modifier
   * and it returns a string thats been formatted and modified
   * @param {number} val - Number to format
   * @param {FormattedStringModifier} modifier - Post-format hook to modify the formatted string before it's returned
   * @returns {string} - Formatted string
   */


  return function (value, options) {
    if (isWholePercent(type)) {
      if (value > 0 && value < 0.01) return '< 1%';
      if (value === 0) return '0%';
      if (value < 0 && value > -0.01) return '< -1%';
    }

    if (!isValidNumber(value)) {
      return formatInvalidNumber(type);
    }

    if (isTimespan(type)) {
      var ts = formatTimespan(Math.abs(value), type === 'TimespanDecimal');
      return value < 0 ? "-".concat(ts) : ts;
    }

    var str = formatter.format(value);

    if (options) {
      var modifier = options.modifier;

      if (isFunction(modifier)) {
        str = modifier(value, str);
      }
    }

    return str;
  };
}
export default formatString;

function isLargeNumber(value) {
  var threshold = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
  return value >= threshold || value <= -threshold;
}
/**
 * This function accepts an object with a value and type and
 * returns a string representation of the input value formatted according
 * input type
 * @param {Number} value the value to format
 * @param {String} type the value format
 * @param {Object} options the formatter options.
 */


export function formatHighlight(value) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Number';
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    exludeSign: false,
    forceSign: false,
    excludePositiveSign: false,
    excludeNegativeSign: false
  };
  type = upperFirst(type) || 'Number';

  if (!isValidNumber(value)) {
    return formatInvalidNumber(type);
  }

  if (isTimespan(type)) {
    var ts = formatTimespan(Math.abs(value), type === 'TimespanDecimal');

    if (value > 0 && !options.excludeSign && !options.excludePositiveSign) {
      return "+".concat(ts);
    }

    if (value < 0.001 && value > -0.001) return options.forceSign ? "+".concat(ts) : ts;

    if (value < 0 && !options.excludeSign && !options.excludeSign) {
      return "-".concat(ts);
    }

    return ts;
  }

  if (isWholePercent(type)) {
    if (value > 0 && value < 0.01) return options.excludeSign || options.excludePositiveSign ? '<1%' : '+<1%';
    if (value === 0) return options.forceSign ? '+0%' : '0%';
    if (value < 0 && value > -0.01) return options.excludeSign || options.excludeNegativeSign ? '<1%' : '-<1%';
  }

  var isLarge = isLargeNumber(value);
  var isLargePercentage = isLargeNumber(value, 10);
  var num = numeral(value);
  var formats = {
    Number: isLarge ? '+0.[00]a' : '+0a',
    Integer: isLarge ? '+0.[00]a' : '+0a',
    Decimal: '+0.[00]a',
    Currency: isLarge ? '+$0.[00]a' : '+$0a',
    CurrencyDecimal: '+$0[.]00a',
    CurrencyNegative: isLarge ? '$0.[00]a' : '$0a',
    CurrencyDecimalNegative: '$0[.]00a',
    Percent: isLargePercentage ? '+0.[00]a%' : '+0a%',
    Percentage: isLargePercentage ? '+0.[00]a%' : '+0a%',
    PercentageDecimal: '+0.[00]a%'
  };
  var format = formats[type] || formats['Integer'];

  if (value < 0) {
    // numeral messes up the standard currency formatting for negative values
    switch (type) {
      case 'Currency':
        format = formats['CurrencyNegative'];
        break;

      case 'CurrencyDecimal':
        format = formats['CurrencyDecimalNegative'];
        break;

      default:
    }
  }

  var val = num.format(format);

  if (!options.forceSign && (value === 0 || options.excludeSign || options.excludeNegativeSign && value < 0 || options.excludePositiveSign && value > 0)) {
    val = val.substr(1);
  }

  return val;
}
/**
 * @typedef {Function} FormattedStringModifier
 * A post formatting hook to let you modifiy a string after i'ts been modified
 * @param {number} val - Raw input value provided to the format function
 * @param {string} str - Formatted string output from the format function
 * @returns {string} - Modified formatted string
 */