import { createMuiTheme } from '@material-ui/core/styles';
import { combineMiddleware } from './middleware';
import typographyMiddleware from './middleware/typography';
import muiOverridesMiddleware from './middleware/muiOverrides';
import defaultOverrides from './overrides';
/**
 * An object that is used as the theme for an app. The appearance of many
 * components in an app can be controlled by changing values in the theme.
 * @typedef {Object} Theme
 * @global
 */

/**
 * Creates a theme for an app that can be used with the {@link ThemeProvider}
 * component. A {@link ThemeOverrides} object can be optionally provided to
 * override the default theme values.
 * @param {ThemeOverrides} [customOverrides={}] - optional theme overrides
 * @return {Theme}
 */

export function createTheme() {
  var customOverrides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}; // Combing list of theme middleware functions to apply

  var applyMiddleware = combineMiddleware([typographyMiddleware, muiOverridesMiddleware]); // Apply theme middleware to the default and custom overrides to get final
  // overrides object to pass to `createMuiTheme()`

  var overrides = applyMiddleware(defaultOverrides, customOverrides);
  return createMuiTheme(overrides);
}
export default createTheme;