var _createReducer;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import { performanceVinDataRequest, performanceVinDataFailure, performanceVinDataSuccess } from './actions';
export var initialState = {};
export var initialSearchState = {
  data: null,
  loading: false,
  error: false
};
export default createReducer((_createReducer = {}, _defineProperty(_createReducer, performanceVinDataRequest, function (state, _ref) {
  var vin = _ref.vin;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, vin, _objectSpread(_objectSpread({}, initialSearchState), {}, {
    loading: true
  })));
}), _defineProperty(_createReducer, performanceVinDataFailure, function (state, _ref2) {
  var vin = _ref2.vin,
      error = _ref2.error;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, vin, _objectSpread(_objectSpread({}, state[vin]), {}, {
    loading: false,
    error: error
  })));
}), _defineProperty(_createReducer, performanceVinDataSuccess, function (state, _ref3) {
  var vin = _ref3.vin,
      data = _ref3.data;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, vin, _objectSpread(_objectSpread({}, state[vin]), {}, {
    loading: false,
    data: data
  })));
}), _createReducer), initialState);