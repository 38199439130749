function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createAction } from 'redux-act';
import partial from 'lodash/partial';
import { REQUEST_ACTION_TYPES } from './constants';
import { getActionType } from './utils';
export * from './constants';
export * from './utils';
/**
 * @global
 * @typedef {Object} RequestActions
 *
 * Action creators to facilitate the request lifecycle in redux.
 *
 * The [`load`]{@link LoadAction} action SHOULD be dispatched to trigger the API
 * request. Before the request is started, the [`request`]{@link RequestAction}
 * action SHOULD be dispatched.
 *
 * Once the request resolves, if the response is valid (meaning 200 and/or an
 * expected response for the action) the [`success`]{@link SuccessAction} action
 * should be dispatched, otherwise the [`failure`]{@link FailureAction} action
 * should be dispatched with an `Error` payload that describes the failure.
 *
 * @property {LoadActionCreator} load
 * @property {RequestActionCreator} request
 * @property {SuccessActionCreator} success
 * @property {FailureActionCreator} failure
 *
 * @see {@link createRequestActions}
 */

/**
 * @typedef {Function} LoadActionCreator
 * Action creator for the {@link LOAD} action type.
 * @returns {LoadAction}
 */

/**
 * @typedef {Function} RequestActionCreator
 * Action creator for the {@link REQUEST} action type.
 * @returns {RequestAction}
 */

/**
 * @typedef {Function} SuccessActionCreator
 * Action creator for the {@link SUCCESS} action type.
 * @param {*} payload
 * The `payload` param SHOULD represent the request's successful response body.
 * @returns {SuccessAction}
 */

/**
 * @typedef {Function} FailureActionCreator
 * Action creator for the {@link FAILURE} action type.
 * @param {Error} payload
 * The `payload` param MUST be an `Error` describing why the request failed.
 * @returns {FailureAction}
 */

/** @typedef {FluxStandardAction} LoadAction */

/** @typedef {FluxStandardAction} RequestAction */

/**
 * @typedef {FluxStandardAction} SuccessAction
 * @property {*} payload
 * The `payload` property MUST represent the request's successful response body.
 */

/**
 * @typedef {FluxStandardAction} FailureAction
 * @property {Error} payload
 * The `payload` property MUST be an `Error` describing why the request failed.
 * @property {Boolean} error
 * Error MUST be set to true for request failure actions.
 */

/**
 * Convenient utility method that creates a set of action creators that allow you
 * to create actions that correspond to the API request lifecycle in redux.
 *
 * @param {String} baseType
 * The base action type &mdash; each of the request action creator types will be
 * prefixed with this to ensure they are grouped/identified properly.
 * @param {Object.<RequestActionType, createRequestActions~ActionEnhancer>} [actionEnhancers={}]
 * An escape hatch for when you need to enhance the default action creator behavior
 * for one or more of the request types.
 *
 * Each `actionEnhancer` function provided will be called with a single argument,
 * the [createEnhancedAction]{@link createRequestActions~createEnhancedAction} method.
 * @param {createRequestActions~ActionEnhancer} [actionEnhancers.LOAD]
 * Action creator enhancer for the {@link LOAD} action type.
 * @param {createRequestActions~ActionEnhancer} [actionEnhancers.REQUEST]
 * Action creator enhancer for the {@link REQUEST} action type.
 * @param {createRequestActions~ActionEnhancer} [actionEnhancers.SUCCESS]
 * Action creator enhancer for the {@link SUCCESS} action type.
 * @param {createRequestActions~ActionEnhancer} [actionEnhancers.FAILURE]
 * Action creator enhancer for the {@link FAILURE} action type.
 *
 * @example
 * // Returns a {@link RequestActions} object with the default action creator
 * // behavior
 * createRequestActions('FETCH_INSIGHTS')
 * @example
 * // Returns a {@link RequestActions} object with an enhanced {@link SUCCESS}
 * // action creator
 * createRequestActions('FETCH_INSIGHTS', {
 *   [types.SUCCESS]: defineActionReducers =>
 *     defineActionReducers(
 *       // set `payloadReducer` to null for default behavior
 *       null,
 *       // provide custom `metaReducer` to specify a `schema` property on the
 *       // action's `meta` property (for normalizr middleware)
 *       () => ({ schema: insights })
 *     )
 * })
 *
 * @return {RequestActions} - Set of actions to facilitate the request lifecycle.
 *
 * @see [redux-act]{@link https://github.com/pauldijou/redux-act#createactiondescription-payloadreducer-metareducer}
 * for documentation on `payloadReducer` and `metaReducer`
 */

export function createRequestActions(baseType) {
  var actionEnhancers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return Object.values(REQUEST_ACTION_TYPES).reduce(function (requestActions, requestType) {
    var actionType = getActionType(baseType, requestType);
    /**
     * A partially applied `createAction` method (from [redux-act]{@link https://github.com/pauldijou/redux-act})
     * that already has the action's `type` defined. To use this partial method, you
     * just call it with 0 or more arguments.
     *
     * If called with 0 arguments, it will return an action creator with the
     * default `createAction` behavior. The first argument is the `payloadReducer`
     * and the second argument is the `metaReducer`.
     *
     * @type {Function}
     * @param {?Function} [payloadReducer]
     * Transform multiple (action creator) arguments into a unique payload.
     * @param {?Function} [metaReducer]
     * Transform multiple (action creator) arguments into a unique metadata object.
     * @returns {ActionCreator}
     */

    var createEnhancedAction = partial(createAction, actionType);
    /**
     * @typedef {Function} ActionEnhancer
     * Function that allows you to enhance the default action creator behavior
     * by providing a `payloadReducer` and/or `metaReducer`.
     *
     * @param {createEnhancedAction} enhanceAction
     * Method that creates an enhanced action creator
     *
     * @returns {ActionCreator}
     * An action creator MUST be returned from an action enhancer
     */

    /**
     * The action enhancer for the current request type.
     * @type {?ActionEnhancer}
     */

    var actionEnhancer = actionEnhancers[requestType]; // If an action enhancer is not provided for the current request type, simply
    // make the action creator use the default behavior

    var requestAction = actionEnhancer ? actionEnhancer(createEnhancedAction) : createEnhancedAction();
    return Object.assign(requestActions, _defineProperty({}, requestType.toLowerCase(), requestAction));
  }, {});
}
export default createRequestActions;