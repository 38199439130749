function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
export var appendParams = function appendParams(strInput, params) {
  var queryParams = !isEmpty(params) ? "?".concat(queryString.stringify(params)) : '';
  return "".concat(strInput).concat(queryParams);
};
/**
 * this utility function applies query params to a base url
 * @param { string } url - base url with or without query params
 * @param { object } params - additional query params
 * @returns {string} the base url with the resulting query params
 */

export var applyUrlParams = function applyUrlParams(url, params) {
  if (!url) return url;

  try {
    var _URL = new URL(url),
        hostname = _URL.hostname,
        protocol = _URL.protocol,
        pathname = _URL.pathname,
        search = _URL.search;

    var baseUrl = "".concat(protocol, "//").concat(hostname).concat(pathname);
    return appendParams(baseUrl, _objectSpread(_objectSpread({}, queryString.parse(search)), params));
  } catch (err) {
    return appendParams(url, params);
  }
};
export default applyUrlParams;