import styled, { css } from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import DotStepper from '@signal/components/DotStepper'
import Fab from '@signal/components/Button/Fab'
import Button from '@signal/components/Button'
import Typography from '@signal/components/Typography'
import IconCancel from '@signal/components/Icons/Cancel'
import IconBack from '@signal/components/Icons/BtnBack'
import IconForward from '@signal/components/Icons/BtnForward'
import IconFinish from '@signal/components/Icons/BtnFinish'
import {
  colorWhiteSelector,
  getColorBackgroundSelector,
  getColorPrimarySelector,
  getSpacingSelector
} from '@signal/theme/selectors'

const tourOuterWrapperMixin = css`
  position: absolute;
  top: calc(50% - 24px);
`

export const AdvanceTourOuterWrapper = styled.div`
  right: 0px;
  ${tourOuterWrapperMixin}
`

export const RecedeTourOuterWrapper = styled.div`
  left: 0px;
  ${tourOuterWrapperMixin}
`

export const TourInnerWrapper = styled.div`
  position: fixed;
`

const tourButtonMixin = css`
  background-color: ${getColorBackgroundSelector('paper')};
  position: absolute;
  min-width: 48px;
  width: 48px;
  height: 48px;
  padding-top: 1px;
`

export const CancelTourButton = styled(Fab)`
  position: fixed;
  background-color: transparent;
  box-shadow: none;
  min-width: 42px;
  width: 42px;
  height: 42px;
  top: ${getSpacingSelector(1)}px;
  right: ${getSpacingSelector(1.5)}px;
`

export const AdvanceTourButton = styled(Fab)`
  left: ${getSpacingSelector(6)}px;
  ${tourButtonMixin}
`

export const RecedeTourButton = styled(Fab)`
  right: ${getSpacingSelector(6)}px;
  ${tourButtonMixin}
`

export const AdvanceIconForward = styled(IconForward).attrs(() => ({
  size: 48
}))`
  color: ${getColorPrimarySelector()};
`

export const AdvanceIconFinish = styled(IconFinish).attrs(() => ({
  size: 48
}))`
  color: ${getColorPrimarySelector()};
`

export const RecedeIconBack = styled(IconBack).attrs(() => ({
  size: 48
}))`
  color: ${getColorPrimarySelector()};
`

export const CancelIcon = styled(IconCancel).attrs(() => ({
  size: 24
}))`
  color: ${colorWhiteSelector};
`

export const MutedTypography = styled(Typography).attrs(() => ({
  variant: 'body3',
  color: 'textSecondary'
}))``

const MutedButtonTypography = styled(MutedTypography).attrs(() => ({
  component: 'button'
}))``

export const MutedButton = styled(Button).attrs(props => ({
  size: 'small',
  component: MutedButtonTypography
}))`
  cursor: pointer;
  position: absolute;
`

export const RestartButton = styled(MutedButton)`
  left: ${getSpacingSelector(1)}px;
  top: ${getSpacingSelector(1)}px;
`

export const SkipButton = styled(MutedButton)`
  right: ${getSpacingSelector(1)}px;
  top: ${getSpacingSelector(1)}px;
`

export const TourDialog = withStyles({
  root: {
    paddingRight: 0
  },
  paper: {
    borderRadius: 0
  }
})(Dialog)

export const TourStepperOuterWrapper = styled.div`
  position: absolute;
  bottom: 0px;
`

export const TourStepperInnerWrapper = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  left: 0px;
  justify-content: center;
`

export const TourStepper = styled(DotStepper).attrs(() => ({
  startStep: 0
}))`
  position: absolute;
  top: ${getSpacingSelector(2.5)}px;
`
