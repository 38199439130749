import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import MonthPickerSkeleton from '@signal/components/MonthPicker/Skeleton'
import { descriptionSelector } from './selectors'
import { STATUS_DEFAULT } from './constants'
import { Header, TitleWrapper, TitleSkeleton, AutoSaveStatus } from './styles'

export function ManageSpendHeaderSkeleton({ description }) {
  return (
    <Header
      title={
        <TitleWrapper>
          <TitleSkeleton />
          <AutoSaveStatus>{STATUS_DEFAULT}</AutoSaveStatus>
          <MonthPickerSkeleton />
        </TitleWrapper>
      }
      description={description}
    />
  )
}

const mapStateToProps = createStructuredSelector({
  description: descriptionSelector
})

export default connect(mapStateToProps)(ManageSpendHeaderSkeleton)
