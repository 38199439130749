import styled, { css } from 'styled-components'
import { createSelector } from 'reselect'
import ArrowDown from '@signal/components/Icons/ArrowDown'
import ArrowUp from '@signal/components/Icons/ArrowUp'
import Typography from '@signal/components/Typography'
import SkeletonTypography from '@signal/components/Skeleton/Typography'
import { getColorGreySelector } from '@signal/theme'

const sortActivePropSelector = props => props.sortActive
const sortDisabledPropSelector = props => props.sortDisabled

const sortableStyles = css`
  cursor: pointer;
  fill: ${getColorGreySelector('light')};
  &:hover {
    fill: currentColor;
  }
`

const sortableStylesSelector = createSelector(
  sortDisabledPropSelector,
  sortDisabled => !sortDisabled && sortableStyles
)

const activeStyles = css`
  && {
    fill: currentColor;
  }
`

const activeStylesSelector = createSelector(
  sortActivePropSelector,
  sortActive => sortActive && activeStyles
)

export const Wrapper = styled.div`
  user-select: none;
  fill: transparent;
  ${sortableStylesSelector}
  ${activeStylesSelector}
`

export const BaseArrowStyles = styled.div.attrs(() => ({
  size: 11
}))`
  margin-left: 6px;
  fill: inherit;
`

export const PaddedArrowDown = BaseArrowStyles.withComponent(ArrowDown)
export const PaddedArrowUp = BaseArrowStyles.withComponent(ArrowUp)

export const HeaderTypography = styled(Typography).attrs(() => ({
  variant: 'body4',
  color: 'textSecondary',
  noWrap: true
}))`
  text-transform: capitalize;
`
export const HeaderTypographySkeleton = HeaderTypography.withComponent(
  SkeletonTypography
)
