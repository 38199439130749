import styled from 'styled-components'
import MuiTabs from '@material-ui/core/Tabs'
import MuiTab from '@material-ui/core/Tab'
import {
  getColorBackgroundSelector,
  getColorPrimarySelector
} from '@signal/theme'

export const Tabs = styled(MuiTabs)`
  flex-shrink: 0;
  height: 100%;
  background-color: ${getColorBackgroundSelector('paper')};
  .MuiTabs-flexContainer {
    height: 100%;
  }
  .MuiTabs-indicator {
    background-color: ${getColorPrimarySelector()};
  }
`

export const Tab = styled(MuiTab)`
  height: 100%;
`
