function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import format from 'date-fns/format';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import { parse } from 'query-string';
import { ALL_QUERY_PARAMETER_SUBST_TYPES, QP_FILTERS, QP_PRIMARY_DATE_START, QP_PRIMARY_DATE_END, QP_COMPARISON_DATE_START, QP_COMPARISON_DATE_END, QP_DATE_FORMAT } from './constants';
import { buildManagedQPString, cookManagedQPValue, getAllManagedQPs, stripAllManagedQPs, validateDateRange, validateFilters } from './utils';
/**
 * this function obtains a single manager query parameter value
 * @param {Object} an object with these members -
 *   {String} defaultValue - the default value to use if no query parameter was found (must be a string!)
 *   {String} qpType - the type of query parameter being requested (one of the QP_ constants)
 *   {Object} queryParams - query params that were parsed by query-string module - this is preferred
 *   {Object} search - the search query string (unparsed) - queryParams is preferred instead of this
 *   {Object} substParams - an object containing values to yield for substitution parameters.  It should be an object
 *     whose keys match the _SUBST strings in constants
 * @return {Varies} - the resulting cooked value, which varies by query parameter, or undefined if the parameter was
 *   not found and there was no default value provided
 */

export function getManagedQP(_ref) {
  var defaultValue = _ref.defaultValue,
      qpType = _ref.qpType,
      queryParams = _ref.queryParams,
      managedQueryParams = _ref.managedQueryParams,
      search = _ref.search,
      _ref$substParams = _ref.substParams,
      substParams = _ref$substParams === void 0 ? {} : _ref$substParams;

  if (!managedQueryParams) {
    managedQueryParams = getAllManagedQPs({
      queryParams: queryParams,
      search: search
    });
  } // apply all substitutions from substParams


  var qpTypeFinal = qpType;
  ALL_QUERY_PARAMETER_SUBST_TYPES.forEach(function (substCur) {
    if (!isUndefined(substParams[substCur])) {
      qpTypeFinal = qpTypeFinal.replace(new RegExp(substCur, 'g'), substParams[substCur]);
    }
  });
  return cookManagedQPValue(qpType, managedQueryParams[qpTypeFinal]) || cookManagedQPValue(qpType, defaultValue) // try the default value, if any
  ;
}
/**
 * this function obtains a single manager query parameter value
 * @param {Object} an object with these members -
 *   {Array} qpTypes - an array of types of query parameter being requested (array of the QP_ constants)
 *   {Array} defaultValues - an array of default value strings for each qpType (should match qpTypes by index)
 *   {Object} queryParams - query params that were parsed by query-string module - this is preferred
 *   {Object} search - the search query string (unparsed) - queryParams is preferred instead of this
 * @return {Array} - an array of cooked values for these query parameters (should match qpTypes by index)
 */

export function getManagedQPs(_ref2) {
  var qpTypes = _ref2.qpTypes,
      defaultValues = _ref2.defaultValues,
      queryParams = _ref2.queryParams,
      search = _ref2.search,
      rest = _objectWithoutProperties(_ref2, ["qpTypes", "defaultValues", "queryParams", "search"]);

  var managedQueryParams = getAllManagedQPs({
    queryParams: queryParams,
    search: search
  });
  return qpTypes.map(function (qpType, index) {
    return getManagedQP(_objectSpread({
      qpType: qpType,
      defaultValue: defaultValues ? defaultValues[index] : undefined,
      managedQueryParams: managedQueryParams
    }, rest));
  });
}
/**
 * this function filters query parameters to remove those which are invalid
 * @param {String} search - a search string
 * @param {Boolean} excludeNonGlobals - if true, only include global query params
 * @return {String} - the search string with any invalid query parameters removed
 */

export function filterManagedQPs(search) {
  var excludeNonGlobals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var queryParams = parse(search);
  return buildManagedQPString(_objectSpread(_objectSpread({}, stripAllManagedQPs(queryParams)), getAllManagedQPs({
    excludeNonGlobals: excludeNonGlobals,
    queryParams: queryParams
  })));
}
/**
 * this function applies the provided global query parameter values to the existing query parameters and returns a
 * string which contains these parameters, plus any valid managed non-global query parameter already in the search
 * string + any unknown query parameters
 * @param {Object} an object with these members (all optional) -
 *   {Object} comparisonDateRange - an object which contains a startDate and endDate (both should be JS Date)
 *   {Object} dateRange - an object which contains a startDate and endDate (both should be JS Date)
 *   {Array} filters - an array of filters
 *   {String} search - the existing search string
 *   {Boolean} clearIfNotSet - if true, then clear any global query parameters not provided by this call
 *   {Boolean} excludeNonGlobalParams - if true, then also exclude any non-global query parameter which may exist
 * @return {String} - the new search string
 */

export function applyGlobalQPs(_ref3) {
  var _ref5, _ref6;

  var comparisonDateRange = _ref3.comparisonDateRange,
      dateRange = _ref3.dateRange,
      filters = _ref3.filters,
      search = _ref3.search,
      _ref3$clearIfNotSet = _ref3.clearIfNotSet,
      clearIfNotSet = _ref3$clearIfNotSet === void 0 ? true : _ref3$clearIfNotSet,
      _ref3$excludeNonGloba = _ref3.excludeNonGlobalParams,
      excludeNonGlobalParams = _ref3$excludeNonGloba === void 0 ? true : _ref3$excludeNonGloba;
  var rawParams = parse(search); // do some parameter validation

  if (!validateFilters(filters)) {
    filters = [];
  }

  if (!validateDateRange(dateRange)) {
    dateRange = undefined;
  }

  if (comparisonDateRange && !validateDateRange(comparisonDateRange)) {
    comparisonDateRange = undefined;
  }

  return buildManagedQPString(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, stripAllManagedQPs(rawParams)), getAllManagedQPs({
    queryParams: rawParams,
    excludeGlobals: clearIfNotSet,
    excludeNonGlobals: excludeNonGlobalParams
  })), filters.length && _defineProperty({}, QP_FILTERS, JSON.stringify(filters))), isObject(dateRange) && (_ref5 = {}, _defineProperty(_ref5, QP_PRIMARY_DATE_START, format(dateRange.startDate, QP_DATE_FORMAT)), _defineProperty(_ref5, QP_PRIMARY_DATE_END, format(dateRange.endDate, QP_DATE_FORMAT)), _ref5)), isObject(comparisonDateRange) && (_ref6 = {}, _defineProperty(_ref6, QP_COMPARISON_DATE_START, format(comparisonDateRange.startDate, QP_DATE_FORMAT)), _defineProperty(_ref6, QP_COMPARISON_DATE_END, format(comparisonDateRange.endDate, QP_DATE_FORMAT)), _ref6)));
}