import styled from 'styled-components'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import Typography from '@signal/components/Typography'
import LegendComponent from '@signal/components/Legend'
import LegendSkeletonComponent from '@signal/components/Legend/Skeleton'

export const Legend = styled(LegendComponent)`
  & > *:last-child {
    margin-right: 0;
  }
`
export const LegendSkeleton = Legend.withComponent(LegendSkeletonComponent)

export const BadgeComponent = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin-right: 20px;
  background-color: ${({ color }) => color};
`

export const Label = styled(Typography).attrs(() => ({
  variant: 'h6',
  color: 'textDark'
}))``
export const LabelSkeleton = styled(
  Label.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonPrimary'
}))``
