import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import Button from '@signal/components/Button'
import { Icon } from './styles'

export const CreateAdButton = memo(function CreateAdButton(props) {
  const { to, children } = props

  return (
    <Button variant="contained" color="primary" component={Link} to={to}>
      <Icon />
      {children}
    </Button>
  )
})

export default CreateAdButton
