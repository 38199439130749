export const MAX_PAGE_WIDTH = 1000
export const NAVIGATION_BAR_HEIGHT_TABLET = 60

export const BREADCRUMB_HEIGHT = 60
export const BREADCRUMB_BORDER_WIDTH = 1

export const BOX_SHADOW = '0 0 2px 0 rgba(0, 0, 0, 0.2)'

export const BUTTON_MIN_WIDTH = 128
export const BUTTON_MIN_HEIGHT = 40
export const BUTTON_SMALL_MIN_WIDTH = 64
export const BUTTON_SMALL_MIN_HEIGHT = 32
export const BUTTON_LARGE_MIN_WIDTH = 192
export const BUTTON_LARGE_MIN_HEIGHT = 44
