import React, { memo, useMemo } from 'react'
import {
  PAGE_INVENTORY_MAKE,
  PAGE_INVENTORY_MODEL,
  PAGE_UNKNOWN,
  APP_ATTRIBUTION
} from '@signal/analytics'
import { parsePerformanceParams, MAKE, MODEL } from '@signal/core/performance'
import Header from '../../containers/AttributionHeader'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import InventoryDetailHeader from '../../containers/InventoryDetailHeader'
import InventoryDrilldownTables from '../../containers/InventoryDrilldownTables'
import { PageWrapper } from '../styles'

export const InventoryDrilldownsPage = memo(props => {
  const { location } = props

  const pageName = useMemo(() => {
    const { lvl } = parsePerformanceParams(location.search)
    // Unless the current logic changes, PAGE_UNKOWN is simply a fallback
    // for a case that should never happen.
    let pageName = PAGE_UNKNOWN
    if (lvl === MAKE) {
      pageName = PAGE_INVENTORY_MAKE
    } else if (lvl === MODEL) {
      pageName = PAGE_INVENTORY_MODEL
    }
    return pageName
  }, [location.search])

  usePageTrack(pageName, APP_ATTRIBUTION)

  // TODO: Remove this hack - hopefully when loadables are refactored. This is because
  // the current implementation of the channels detail loadable requires the component to
  // be unmounted and remounted. So adding the key here forces React to unmount and remount
  // the Layout component (and thereby all of the child components as well).
  const key = location.pathname + location.search

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      headerComponent={props => <InventoryDetailHeader {...props} />}
      location={location}
      key={key}
    >
      <PageWrapper>
        <InventoryDrilldownTables location={location} />
      </PageWrapper>
    </LayoutDefaultWithPageHeader>
  )
})

InventoryDrilldownsPage.displayName = 'InventoryDrilldownsPage'

export default InventoryDrilldownsPage
