import React from 'react'
import DrilldownTableSkeleton from '../DrilldownTable/Skeleton'
import { ScoreChartSkeleton } from './styles'

export class InventoryDrilldownTableSkeleton extends React.Component {
  static getColumnInfo = (detailsColumn, primaryColumn, secondaryColumns) => {
    if (primaryColumn) {
      primaryColumn = {
        ...primaryColumn,
        component: ScoreChartSkeleton
      }
    }
    return [detailsColumn, secondaryColumns, primaryColumn]
      .filter(Boolean)
      .reduce((columnInfo, column) => columnInfo.concat(column), [])
  }

  render() {
    return (
      <DrilldownTableSkeleton
        getColumnInfo={InventoryDrilldownTableSkeleton.getColumnInfo}
        {...this.props}
      />
    )
  }
}

export default InventoryDrilldownTableSkeleton
