import styled from 'styled-components'
import Typography from '@signal/components/Typography'
import Paper from '@material-ui/core/Paper'
import {
  getColorBackgroundSelector,
  getSpacingSelector
} from '@signal/theme/selectors'
import { constrainToMaxPageWidth } from '@signal/theme/mixins'
import {
  navigationBarOuterHeightSelector,
  navigationBarOuterHeightTabletSelector,
  navigationBarOuterHeightLandscapeSelector
} from '@signal/theme/selectors'
import { createViewportHeightMixin } from '@signal/theme/mixins'
import { breadcrumbOuterHeightSelector } from '../../theme/selectors'

const SPACING_2X = getSpacingSelector(2)

const headerHeightSelectors = {
  default: [navigationBarOuterHeightSelector, breadcrumbOuterHeightSelector],
  tablet: [
    navigationBarOuterHeightTabletSelector,
    breadcrumbOuterHeightSelector
  ],
  landscape: [
    navigationBarOuterHeightLandscapeSelector,
    breadcrumbOuterHeightSelector
  ]
}

export const PageContent = styled.div`
  width: 100vw;
  /* Constrain width to app's root div */
  max-width: 100%;
  background-color: ${getColorBackgroundSelector('paper')};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${constrainToMaxPageWidth}
  ${createViewportHeightMixin('height', headerHeightSelectors)}
`

export const AccountPickerLabel = styled(Typography).attrs(() => ({
  variant: 'h6'
}))`
  padding: ${SPACING_2X}px 0 0;
`

export const UserInfoWrapper = styled(Paper).attrs(() => ({
  elevation: 0
}))`
  padding: ${getSpacingSelector(4)}px 0 ${getSpacingSelector(2)}px;
`
