import styled, { css } from 'styled-components'
import ConstrainedImage from '@signal/components/ConstrainedImage'
import PerformanceDetailView from '@signal/components/PerformanceDetailView'
import AvatarIcon from '@signal/components/AvatarIcon'
import AvatarIconSkeleton from '@signal/components/AvatarIcon/Skeleton'
import ContributionChart from '@signal/components/ContributionChart'
import ContributionChartSkeleton from '@signal/components/ContributionChart/Skeleton'
import FeaturedMetric from '@signal/components/FeaturedMetric'
import FeaturedMetricSkeleton from '@signal/components/FeaturedMetric/Skeleton'
import Legend from '@signal/components/Legend'
import LegendSkeleton from '@signal/components/Legend/Skeleton'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import {
  getSpacingSelector,
  getColorGreySelector,
  getColorPrimarySelector
} from '@signal/theme/selectors'
import { IMAGE_SIZE, IMAGE_ASPECT_RATIO } from './constants'

const colorLightGreySelector = getColorGreySelector('light')

const imageCommonStyles = css`
  margin-right: ${getSpacingSelector(5)}px;
`

export const getLegendFillColors = theme => [
  getColorPrimarySelector('main')(theme),
  colorLightGreySelector(theme)
]

export const Wrapper = styled(PerformanceDetailView)`
  padding: ${getSpacingSelector(3)}px 0;
  align-items: flex-start;
`

export const AttributedSalesMetric = styled(FeaturedMetric).attrs(() => ({
  name: 'Attributed Sales'
}))`
  align-self: center;
`

export const AttributedSalesMetricSkeleton = styled(
  AttributedSalesMetric.withComponent(FeaturedMetricSkeleton)
).attrs(() => ({
  metricWidth: 200
}))``

export const Avatar = styled(AvatarIcon).attrs(() => ({
  size: IMAGE_SIZE
}))`
  ${imageCommonStyles}
`

export const AvatarSkeleton = Avatar.withComponent(AvatarIconSkeleton)

export const ChannelLegend = styled(Legend).attrs(() => ({
  marginSpacing: 2,
  seriesMarginSpacing: 6
}))``

export const ChannelLegendSkeleton = ChannelLegend.withComponent(LegendSkeleton)

export const CentralWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SummaryContributionChartSkeleton = styled(
  ContributionChartSkeleton
).attrs(() => ({
  showPercent: false
}))`
  max-width: 400px;
  margin-top: ${getSpacingSelector(2)}px;
`

export const SummaryContributionChart = styled(
  SummaryContributionChartSkeleton.withComponent(ContributionChart)
).attrs(props => ({
  value: props.contributionPercentage || props.value
}))``

export const TitleSkeleton = styled(TypographySkeleton).attrs(() => ({
  variant: 'h3',
  color: 'skeletonDark'
}))``

export const TypographyContribution = styled(Typography).attrs(() => ({
  variant: 'body3'
}))`
  margin: ${getSpacingSelector(1.25)}px 0px ${getSpacingSelector(2)}px 0px;
`

export const TypographyContributionSkeleton = styled(
  TypographyContribution.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 250
}))``

export const Image = styled(ConstrainedImage).attrs(() => ({
  ratio: IMAGE_ASPECT_RATIO
}))`
  width: ${IMAGE_SIZE}px;
  ${imageCommonStyles}
`
