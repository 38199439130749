export var overrides = function overrides(_ref) {
  var _ref$constants = _ref.constants,
      BUTTON_PADDING_VERTICAL = _ref$constants.BUTTON_PADDING_VERTICAL,
      BUTTON_PADDING_HORIZONTAL = _ref$constants.BUTTON_PADDING_HORIZONTAL,
      BOX_SHADOW = _ref$constants.BOX_SHADOW,
      palette = _ref.palette;
  return {
    MuiListItem: {
      root: {
        '&:last-child': {
          borderBottom: 0
        }
      }
    },
    MuiButton: {
      root: {
        padding: "".concat(BUTTON_PADDING_VERTICAL, "px ").concat(BUTTON_PADDING_HORIZONTAL, "px")
      },
      // Name of the styleSheet
      sizeSmall: {
        padding: "".concat(BUTTON_PADDING_VERTICAL, "px ").concat(BUTTON_PADDING_HORIZONTAL, "px")
      }
    },
    // TODO: This palette is not being updated when theme colors change.
    // This means that we will have to override each Mui component throughout
    // the application with color selectors and CSS queries to get proper colors.
    // We should see if there's a way to get the overrides to refresh once we
    // change the values of theme colors.
    MuiCheckbox: {
      color: palette.grey.main,
      colorPrimary: {
        color: palette.grey.main,
        '&$checked': {
          color: palette.grey.main
        }
      },
      colorSecondary: {
        color: palette.grey.main,
        '&$checked': {
          color: palette.grey.main
        }
      }
    },
    MuiTypography: {
      h4: {
        color: palette.text.deep
      },
      body1: {
        color: palette.text.dark
      },
      body2: {
        color: palette.text.dark
      }
    },
    MuiTabs: {
      root: {
        backgroundColor: palette.common.white
      },
      indicator: {
        backgroundColor: palette.grey.deep
      }
    },
    MuiTab: {
      root: {
        textTransform: 'capitalize'
      },
      textColorPrimary: {
        color: palette.grey.main,
        '&$selected': {
          color: palette.grey.deep
        }
      },
      textColorSecondary: {
        color: palette.grey.light,
        '&$selected': {
          color: palette.grey.deep
        }
      },
      selected: {}
    },
    MuiPaper: {
      elevation1: {
        boxShadow: BOX_SHADOW
      }
    }
  };
};
export default overrides;