import styled from 'styled-components'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import ContentSectionHeadingSkeleton from '@signal/components/ContentSectionHeading/Skeleton'
import { getSpacingSelector } from '@signal/theme/selectors'

export const Wrapper = styled.div``

export const SectionHeading = styled(ContentSectionHeading)`
  margin-bottom: ${getSpacingSelector(2)}px;
`
export const SectionHeadingSkeleton = SectionHeading.withComponent(
  ContentSectionHeadingSkeleton
)
