import React, { memo } from 'react'
import Content from '@signal/layouts/Content'
import Section from '@signal/layouts/Section'
import { PAGE_OFFERS_UNIVERSAL_CREATE, APP_OFFERS } from '@signal/analytics'
import { usePageTrack, useRoutePath } from '@signal/core-hooks'
import { OFFERS_UNIVERSAL, AUDIENCE_CREATE } from '@signal/routes'
import { useBodyBackgroundColor } from '@signal/theme'
import OfferDetailHeader from '../../../containers/AdDetailHeader'
import { OfferDetailTitle } from '../../../containers/AdDetailTitle/component'
import OfferDetailForm from '../../../containers/AdDetailForm'
import OfferCreatedModal from '../../../containers/AdCreatedModal'
import { Wrapper } from './styles'

export const UniversalOfferCreate = memo(function UniversalOfferCreate() {
  usePageTrack(PAGE_OFFERS_UNIVERSAL_CREATE, APP_OFFERS)
  useBodyBackgroundColor('light')

  const universalOffersPath = useRoutePath(OFFERS_UNIVERSAL)
  const audienceCreatePath = useRoutePath(AUDIENCE_CREATE)

  return (
    <Wrapper constrainWidth={false} disableGutter component="main">
      <OfferDetailHeader
        parentCrumb={{ label: 'Universal Offers', to: universalOffersPath }}
        isUniversal
      />
      <Content component="section" animateIn>
        <Section component="div">
          <OfferDetailTitle title="Create an offer" isUniversal />
        </Section>
        <Section component="div">
          <OfferDetailForm
            submitLabel="Create offer"
            resetLabel="Clear"
            offersPath={universalOffersPath}
            audienceCreatePath={audienceCreatePath}
            isUniversal
          />
        </Section>
        <OfferCreatedModal returnTo={universalOffersPath} />
      </Content>
    </Wrapper>
  )
})

export default UniversalOfferCreate
