function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

import queryString from 'query-string';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import last from 'lodash/last';
import isString from 'lodash/isString';
import conformsTo from 'lodash/conformsTo';
import reduceRight from 'lodash/reduceRight';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
export var breadCrumbFromApiFormat = {
  label: isString,
  data: isString // the drilldown link which is actually currently unused

};
/**
 * Given the current breadcrumbs and
 * @param {Array} crumbs
 * @param {string} search
 * @param {string} pathname
 */

export function constructPerformanceBreadcrumbLinks(allCrumbs, search, pathname, idsAtLvl) {
  var previousBackParams = queryString.parse(search);
  /**
   * This function reduces the crumbs in reverse to create the links
   * for them. The last (first for the iteration) crumb should represent
   * the current path, so it will not be a link but just a string. The n-1 crumb
   * (second to last) link will be created by taking the current search params
   * and calling getBackParams(currentSearchParams). That will represent the
   * search params for the n-1 crumb. Then the n-2 crumb params are calculated by
   * calling getBackParams(n-1 params) and so on until all crumb links are created.
   */

  var betweenCrumbs = _toConsumableArray(allCrumbs);

  var lastCrumb = betweenCrumbs.pop();
  return [].concat(_toConsumableArray(reduceRight(betweenCrumbs, function (crumbs, crumb) {
    if (!conformsTo(crumb, breadCrumbFromApiFormat)) {
      // If one of the crumbs is not a crumb from the api, then it's not expected to be a part of the
      // crumb link chain. This should never happen. If it does, the link creation could break.
      // Only the last crumb is expected to be a different format. That's why it is broken out above.
      console.warn('Function `constructPerformanceBreadcrumbLinks` received an unexpected breadcrumb format. This could break the creation of breadcrumb links.');
      return [crumb].concat(_toConsumableArray(crumbs));
    }

    previousBackParams = getBackPathParams({
      search: queryString.stringify(previousBackParams)
    }, idsAtLvl);
    return [{
      label: crumb.label,
      to: {
        pathname: pathname,
        search: queryString.stringify(previousBackParams)
      }
    }].concat(_toConsumableArray(crumbs));
  }, [])), [lastCrumb]);
}
/**
 * Util function that parses the different performance parameters.
 * @param {String} search
 */

export var parsePerformanceParams = function parsePerformanceParams(search) {
  var _queryString$parse = queryString.parse(search),
      _queryString$parse$st = _queryString$parse.stack,
      stack = _queryString$parse$st === void 0 ? '' : _queryString$parse$st,
      lvl = _queryString$parse.lvl,
      breakdown = _queryString$parse.breakdown,
      ids = _objectWithoutProperties(_queryString$parse, ["stack", "lvl", "breakdown"]);

  return {
    stack: stack.split(','),
    lvl: lvl,
    breakdown: breakdown,
    ids: ids
  };
};
/**
 * This function takes in a location and the idsAtLvl mapping and returns the params
 * for the previous path.
 *
 * @param {Object} location - the location object (requires search to be defined)
 * @param {Object} idsAtLvl - this mapping should define which ids correspond to which lvl.
 * When we remove the stack's last item and traverse to the previous lvl, we want to omit any
 * ids that match the removed (or current) lvl.
 *
 * @returns {Object} - previous path params
 */

export var getBackPathParams = function getBackPathParams(location, idsAtLvl) {
  var _parsePerformancePara = parsePerformanceParams(location.search),
      stack = _parsePerformancePara.stack,
      lvl = _parsePerformancePara.lvl,
      breakdown = _parsePerformancePara.breakdown,
      ids = _parsePerformancePara.ids;

  var currentLvl = stack.pop();
  if (stack.length === 0) return {}; // Since we're removing the current lvl from the stack, we also need to remove
  // any ids that are associated with it.

  ids = omit(ids, idsAtLvl[currentLvl]); // The new lvl will now be the last item on the stack (after popping off the curernt lvl)

  lvl = last(stack);
  breakdown = currentLvl;
  return _objectSpread({
    stack: stack.join(','),
    lvl: lvl,
    breakdown: breakdown
  }, ids);
};
/**
 * @param {String} search - the search to extract the stack from
 */

export function getStackFromSearch(search) {
  var _queryString$parse2 = queryString.parse(search),
      stack = _queryString$parse2.stack;

  if (stack) return stack.split(','); // TODO Currently, is the stack doesn't exist this will break everything.
  // We should be able to derive the stack from the give search.

  return [];
}
export var performanceItemLinkReducer = function performanceItemLinkReducer(item, path, location) {
  var currStack = getStackFromSearch(location.search);

  var _item$drillDown$split = item.drillDown.split('?'),
      _item$drillDown$split2 = _slicedToArray(_item$drillDown$split, 2),
      itemPath = _item$drillDown$split2[0],
      itemSearch = _item$drillDown$split2[1];

  var _parsePerformancePara2 = parsePerformanceParams(itemSearch),
      breakdown = _parsePerformancePara2.breakdown,
      lvl = _parsePerformancePara2.lvl,
      ids = _parsePerformancePara2.ids;

  var pathname = isFunction(path) ? path(itemPath, itemSearch) : path;
  var search = queryString.stringify(_objectSpread(_objectSpread({
    breakdown: breakdown,
    lvl: lvl
  }, ids), {}, {
    stack: [].concat(_toConsumableArray(currStack), [lvl]).join(',')
  }));
  return _objectSpread(_objectSpread({}, omit(item, 'drillDown')), {}, {
    to: {
      pathname: pathname,
      search: search
    }
  });
};
export var performanceItemsLinkReducer = function performanceItemsLinkReducer(items, path, location) {
  return items.map(function (item) {
    return performanceItemLinkReducer(item, path, location);
  });
};
export var performanceGroupLinkReducer = function performanceGroupLinkReducer(item, path, location) {
  var _item$drillDown$split3 = item.drillDown.split('?'),
      _item$drillDown$split4 = _slicedToArray(_item$drillDown$split3, 2),
      itemPath = _item$drillDown$split4[0],
      itemSearch = _item$drillDown$split4[1];

  var pathname = isFunction(path) ? path(itemPath, itemSearch) : path;
  return _objectSpread(_objectSpread({}, omit(item, 'drillDown')), {}, {
    to: {
      pathname: pathname,
      state: {
        selectedGroup: item.title
      }
    }
  });
};
export var performanceGroupsLinkReducer = function performanceGroupsLinkReducer(items, path, location) {
  return items.map(function (item) {
    return performanceGroupLinkReducer(item, path, location);
  });
};
export var noopItemReducer = function noopItemReducer(item) {
  return item;
};
/**
 * @param {Object} commonReducer - a reducer that all items will be run through
 * @param {Object|Function} customItemReducer - this can be a function or an object that contains
 * keys that point to functions. The keys should correspond to the given lvl.
 *
 * @returns {Function} - items reducer
 *
 * Items Reducer function params
 * @param {Array<Object>} items - the array of performance items
 * @param {String|Function} path - The path string or function for the item
 * @param {Object} location - the current location
 */

export var createPerformanceItemsReducer = function createPerformanceItemsReducer(commonReducer, customReducer) {
  return function () {
    var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var path = arguments.length > 1 ? arguments[1] : undefined;
    var location = arguments.length > 2 ? arguments[2] : undefined;
    return items.map(function (item) {
      var itemSearch = item.drillDown.split('?').pop();

      var _parsePerformancePara3 = parsePerformanceParams(itemSearch),
          lvl = _parsePerformancePara3.lvl; // Determine the custom reducer. It defaults to noopItemReducer (a function) if it's not provided.
      // If it's an object, the proper function of that object will be derived from the lvl.
      // If that specific lvl is not defined and there's no default, it will again default to
      // the noopItemReducer


      var reducer = customReducer || noopItemReducer;

      if (!isFunction(reducer)) {
        reducer = customReducer[lvl] || customReducer.default || noopItemReducer;
      }

      return _objectSpread(_objectSpread({}, commonReducer(item)), reducer(item, path, location));
    });
  };
};
export var GROUPING_SORT_ORDER = [// 75e8d192-b721-0d2b-2406-92b448dc68be
'Paid', // 0e7a5e01-6b87-5cab-569b-42841f81f0b6
'Third Party Vendor', // 63162e5b-ec9f-4f6d-bba5-a6c35d50b181
'OEM Referral', // ded5dfe2-bbf1-cf63-9b7d-1d37e6a8ff52
'Other'];
export var sortByGrouping = function sortByGrouping(items, valueGetter) {
  return sortBy(items, function (item) {
    var sortIndex = GROUPING_SORT_ORDER.findIndex(function (grouping) {
      return grouping === valueGetter(item);
    });
    return sortIndex >= 0 ? sortIndex : GROUPING_SORT_ORDER.length;
  });
};
/**
 * A simple reducer that groups the items by their `grouping` property
 */

export var performanceItemGroupReducer = function performanceItemGroupReducer(items) {
  return groupBy(sortByGrouping(items, function (val) {
    return val.grouping;
  }), 'grouping');
};