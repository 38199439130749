import React, { memo } from 'react'
import Content from '@signal/layouts/Content'
import Section from '@signal/layouts/Section'
import { PAGE_OFFERS_PERSONALIZED_CREATE, APP_OFFERS } from '@signal/analytics'
import { usePageTrack, useRoutePath } from '@signal/core-hooks'
import { OFFERS_PERSONALIZED, AUDIENCE_CREATE } from '@signal/routes'
import { useBodyBackgroundColor } from '@signal/theme'
import OfferDetailHeader from '../../../containers/AdDetailHeader'
import { OfferDetailTitle } from '../../../containers/AdDetailTitle/component'
import OfferDetailForm from '../../../containers/AdDetailForm'
import OfferCreatedModal from '../../../containers/AdCreatedModal'
import { Wrapper } from './styles'

export const PersonalizedOfferCreate = memo(function PersonalizedOfferCreate() {
  const personalizedOffersPath = useRoutePath(OFFERS_PERSONALIZED)
  const audienceCreatePath = useRoutePath(AUDIENCE_CREATE)

  usePageTrack(PAGE_OFFERS_PERSONALIZED_CREATE, APP_OFFERS)
  useBodyBackgroundColor('light')

  return (
    <Wrapper constrainWidth={false} disableGutter component="main">
      <OfferDetailHeader
        parentCrumb={{
          label: 'Personalized Offers',
          to: personalizedOffersPath
        }}
      />
      <Content component="section" animateIn>
        <Section component="div">
          <OfferDetailTitle title="Create an offer" />
        </Section>
        <Section component="div">
          <OfferDetailForm
            submitLabel="Create offer"
            resetLabel="Clear"
            offersPath={personalizedOffersPath}
            audienceCreatePath={audienceCreatePath}
          />
        </Section>
        <OfferCreatedModal returnTo={personalizedOffersPath} />
      </Content>
    </Wrapper>
  )
})

export default PersonalizedOfferCreate
