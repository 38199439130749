import loadable from '@signal/hoc/loadable'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "ad-created-modal" */ './component'),
  dataLoader: () => import(/* webpackChunkName: "lib-ad" */ '@signal/core/ad'),
  loadData: ({ adDetailsLoader }, loaderInterface) =>
    adDetailsLoader(loaderInterface),
  loading: () => null
})
