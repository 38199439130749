import React from 'react'
import Content from '@signal/layouts/Content'
import NotFound from '../components/NotFound'

export default function NotFoundView() {
  return (
    <Content animateIn>
      <NotFound />
    </Content>
  )
}
