import { REPORT, SUB_REPORT } from '@signal/routes';
/**
 * this function obtains the the reportId from a relative path which should contain /report/:reportSlug/:subReportSlug
 * @param {Object} an object with these members -
 *   {Array} serviceSlugs - the slugs returned from calling useServiceSlugs
 *   {String} accountId - the accountId (needed for hydrating paths)
 *   {String} path - the actual path being checked to see if it refers to a report id
 * @return {String} - the report ID referred to by the path, or undefined if not a report path or no slug service match
 */

export function getReportIdFromPath(_ref) {
  var serviceSlugs = _ref.serviceSlugs,
      accountId = _ref.accountId,
      path = _ref.path;
  var foundSlug = serviceSlugs.find(function (_ref2) {
    var slug = _ref2.slug;
    var slugs = slug.split('/'); // TODO: if we ever support > 2 levels of hierachy account for that here

    var route = slugs.length === 2 ? SUB_REPORT : REPORT;
    var pathname = route.buildPath({
      accountId: accountId,
      reportSlug: slugs[0],
      subReportSlug: slugs[1]
    });
    return path === pathname;
  });
  return foundSlug ? foundSlug.reportId : '';
}
/**
 * this function obtains the report id from an array of "active slugs", which imply slugs active on the URL
 * @param {Object} an object with these members -
 *   {Array} serviceSlugs - the slugs returned from calling useServiceSlugs
 *   {Array} activeSlugs - an array of strings, indicating the slugs on the URL, like ['performance', 'social']
 *     reportSlug should be first member, and subReportSlug (if it exists) should be second member of the array
 * @return {String} - the report ID referred to by the active slugs, or undefined if no match in the slug service
 */

export function getReportIdFromSlugs(_ref3) {
  var serviceSlugs = _ref3.serviceSlugs,
      activeSlugs = _ref3.activeSlugs;
  var slugToFind = activeSlugs.join('/');
  var foundSlug = serviceSlugs.find(function (_ref4) {
    var slug = _ref4.slug;
    return slug === slugToFind;
  });
  return foundSlug ? foundSlug.reportId : '';
}
/**
 * this function obtains the the set of slugs that correspond to the given reportId (opposite of getReportIdFromSlugs)
 * @param {Object} an object with these members -
 *   {Array} serviceSlugs - the slugs returned from calling useServiceSlugs
 *   {String} reportId - a reportId to find the slugs for
 * @return {Array} - an array that contains the slugs that refer to that reportId (e.g. ['inventory', 'detail']
 */

export function getSlugsFromReportId(_ref5) {
  var serviceSlugs = _ref5.serviceSlugs,
      reportIdToFind = _ref5.reportId;
  var foundSlug = serviceSlugs.find(function (_ref6) {
    var reportId = _ref6.reportId;
    return reportId === reportIdToFind;
  });
  return foundSlug ? foundSlug.slug.split('/') : [];
}