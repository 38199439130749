import { getAnimationOverrides, getNavigationStacks } from './transitions'
import routeDefinitions from './routeDefinitions'
import routeKeyGenerator from '@signal/router/routeKeyGenerator'

const animationOverrides = getAnimationOverrides()
const navigationStacks = getNavigationStacks()

export default {
  animationOverrides,
  navigationStacks,
  routeKeyGenerator,
  routeDefinitions
}
