/**
 * The `LOAD` action type indicates that an API request should be initialized.
 * @type {String}
 */
export var LOAD = 'LOAD';
/**
 * The `REQUEST` action type indicates that an API request has been initialized.
 * @type {String}
 */

export var REQUEST = 'REQUEST';
/**
 * The `SUCCESS` action type indicates that an API request was successful.
 * @type {String}
 */

export var SUCCESS = 'SUCCESS';
/**
 * The `SUCCESS` action type indicates that an API request has failed.
 * @type {String}
 */

export var FAILURE = 'FAILURE';
/**
 * @typedef RequestActionType
 * @type {(LOAD|REQUEST|SUCCESS|FAILURE)}
 */

/**
 * @todo Rename types constant in createRequestActions
 * @body The `types` constant inside `utils/createRequestActions.js` should be converted to constant case and renamed to be more contextual. Let's go with something like `REQUEST_ACTION_TYPES`.
 */

/**
 * @name RequestActionTypes
 * Enum for request action types.
 * @readonly
 * @enum {RequestActionType}
 */

export var REQUEST_ACTION_TYPES = {
  LOAD: LOAD,
  REQUEST: REQUEST,
  SUCCESS: SUCCESS,
  FAILURE: FAILURE
};