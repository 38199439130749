import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import renderIf from '@signal/utils/renderIf'
import AlertBanner from '@signal/components/AlertBanner'
import { crumbPropType } from '@signal/components/Breadcrumbs'
import {
  ContentWrapper,
  ConstrainWidth,
  BreadcrumbsWrapper,
  BreadcrumbsContent,
  Breadcrumbs
} from './styles'

export default function ContextualPageHeader({
  alertMessage,
  crumbs,
  children,
  className,
  style
}) {
  return (
    <Fragment>
      {renderIf(!isEmpty(crumbs), () => (
        <BreadcrumbsWrapper>
          <BreadcrumbsContent>
            {renderIf(!isEmpty(crumbs), () => (
              <Breadcrumbs crumbs={crumbs} />
            ))}
          </BreadcrumbsContent>
        </BreadcrumbsWrapper>
      ))}
      {renderIf(alertMessage, () => (
        <AlertBanner>{alertMessage}</AlertBanner>
      ))}
      {renderIf(children, () => (
        <ContentWrapper>
          <ConstrainWidth className={className} style={style}>
            {children}
          </ConstrainWidth>
        </ContentWrapper>
      ))}
    </Fragment>
  )
}

ContextualPageHeader.propTypes = {
  alertMessage: PropTypes.string,
  crumbs: PropTypes.arrayOf(crumbPropType)
}

ContextualPageHeader.defaultProps = {
  crumbs: []
}
