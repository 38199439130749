import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Link } from 'react-router-dom'
import { manageSpendPathSelector } from '@signal/core/router/selectors'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import DatePickerSkeleton from '@signal/components/DatePicker/Skeleton'
import Typography from '@signal/components/Typography'

export const OverviewSnapshotSkeleton = ({ manageSpendPath }) => (
  <ContentSectionHeading
    title={
      <Typography variant="h3" color="textDark">
        Historical Performance
      </Typography>
    }
    description={
      <Typography variant="subtitle2">
        Performance data represents an average based off historical spend and is
        directly linked to{' '}
        <Link to={manageSpendPath}>spend that you've entered.</Link>
      </Typography>
    }
    rightContent={<DatePickerSkeleton />}
  />
)

const mapStateToProps = createStructuredSelector({
  manageSpendPath: manageSpendPathSelector
})

export default connect(mapStateToProps)(OverviewSnapshotSkeleton)
