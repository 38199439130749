import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
/**
 * Creates a custom PropType validator that conditionally derives its type
 * checker function based on another prop's value.
 *
 * @property {String} valuePropName - name of the "value" prop to derive from
 * @property {Object.<String,Function>} conditionalTypes - map of type checker
 * functions to conditionally use based on the "value" prop
 * @property {Function} [defaultType] - type checker used as a fallback if/when
 * the "value" prop is unsupported (i.e. type checker for value is missing from
 * `conditionalTypes` map)
 *
 * @example
 * SomeComponent.propTypes = {
 *   type: PropTypes.oneOf(['text', 'checkbox', 'select']),
 *   opts: derivePropTypeFromValueOf('type', {
 *     text: PropTypes.shape({
 *       value: PropTypes.string
 *     }),
 *     checkbox: PropTypes.shape({
 *       value: PropTypes.string,
 *       checked: PropTypes.bool
 *     }),
 *     select: PropTypes.shape({
 *       value: PropTypes.string,
 *       options: PropTypes.arrayOf(PropTypes.shape({
 *         value: PropTypes.string,
 *         label: PropTypes.string
 *       }))
 *     })
 *   })
 * }
 */

export default function derivePropTypeFromValueOf(valuePropName, conditionalTypes, defaultType) {
  return function (props, propName, componentName, location, propFullName) {
    var value = props[valuePropName];
    var supportedValues = Object.keys(conditionalTypes);
    var propType = isFunction(conditionalTypes) ? conditionalTypes(value) || defaultType : get(conditionalTypes, value, defaultType);

    if (!isFunction(propType)) {
      return new Error("Invalid prop `".concat(propFullName || propName, "` supplied to `").concat(componentName, "`. Unsupported `").concat(valuePropName, "` prop value, must be one of: ").concat(supportedValues.map(function (val) {
        return "'".concat(val, "'");
      }).join(', '), "."));
    } // We also must validate the property's type (if provided)


    for (var _len = arguments.length, rest = new Array(_len > 5 ? _len - 5 : 0), _key = 5; _key < _len; _key++) {
      rest[_key - 5] = arguments[_key];
    }

    return propType.apply(void 0, [props, propName, componentName, location, propFullName].concat(rest));
  };
}