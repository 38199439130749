var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getColorPrimarySelector, getSpacingSelector } from '@signal/theme/selectors';
import { constrainToMaxPageWidth } from '@signal/theme/mixins';
import Typography from '../Typography';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n"])), getColorPrimarySelector());
export var TextContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n  padding-top: ", "px;\n  padding-bottom: ", "px;\n"])), constrainToMaxPageWidth, getSpacingSelector(1.5), getSpacingSelector(1.5));
export var Label = styled(Typography).attrs(function () {
  return {
    variant: 'h6',
    color: 'inverse',
    noWrap: true
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));