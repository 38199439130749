export const TITLE_KEY = 'title'
export const LAST_EDITED_KEY = 'lastEdited'
export const DELETE_KEY = 'delete'

const AUDIENCE_LIST_COLUMNS = [
  {
    key: TITLE_KEY,
    label: 'Audience',
    width: '55%',
    sortKey: 'title',
    inverse: true
  },
  {
    key: LAST_EDITED_KEY,
    label: 'Last Edited',
    sortKey: 'lastEdited',
    sortReverse: true,
    inverse: true
  },
  {
    key: DELETE_KEY
  }
]

export const generateAudienceListColumns = columnInfo =>
  AUDIENCE_LIST_COLUMNS.map(columnCur => ({
    ...columnCur,
    ...columnInfo[columnCur.key]
  }))
