import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import { getSpacingSelector } from '@signal/theme/selectors'

export const Wrapper = styled.div``

export const StatsList = List

export const StatListItem = styled(ListItem)`
  padding: ${getSpacingSelector(3)}px 0;
  display: flex;
  justify-content: space-between;
  &:last-child {
    border-bottom: 0;
  }
`

export const StatLabel = styled(Typography).attrs(() => ({
  variant: 'subtitle2'
}))``
export const StatLabelSkeleton = styled(
  StatLabel.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 120
}))``

export const StatMetric = styled(Typography).attrs(() => ({
  variant: 'subtitle2'
}))``
export const StatMetricSkeleton = styled(
  StatMetric.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 40
}))``
