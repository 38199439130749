import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { adDetailsNameSelector } from '@signal/core/ad'
import Typography from '@signal/components/Typography'

export const OfferDetailTitle = memo(function OfferDetailTitle({ title }) {
  return (
    <Typography variant="h3" color="textDark">
      {title}
    </Typography>
  )
})

OfferDetailTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export const OfferDetailTitleContainer = memo(
  function OfferDetailTitleContainer(props) {
    const title = useSelector(state => adDetailsNameSelector(state, props))
    return <OfferDetailTitle title={title} />
  }
)

OfferDetailTitleContainer.propTypes = {
  offerId: PropTypes.string
}

export default OfferDetailTitleContainer
