import React from 'react'
import PropTypes from 'prop-types'
import {
  SORT_ASCENDING,
  SORT_DESCENDING,
  SORT_NONE
} from '@signal/components/SortableTable'
import {
  Wrapper,
  HeaderTypography,
  PaddedArrowDown,
  PaddedArrowUp
} from './styles'

export const ARROW_COMPONENTS = {
  [SORT_ASCENDING]: PaddedArrowUp,
  [SORT_DESCENDING]: PaddedArrowDown
}

export const sortDirectionPropType = PropTypes.oneOf([
  SORT_NONE,
  SORT_ASCENDING,
  SORT_DESCENDING
]).isRequired

export class DrilldownTableHeader extends React.PureComponent {
  static propTypes = {
    allowSort: PropTypes.bool,
    sortDirection: sortDirectionPropType,
    onSortChange: PropTypes.func
  }

  static defaultProps = {
    allowSort: true
  }

  handleSortChange = () => {
    const { sortDirection, onSortChange, inverse } = this.props
    if (sortDirection === SORT_NONE) {
      return onSortChange(inverse ? SORT_ASCENDING : SORT_DESCENDING)
    }
    return onSortChange(
      sortDirection === SORT_DESCENDING ? SORT_ASCENDING : SORT_DESCENDING
    )
  }

  render() {
    const {
      allowSort,
      children,
      onSortChange,
      sortDirection,
      inverse,
      ...rest
    } = this.props
    const sortActive = sortDirection !== SORT_NONE
    let ArrowComponent
    let textColor
    if (sortActive) {
      ArrowComponent = ARROW_COMPONENTS[sortDirection]
      textColor = 'textPrimary'
    } else {
      ArrowComponent = inverse ? PaddedArrowUp : PaddedArrowDown
      textColor = 'textSecondary'
    }
    return (
      <Wrapper
        onClick={allowSort && onSortChange ? this.handleSortChange : null}
        sortActive={allowSort && sortDirection !== SORT_NONE}
        sortDisabled={!allowSort}
      >
        <HeaderTypography color={textColor} {...rest}>
          {children}
          <ArrowComponent {...rest} />
        </HeaderTypography>
      </Wrapper>
    )
  }
}

export default DrilldownTableHeader
