import styled, { css } from 'styled-components'
import IconButton from '@signal/components/IconButton'
import { getColorGreySelector } from '@signal/theme/selectors'

export const Button = styled(IconButton)`
  .strawberry-menu > path {
    ${({ disabled }) =>
      disabled
        ? css`
            fill: ${getColorGreySelector('lightest')};
          `
        : ''};
  }
`
