var _templateObject;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { getColorPrimarySelector } from '@signal/theme/selectors';
var linkColorSelector = getColorPrimarySelector();
var activeLinkColorSelector = getColorPrimarySelector('dark');
export var TypographyBase = styled(Typography)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  > a {\n    color: ", ";\n    &:active,\n    &:hover,\n    &:focus {\n      color: ", ";\n      text-decoration: underline;\n    }\n  }\n"])), linkColorSelector, activeLinkColorSelector);