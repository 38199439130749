import React from 'react'
import loadable from '@signal/hoc/loadable'
import { ChannelsDetailDataGridSkeleton } from './styles'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "channels-detail-data-grid" */ './component'),
  dataLoader: () =>
    import(
      /* webpackChunkName: "lib-performance-query" */ '@signal/core/performanceQuery'
    ),
  loadData: ({ performanceQueryDataLoader }, loaderInterface) =>
    performanceQueryDataLoader(loaderInterface),
  loading: () => <ChannelsDetailDataGridSkeleton items={3} />
})
