import React from 'react'
import styled from 'styled-components'
import { createSelector } from 'reselect'
import AvatarIcon from '@signal/components/AvatarIcon'
import AvatarIconSkeleton from '@signal/components/AvatarIcon/Skeleton'
import DataList from '@signal/components/DataList'
import DataListSkeleton from '@signal/components/DataList/DataListSkeleton'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import StackedBarChartWithLegend from '@signal/components/StackedBarChartWithLegend'
import StackedBarChartWithLegendSkeleton from '@signal/components/StackedBarChartWithLegend/Skeleton'
import {
  getSpacingSelector,
  getColorBackgroundSelector
} from '@signal/theme/selectors'
import {
  PATTERN_ID,
  PATTERN_FILL,
  PATTERN_ROTATION,
  PATTERN_STROKE_WIDTH,
  PATTERN_STROKE_SPACING
} from './constants'

export const chartBgColorSelector = getColorBackgroundSelector()
export const chartLineColorSelector = getColorBackgroundSelector('light')

export const Wrapper = styled.div`
  && {
    margin-top: ${getSpacingSelector(4)}px;
    margin-bottom: ${getSpacingSelector(6)}px;
  }
  > * {
    margin: ${getSpacingSelector(2)}px 0;
  }
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
`

export const ChannelAvatar = styled(AvatarIcon)`
  margin-right: ${getSpacingSelector(2)}px;
`
export const ChannelAvatarSkeleton = ChannelAvatar.withComponent(
  AvatarIconSkeleton
)

export const HeadingTitle = styled(Typography).attrs(() => ({
  variant: 'body1',
  color: 'textDark'
}))``

export const HeadingTitleSkeleton = styled(
  HeadingTitle.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonDark',
  width: 200
}))``

export const SummaryText = styled(Typography).attrs(() => ({
  variant: 'subtitle2'
}))``
export const SummaryTextSkeleton = SummaryText.withComponent(TypographySkeleton)

export const ContributionText = styled(Typography).attrs(() => ({
  variant: 'subtitle2',
  color: 'textDark'
}))`
  margin-top: ${getSpacingSelector(4)}px;
`
export const ContributionTextSkeleton = styled(
  ContributionText.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 430,
  color: 'skeletonDark'
}))``

const patternSelector = createSelector(
  chartBgColorSelector,
  chartLineColorSelector,
  (bgColor, lineColor) => (
    <pattern
      id={PATTERN_ID}
      width={PATTERN_STROKE_SPACING}
      height={PATTERN_STROKE_SPACING}
      patternTransform={`rotate(${PATTERN_ROTATION} 0 0)`}
      patternUnits="userSpaceOnUse"
    >
      <rect
        width={PATTERN_STROKE_SPACING}
        height={PATTERN_STROKE_SPACING}
        x="0"
        y="0"
        fill={bgColor}
      />
      <line
        x1="0"
        y1="0"
        x2="0"
        y2={PATTERN_STROKE_SPACING}
        style={{
          stroke: lineColor,
          strokeWidth: PATTERN_STROKE_WIDTH
        }}
      />
    </pattern>
  )
)
export const ContributionBarChart = styled(StackedBarChartWithLegend).attrs(
  props => ({
    hideLegend: true,

    chartWrapperProps: {
      style: {
        height: '10px'
      }
    },

    pattern: patternSelector(props)
  })
)``

const seriesSelector = createSelector(chartBgColorSelector, bgColor => [
  {
    label: 'Default',
    value: 50,
    fill: PATTERN_FILL
  },
  {
    label: 'Other',
    value: 50,
    fill: bgColor
  }
])
export const ContributionBarChartSkeleton = styled(
  ContributionBarChart.withComponent(StackedBarChartWithLegendSkeleton)
).attrs(props => ({
  series: seriesSelector(props)
}))``

const sectionSelector = createSelector(
  getSpacingSelector(2),
  horizontalSpacing => ({
    style: {
      paddingLeft: horizontalSpacing,
      paddingRight: horizontalSpacing
    }
  })
)
export const MetricList = styled(DataList).attrs(props => ({
  typographyVariant: 'subtitle2',

  sectionProps: sectionSelector(props)
}))`
  grid-template-columns: repeat(2, 1fr) !important;
  column-gap: 20px;
`
export const MetricListSkeleton = styled(
  MetricList.withComponent(DataListSkeleton)
).attrs(() => ({
  items: 2
}))``
