import React, { Fragment } from 'react'
import DrilldownSectionSkeleton from '../../components/DrilldownSection/Skeleton'
import ChannelsDrilldownTableSkeleton from '../../components/ChannelsDrilldownTable/Skeleton'

export const ChannelsOverviewSkeleton = () => (
  <Fragment>
    <DrilldownSectionSkeleton
      drilldownTableComponent={ChannelsDrilldownTableSkeleton}
      showAvatar
    />
  </Fragment>
)

export default ChannelsOverviewSkeleton
