var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Typography from '../Typography';
import TypographySkeleton from '../Skeleton/Typography'; // import { getSpacingSelector } from '@signal/theme/selectors'

import { getSpacingSelector, getColorGreySelector, getColorPrimarySelector } from '@signal/theme/selectors'; // const spacing2xSelector = getSpacingSelector(2)

var spacing3xSelector = getSpacingSelector(3);
var colorPrimarySelector = getColorPrimarySelector();
export var ListWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
export var BlankIcon = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  height: ", "px;\n  width: ", "px;\n  min-width: ", "px;\n  border-radius: ", "px;\n  color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), getColorGreySelector(), spacing3xSelector, spacing3xSelector, spacing3xSelector, getSpacingSelector(1.5), colorPrimarySelector);
export var MenuItemText = styled(Typography).attrs(function () {
  return {
    variant: 'body4',
    color: 'textDark'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var SubMenuItemText = styled(Typography).attrs(function () {
  return {
    variant: 'body3',
    color: 'textPrimary'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var MenuItemSkeleton = styled(TypographySkeleton).attrs(function () {
  return {
    width: 90,
    color: 'textDark'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var SubMenuItemSkeleton = styled(TypographySkeleton).attrs(function () {
  return {
    width: 90
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));