import React from 'react'
import Brand from '@signal/components/Brand'
import {
  Header,
  AppTitle,
  Middle,
  Left,
  AppIconWrapper
} from '@signal/components/DesktopHeader/styles'

export default function EmptyHeader({ title, isPageChild, icon: AppIcon }) {
  const LeftContent = () => (
    <Left>
      <AppIconWrapper>
        <AppIcon />
      </AppIconWrapper>
    </Left>
  )
  return (
    <Header isPageChild={isPageChild} fixed left={LeftContent}>
      <Middle>
        <AppTitle>{title}</AppTitle>
      </Middle>
    </Header>
  )
}

EmptyHeader.defaultProps = {
  title: '',
  icon: Brand
}
