import loadable from '@signal/hoc/loadable';
import combinedError from '@signal/hoc/combinedError';
import ErrorScreenApi from '../ErrorScreenApi';
import Skeleton from './skeleton';
export default loadable({
  loader: function loader() {
    return import(
    /* webpackChunkName: "container-user-info" */
    './component');
  },
  dataLoader: function dataLoader() {
    return import(
    /* webpackChunkName: "lib-user" */
    '@signal/core/user');
  },
  loadData: function loadData(_ref, loaderInterface) {
    var userDetailsLoader = _ref.userDetailsLoader;
    return userDetailsLoader(loaderInterface);
  },
  loading: Skeleton,
  error: combinedError(ErrorScreenApi)
});