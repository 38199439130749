var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Typography from '../Typography';
import Button from '../Button';
import { getColorBackgroundSelector, getSpacingSelector } from '@signal/theme/selectors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n"])), getColorBackgroundSelector('paper'));
export var Grid = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr;\n  justify-content: center;\n  align-items: center;\n  grid-gap: ", "px;\n  width: 80%;\n  max-width: 300px;\n"])), getSpacingSelector(2));
export var ErrorImage = styled.img(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  justify-self: center;\n  margin: ", "px;\n"])), getSpacingSelector(3));
export var Title = styled(Typography).attrs(function () {
  return {
    variant: 'h6'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  text-align: center;\n  color: ", ";\n"])), function (props) {
  return props.theme.palette.grey[500];
});
export var Description = styled(Typography).attrs(function () {
  return {
    variant: 'subtitle1'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  text-align: center;\n  color: ", ";\n  margin: ", "px 0;\n"])), function (props) {
  return props.theme.palette.grey[500];
}, getSpacingSelector());
export var PrimaryButton = styled(Button).attrs(function () {
  return {
    variant: 'contained',
    color: 'primary',
    size: 'large'
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
export var SecondaryButton = styled(Button).attrs(function () {
  return {
    size: 'small'
  };
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n  text-decoration: underline;\n  justify-self: center;\n"])), function (props) {
  return props.theme.palette.grey[500];
});