import { NO_THEME } from '@signal/core/tenantTheme'
import AB_TEST_PARAMS from '@signal/utils/ab-testing'
import {
  AB_DATE_PICKER,
  AB_DESIGN,
  AB_THEME,
  AB_CHART_GRADIENT,
  DATE_PICKER_OLD,
  DESIGN_NEW,
  THEME_SERVER,
  ALL_CHART_GRADIENT,
  MV_CHART_GRADIENT
} from './constants'

// re-export constants
export * from './constants'

// utility functions related to the ab-testing parameters
export function isNewDesign() {
  return AB_TEST_PARAMS[AB_DESIGN] === DESIGN_NEW
}

export function isAllChartGradient() {
  return AB_TEST_PARAMS[AB_CHART_GRADIENT] === ALL_CHART_GRADIENT
}

export function isMVChartGradient() {
  return AB_TEST_PARAMS[AB_CHART_GRADIENT] === MV_CHART_GRADIENT
}

export function isOldDatePicker() {
  return AB_TEST_PARAMS[AB_DATE_PICKER] === DATE_PICKER_OLD
}

export function getMockTheme() {
  const themeParam = AB_TEST_PARAMS[AB_THEME]
  // TODO: make this the default behavior (return empty string) once tenant API call is in place on BE
  if (themeParam === THEME_SERVER) {
    return ''
  }
  return themeParam || NO_THEME
}
