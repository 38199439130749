var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getSpacingSelector } from '@signal/theme/selectors';
import Typography from '../Typography';
import TypographySkeleton from '../Skeleton/Typography';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n"])));
export var CrumbWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  white-space: nowrap;\n  display: inline-flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var Crumb = styled(Typography).attrs(function () {
  return {
    variant: 'subtitle2'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var CrumbLink = styled(Crumb).attrs(function () {
  return {
    component: Link
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  &:hover {\n    text-decoration: underline;\n  }\n"])));
export var CrumbSeparator = styled(Crumb)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 0 ", "px;\n"])), getSpacingSelector(1));
export var CrumbSkeleton = styled(Crumb.withComponent(TypographySkeleton)).attrs(function () {
  return {
    width: 80
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));