import styled from 'styled-components'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import Notice from '@signal/components/Notice'
import TimelineMarker from '@signal/components/TimelineMarker'
import Typography from '@signal/components/Typography'
import { getSpacingSelector } from '@signal/theme/selectors'
import { COLOR_WARN } from '@signal/theme/variables'

export const NoChannelNotice = styled(Notice)`
  margin-bottom: ${getSpacingSelector(6)}px !important;
`

export const PurchaseActivityWrapper = styled.div`
  display: flex;
`

export const PurchaseMarker = styled(TimelineMarker).attrs(() => ({
  innerProps: {
    style: {
      backgroundColor: COLOR_WARN
    }
  }
}))`
  margin: 0px ${getSpacingSelector(2)}px 0px 0px !important;
`

export const PurchaseTypography = styled(Typography).attrs(() => ({
  variant: 'body2'
}))``

export const UserActivityHeading = styled(ContentSectionHeading).attrs(() => ({
  title: 'Activity Timeline',

  description:
    'Below is the web activity related to the purchase of this vehicle.'
}))``
