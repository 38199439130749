import React from 'react'
import { Switch, Route } from 'react-router'
import { REPORT, REPORTS, SHARED_REPORT, SUB_REPORT } from '@signal/routes'
import NotFound from '../../components/NotFound'
import ReportView from './Report'
import SignalPage from '../SignalPage'

export function SharedReportView(props) {
  return <ReportView isShared {...props} />
}

export function DashboardView() {
  return (
    <SignalPage>
      <Switch>
        <Route path={SUB_REPORT.path} exact component={ReportView} />
        <Route path={REPORT.path} exact component={ReportView} />
        <Route path={REPORTS.path} exact component={ReportView} />
        <Route path={SHARED_REPORT.path} exact component={SharedReportView} />
        <Route component={NotFound} />
      </Switch>
    </SignalPage>
  )
}
