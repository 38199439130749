import get from 'lodash/get';
/**
 * Attempts to get an error from the provided action's payload. Falls back to
 * default error message "Unable to fetch data, something went wrong."
 * @param {FluxStandardAction} action - Action object.
 * @return {(String|Error)} - Error from the action object.
 */

export function getError(action) {
  var error = 'Unable to fetch data, something went wrong.';
  if (action.error) error = get(action, 'payload.error') || get(action, 'payload');
  return error;
}