import createLoader from '@signal/utils/createLoader';
import { rootLoader } from '../app';
import reducer from './reducer';
import { loadPerformanceData, performanceListener, loadInventoryPerformanceData, inventoryPerformanceDataListener } from './sagas';
export var performanceDataLoader = createLoader('performance', {
  load: loadPerformanceData,
  listen: performanceListener,
  injectReducers: function injectReducers(injectReducer) {
    return injectReducer('performance', reducer);
  },
  dependencies: [rootLoader]
});
export var inventoryPerformanceDataLoader = createLoader('inventoryPerformance', {
  load: loadInventoryPerformanceData,
  listen: inventoryPerformanceDataListener,
  injectReducers: function injectReducers(injectReducer) {
    return injectReducer('performance', reducer);
  },
  dependencies: [rootLoader]
});