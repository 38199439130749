import React, { memo } from 'react'
import { PAGE_INVENTORY_LIST, APP_ATTRIBUTION } from '@signal/analytics'
import Header from '../../containers/AttributionHeader'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import InventoryDrilldownTables from '../../containers/InventoryDrilldownTables'
import InventorySummaryHeader from '../../containers/InventorySummaryHeader'
import { PageWrapper } from '../styles'

export const InventoryOverviewPage = memo(props => {
  const { location } = props
  usePageTrack(PAGE_INVENTORY_LIST, APP_ATTRIBUTION)

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      headerComponent={InventorySummaryHeader}
      location={location}
    >
      <PageWrapper>
        <InventoryDrilldownTables
          location={location}
          groupOrder={['New', 'Used']}
        />
      </PageWrapper>
    </LayoutDefaultWithPageHeader>
  )
})

InventoryOverviewPage.displayName = 'InventoryOverviewPage'

export default InventoryOverviewPage
