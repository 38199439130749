/**
 * @interface IAccountFeature
 * this interface defines a feature definition in an account definition
 */

/**
 * @interface IAccountGroup
 * this interface defines a group definition in an account definition
 */

/**
 * @interface IUserAccount
 * this interface defines a single user account returned from the user accounts endpoint
 */

/**
 * @interface IUserDetails
 * this interface defines the user details returned from the user details endpoint
 */

/**
 * Use this to confirm if the type of the passed variable is of type IUserDetails
 * @param arg An argument you wish to check if it is type of IUserDetails
 * @returns {boolean}
 */
export function isUserDetails(arg) {
  return arg.id !== undefined;
}
/**
 * @tyoe AccountChangeHandlerType
 * The specific function signature for an account change handler function
 */