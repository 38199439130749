import checkStore from '@signal/utils/checkStore';
import invariant from 'invariant';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import { createReducer } from './createReducer';
/**
 * Creates a function that allows a user to inject a reducer into the provided
 * redux store.
 *
 * @param {Store} store - A redux store to inject reducers into
 * @param {object} isValid - A flag to specify that the store has already been
 * check for validity.
 */

export function injectReducerFactory(store, isValid) {
  /**
   * Dynamically injects a reducer
   *
   * @param {string} key - The reducer key to use
   * @param {function} reducer - The reducer to inject
   */
  return function injectReducer(key, reducer) {
    if (!isValid) checkStore(store);
    invariant(isString(key) && !isEmpty(key) && isFunction(reducer), '(lib/utils...) injectReducer: Expected `reducer` to be a reducer function'); // Check `store.injectedReducers[key] === reducer` for hot reloading when a key is the same but a reducer is different

    if (Reflect.has(store.injectedReducers, key) && store.injectedReducers[key] === reducer) return;
    store.injectedReducers[key] = reducer;
    store.replaceReducer(createReducer(store.injectedReducers, store.history));
  };
}
/**
 * Reducer-related helper functions bound to a redux store.
 * @global
 * @typedef {Object} ReducerHelpers
 * @property {injectReducer} injectReducer - injectReducer helper function
 */

/**
 * Creates reducer-related helpers for the provided store.
 *
 * @param {Store} store - A redux store to pass to helper factories
 * @returns {ReducerHelpers} - Reducer-related helper functions
 */

export function createReducerHelpers(store) {
  checkStore(store);
  return {
    injectReducer: injectReducerFactory(store, true)
  };
}
export default createReducerHelpers;