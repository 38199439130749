import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "most-active-shoppers" */ './component'),
  dataLoader: () =>
    import(
      /* webpackChunkName: "lib-shoppers-bucket" */ '@signal/core/shoppersBucket'
    ),
  loading: Skeleton
})
