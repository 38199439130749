import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, HeaderTypographySkeleton } from './styles'

export class DrilldownTableHeaderSkeleton extends React.PureComponent {
  static propTypes = {
    width: PropTypes.number
  }

  static defaultProps = {
    width: 60
  }

  render() {
    const { children, ...rest } = this.props
    return (
      <Wrapper>
        <HeaderTypographySkeleton {...rest} />
      </Wrapper>
    )
  }
}

export default DrilldownTableHeaderSkeleton
