import { normalize } from 'normalizr';
export default function createNormalizrMiddleware() {
  return function () {
    return function (next) {
      return function (action) {
        var schema = action.meta && action.meta.schema;

        if (schema && action.payload && !action.error) {
          var normalized = normalize(action.payload, schema); // Mutate the action to prevent loss of non-enumerable props (like symbols)

          action.payload = normalized;
        }

        return next(action);
      };
    };
  };
}