import React from 'react'
import { useSelector } from 'react-redux'
// import React, { useCallback } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import Link from '@signal/components/Link'
import Typography from '@signal/components/Typography'
// import { beginActivateTour } from '@signal/core/local'
import { actionsSelector } from './selectors'
import { Wrapper, Actions, Action } from './styles'

export function UserMenuNavLinks({ onClickLink }) {
  const actions = useSelector(actionsSelector)
  // const dispatch = useDispatch()
  // const showTour = useCallback(() => {
  //   onClickLink && onClickLink()
  //   dispatch(beginActivateTour())
  // }, [dispatch, onClickLink])

  return (
    <Wrapper>
      <Actions>
        {actions.map(({ id, label, to }) => (
          <Action component={Link} to={to} key={to} onClick={onClickLink}>
            <Typography variant="body4" component="span">
              {label}
            </Typography>
          </Action>
        ))}
        {/* <Action onClick={showTour} key="show tour">
          <Typography variant="body4" component="span">
            Show Tour
          </Typography>
        </Action> */}
      </Actions>
    </Wrapper>
  )
}

UserMenuNavLinks.defaultProps = {
  onClickLink: () => null
}

export default UserMenuNavLinks
