import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "channels-vin-header" */ './component'),
  dataLoader: () =>
    import(
      /* webpackChunkName: "lib-performance-vin" */ '@signal/core/performanceVin'
    ),
  loading: Skeleton
})
