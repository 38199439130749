var _createReducer;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import { authRequest, authSuccess, authFailure, setActiveAccount } from './actions';
var initialState = {
  activeAccount: null,
  authenticating: false,
  authenticated: false,
  isInitialized: false,
  error: null
};
export var authReducer = createReducer((_createReducer = {}, _defineProperty(_createReducer, authRequest, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    authenticated: false,
    authenticating: true,
    isInitialized: true
  });
}), _defineProperty(_createReducer, authSuccess, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    authenticated: true,
    authenticating: false
  });
}), _defineProperty(_createReducer, authFailure, function (state, payload) {
  return _objectSpread(_objectSpread({}, state), {}, {
    token: null,
    expires: null,
    error: payload,
    authenticating: false
  });
}), _defineProperty(_createReducer, setActiveAccount, function (state, newActiveAccount) {
  return _objectSpread(_objectSpread({}, state), {}, {
    activeAccount: newActiveAccount
  });
}), _createReducer), initialState);
export default authReducer;