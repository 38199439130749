import { createAction } from 'redux-act';
import { EventTypes } from '@purecarslabs/redux-segment';
import { createMetaPayload } from './utils';
export var ACTION_PREFIX = 'ANALYTICS';
export var setPageName = createAction(ACTION_PREFIX + '/SET_PAGE_NAME', null, function (name) {
  var app = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
  return createMetaPayload(EventTypes.page, {
    name: name,
    properties: {
      app: app
    }
  });
});
export var trackEvent = createAction(ACTION_PREFIX + '/TRACK_EVENT', null, function (_ref, label) {
  var event = _ref.event,
      category = _ref.category;
  return createMetaPayload(EventTypes.track, {
    event: event,
    properties: {
      category: category,
      label: label
    }
  });
});