import createLoader from '@signal/utils/createLoader';
import { rootLoader } from '../app/loaders';
import { loadAudiences, audiencesListener, loadAudienceSegments, audienceSegmentsListener, loadAudienceDetails, audienceDetailsListener } from './sagas';
import reducer from './reducer';
export var audiencesLoader = createLoader('audiences', {
  load: loadAudiences,
  listen: audiencesListener,
  injectReducers: function injectReducers(injectReducer) {
    return injectReducer('audience', reducer);
  },
  dependencies: [rootLoader]
});
export var audienceSegmentsLoader = createLoader('audienceSegments', {
  load: loadAudienceSegments,
  listen: audienceSegmentsListener,
  injectReducers: function injectReducers(injectReducer) {
    return injectReducer('audience', reducer);
  },
  dependencies: [rootLoader]
});
export var audienceDetailsLoader = createLoader('audienceDetails', {
  load: loadAudienceDetails,
  listen: audienceDetailsListener,
  injectReducers: function injectReducers(injectReducer) {
    return injectReducer('audience', reducer);
  },
  dependencies: [rootLoader]
});