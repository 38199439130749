var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled, { keyframes } from 'styled-components';
var fadeIn = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    margin-top: -20%;\n  }\n  75% {\n    opacity: 1;\n  }\n  100% {\n    margin-top: 0;\n  }\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  animation: 375ms ", " ease-out;\n"])), fadeIn);