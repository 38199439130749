function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

import isFunction from 'lodash/isFunction';
/**
 * Creates a custom PropType validator that ensures two or more properties on an
 * object are mutually exclusive, and optionally that at least one of them is
 * set (if required).
 *
 * Prop-type validators for all of the expected object keys can (and in some
 * case should) be provided; they will be properly used to validate the type of
 * each value.
 *
 * @property {Object.<String,Function>} propTypes - prop-types validators for
 * all of the expected object keys/properties
 * @property {Array.<String>} mutuallyExclusivePropertyNames - list of mutually
 * exclusive object property keys, or "names"
 * @property {Boolean} [isRequired=false] - whether or not one of the mutually
 * exclusive properties is required
 * @returns {Function} - custom prop-type validator for validating mutually
 * exclusive properties
 *
 * @example
 * // NOTE: This example is provided only to provide clarity and demonstrate
 * // basic functionality. For a demonstration of the function's intended usage,
 * // and a more common use-case, proceed to the next example.
 *
 * // `eitherAOrB` is a custom prop-type validator
 * const eitherAOrB = mutuallyExclusiveProperties(
 *   // Provide `propTypes` just like you would for a component
 *   {
 *     propA: PropTypes.string,
 *     propB: PropTypes.func,
 *     someOtherProp: PropTypes.bool.isRequired,
 *   },
 *
 *   // Provide the keys that are mutually exclusive
 *   ['propA', 'propB'],
 *
 *   // Enable the `isRequired` flag if either `propA` or `propB` is required
 *   true
 * )
 *
 * // `eitherAOrB` can be used to validate any/all of the props included in the
 * // `propTypes` object used to create it above
 * SomeComponent.propTypes = {
 *   propA: eitherAOrB,
 *   propB: eitherAOrB,
 *   someOtherProp: eitherAOrB,
 *
 *   // No other keys can use `eitherAOrB` for prop-type validation because they
 *   // were not included in the `propTypes` object used to create it above
 *   unknownKey: eitherAOrB,
 *   willNotBeValidated: eitherAOrB
 * }
 *
 * @example
 * // `mutuallyExclusiveProperties` was created with `PropTypes.objectOf()` from
 * // the `prop-types` library in mind. Here is a demonstration of it's intended
 * // usage: validating an object prop containing mutually exclusive properties.
 * SomeComponent.propTypes = {
 *   someObjectProp: PropTypes.objectOf(mutuallyExclusiveProperties(
 *     // Provide `propTypes` for all of the expected object properties
 *     {
 *       condition: PropTypes.bool.isRequired,
 *       redirectTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
 *       onFailure: PropTypes.func
 *     },
 *     ['redirectTo', 'onFailure'],
 *     true
 *   ))
 * }
 */

export default function mutuallyExclusiveProperties(propTypes, mutuallyExclusivePropertyNames) {
  var isRequired = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return function (propValue, key, componentName, location, propFullName) {
    var mutuallyExclusiveProperties = Object.entries(propValue).filter(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      return mutuallyExclusivePropertyNames.includes(key) && value != null;
    });

    if (isRequired && mutuallyExclusiveProperties.length === 0) {
      return new Error("Invalid prop `".concat(propFullName || key, "` supplied to `").concat(componentName, "`. One of the required mutually exclusive properties must be specified: ").concat(mutuallyExclusivePropertyNames.map(function (name) {
        return "`".concat(name, "`");
      }).join(', '), "."));
    }

    if (mutuallyExclusiveProperties.length > 1) {
      return new Error("Invalid prop `".concat(propFullName || key, "` supplied to `").concat(componentName, "`. These properties are mutually exclusive: ").concat(mutuallyExclusiveProperties.map(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 1),
            name = _ref4[0];

        return "`".concat(name, "`");
      }).join(', '), "."));
    }

    var propType = propTypes[key];

    if (isFunction(propType)) {
      for (var _len = arguments.length, rest = new Array(_len > 5 ? _len - 5 : 0), _key = 5; _key < _len; _key++) {
        rest[_key - 5] = arguments[_key];
      } // We also must validate the property's type (if provided)


      return propType.apply(void 0, [propValue, key, componentName, location, propFullName].concat(rest));
    }
  };
}