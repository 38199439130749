import React from 'react'
import {
  OFFERS_OVERVIEW,
  OFFERS_UNIVERSAL,
  OFFERS_PERSONALIZED
} from '@signal/routes'
import { useLocation } from 'react-router'
import Offers from '@signal/components/Icons/Offers'
import { Nav, NavLink } from '@signal/components/Nav'
import { useRoutePath } from '@signal/core-hooks'
import SignalHeader from '../Header'

export default function Header({ isPageChild }) {
  const location = useLocation()
  const overviewPath = useRoutePath(OFFERS_OVERVIEW)
  const offersPath = useRoutePath(OFFERS_UNIVERSAL)
  const personalizedPath = useRoutePath(OFFERS_PERSONALIZED)

  return (
    <SignalHeader isPageChild={isPageChild} icon={Offers} title="Offers">
      <Nav location={location}>
        <NavLink label="Overview" to={overviewPath} />
        <NavLink label="Universal" to={offersPath} />
        <NavLink label="Personalized" to={personalizedPath} />
      </Nav>
    </SignalHeader>
  )
}
