var _createReducer;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createReducer } from 'redux-act';
import { userDetailsRequest, userDetailsFailure, userDetailsSuccess, userAccountsRequest, userAccountsFailure, userAccountsSuccess, usersRequest, usersFailure, usersSuccess, setFeatureFlags } from './actions';
export var initialState = {
  me: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    loading: false,
    error: false
  },
  accounts: {
    byId: null,
    list: null,
    loading: false,
    error: false
  },
  users: {
    byId: null,
    list: null,
    loading: false,
    error: false
  },
  featureFlags: {}
};
export default createReducer((_createReducer = {}, _defineProperty(_createReducer, userDetailsRequest, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    me: _objectSpread(_objectSpread({}, initialState.me), {}, {
      loading: true
    })
  });
}), _defineProperty(_createReducer, userDetailsFailure, function (state, error) {
  return _objectSpread(_objectSpread({}, state), {}, {
    me: _objectSpread(_objectSpread({}, state.me), {}, {
      loading: false,
      error: error
    })
  });
}), _defineProperty(_createReducer, userDetailsSuccess, function (state, data) {
  return _objectSpread(_objectSpread({}, state), {}, {
    me: _objectSpread(_objectSpread(_objectSpread({}, state.me), data), {}, {
      loading: false
    })
  });
}), _defineProperty(_createReducer, userAccountsRequest, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    accounts: _objectSpread(_objectSpread({}, initialState.accounts), {}, {
      loading: true
    })
  });
}), _defineProperty(_createReducer, userAccountsFailure, function (state, error) {
  return _objectSpread(_objectSpread({}, state), {}, {
    accounts: _objectSpread(_objectSpread({}, state.accounts), {}, {
      loading: false,
      error: error
    })
  });
}), _defineProperty(_createReducer, userAccountsSuccess, function (state, payload) {
  var list = payload.result,
      accounts = payload.entities.accounts;
  return _objectSpread(_objectSpread({}, state), {}, {
    accounts: _objectSpread(_objectSpread({}, state.accounts), {}, {
      byId: accounts,
      list: list,
      loading: false
    })
  });
}), _defineProperty(_createReducer, usersRequest, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    users: _objectSpread(_objectSpread({}, initialState.users), {}, {
      loading: true
    })
  });
}), _defineProperty(_createReducer, usersFailure, function (state, error) {
  return _objectSpread(_objectSpread({}, state), {}, {
    users: _objectSpread(_objectSpread({}, state.users), {}, {
      loading: false,
      error: error
    })
  });
}), _defineProperty(_createReducer, usersSuccess, function (state, payload) {
  var list = payload.result,
      users = payload.entities.users;
  return _objectSpread(_objectSpread({}, state), {}, {
    users: _objectSpread(_objectSpread({}, state.users), {}, {
      byId: users,
      list: list,
      loading: false
    })
  });
}), _defineProperty(_createReducer, setFeatureFlags, function (state, payload) {
  return _objectSpread(_objectSpread({}, state), {}, {
    featureFlags: payload
  });
}), _createReducer), initialState);