import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { getFeatureFlagSelector } from '@signal/core/user'
import renderIf from '@signal/utils/renderIf'
import DatePickerSkeleton from '@signal/components/DatePicker/Skeleton'
import DropdownSkeleton from '@signal/components/StyledSelectDropdown/Skeleton'
import ToolbarControl from '../../layouts/ToolbarControl'
import { FilterToolbar } from './styles'

const profitabilitySelector = getFeatureFlagSelector('profitability')

export const ChannelsToolbarSkeleton = () => {
  const showProfitability = useSelector(profitabilitySelector)

  return (
    <FilterToolbar>
      {renderIf(showProfitability, () => (
        <ToolbarControl label="View Data By">
          <DropdownSkeleton />
        </ToolbarControl>
      ))}
      <ToolbarControl label="Filter List">
        <DropdownSkeleton />
      </ToolbarControl>
      <ToolbarControl label="Date Range">
        <DatePickerSkeleton />
      </ToolbarControl>
    </FilterToolbar>
  )
}

export default memo(ChannelsToolbarSkeleton)
