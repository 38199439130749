import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "channels-vin-user-activity" */ './component'),
  dataLoader: {
    performanceVin: () =>
      import(
        /* webpackChunkName: "lib-performance-vin" */ '@signal/core/performanceVin'
      ),
    performancePathToSale: () =>
      import(
        /* webpackChunkName: "lib-performance-path-to-sale" */ '@signal/core/performancePathToSale'
      )
  },
  loadData: ({ performanceVin, performancePathToSale }, loaderInterface) => {
    const { default: performanceVinLoader } = performanceVin
    const { default: performancePathToSaleLoader } = performancePathToSale
    return Promise.all([
      performanceVinLoader(loaderInterface),
      performancePathToSaleLoader(loaderInterface)
    ])
  },
  loading: Skeleton
})
