import React from 'react'
import Attribution from '@signal/components/Icons/Attribution'
import MainNavTabs from '../../containers/MainNavTabs'
import SignalHeader from '../Header'

export default function Header({ isPageChild }) {
  return (
    <SignalHeader
      isPageChild={isPageChild}
      icon={Attribution}
      title="Attribution"
    >
      <MainNavTabs />
    </SignalHeader>
  )
}
