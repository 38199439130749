var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import MuiTable from '@material-ui/core/Table';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
export function createTableStyles(rowSpacing) {
  return {
    borderSpacing: "0 ".concat(rowSpacing, "px")
  };
}
export function createTableCellStyles(cellWidth) {
  return {
    width: cellWidth
  };
}
export var Table = styled(MuiTable).attrs(function () {
  return {
    padding: 'none'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-collapse: separate;\n"])));
export var TableHead = styled(MuiTableHead)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  height: auto;\n"])));
export var TableBody = styled(MuiTableBody)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: auto;\n"])));
export var TableRow = styled(MuiTableRow)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  height: auto;\n"])));
export var TableCell = styled(MuiTableCell).attrs(function () {
  return {
    padding: 'none'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  border-bottom: none;\n"])));
export var TableCellSticky = styled(TableCell)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: sticky;\n  top: 0;\n"])));