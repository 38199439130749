import React from 'react'
import {
  ChannelAvatarWrapper,
  AvatarSkeleton,
  AvatarTitleSkeleton
} from './styles'

export default function ChannelAvatarSkeleton({ TitleProps }) {
  return (
    <ChannelAvatarWrapper>
      <AvatarSkeleton />
      <AvatarTitleSkeleton {...TitleProps} />
    </ChannelAvatarWrapper>
  )
}
