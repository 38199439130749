var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getSpacingSelector, getColorBackgroundSelector, getColorTextSelector } from '@signal/theme/selectors';
import Typography from '../Typography';
var gridSpacingSelector = getSpacingSelector(1.75);
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n\n  && {\n    padding: ", "px 0;\n  }\n"])), getColorBackgroundSelector('paper'), getSpacingSelector(10));
export var Grid = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr;\n  justify-items: center;\n  align-items: center;\n  grid-gap: ", "px;\n  margin: ", "px;\n  max-width: 620px;\n"])), gridSpacingSelector, gridSpacingSelector);
export var Description = styled(Typography).attrs(function () {
  return {
    variant: 'subtitle1',
    align: 'center'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Title = styled(Typography).attrs(function () {
  return {
    variant: 'h3',
    align: 'center'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
export var Icon = styled.img(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  justify-self: center;\n  color: ", ";\n  font-size: 60px;\n"])), function (props) {
  return props.iconColor || getColorTextSelector()(props);
});
export var Image = styled.img(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  height: auto;\n  justify-self: center;\n  width: 454px;\n"])));