import find from 'lodash/find';
import { REPORT, SUB_REPORT } from '@signal/routes';
import * as activateReportLinks from '@signal/routes/activateReports';
import * as industryReportLinks from '@signal/routes/industry';
import * as localSearchReportLinks from '@signal/routes/localSearch';
import * as valueIntelligenceReportLinks from '@signal/routes/valueIntelligence';
var OLD_REDIRECTS = {
  '/account/:accountId/activateReports/:reportSlug': 'activate',
  '/account/:accountId/industryReports/:reportSlug': 'industry',
  '/account/:accountId/localSearchReports/:reportSlug': 'local-search',
  '/account/:accountId/valueIntelligence/:reportSlug': 'value-intelligence'
};
var SUB_REPORT_SLUG_MAPPING = {
  overview: undefined,
  // overview is redundant for these, we don't need any sub-report slug
  fixedOps: 'fixed-ops'
};
/**
 * this function redirects obsolete report paths that didn't conform the meridian report link scheme
 * (/report/:reportSlug/:subReportSlug)
 * @param {String} accountId - the accountId to place in the URL
 * @param {Object} history - react-router history object, for changing the current URL
 * @param {Object} location - react-router location object
 * @param {String} path - the current router path, as obtained from useRouteMatch
 */

export default function redirectOldReports(accountId, history, location, path) {
  // these need to be redirected
  if (find(activateReportLinks, function (link) {
    return link.path === path;
  }) || find(industryReportLinks, function (link) {
    return link.path === path;
  }) || find(localSearchReportLinks, function (link) {
    return link.path === path;
  }) || find(valueIntelligenceReportLinks, function (link) {
    return link.path === path;
  })) {
    var reportSlug = OLD_REDIRECTS[path];

    if (reportSlug) {
      var pathname = location.pathname,
          search = location.search;
      var oldReportSlug = pathname.match(/([^/]+)\/?$/);
      var subReportSlug = oldReportSlug && oldReportSlug.length > 1 ? SUB_REPORT_SLUG_MAPPING[oldReportSlug[1]] : undefined;
      var route = subReportSlug ? SUB_REPORT : REPORT;
      var redirectPathname = route.buildPath({
        accountId: accountId,
        reportSlug: reportSlug,
        subReportSlug: subReportSlug
      });
      console.log('redirecting old report', pathname, 'to', redirectPathname);
      history.replace("".concat(redirectPathname).concat(search));
    }
  }
}