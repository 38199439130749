import React from 'react'
import styled from 'styled-components'
import isString from 'lodash/isString'
import conforms from 'lodash/conforms'
import {
  getSpacingSelector,
  getColorBackgroundSelector
} from '@signal/theme/selectors'
import renderIf from '@signal/utils/renderIf'
import ProgressiveImage from '@signal/components/ProgressiveImage'
import ConstrainedImage from '@signal/components/ConstrainedImage'
import AvatarIcon from '@signal/components/AvatarIcon'
import AvatarIconSkeleton from '@signal/components/AvatarIcon/Skeleton'
import Typography from '@signal/components/Typography'
import SkeletonTypography from '@signal/components/Skeleton/Typography'

const isAvatar = conforms({
  letter: isString,
  imageUrl: isString
})

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Image = styled(ConstrainedImage)`
  height: auto;
  justify-self: center;
  width: 54px;
  margin-right: ${getSpacingSelector(2)}px;
`

export const Avatar = styled(AvatarIcon)`
  margin-right: ${getSpacingSelector(2)}px;
`
export const AvatarSkeleton = Avatar.withComponent(AvatarIconSkeleton)

export const ImageSkeleton = styled(Image).attrs(() => ({
  imgStyle: { objectFit: 'cover' }
}))`
  background-color: ${getColorBackgroundSelector()};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'h6',
  color: 'textDark',
  noWrap: true
}))``
export const TitleSkeleton = styled(
  Title.withComponent(SkeletonTypography)
).attrs(() => ({
  color: 'skeletonDark',
  width: 100
}))``

export const Subtitle = styled(Typography).attrs(() => ({ noWrap: true }))``
export const SubtitleSkeleton = styled(SkeletonTypography).attrs(() => ({
  width: 150
}))``

export const PrimaryValue = styled(Typography).attrs(() => ({
  variant: 'subtitle1',
  align: 'right'
}))``

export const PrimaryValueSkeleton = styled(
  PrimaryValue.withComponent(SkeletonTypography)
).attrs(() => ({
  color: 'skeletonPrimary',
  width: 80
}))``

export const SecondaryValue = styled(Typography).attrs(() => ({
  variant: 'subtitle2'
}))`
  text-transform: capitalize;
`
export const SecondaryValueSkeleton = styled(
  SecondaryValue.withComponent(SkeletonTypography)
).attrs(() => ({
  width: 60
}))``

export const Details = ({ avatar, imageUrl, title, subtitle }) => (
  <DetailsWrapper>
    {renderIf(isAvatar(avatar), () => (
      <Avatar src={avatar.imageUrl} letter={avatar.letter} />
    ))}
    {renderIf(isString(imageUrl), () => (
      <ProgressiveImage src={imageUrl}>
        {(src, loading) => (
          <Image
            imgStyle={{ objectFit: loading ? 'cover' : 'contain' }}
            src={src}
            alt={title}
          />
        )}
      </ProgressiveImage>
    ))}
    <TitleWrapper>
      <Title>{title}</Title>
      {renderIf(subtitle, () => (
        <Subtitle>{subtitle}</Subtitle>
      ))}
    </TitleWrapper>
  </DetailsWrapper>
)

export const DetailsSkeleton = ({ showAvatar, showImage, showSubtitle }) => (
  <DetailsWrapper>
    {renderIf(showAvatar, () => (
      <AvatarSkeleton />
    ))}
    {renderIf(showImage, () => (
      <ProgressiveImage>
        {src => <ImageSkeleton src={src} alt="loading" />}
      </ProgressiveImage>
    ))}
    <TitleWrapper>
      <TitleSkeleton />
      {renderIf(showSubtitle, () => (
        <SubtitleSkeleton />
      ))}
    </TitleWrapper>
  </DetailsWrapper>
)
