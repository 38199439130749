import { useState } from 'react';
import usePersistedState from './usePersistedState';
import createStorage from './createStorage';

var createPersistedState = function createPersistedState(key) {
  var provider = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : global.localStorage;

  if (provider) {
    var storage = createStorage(provider);
    return function (initialState) {
      return usePersistedState(initialState, key, storage);
    };
  }

  return useState;
};

export default createPersistedState;