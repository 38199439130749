import React, { memo } from 'react'
import Content from '@signal/layouts/Content'
import Section from '@signal/layouts/Section'
import { PAGE_OFFERS_UNIVERSAL_EDIT, APP_OFFERS } from '@signal/analytics'
import { usePageTrack, useRoutePath } from '@signal/core-hooks'
import { OFFERS_UNIVERSAL, AUDIENCE_CREATE } from '@signal/routes'
import { useBodyBackgroundColor } from '@signal/theme'
import OfferDetailHeader from '../../../containers/AdDetailHeader'
import OfferDetailTitle from '../../../containers/AdDetailTitle'
import OfferDetailForm from '../../../containers/AdDetailForm'
import OfferCreatedModal from '../../../containers/AdCreatedModal'

export const UniversalOfferEdit = memo(function UniversalOfferEdit(props) {
  const { offerId } = props.match.params
  const universalOffersPath = useRoutePath(OFFERS_UNIVERSAL)
  const audienceCreatePath = useRoutePath(AUDIENCE_CREATE)

  usePageTrack(PAGE_OFFERS_UNIVERSAL_EDIT, APP_OFFERS)
  useBodyBackgroundColor('light')

  return (
    <Content constrainWidth={false} disableGutter component="main">
      <OfferDetailHeader
        offerId={offerId}
        isUniversal
        parentCrumb={{ label: 'Universal Offers', to: universalOffersPath }}
      />
      <Content component="section" animateIn>
        <Section component="div">
          <OfferDetailTitle offerId={offerId} isUniversal />
        </Section>
        <Section component="div">
          <OfferDetailForm
            offerId={offerId}
            offersPath={universalOffersPath}
            audienceCreatePath={audienceCreatePath}
            isUniversal
          />
        </Section>
        <OfferCreatedModal offerId={offerId} returnTo={universalOffersPath} />
      </Content>
    </Content>
  )
})

export default UniversalOfferEdit
