import loadable from '@signal/hoc/loadable'
import Skeleton from '../../components/AdListGroup/Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "universal-offers-list-group" */ './component'),
  dataLoader: () => import(/* webpackChunkName: "lib-ad" */ '@signal/core/ad'),
  loadData: ({ universalOffersLoader, adDetailsLoader }, loaderInterface) =>
    Promise.all([
      universalOffersLoader(loaderInterface),
      adDetailsLoader(loaderInterface)
    ]),
  loading: Skeleton
})
