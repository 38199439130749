var globalState = {};

var createGlobalState = function createGlobalState(key, thisCallback, initialValue) {
  if (!globalState[key]) {
    globalState[key] = {
      callbacks: [],
      value: initialValue
    };
  }

  globalState[key].callbacks.push(thisCallback);
  return {
    deregister: function deregister() {
      var arr = globalState[key].callbacks;
      var index = arr.indexOf(thisCallback);

      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    emit: function emit(value) {
      if (globalState[key].value !== value) {
        globalState[key].value = value;
        globalState[key].callbacks.forEach(function (callback) {
          if (thisCallback !== callback) {
            callback(value);
          }
        });
      }
    }
  };
};

export default createGlobalState;