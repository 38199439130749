import React, { memo } from 'react'
import TypographySkeleton from '@signal/components/Skeleton/Typography'

export const AdDetailTitleSkeleton = memo(() => (
  <TypographySkeleton variant="h3" width={150} />
))

AdDetailTitleSkeleton.displayName = 'AdDetailTitleSkeleton'

export default AdDetailTitleSkeleton
