import React, { memo } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { adsPathSelector } from '@signal/core/router'
import {
  titleSelector,
  reportStartSelector,
  reportEndSelector
} from './selectors'
import Dialog from '../../components/Dialog'
import AdReport from './index'
import { ContentWrapper } from './styles'

/**
 * =======
 * WARNING
 * =======
 * Eventually, this might need to be improved. Currently, this modal is coupled
 * to the router heirarchy. It's expecting the adsList to be fetched by the AdListGroup container
 * since it's a modal on the same page this was an easy way to grab the details of the
 * ad to display the stats. So, `title`, `reportStart`, and `reportEnd` are all props
 * provided by the ad details coming from the adList. A better and safer way to get the details
 * would be to kick off a request for the details of the ad based on the id in the url.
 * However, this is a lot more work than necessary right now since this modal is shown
 * as a subroute to the adslist route which uses the adsListGroup container which fetches
 * the list of ads. So, it's a dangerous assumption to make if this component is ever moved,
 * but an easy refactor when and if necessary
 */
const AdStatsModalController = memo(function({
  reportStart,
  reportEnd,
  title,
  returnTo,
  adId,
  isUniversal
}) {
  const canLoad = !!(reportStart && reportEnd)

  /**
   * Always show the dialog because this component will only mount when a certain
   * URL is hit and therefore we always want the dialog to show at that URL.
   */
  const showDialog = true

  return (
    <Dialog
      open={showDialog}
      titleSkeleton={!title}
      title={title}
      closeHref={returnTo}
    >
      <ContentWrapper>
        <AdReport
          shouldLoadData={canLoad}
          id={adId}
          start={reportStart}
          end={reportEnd}
        />
      </ContentWrapper>
    </Dialog>
  )
})

AdStatsModalController.displayName = 'AdStatsModalController'

const mapStateToProps = createStructuredSelector({
  title: titleSelector,
  reportStart: reportStartSelector,
  reportEnd: reportEndSelector,
  adsListPath: adsPathSelector
})

export default connect(mapStateToProps)(AdStatsModalController)
