import React, { memo } from 'react'
import Content from '@signal/layouts/Content'
import Section from '@signal/layouts/Section'
import { PAGE_OFFERS_PERSONALIZED_EDIT, APP_OFFERS } from '@signal/analytics'
import { usePageTrack, useRoutePath } from '@signal/core-hooks'
import { OFFERS_PERSONALIZED, AUDIENCE_CREATE } from '@signal/routes'
import { useBodyBackgroundColor } from '@signal/theme'
import OfferDetailHeader from '../../../containers/AdDetailHeader'
import OfferDetailTitle from '../../../containers/AdDetailTitle'
import OfferDetailForm from '../../../containers/AdDetailForm'
import OfferCreatedModal from '../../../containers/AdCreatedModal'

export const PersonalizedOfferEdit = memo(function PersonalizedOfferEdit(
  props
) {
  const { offerId } = props.match.params

  const personalizedOffersPath = useRoutePath(OFFERS_PERSONALIZED)
  const audienceCreatePath = useRoutePath(AUDIENCE_CREATE)

  usePageTrack(PAGE_OFFERS_PERSONALIZED_EDIT, APP_OFFERS)
  useBodyBackgroundColor('light')

  return (
    <Content constrainWidth={false} disableGutter component="main">
      <OfferDetailHeader
        offerId={offerId}
        parentCrumb={{
          label: 'Personalized Offers',
          to: personalizedOffersPath
        }}
      />
      <Content component="section" animateIn>
        <Section component="div">
          <OfferDetailTitle offerId={offerId} />
        </Section>
        <Section component="div">
          <OfferDetailForm
            offerId={offerId}
            offersPath={personalizedOffersPath}
            audienceCreatePath={audienceCreatePath}
          />
        </Section>
        <OfferCreatedModal
          offerId={offerId}
          returnTo={personalizedOffersPath}
        />
      </Content>
    </Content>
  )
})

export default PersonalizedOfferEdit
