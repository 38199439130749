import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import Fuse from 'fuse.js';
/**
 * Hook that returns a function that performs a fuzzy search on the
 * list provided to the hook.
 *
 * See options here https://fusejs.io/
 */

export default function useFuzzySearch(list, query) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var fuse = useMemo(function () {
    return new Fuse(list, options);
  }, [list, options]);
  var trimmedQuery = trim(query);
  var searchResults = useMemo(function () {
    return fuse.search(trimmedQuery);
  }, [fuse, trimmedQuery]);
  if (isEmpty(trimmedQuery)) return list;
  return searchResults;
}