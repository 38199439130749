import {
  salePriceSelector,
  saleDateSelector
} from '@signal/core/performanceVin'
import { createSelector } from 'reselect'
import moment from 'moment'
import getStringFormatter from '@signal/utils/getStringFormatter'

const formatCurrency = getStringFormatter('Currency')

export const selectSalesStatement = createSelector(
  salePriceSelector,
  saleDateSelector,
  (salePrice, saleDate) => {
    return salePrice
      ? `This vehicle sold on ${moment(saleDate).format(
          'MMM Do, YYYY'
        )} for ${formatCurrency(salePrice)}`
      : ''
  }
)
