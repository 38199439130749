import styled from 'styled-components'
import { getSpacingSelector } from '@signal/theme/selectors'
import { constrainToMaxPageWidth } from '@signal/theme/mixins'

export const PageTitleWrapper = styled.div`
  ${constrainToMaxPageWidth}

  > * {
    margin: ${getSpacingSelector(1.75)}px 0;
  }
`

export const PageWrapper = styled.div`
  ${constrainToMaxPageWidth}

  /* Common spacing between each page section */
  > * {
    margin: ${getSpacingSelector(4)}px 0;
    &:last-child {
      margin-bottom: ${getSpacingSelector(8)}px;
    }
  }
`
