import createSymbol from '../createSymbol';
/**
 * Symbol that can be used to detect if a function is a loader.
 * @type {SYMBOL}
 */

export var LOADER = createSymbol('LOADER');
/**
 * Saga types that are supported by a loader.
 * @type {Array}
 */

export var SAGA_TYPES = ['load', 'listen'];