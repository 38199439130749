import React, { memo } from 'react'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import DesktopEmptyScreen from '@signal/components/DesktopEmptyScreen'
import { Wrapper, ComponentWrapper } from './styles'

export function ReportErrorScreen({
  title = 'Oh No! An Error Occurred',
  description = 'An error occurred. Our team is working hard to fix the problem.',
  Icon = ErrorIcon
}) {
  return (
    <Wrapper>
      <ComponentWrapper>
        <DesktopEmptyScreen
          icon={Icon}
          title={title}
          description={description}
        />
      </ComponentWrapper>
    </Wrapper>
  )
}

export default memo(ReportErrorScreen)
