import { createSelector } from 'reselect';
import select from '@signal/utils/select';
import createNowDateSelector from '@signal/utils/createNowDateSelector';
import { getLocation } from 'connected-react-router';
export var locationSelector = getLocation;
/**
 * This selector is not tied to any state. However, it should
 * be used anywhere we need to use the current time (e.g. generating
 * relative time labels). This selector will update that time every minute
 * (when a state change has been triggered).
 */

export var nowDateSelector = createNowDateSelector();
export var locationSearchSelector = createSelector(locationSelector, select('search'));
export var locationStateSelector = createSelector(locationSelector, select('state'));
export var locationPathnameSelector = createSelector(locationSelector, select('pathname'));
/**
 * `canGoBack` can be set to determine whether a user got to a specific route
 * via a link in the app or directly through the browser address bar.
 * @example
 * The insight details page uses this value to determine whether it can use the
 * `goBack` action from `connected-react-router` to return to the previous page
 * or if it needs to decide where to route itself, making an assumption that
 * there is now location history to return to.
 */

export var canGoBackSelector = createSelector(locationStateSelector, select('canGoBack', false));