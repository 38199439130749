import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setPageName, trackEvent } from './actions';
/**
 *
 * @param {String} name
 * @example
 * function FakePage() {
 *   usePageName('Fake Page')
 *
 *   return <Page><Container /></Page>
 * }
 */

export var usePageName = function usePageName(name) {
  var dispatch = useDispatch();
  useEffect(function () {
    dispatch(setPageName(name));
  }, [dispatch, name]);
};
/**
 *
 * @param {Object} event
 * @param {String} event.event
 * @param {String} event.category
 * @example
 * // It's recommended to use the `createEventDefinition` util to create the event object
 * // that should be passed to the `useTrackEvent` hook
 * function FakeButtonWrapper() {
 *   const handleTrackEvent = useTrackEvent(createEventDefinition('category', 'eventName'))
 *
 *   return <button onClick={handleTrackEvent} />
 * }
 */

export var useTrackEvent = function useTrackEvent(event) {
  var dispatch = useDispatch();
  var callback = useCallback(function (label) {
    return dispatch(trackEvent(event, label));
  }, [dispatch, event]);
  return callback;
};