import styled from 'styled-components'
import { getSpacingSelector } from '@signal/theme/selectors'
import ContextualPageHeader from '../../components/ContextualPageHeader'
import ContextualPageHeaderSkeleton from '../../components/ContextualPageHeader/Skeleton'

export const Wrapper = styled(ContextualPageHeader)`
  > * {
    margin: ${getSpacingSelector(3)}px 0;
  }
`

export const WrapperSkeleton = Wrapper.withComponent(
  ContextualPageHeaderSkeleton
)
