import styled from 'styled-components'
import { Form } from 'formik'
import Button from '@signal/components/Button'
import { getSpacingSelector } from '@signal/theme/selectors'

import AdForm from '../../components/AdForm'
import AdFormSkeleton from '../../components/AdForm/Skeleton'

const buttonSpacingSelector = getSpacingSelector()

export const Wrapper = styled(Form)`
  width: 100%;
  > * {
    margin-bottom: ${getSpacingSelector(6)}px;
  }
`

export const FormWrapper = styled.div``

export const AdFormStyled = styled(AdForm)`
  display: grid;
  grid-template-columns: auto auto;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > * {
    margin: 0 ${buttonSpacingSelector}px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

export const AdFormSkeletonStyled = styled(AdFormSkeleton)`
  display: grid;
  grid-template-columns: auto auto;
`

export const AdConfigurationWidgetWrapper = styled.div`
  margin-top: ${getSpacingSelector(9)}px;
`

export const SaveButton = styled(Button).attrs(() => ({
  variant: 'contained',
  color: 'primary'
}))``

export const ClearButton = styled(Button).attrs(() => ({
  variant: 'outlined'
}))``
