import React, { memo, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { push } from 'connected-react-router'
import CommonHeader from '@signal/components/CommonHeader/Component'
import { logout } from '@signal/core/auth'
import { filterManagedQPs } from '@signal/core/dashboard'
import { ActiveAccountContext } from '@signal/core/user/Context'
import getAppRedirectUrl from '@signal/utils/getAppRedirectUrl'
import isRelativeUrl from '@signal/utils/isRelativeUrl'
import UserMenuNavLinks from '../UserMenuNavLinks'
import { AppziIcon } from './styles'

// legacy menu items not supported by the menu service.
const LEGACY_MENU_ITEMS = [
  {
    title: 'Leave Feedback',
    attributes: { 'data-az-l': 'aa9e30df-1670-4e9c-b5c1-5e548f3fea03' },
    link: '#',
    icon: AppziIcon
  }
]

export function SignalHeader({ children, ...rest }) {
  const { changeActiveAccount } = useContext(ActiveAccountContext)
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location
  const search = filterManagedQPs(location.search)
  const pathWithSearch = `${pathname}${search ? `?${search}` : ''}`

  const handleAccountChange = useCallback(
    account => {
      changeActiveAccount(account)
      dispatch(push(getAppRedirectUrl(pathWithSearch, account.accountId)))
    },
    [changeActiveAccount, dispatch, pathWithSearch]
  )

  const handleLogout = useCallback(() => dispatch(logout()), [dispatch])

  const handleNavigate = useCallback(
    link => {
      if (!isRelativeUrl(link)) {
        const url = new URL(link)
        window.open(url.href)
        return
      }
      dispatch(push(`${link}${search}`))
    },
    [dispatch, search]
  )

  return (
    <CommonHeader
      hideLogo
      hideMultiAppMenu
      extraMenuItems={LEGACY_MENU_ITEMS}
      location={location}
      onAccountChange={handleAccountChange}
      onLogout={handleLogout}
      onNavigate={handleNavigate}
      UserMenuNavLinks={UserMenuNavLinks}
      {...rest}
    >
      {children}
    </CommonHeader>
  )
}

export default memo(SignalHeader)
