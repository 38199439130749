import isTest from '@signal/utils/env/isTest';
import isStorybook from '@signal/utils/env/isStorybook';
import { replaceCacheURL, useGet } from '../../fetch';
import { API_VERSION, API_URL } from '../constants';
import mockData from './mockData.json';
var forceMockData = false;
export function getSlugServiceURL(_ref) {
  var accountId = _ref.accountId,
      userId = _ref.userId;
  return "".concat(API_URL, "/v").concat(API_VERSION, "/slugs/").concat(accountId, "/").concat(userId);
} // use this function to actually use the slug data from a React component w/suspense loading state

export function useSlugService(_ref2) {
  var accountId = _ref2.accountId,
      userId = _ref2.userId,
      _ref2$useMockData = _ref2.useMockData,
      useMockData = _ref2$useMockData === void 0 ? isStorybook() || isTest() : _ref2$useMockData;
  var url = getSlugServiceURL({
    accountId: accountId,
    userId: userId
  });

  if (useMockData || forceMockData) {
    replaceCacheURL(url, '', mockData);
  }

  return useGet(url, 'useSlugService');
}
export default useSlugService;