import styled from 'styled-components'
import { getSpacingSelector } from '@signal/theme/selectors'
import PerformanceDetailView from '@signal/components/PerformanceDetailView'
import ShopperBucketIcon from '@signal/components/ShopperBucketIcon'
import Typography from '@signal/components/Typography'

const edgeMargin = getSpacingSelector(3.75)
const padding3X = getSpacingSelector(3)

export const Wrapper = styled(PerformanceDetailView)`
  padding-top: ${padding3X}px;
  padding-bottom: ${edgeMargin}px;
`

export const Icon = styled(ShopperBucketIcon)`
  margin-right: ${edgeMargin}px;
`

export const Metric = styled.div`
  margin-left: ${edgeMargin}px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
`

export const MetricValue = styled(Typography).attrs(() => ({
  variant: 'h1',
  noWrap: true
}))`
  margin-bottom: ${getSpacingSelector()}px;
`

export const MetricLabel = styled(Typography).attrs(() => ({
  variant: 'body4',
  noWrap: true
}))``

export const Description = styled(Typography).attrs(() => ({
  variant: 'subtitle2'
}))`
  margin-top: ${padding3X}px;
`
