import React, { memo } from 'react'

import AdConfigurationWidgetSkeleton from '../../components/AdConfigurationWidget/Skeleton'
import {
  AdFormSkeletonStyled,
  Wrapper,
  ButtonWrapper,
  ClearButton,
  SaveButton
} from './styles'

const AdDetailFormSkeleton = memo(props => {
  return (
    <Wrapper>
      <AdFormSkeletonStyled isUniversal={props.isUniversal} />
      <AdConfigurationWidgetSkeleton />
      <ButtonWrapper>
        <ClearButton disabled>Cancel</ClearButton>
        <SaveButton disabled>{props.submitLabel}</SaveButton>
      </ButtonWrapper>
    </Wrapper>
  )
})

AdDetailFormSkeleton.defaultProps = {
  submitLabel: 'Save ad',
  isUniversal: true
}

export default AdDetailFormSkeleton
