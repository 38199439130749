import styled from 'styled-components'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import DataGrid from '@signal/components/DataGrid'
import DataGridSkeleton from '@signal/components/DataGrid/Skeleton'
import { getSpacingSelector } from '@signal/theme/selectors'

export const Wrapper = styled.div`
  padding-bottom: ${getSpacingSelector(2.5)}px;
`

export const AppearancesDataGrid = styled(DataGrid).attrs(() => ({
  LabelProps: {
    style: {
      textTransform: 'uppercase'
    }
  },

  SectionProps: {
    style: {
      alignItems: 'center'
    }
  }
}))``

export const AppearancesDataGridSkeleton = styled(
  AppearancesDataGrid.withComponent(DataGridSkeleton)
).attrs(() => ({
  items: 2
}))``

export const SectionHeading = styled(ContentSectionHeading).attrs(() => ({
  description:
    'Below are the total number of times this vehicle appeared on Search Results Pages (SRP) and Vehicle Detail Pages (VDP)'
}))`
  margin-bottom: ${getSpacingSelector(3)}px;
`
