function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import get from 'lodash/get';
import property from 'lodash/property';
import { parse } from 'query-string';
import { createSelector } from 'reselect';
import { getLocation } from 'connected-react-router';
import isServer from '@signal/utils/env/isServer';
import getAppRedirectUrl from '@signal/utils/getAppRedirectUrl';
import { hasPermission, PERMISSION_LEVEL_WRITE, ACTIVATE_FEATURE_KEY } from '@signal/utils/permissions';
export var rootSelector = property('auth');
export var isAuthenticatingSelector = createSelector(rootSelector, property('authenticating'));
export var isAuthenticatedSelector = createSelector(rootSelector, property('authenticated'));
export var isInitializedSelector = createSelector(rootSelector, property('isInitialized'));
export var activeAccountSelector = createSelector(rootSelector, function (root) {
  return (// returns an object if on the server so react-snapshot can generate routes
    !isServer() ? get(root, 'activeAccount') : {}
  );
});
export var hasActiveAccountSelector = createSelector(activeAccountSelector, Boolean);
export var errorSelector = createSelector(rootSelector, property('error'));
export var errorMessageSelector = createSelector(errorSelector, property('message'));
export var activeAccountTokenSelector = createSelector(activeAccountSelector, property('id'));
export var activeAccountIdRawSelector = createSelector(activeAccountSelector, property('accountId'));
export var activeAccountFeaturesSelector = createSelector(activeAccountSelector, property('features'));
export var activeAccountGroupSelector = createSelector(activeAccountSelector, property('group'));
export var activeAccountSubGroupSelector = createSelector(activeAccountSelector, property('subGroup'));
export var hasActivateWriteSelector = createSelector(activeAccountFeaturesSelector, function (features) {
  return hasPermission(features, {
    feature: ACTIVATE_FEATURE_KEY,
    level: PERMISSION_LEVEL_WRITE
  });
});
export var activeAccountIdSelector = createSelector(activeAccountIdRawSelector, // returns fake ID if on the server so react-snapshot can generate routes
function (accountId) {
  return !isServer() ? accountId : 'skeleton';
});
export var activeAccountNameSelector = createSelector(activeAccountSelector, property('name'));
export var accountRedirectUrlSelector = createSelector(getLocation, activeAccountIdSelector, function (location, activeAccountId) {
  var queryParams = parse(location.search);
  var redirectUrl = queryParams.referrer || get(location, 'state.referrer') || '/';
  return getAppRedirectUrl(redirectUrl, activeAccountId);
}); // functions to create simulated redux state for data obtained by context fetcher for use with selectors

export function cookActiveAccountForSelectors(_ref) {
  var activeAccount = _ref.activeAccount;

  if (activeAccount) {
    var _activeAccount = activeAccount,
        accountName = _activeAccount.accountName,
        accountId = _activeAccount.accountId,
        rest = _objectWithoutProperties(_activeAccount, ["accountName", "accountId"]);

    activeAccount = _objectSpread({
      accountId: accountId,
      id: accountId,
      name: accountName
    }, rest);
  }

  return {
    auth: {
      activeAccount: activeAccount
    }
  };
}