import styled from 'styled-components'
import { getSpacingSelector } from '@signal/theme'

export const Wrapper = styled.div`
  padding: ${getSpacingSelector(8)}px;
  margin: ${getSpacingSelector(8)}px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Image = styled.img`
  margin: ${getSpacingSelector(2)}px;
  width: 450px;
`
