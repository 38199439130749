import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getSpacingSelector, getColorGreySelector } from '@signal/theme'
import Button from '@signal/components/Button'

export const Wrapper = styled.div``

export const FormDetailsWrapper = styled.div`
  display: flex;
`

export const ControlsSeparator = styled.div`
  width: 1px;
  background-color: ${getColorGreySelector(300)};
  margin: 0 ${getSpacingSelector(2)}px;
`

export const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const SummaryWrapper = styled.div`
  width: 240px;
`

export const ActionsWrapper = styled.div`
  margin-top: ${getSpacingSelector(2)}px;
  display: flex;
  justify-content: flex-end;
  & > *:first-child {
    margin-right: ${getSpacingSelector(2)}px;
  }
`

export const SegmentsWrapper = styled.div`
  position: relative;
`

export const SegmentLoadingOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LoadingSpinner = styled(CircularProgress)``

export const ClearButton = styled(Button).attrs(() => ({
  variant: 'outlined'
}))``
export const SaveButton = styled(Button).attrs(() => ({
  variant: 'contained',
  color: 'primary'
}))``
