var _INVENTORY_IDS_AT_LVL, _CHANNELS_IDS_AT_LVL;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/**
 * These constants represent the string representation of a `lvl`
 */


export var CHANNEL = 'Channel';
export var CONDITION = 'Condition';
export var MAKE = 'Make';
export var MODEL = 'Model';
export var VIN = 'Vin';
/**
 * The IDS_AT_LVL mappings contain a key, value mapping defined by the key being a
 * performance query `lvl` value and the value being an array of `id` names that correspond
 * to that `lvl`. E.G. at the CHANNEL lvl for channel items, the `channel` id should be present.
 *
 * It's almost a one-to-one mapping with id === lvl.toLowerCase(), however, the MAKE lvl contains
 * two related ids (make and condition) at the current time of implementation.
 *
 * @example
 * DESCRIPTIVE_AT_LVL = {
 *   [LVL_STRING_CONSTANT]: [ID1_THAT_SHUOLD_BE_PRESENT_AT_LVL, ID2_THAT_SHUOLD_BE_PRESENT_AT_LVL, ...]
 * }
 *
 */

export var INVENTORY_IDS_AT_LVL = (_INVENTORY_IDS_AT_LVL = {}, _defineProperty(_INVENTORY_IDS_AT_LVL, VIN, ['vin']), _defineProperty(_INVENTORY_IDS_AT_LVL, MODEL, ['model']), _defineProperty(_INVENTORY_IDS_AT_LVL, MAKE, ['make', 'condition']), _INVENTORY_IDS_AT_LVL);
export var CHANNELS_IDS_AT_LVL = (_CHANNELS_IDS_AT_LVL = {}, _defineProperty(_CHANNELS_IDS_AT_LVL, VIN, ['vin']), _defineProperty(_CHANNELS_IDS_AT_LVL, MODEL, ['model']), _defineProperty(_CHANNELS_IDS_AT_LVL, MAKE, ['make', 'condition']), _defineProperty(_CHANNELS_IDS_AT_LVL, CHANNEL, ['channel']), _CHANNELS_IDS_AT_LVL);