function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import isObject from 'lodash/isObject';

var mapObjectToValue = function mapObjectToValue(property) {
  if (!property || !isObject(property)) return property;
  return property.key;
};

export function transformAudienceFormData(audience) {
  return _objectSpread(_objectSpread({}, audience), {}, {
    target: _objectSpread(_objectSpread({}, audience.target), {}, {
      include: _objectSpread(_objectSpread({}, audience.target.include), {}, {
        focusLevel: mapObjectToValue(audience.target.include.focusLevel),
        focusMakeGuid: mapObjectToValue(audience.target.include.focusMakeGuid),
        focusModelGuid: mapObjectToValue(audience.target.include.focusModelGuid),
        focusTrimGuid: mapObjectToValue(audience.target.include.focusTrimGuid)
      })
    })
  });
}