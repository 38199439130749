var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled, { css } from 'styled-components';
import { colorWhiteSelector, getColorGreySelector, getSpacingSelector } from '@signal/theme/selectors';
var DEFAULT_DOT_SIZE = '8px';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n"])));
var dotMixin = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  border-radius: ", ";\n  height: ", ";\n  width: ", ";\n  margin: 0px ", "px;\n"])), DEFAULT_DOT_SIZE, DEFAULT_DOT_SIZE, DEFAULT_DOT_SIZE, getSpacingSelector(0.5));
export var ActiveDot = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  ", "\n"])), colorWhiteSelector, dotMixin);
export var InactiveDot = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  ", "\n"])), getColorGreySelector(400), dotMixin);