var _templateObject;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import { createGlobalStyle } from 'styled-components';
export var createGlobalStyles = function createGlobalStyles(rootId) {
  return createGlobalStyle(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  * {\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,\n      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;\n    text-rendering: optimizeLegibility;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    box-sizing: border-box;\n  }\n\n  html, body, #", " {\n    min-height: 100%;\n    margin: 0;\n  }\n\n  body {\n    overflow-y: auto;\n  }\n\n  #", " {\n    display: flex;\n    flex-direction: column;\n  }\n  \n"])), rootId, rootId);
};
export default createGlobalStyles('root');