var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled, { css } from 'styled-components';
import { getSpacingSelector, getColorGreySelector } from '@signal/theme/selectors';
import Typography from '../Typography';
export var Wrapper = styled.fieldset(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: none;\n  padding: 0;\n  margin: 0;\n"])));
export var FormControlWrapper = styled.div.attrs({
  className: 'sig-form-control-wrapper'
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
var controlsMixin = css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n"])));
export var ControlsColumn = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n"])), controlsMixin);
export var ControlsRow = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  flex-wrap: wrap;\n  & > .sig-form-control-wrapper {\n    margin-right: ", "px;\n  }\n"])), controlsMixin, getSpacingSelector(2));
export var Title = styled(Typography).attrs(function () {
  return {
    variant: 'h5',
    color: 'textDark',
    component: 'legend'
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top: ", "px;\n"])), getSpacingSelector(2));
export var Separator = styled.hr(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  height: 1px;\n  border: none;\n  background-color: ", ";\n  margin-top: ", "px;\n  margin-bottom: 0;\n"])), getColorGreySelector(300), getSpacingSelector(2));