import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import TableListViewBase from '@signal/components/TableListView'
import Typography from '@signal/components/Typography'
import SkeletonTypography from '@signal/components/Skeleton/Typography'
import {
  getSpacingSelector,
  paletteDividerSelector
} from '@signal/theme/selectors'

export const Wrapper = styled(Paper).attrs(() => ({
  elevation: 1
}))`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${getSpacingSelector(3)}px;
  width: 300px;
`

export const Table = styled(TableListViewBase).attrs(props => ({
  rowSpacing: getSpacingSelector(2)(props)
}))``

export const Title = styled(Typography).attrs(() => ({
  variant: 'h6',
  color: 'textDark'
}))`
  margin-top: ${getSpacingSelector(5)}px;
`
export const TitleSkeleton = styled(
  Title.withComponent(SkeletonTypography)
).attrs(() => ({
  color: 'skeletonDark',
  width: 120
}))``

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${getSpacingSelector(3)}px 0;
  border-bottom: 1px solid ${paletteDividerSelector};
`

export const Label = styled(Typography).attrs(() => ({
  variant: 'subtitle2'
}))``
export const LabelSkeleton = Label.withComponent(SkeletonTypography)

export const Value = styled(Typography).attrs(() => ({
  variant: 'subtitle2'
}))`
  float: right;
`
export const ValueSkeleton = styled(
  Value.withComponent(SkeletonTypography)
).attrs(() => ({
  color: 'skeletonDark'
}))``
