import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import componentPropType from '@signal/prop-types/component'
import DefaultLayout from '../Default'
import { Header, Content } from './styles'

export class LayoutDefaultWithPageHeader extends PureComponent {
  static propTypes = {
    /**
     * The component used for the page header node.
     * Either a string to use a DOM element or a component.
     */
    headerComponent: componentPropType.isRequired,
    /**
     * These props will be forwarded to the page header component.
     */
    headerProps: PropTypes.object,
    /**
     * These props will be forwarded to the page content component.
     */
    contentProps: PropTypes.object
  }

  render() {
    const {
      headerComponent: HeaderComponent,
      headerProps,
      contentProps,
      children,
      ...rest
    } = this.props

    return (
      <DefaultLayout {...rest}>
        <Header>
          <HeaderComponent {...rest} {...headerProps} />
        </Header>
        <Content {...contentProps}>{children}</Content>
      </DefaultLayout>
    )
  }
}

export default LayoutDefaultWithPageHeader
