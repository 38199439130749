function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createSelector } from 'reselect';
import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import capitalize from 'lodash/capitalize';
import select from '@signal/utils/select';
import { stackSelector } from '../performance/selectors';
import { constructPerformanceBreadcrumbLinks } from '../performance/utils';
import { searchSelector, performanceChannelsDetailPathSelector } from '../router/selectors';
import { CHANNELS_IDS_AT_LVL } from '../performance/constants';
export var rootStateSelector = function rootStateSelector(state) {
  return get(state, 'performanceVin');
};
export var vinSelector = function vinSelector(state, props) {
  return get(props, 'match.params.vin');
};
export var performanceVinSelector = createSelector(rootStateSelector, vinSelector, function (rootState, vin) {
  return get(rootState, vin);
});
export var isLoadingSelector = createSelector(performanceVinSelector, function (performanceVin) {
  return get(performanceVin, 'loading');
});
export var dataSelector = createSelector(performanceVinSelector, function (performanceVin) {
  return get(performanceVin, 'data');
});
export var isLoadedSelector = createSelector(dataSelector, function (data) {
  return !!data;
});
export var saleDateSelector = createSelector(dataSelector, function (data) {
  return get(data, 'saleDate');
});
export var headlineSelector = createSelector(dataSelector, function (data) {
  return get(data, 'headline');
});
export var vehicleImageSelector = createSelector(dataSelector, function (data) {
  return get(data, 'vehicleImage');
});
export var salePriceSelector = createSelector(dataSelector, function (data) {
  return get(data, 'salePrice');
});
export var purchaseLineSelector = createSelector(dataSelector, function (data) {
  return get(data, 'purchaseLine');
});
export var channelAttributionSelector = createSelector(dataSelector, function (data) {
  return get(data, 'channelAttribution');
});
export var activeChannelInfoSelector = createSelector(dataSelector, function (data) {
  return get(data, 'channelInfo');
});
export var attributionValuesSelector = createSelector(activeChannelInfoSelector, function (activeChannelInfo) {
  return get(activeChannelInfo, 'values');
});
export var totalValuesSelector = createSelector(dataSelector, function (data) {
  return get(data, 'totalValues');
});
export var attrValuesSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'attrValues');
});
export var attributedChannelsSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'channels');
});
export var channelCustomersSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'channelCustomers');
});
export var channelNameSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'channelName');
});
export var channelLogoSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'channelLogo');
});
export var channelPercentSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'channelPercent');
});
export var channelSalesValueSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'channelSalesValue');
});
export var channelSummarySelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'channelSummary');
});
export var srpViewsSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'srpViews');
});
export var vdpViewsSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'vdpViews');
});
export var totalCustomersSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'totalCustomers');
});
export var vehicleValueSelector = createSelector(channelAttributionSelector, function (channelAttribution) {
  return get(channelAttribution, 'vehicleValue');
});
export var activeChannelIdSelector = createSelector(activeChannelInfoSelector, select('channelId'));
export var pathToSaleSelector = createSelector(dataSelector, select('path', []));
export var breadcrumbSelector = createSelector(dataSelector, function (data) {
  return get(data, 'breadcrumb');
});
export var crumbsDefinitionSelector = createSelector(vinSelector, breadcrumbSelector, function (vin, breadcrumb) {
  return _objectSpread(_objectSpread({}, mapKeys(breadcrumb, function (crumb, key) {
    return capitalize(key);
  })), {}, {
    Vin: "VIN #".concat(vin)
  });
});
export var crumbsSelector = createSelector(crumbsDefinitionSelector, stackSelector, searchSelector, performanceChannelsDetailPathSelector, function (breakdownDefinition, stack, search, path) {
  return constructPerformanceBreadcrumbLinks(stack.map(function (stackItem) {
    return breakdownDefinition[stackItem];
  }).filter(Boolean), search, path, CHANNELS_IDS_AT_LVL);
});