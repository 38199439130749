/**
 * this function is a testable wrapper around React.lazy(), which is not supported (currently) in jest unit tests
 *
 * In order to use, this, you need to do the following things:
 *   1) Create a lazy-map.js in your project's root src folder which exports a getLazyMap() function
 *   2) Create a src/__mocks__/lazy-map.js which also exports a getLazyMap() function
 *   3) Add a jest.mock('./lazy-map.js') in your setupTests.js file
 *
 * The getLazyMap() functions should return objects which have the same keys.  The non-mock version should return a
 * lazy() call for each key.  The mock version should import the module and return it directly, using require.
 * For an example, see the implementation in apps/desktop/src/lazy-map.js and apps/desktop/src/__mocks__/lazy-map.js
 *
 * @param {Object} getLazyMap - the getLazyMap() function exported from lazy-map.js (or its mock)
 * @param {String} lazyKey - the key of the component to be lazy-load
 * @return {React.Component} - the lazy loaded component (or not lazy loaded, in a jest test)
 */
export function testableLazy(getLazyMap) {
  return function (lazyKey) {
    return getLazyMap()[lazyKey];
  };
}
export default testableLazy;