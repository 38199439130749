import React, { memo } from 'react'
import PropTypes from 'prop-types'
import SimpleBackdrop from '@material-ui/core/Modal/SimpleBackdrop'
import isTest from '@signal/utils/env/isTest'
import renderIf from '@signal/utils/renderIf'
import Dialog from '../Dialog'
import {
  ButtonWrapper,
  ActionButton,
  CancelButton,
  DialogBackdrop,
  DialogPaper,
  DialogWrapper,
  DismissButton,
  DismissIcon,
  MessageTypography
} from './styles'

function ResponseDialog(props) {
  const {
    action: { label, onClick },
    message,
    onCancel,
    onClose,
    open,
    ...rest
  } = props
  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropComponent={isTest() ? SimpleBackdrop : DialogBackdrop}
      PaperComponent={DialogPaper}
      showHeader={false}
      {...rest}
    >
      <DismissButton onClick={onClose}>
        <DismissIcon />
      </DismissButton>
      <DialogWrapper>
        <MessageTypography>{message}</MessageTypography>
        <ButtonWrapper>
          {renderIf(onCancel, () => (
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
          ))}
          <ActionButton onClick={onClick}>{label}</ActionButton>
        </ButtonWrapper>
      </DialogWrapper>
    </Dialog>
  )
}

ResponseDialog.propTypes = {
  /* defines the action which will be taken when clicking the action button or hitting the enter key */
  action: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }),
  /* the message to display in the modal */
  message: PropTypes.string.isRequired,
  /* if set, a cancel button will be rendered that will invoke this callback when clicked */
  onCancel: PropTypes.func,
  /* called when the dialog is dismissed with the dismiss icon or escape key */
  onClose: PropTypes.func.isRequired,
  /* determines if the dialog is displayed */
  open: PropTypes.bool.isRequired
}

export default memo(ResponseDialog)
