import React from 'react'
import AdStatsModalController from '../AdStats/ModalController'
import { useRoutePath } from '@signal/core-hooks'
import { OFFERS_UNIVERSAL } from '@signal/routes'

export default function UniversalOfferStatsModal({ match }) {
  const path = useRoutePath(OFFERS_UNIVERSAL)
  const {
    params: { offerId }
  } = match

  return <AdStatsModalController returnTo={path} adId={offerId} isUniversal />
}
