import createLoader from '@signal/utils/createLoader';
import { rootLoader } from '../app';
import { loadAllSpend, fetchAllSpendListener } from './sagas';
import { LOADER_KEY, REDUCER_KEY } from './constants';
import reducer from './reducer';
export var fetchAllSpendLoader = createLoader(LOADER_KEY, {
  load: loadAllSpend,
  listen: fetchAllSpendListener,
  injectReducers: function injectReducers(injectReducer) {
    return injectReducer(REDUCER_KEY, reducer);
  },
  dependencies: [rootLoader]
});