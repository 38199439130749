import React from 'react'
import { Switch, Route } from 'react-router'
import {
  INDUSTRY_SUB_REPORT,
  INDUSTRY_REPORT,
  INDUSTRY_REPORTS
} from '@signal/routes'
import NotFound from '../../components/NotFound'
import Header from '../../containers/IndustryReportsHeader'
import ReportView from '../dashboard/Report'
import SignalPage from '../SignalPage'

export function IndustryReportsView() {
  return (
    <SignalPage header={Header}>
      <Switch>
        <Route path={INDUSTRY_SUB_REPORT.path} exact component={ReportView} />
        <Route path={INDUSTRY_REPORT.path} exact component={ReportView} />
        <Route path={INDUSTRY_REPORTS.path} exact component={ReportView} />
        <Route component={NotFound} />
      </Switch>
    </SignalPage>
  )
}
