/**
 * cookieExists
 * @description Checks to see if a cookie exists in the dealer site context
 * @param {String} name - the name of the cookie
 * @return {Boolean} whether or not this cookie was defined on this dealer site
 */
export function cookieExists(name) {
  var nameEquals = "".concat(name, "=");
  return !!document.cookie.split('; ').find(function (cookieNameValCur) {
    return cookieNameValCur.indexOf(nameEquals) === 0;
  });
}
/**
 * readCookie
 * @description Reads a cookie from the dealer site context
 * @param {String} name - the name of the cookie
 * @return {String} the value of the cookie (or undefined, if not exist)
 */

export function readCookie(name) {
  if (name === '') {
    return undefined;
  }

  var nameEquals = "".concat(name, "=");
  return document.cookie.split('; ').reduce(function (ret, cookieNameValCur) {
    return cookieNameValCur.indexOf(nameEquals) === 0 ? decodeURI(cookieNameValCur.substr(nameEquals.length)) : ret;
  }, undefined);
}
/**
 * setCookie
 * @description Sets a cookie in the dealer site context
 * @param {Object} config - details on the cookie, including name, value, domain, and ttl (in seconds)
 */

export function setCookie(_ref) {
  var name = _ref.name,
      value = _ref.value,
      ttl = _ref.ttl,
      domain = _ref.domain;
  var cookieNameVal = "".concat(name, "=").concat(decodeURI(value));
  var cookieExpiry = '';

  if (ttl) {
    var expireTime = new Date();
    expireTime.setTime(expireTime.getTime() + ttl * 1000);
    cookieExpiry = "; expires=".concat(expireTime.toGMTString(), ";");
  }

  var domainString = '';

  if (domain) {
    domainString = "domain=".concat(domain, ";");
  }

  document.cookie = "".concat(cookieNameVal).concat(cookieExpiry, "; path=/; ").concat(domainString);
}