var matchAppRoute = function matchAppRoute(getRouteMatcher) {
  return new RegExp("^".concat(getRouteMatcher('([^/]+)')));
};

var matchAudiencesApp = matchAppRoute(function (p) {
  return "/account/".concat(p, "/audiences");
});
var matchOffersApp = matchAppRoute(function (p) {
  return "/account/".concat(p, "/offers");
});
var matchPerformanceApp = matchAppRoute(function (p) {
  return "/account/".concat(p, "/performance");
});
var matchReportsApp = matchAppRoute(function (p) {
  return "/account/".concat(p, "/reports");
});
export function getAccountRedirectUrl(url, accountId) {
  if (matchAudiencesApp.test(url)) return "/account/".concat(accountId, "/audiences");
  if (matchOffersApp.test(url)) return "/account/".concat(accountId, "/offers/overview");
  if (matchPerformanceApp.test(url)) return "/account/".concat(accountId, "/performance");
  if (matchReportsApp.test(url)) return "/account/".concat(accountId, "/reports/overview-v2");
  return '/';
}
export default getAccountRedirectUrl;