import React, { memo, useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import CommonPage from '@signal/components/CommonPage'
import { logout } from '@signal/core/auth/actions'
import { filterManagedQPs } from '@signal/core/dashboard'
import { RouterParamsType } from '@signal/core/dashboard/types'
import { ActiveAccountContext } from '@signal/core/user/Context'
import { IUserAccount } from '@signal/core/user/types'
import getAppRedirectUrl from '@signal/utils/getAppRedirectUrl'
import isRelativeUrl from '@signal/utils/isRelativeUrl'
import UserMenuNavLinks from '../containers/UserMenuNavLinks'
import { AppziIcon } from '../containers/Header/styles'

// legacy menu items not supported by the menu service.
const LEGACY_MENU_ITEMS = [
  {
    name: 'Leave Feedback',
    attributes: { 'data-az-l': 'aa9e30df-1670-4e9c-b5c1-5e548f3fea03' },
    link: '#',
    icon: AppziIcon
  }
]

type SignalPageProps = {
  children?: React.ReactNode
}

export function SignalPage(props: SignalPageProps): JSX.Element {
  const { changeActiveAccount } = useContext(ActiveAccountContext)
  const dispatch = useDispatch()
  const rawLocation = useLocation<Location>()
  // I presume I am doing something wrong by defining Location as the type for "location" in the CommonPageProps because
  // this ugly typecast was necessary but the internet did not seem to have a clear answer for what was wrong
  const location = (rawLocation as unknown) as Location
  const { accountId } = useParams<RouterParamsType>()
  const { pathname } = location
  const search = filterManagedQPs(location.search)
  const pathWithSearch = `${pathname}${search ? `?${search}` : ''}`

  const handleAccountChange = useCallback(
    (account: IUserAccount) => {
      if (changeActiveAccount) {
        changeActiveAccount(account)
      }
      dispatch(push(getAppRedirectUrl(pathWithSearch, account.accountId)))
    },
    [changeActiveAccount, dispatch, pathWithSearch]
  )

  const handleLogout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const handleNavigate = useCallback(
    (link: string) => {
      if (!isRelativeUrl(link)) {
        const url = new URL(link)
        window.open(url.href)
        return
      }
      dispatch(push(`${link}${search}`))
    },
    [dispatch, search]
  )

  return (
    <CommonPage
      accountId={accountId}
      extraMenuItems={LEGACY_MENU_ITEMS}
      location={location}
      onAccountChange={handleAccountChange}
      onLogout={handleLogout}
      onNavigate={handleNavigate}
      UserMenuNavLinks={UserMenuNavLinks}
      {...props}
    />
  )
}

export default memo(SignalPage)
