var _LABELS, _DESCRIPTIONS, _DAYS_FROM_SALE_LABEL, _DAYS_FROM_SALE_DESCR;

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { BROWSERS, BUYERS, RESEARCHERS } from '../root-constants';
/**
 * Buckets
 */
// Order matters

export var BUCKETS = [RESEARCHERS, BROWSERS, BUYERS];
export var LABELS = (_LABELS = {}, _defineProperty(_LABELS, RESEARCHERS, 'Researchers'), _defineProperty(_LABELS, BROWSERS, 'Browsers'), _defineProperty(_LABELS, BUYERS, 'Buyers'), _LABELS);
export var DESCRIPTIONS = (_DESCRIPTIONS = {}, _defineProperty(_DESCRIPTIONS, RESEARCHERS, 'Shoppers whose focus and activity is across several makes or models. Shoppers at this stage are typically at the beginning of their consumer journey.'), _defineProperty(_DESCRIPTIONS, BROWSERS, 'Shoppers whose focus has shifted to a model level. Shoppers at this stage have typically narrowed their search at a model level.'), _defineProperty(_DESCRIPTIONS, BUYERS, 'Shoppers whose focus is primarily at the VIN level. Shoppers at this stage have typically narrowed in on a specific vehicle are closest to an actual purchase.'), _DESCRIPTIONS);
export var DAYS_FROM_SALE_LABELS = (_DAYS_FROM_SALE_LABEL = {}, _defineProperty(_DAYS_FROM_SALE_LABEL, RESEARCHERS, '30+'), _defineProperty(_DAYS_FROM_SALE_LABEL, BROWSERS, '14-28'), _defineProperty(_DAYS_FROM_SALE_LABEL, BUYERS, '7-13'), _DAYS_FROM_SALE_LABEL);
export var DAYS_FROM_SALE_DESCRIPTIONS = (_DAYS_FROM_SALE_DESCR = {}, _defineProperty(_DAYS_FROM_SALE_DESCR, RESEARCHERS, '30d+ from sale'), _defineProperty(_DAYS_FROM_SALE_DESCR, BROWSERS, '14 - 28d from sale'), _defineProperty(_DAYS_FROM_SALE_DESCR, BUYERS, 'Less than 2 wks from sale'), _DAYS_FROM_SALE_DESCR);