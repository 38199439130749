import loadable from '@signal/hoc/loadable'
import Skeleton from '../../components/AdListGroup/Skeleton'

export default loadable({
  loader: () => import(/* webpackChunkName: "ad-list-group" */ './component'),
  dataLoader: () => import(/* webpackChunkName: "lib-ad" */ '@signal/core/ad'),
  loadData: ({ adsLoader, adDetailsLoader }, loaderInterface) =>
    Promise.all([adsLoader(loaderInterface), adDetailsLoader(loaderInterface)]),
  loading: Skeleton
})
