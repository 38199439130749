var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getSpacingSelector } from '@signal/theme';
import DesktopEmptyScreen from '../DesktopEmptyScreen';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  height: calc(100vh - 60px);\n  justify-content: center;\n  align-items: center;\n"])));
export var EmptyScreen = styled(DesktopEmptyScreen)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background: none;\n"])));
export var ComponentWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: ", "px;\n  margin: auto;\n  max-width: 600px;\n"])), getSpacingSelector(5));