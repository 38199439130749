import React from 'react'
import ContextualPageHeaderSkeleton from '../../components/ContextualPageHeader/Skeleton'
import ChannelsDetailSummarySkeleton from '../ChannelsDetailSummary/Skeleton'

export function ChannelsDetailHeaderSkeleton() {
  return (
    <ContextualPageHeaderSkeleton showCrumbs showAlertMessage showDatePicker>
      <ChannelsDetailSummarySkeleton />
    </ContextualPageHeaderSkeleton>
  )
}

export default ChannelsDetailHeaderSkeleton
