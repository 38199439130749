import React from 'react'
import StrawberryMenu from '@signal/components/Icons/StrawberryMenu'
import { Button } from './styles'
export const ReportFilterSkeleton = function ReportFilterSkeleton() {
  return (
    <div>
      <Button disabled>
        <StrawberryMenu className="strawberry-menu" />
      </Button>
    </div>
  )
}

export default ReportFilterSkeleton
