import loadable from '@signal/hoc/loadable'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "channels-detail-header" */ './component'),
  dataLoader: () =>
    import(
      /* webpackChunkName: "lib-performance-query" */ '@signal/core/performanceQuery'
    ),
  loadData: ({ performanceQueryDataLoader }, loaderInterface) =>
    performanceQueryDataLoader(loaderInterface),
  loading: Skeleton
})
