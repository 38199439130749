var _createRequestReducer, _initialNonRequestSta, _createReducer, _createReducer2, _combineReducers;

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { combineReducers } from 'redux';
import { createReducer } from 'redux-act';
import { createRequestReducerV2 } from '@signal/utils/createRequestReducer';
import { AD_LIST_REDUCERS, AD_DETAILS_REDUCERS, AD_PHOTOS_REDUCERS, ACCOUNT_AD_PHOTOS_REDUCERS, NON_REQUEST_REDUCERS, UNIVERSAL_OFFERS_LIST_REDUCER, SHOW_AD_CREATED, PRIOR_AD, NO_AD } from './constants';
import { adsActions, adDetailsActions, adPhotosActions, accountAdPhotosActions, accountAdPhotoUploadActions, accountAdPhotoDeleteActions, adCreateSuccess, adStatsActions, dismissAdCreatedModal, setPriorAd, universalOffersActions } from './actions';
export var adList = createRequestReducerV2(adsActions);
export var adDetails = createRequestReducerV2(adDetailsActions);
export var adPhotos = createRequestReducerV2(adPhotosActions);
export var accountAdPhotos = createRequestReducerV2(accountAdPhotosActions, (_createRequestReducer = {}, _defineProperty(_createRequestReducer, accountAdPhotosActions.success, function (state) {
  var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return _objectSpread(_objectSpread({}, state), {}, {
    data: payload.map(function (image) {
      return _objectSpread(_objectSpread({}, image), {}, {
        editable: true
      });
    })
  });
}), _defineProperty(_createRequestReducer, accountAdPhotoUploadActions.request, function (state, payload) {
  var _state$data = state.data,
      data = _state$data === void 0 ? [] : _state$data;
  return _objectSpread(_objectSpread({}, state), {}, {
    data: [_objectSpread(_objectSpread({}, payload), {}, {
      loading: true
    })].concat(_toConsumableArray(data))
  });
}), _defineProperty(_createRequestReducer, accountAdPhotoUploadActions.failure, function (state, payload) {
  var tempId = payload.tempId,
      error = _objectWithoutProperties(payload, ["tempId"]);

  var data = state.data.map(function (photo) {
    if (photo.galleryPhotoId === tempId) {
      return _objectSpread(_objectSpread({}, photo), {}, {
        error: error,
        loading: false,
        editable: true
      });
    }

    return photo;
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    data: data
  });
}), _defineProperty(_createRequestReducer, accountAdPhotoUploadActions.success, function (state, payload) {
  var tempId = payload.tempId,
      image = _objectWithoutProperties(payload, ["tempId"]);

  var data = state.data.map(function (photo) {
    if (photo.galleryPhotoId === tempId) {
      return _objectSpread(_objectSpread({}, image), {}, {
        loading: false,
        editable: true
      });
    }

    return photo;
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    data: data
  });
}), _defineProperty(_createRequestReducer, accountAdPhotoDeleteActions.request, function (state, _ref) {
  var idToDelete = _ref.galleryPhotoId;
  var data = state.data.filter(function (_ref2) {
    var galleryPhotoId = _ref2.galleryPhotoId;
    return galleryPhotoId !== idToDelete;
  });
  return _objectSpread(_objectSpread({}, state), {}, {
    data: data
  });
}), _createRequestReducer));
export var universalOffersList = createRequestReducerV2(universalOffersActions);
export var initialNonRequestState = (_initialNonRequestSta = {}, _defineProperty(_initialNonRequestSta, SHOW_AD_CREATED, NO_AD), _defineProperty(_initialNonRequestSta, PRIOR_AD, NO_AD), _initialNonRequestSta);
export var nonRequestReducers = createReducer((_createReducer = {}, _defineProperty(_createReducer, adCreateSuccess, function (state, _ref3) {
  var adId = _ref3.id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_AD_CREATED, adId));
}), _defineProperty(_createReducer, dismissAdCreatedModal, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, SHOW_AD_CREATED, NO_AD));
}), _defineProperty(_createReducer, setPriorAd, function (state, priorAd) {
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, PRIOR_AD, priorAd));
}), _createReducer), initialNonRequestState);
export var statsInitialState = {
  isLoading: false,
  isLoaded: false,
  data: null,
  error: null
};
export var statsReducer = createReducer((_createReducer2 = {}, _defineProperty(_createReducer2, adStatsActions.load, function (state, _ref4) {
  var id = _ref4.id;
  return _defineProperty({}, id, statsInitialState);
}), _defineProperty(_createReducer2, adStatsActions.request, function (state, _ref6) {
  var id = _ref6.id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, _objectSpread(_objectSpread({}, state[id]), {}, {
    isLoaded: false,
    isLoading: true
  })));
}), _defineProperty(_createReducer2, adStatsActions.success, function (state, _ref7) {
  var data = _ref7.data,
      id = _ref7.id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, {
    isLoading: false,
    isLoaded: true,
    error: null,
    data: data
  }));
}), _defineProperty(_createReducer2, adStatsActions.failure, function (state, _ref8) {
  var error = _ref8.error,
      id = _ref8.id;
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, id, _objectSpread(_objectSpread({}, statsInitialState), {}, {
    isLoadeed: true,
    error: error
  })));
}), _createReducer2), {});
export default combineReducers((_combineReducers = {}, _defineProperty(_combineReducers, AD_LIST_REDUCERS, adList), _defineProperty(_combineReducers, AD_DETAILS_REDUCERS, adDetails), _defineProperty(_combineReducers, AD_PHOTOS_REDUCERS, adPhotos), _defineProperty(_combineReducers, ACCOUNT_AD_PHOTOS_REDUCERS, accountAdPhotos), _defineProperty(_combineReducers, NON_REQUEST_REDUCERS, nonRequestReducers), _defineProperty(_combineReducers, UNIVERSAL_OFFERS_LIST_REDUCER, universalOffersList), _defineProperty(_combineReducers, "stats", statsReducer), _combineReducers));