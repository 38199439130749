function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createSelector } from 'reselect';
import { activeAccountGroupSelector, activeAccountSubGroupSelector } from '@signal/core/auth/selectors';
import { accountsByIdSelector } from '@signal/core/user/selectors';
export var dealerGroupSelector = createSelector(accountsByIdSelector, activeAccountGroupSelector, activeAccountSubGroupSelector, function (accountDict, group, subgroup) {
  if (!accountDict) {
    return [];
  }

  var allGroups = [group, subgroup];
  return allGroups.filter(Boolean).map(function (_ref) {
    var accountId = _ref.accountId;
    return accountDict[accountId];
  }).filter(Boolean).map(function (account) {
    var level = account.level;
    return _objectSpread({
      levelDisplay: "Dealer ".concat(level === 'subGroup' ? 'Sub ' : '', "Group")
    }, account);
  });
});