import conformsTo from 'lodash/conformsTo';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import invariant from 'invariant';
/**
 * A properly configured redux store.
 *
 * @global
 * @typedef {Object} Store
 * @property {function} dispatch - redux dispatch method
 * @property {function} subscribe - redux subscribe method (enhanced w/ batching)
 * @property {function} getState - redux getState method
 * @property {function} replaceReducer - redux replaceReducer method
 * @property {function} runSaga - redux-saga runSaga method
 * @property {function} injectedReducers - registry of injected reducers
 * @property {function} injectedSagas - registry of injected sagas
 */

/**
 * Validate the shape of redux store.
 *
 * @param {Store} store - Redux store to validate
 * @throws {Error} - Will throw with message "(lib/utils...) injectors: Expected
 * a valid redux store" if store is invalid.
 */

export default function checkStore(store) {
  var shape = {
    dispatch: isFunction,
    subscribe: isFunction,
    getState: isFunction,
    replaceReducer: isFunction,
    runSaga: isFunction,
    injectedReducers: isObject,
    injectedSagas: isObject
  };
  invariant(conformsTo(store, shape), '(lib/utils...) injectors: Expected a valid redux store');
}