import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import hoistNonReactStatics from 'hoist-non-react-statics'
import {
  setSummaryExpanded,
  createSummaryExpandedSelector
} from '@signal/core/local'

const summaryLoaded = {}

export const withExpandable = key => WrappedComponent => {
  class ComponentWithExpandable extends PureComponent {
    static WrappedComponent = WrappedComponent
    static displayName = `withExpandable(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`

    state = {
      isLoaded: false
    }

    static getDerivedStateFromProps(props, state) {
      const isLoaded = summaryLoaded[key]
      if (isLoaded !== state.isLoaded) {
        return { isLoaded }
      }
      return null
    }

    componentDidMount() {
      if (!summaryLoaded[key]) {
        this.timeout = setTimeout(() => {
          summaryLoaded[key] = true
          // must set state to trigger re-render
          this.setState({ isMounted: true })
        }, 1000)
      }
    }

    componentWillUnmount() {
      if (this.timeout) clearTimeout(this.timeout)
    }

    handleExpandedChange = (evt, expanded) => {
      this.props.setSummaryExpanded(key, expanded)
    }

    render() {
      const {
        setChannelsExpanded,
        channelsExpandableReady,
        ...rest
      } = this.props
      const { isLoaded } = this.state

      return (
        <WrappedComponent
          isExpandable={isLoaded}
          onChange={this.handleExpandedChange}
          {...rest}
        />
      )
    }
  }

  hoistNonReactStatics(ComponentWithExpandable, WrappedComponent)

  const mapStateToProps = createStructuredSelector({
    expanded: createSummaryExpandedSelector(key)
  })

  const mapDispatchToProps = {
    setSummaryExpanded
  }

  return connect(mapStateToProps, mapDispatchToProps)(ComponentWithExpandable)
}

export default withExpandable
