import React from 'react'
import getStringFormatter from '@signal/utils/getStringFormatter'
import {
  Wrapper,
  Icon,
  Metric,
  MetricValue,
  MetricLabel,
  Description
} from './styles'

export default function ShopperBucketDetailHeader({
  bucket,
  description,
  metric
}) {
  return (
    <Wrapper
      leftContent={<Icon bucket={bucket.toLowerCase()} />}
      rightContent={
        <Metric>
          <MetricValue>
            {getStringFormatter(metric.format)(metric.value)}
          </MetricValue>
          <MetricLabel>{metric.label}</MetricLabel>
        </Metric>
      }
      title={bucket}
    >
      <Description>{description}</Description>
    </Wrapper>
  )
}
