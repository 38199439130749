import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import partial from 'lodash/partial';
import set from 'lodash/set';
import reduce from 'lodash/reduce';
import { getSignalFontVariantName } from '../../utils';
var KEYS_TO_CONVERT_TO_REM = ['fontSize', 'lineHeight'];
/**
 * Converts the values of appropriate keys in a style object to `rem`.
 * @param {Function} pxToRem - function that converts a pixel size (expressed as
 * an integer) to it's equivalent `rem` value (expressed as a string)
 * @param {Object} style - a JSS style object
 * @returns {Object} - a JSS style object where appropriate values have been
 * converted to `rem` string values
 */

function pxToRemAdaptor(pxToRem, style) {
  /**
   * Reducer function used to convert the values of appropriate keys in a style
   * object to `rem` recursively
   */
  var convertValuesToRem = function convertValuesToRem(result, value, property) {
    /**
     * Sstyle object values will be converted recursively. This is import, for
     * example, if a CSS selector is use as a property key, for example if "&&"
     * is used to increase CSS specificity
     */
    if (isObject(value)) {
      var conversionResult = reduce(value, convertValuesToRem, {});
      return set(result, property, conversionResult);
    } // Convert the value to rem if the property is one of the keys to convert


    if (KEYS_TO_CONVERT_TO_REM.includes(property)) {
      var remValue = isNumber(value) ? pxToRem(value) : value;
      return set(result, property, remValue);
    } // Otherwise, leave the value as is


    return set(result, property, value);
  }; // Convert the keys of the input style object and return the result


  return reduce(style, convertValuesToRem, {});
}
/**
 * This function formats the custom typography overrides by doinga few things.
 * 1) It determines whether typography is a function or not and if it is it calls
 * it with the overrides. 2) Then the keys of the overrides are prefixed. 3) Lastly
 * certain keys are mapped to rem from pixel values.
 * @param {Oject|Function} typography - The typography overrides
 * @param {Object} overrides - All merged overrides (base with input)
 * @param {Function} pxToRem
 */


export function formatTypography(typography, overrides, pxToRem) {
  typography = isFunction(typography) ? typography(overrides, pxToRem) : typography;
  var convertPxToRem = partial(pxToRemAdaptor, pxToRem);
  /**
   * Below the typography definitions keys and values are both mapped.
   * Keys: The keys are modified and a prefix is added to the keys (variant names)
   * so that there are no clashes with muiTypography.
   * Values: For each key in the `keysToConvertToRem` array the values are converted,
   * if they're numbers to an rem string.
   */

  return reduce(typography, function (result, style, variant) {
    return set(result, getSignalFontVariantName(variant), convertPxToRem(style));
  }, {});
}
export function formatMuiTypography(typography, _ref) {
  var palette = _ref.palette; // This line is duplicating the functionality found at the link below.
  // https://github.com/mui-org/material-ui/blob/9410e6edaf5ecc9ae0049e4a14f29b66c42fd910/packages/material-ui/src/styles/createTypography.js#L24

  return isFunction(typography) ? typography(palette) : typography;
}