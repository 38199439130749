import { useEffect } from 'react';
import { useTheme } from '../Context';
export default function useBackgroundColor() {
  var background = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  var resetOnUnmount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  var _useTheme = useTheme(),
      setValues = _useTheme.setValues;

  useEffect(function () {
    setValues({
      background: background
    });

    if (resetOnUnmount) {
      return function () {
        return setValues({
          background: 'default'
        });
      };
    }
  }, [background, setValues, resetOnUnmount]);
}