import React from 'react'
import AdStatsModalController from '../AdStats/ModalController'
import { useRoutePath } from '@signal/core-hooks'
import { OFFERS_PERSONALIZED } from '@signal/routes'

export default function PersonalizedOfferStatsModal({ match }) {
  const path = useRoutePath(OFFERS_PERSONALIZED)
  const {
    params: { offerId }
  } = match

  return <AdStatsModalController returnTo={path} adId={offerId} />
}
