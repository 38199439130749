import styled, { css } from 'styled-components'
import {
  paletteDividerSelector,
  getColorBackgroundSelector
} from '@signal/theme'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'

export const Wrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: ${getColorBackgroundSelector('paper')};
  max-height: 500px;
  min-height: 300px;
  height: 60vw;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
`

export const Labels = styled.div.attrs(() => ({
  className: 'sig-shoppers-funnel-chart-labels'
}))`
  display: flex;
`

export const Label = styled(Typography).attrs(() => ({
  variant: 'h6',
  color: 'textDark'
}))`
  flex: 1;
  text-align: center;
  padding: 20px 10px 10px 10px;
`

export const Descriptions = styled.div`
  display: flex;
`

export const Description = styled(Typography)`
  flex: 1;
  text-align: center;
  padding: 12px;
`
export const DescriptionSkeleton = styled(
  Description.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonPrimary',
  width: 130
}))`
  flex: none;
  display: inline-block;
`
export const LabelSkeletonWrapper = styled.div`
  flex: 1;
  text-align: center;
`
export const LabelSkeleton = styled(
  Label.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonPrimary',
  width: 130
}))`
  flex: none;
  display: inline-block;
`

export const ChartWrapper = styled.div`
  flex: 1;
  min-height: 0;
`

export const VerticalGridLine = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${({ y }) => `${y * 100}%`};
  top: 0;
  height: 100%;
  width: ${100 / 3}%;
  ${props =>
    props.noBorder
      ? ''
      : css`
          border-left: solid 1px ${paletteDividerSelector};
        `}
`

export const TooltipShadow = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  transition: opacity 275ms ease-out;
  opacity: ${props => (props.tooltipActive ? 0.2 : 0)};
  pointer-events: none;
`
