import React from 'react'
import Typography from '@signal/components/Typography'
import Content from '@signal/layouts/Content'
import { Wrapper, Image } from './styles'

export default function NotFound() {
  return (
    <Content>
      <Wrapper>
        <Typography variant="h2">Oh no!</Typography>
        <Typography variant="h3"> This page has gone missing.</Typography>
        <Image src="https://purecars-signal.imgix.net/onboarding-tour/tour-page-1@2x.png" />
      </Wrapper>
    </Content>
  )
}
