var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import AppMenuIconBase from '../Icons/AppMenu';
import { getColorBackgroundSelector, getColorTextSelector, drawerZIndexSelector } from '@signal/theme/selectors';
export var MenuWrapper = styled(Paper)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  min-width: 280px;\n  max-width: 310px;\n  z-index: ", ";\n"])), drawerZIndexSelector);
export var MenuButton = styled(IconButton)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  height: 36px;\n  width: 36px;\n  padding: 0;\n"])), getColorBackgroundSelector());
export var MenuComponentWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var AppMenuIcon = styled(AppMenuIconBase)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), getColorTextSelector());