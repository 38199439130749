import React from 'react'
import { Table, RowBase, CellBase, HeaderCellBase } from './styles'

const withProps = (Component, baseProps) => props => (
  <Component {...baseProps} {...props} />
)

export function TableListView({
  rowComponent: RowComponentProp,
  cellComponent: CellComponentProp,
  headerCellComponent: HeaderCellComponentProp,
  ...rest
}) {
  const RowComponent = withProps(RowBase, {
    elevation: 0,
    component: RowComponentProp
  })
  const CellComponent = withProps(CellBase, {
    elevation: 0,
    component: CellComponentProp
  })
  const HeaderCellComponent = withProps(HeaderCellBase, {
    elevation: 0,
    component: HeaderCellComponentProp
  })
  return (
    <Table
      rowComponent={RowComponent}
      cellComponent={CellComponent}
      headerCellComponent={HeaderCellComponent}
      {...rest}
    />
  )
}

export default TableListView
