import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import {
  Table,
  TitleSkeleton,
  LabelSkeleton,
  ValueSkeleton,
  Row
} from './styles'

const columnInfo = [
  {
    key: 'label',
    component: ({ index }) => (
      <LabelSkeleton width={index % 2 === 0 ? 120 : 90} />
    )
  },
  {
    key: 'value',
    component: ({ index }) => <ValueSkeleton width={40} />
  }
]

const cellRenderer = (item, { component: Component }) => <Component {...item} />

export function TableViewModalSkeleton({ items }) {
  const rows = times(items, i => ({ key: i, index: i }))
  return (
    <Fragment>
      <TitleSkeleton />
      <Table
        rows={rows}
        renderRowCell={cellRenderer}
        columnInfo={columnInfo}
        rowComponent={Row}
      />
    </Fragment>
  )
}

TableViewModalSkeleton.propTypes = {
  items: PropTypes.number
}

TableViewModalSkeleton.defaultProps = {
  items: 6
}

export default TableViewModalSkeleton
