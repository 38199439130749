import React from 'react'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import TimelineGroupSkeleton from './../TimelineGroup/Skeleton'
import {
  Timeline,
  Wrapper,
  GroupTitle,
  GroupContent,
  GroupWrapper,
  MarkerSkeleton
} from './styles'

const TimelineView = () => (
  <Timeline verticalLineOffset={170}>
    {() => (
      <React.Fragment>
        <Wrapper>
          <GroupTitle>
            <TypographySkeleton variant="h3" />
          </GroupTitle>
          <MarkerSkeleton />
          <GroupWrapper>
            <GroupContent>
              <TimelineGroupSkeleton />
              <TimelineGroupSkeleton />
            </GroupContent>
          </GroupWrapper>
        </Wrapper>
        <Wrapper>
          <GroupTitle>
            <TypographySkeleton variant="h3" />
          </GroupTitle>
          <MarkerSkeleton />
          <GroupWrapper>
            <GroupContent>
              <TimelineGroupSkeleton />
              <TimelineGroupSkeleton />
            </GroupContent>
          </GroupWrapper>
        </Wrapper>
        <Wrapper>
          <GroupTitle>
            <TypographySkeleton variant="h3" />
          </GroupTitle>
          <MarkerSkeleton />
          <GroupWrapper>
            <GroupContent>
              <TimelineGroupSkeleton />
              <TimelineGroupSkeleton />
            </GroupContent>
          </GroupWrapper>
        </Wrapper>
      </React.Fragment>
    )}
  </Timeline>
)

export default TimelineView
