import loadable from '@signal/hoc/loadable'
import combinedError from '@signal/hoc/combinedError'
import ErrorScreenApi from '@signal/containers/ErrorScreenApi'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "shoppers-heading" */ './component'),
  dataLoader: () =>
    import(/* webpackChunkName: "lib-shoppers" */ '@signal/core/shoppers'),
  loading: Skeleton,
  error: combinedError(ErrorScreenApi)
})
