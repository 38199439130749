/**
 * Pendo function constants
 */
export var PENDO_CHECK_INTERVAL_MSEC = 100;
export var PENDO_MAX_WAIT_FOR_INIT = 4000;
export var PENDO_MAX_WAIT_FOR_CALL = PENDO_MAX_WAIT_FOR_INIT * 2;
/**
 * Generic Email Domains
 */

export var EMAIL_DOMAINS_GENERIC = ['@gmail', '@outlook', '@yahoo', '@icloud', '@comcast', '@aol', '@hotmail']; // Internal Email Domain

export var EMAIL_DOMAIN_INTERNAL = '@purecars.com'; // Account Types

export var USER_TYPE_EXTERNAL = 'External';
export var USER_TYPE_INTERNAL = 'Internal'; // USER Domain Bucket Names

export var USER_DOMAIN_PURECARS = 'PureCars';
export var USER_DOMAIN_GENERIC = 'Generic';