import React, { Fragment } from 'react'
import PathToSaleSkeleton from '../../components/PathToSale/Skeleton'
import {
  PurchaseActivityWrapper,
  PurchaseMarker,
  PurchaseTypography,
  UserActivityHeading
} from './styles'

export const ChannelsVinUserActivitySkeleton = () => (
  <Fragment>
    <UserActivityHeading />
    <PurchaseActivityWrapper>
      <PurchaseMarker />
      <PurchaseTypography>Activity related to this VIN</PurchaseTypography>
    </PurchaseActivityWrapper>
    <PathToSaleSkeleton />
  </Fragment>
)

export default ChannelsVinUserActivitySkeleton
