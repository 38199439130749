import React from 'react'
import isEqual from 'lodash/isEqual'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import RetryableErrorBoundaryBase from '@signal/components/RetryableErrorBoundaryBase'
import { UnauthorizedError, UnknownReportError } from '@signal/utils/errors'
import ReportErrorScreen from '../../../components/dashboard/ReportErrorScreen'

export class ReportErrorBoundary extends RetryableErrorBoundaryBase {
  static getDerivedStateFromProps(props, state) {
    const reportChanged = !isEqual(props.reportContext, state.reportContext)
    return {
      error: reportChanged ? undefined : state.error,
      errorCount: reportChanged ? 0 : state.errorCount,
      reportContext: props.reportContext
    }
  }

  getErrorMarkup = () => {
    const { error, errorCount } = this.state
    if (error instanceof UnknownReportError) {
      const { report } = error
      let description = 'Unknown Report'
      if (report) description = `${description}: "${report}"`
      return (
        <ReportErrorScreen
          key={errorCount}
          title="Oops, something went wrong"
          description={description}
          Icon={ReportProblemIcon}
        />
      )
    } else {
      return <ReportErrorScreen key={errorCount} />
    }
  }

  shouldCatchError = error => !(error instanceof UnauthorizedError)
}

export default ReportErrorBoundary
