import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import merge from 'lodash/merge'
import MuiDialog from '@material-ui/core/Dialog'
import SimpleBackdrop from '@material-ui/core/Modal/SimpleBackdrop'
import isTest from '@signal/utils/env/isTest'
import renderIf from '@signal/utils/renderIf'
import {
  Backdrop,
  TitleWrapper,
  TitlePlaceholder,
  Title,
  Header,
  DismissButton,
  DismissIcon,
  TitleSkeleton
} from './styles'

/**
 * Defaults will be deeply merged with the provided props.
 */
const defaultProps = {
  fullWidth: true,
  maxWidth: 'sm',
  BackdropComponent: Backdrop,
  PaperProps: {
    elevation: 0
  }
}

const Dialog = props => {
  const {
    children,
    title,
    titleSkeleton,
    onClose,
    closeHref,
    showHeader,
    ...rest
  } = props

  const closeProps = closeHref
    ? {
        component: Link,
        to: closeHref
      }
    : {
        onClick: onClose
      }

  const dialogProps = merge({}, defaultProps, rest)
  if (isTest()) {
    // the snapshot echos all kinds of painful details about the BackdropComponent for some reason, including
    // randomized styled-component ids.  Don't apply it in unit test mode
    dialogProps.BackdropComponent = SimpleBackdrop
  }

  return (
    <MuiDialog {...dialogProps}>
      {renderIf(showHeader, () => (
        <Header>
          {renderIf(
            title || titleSkeleton,
            () => (
              <TitleWrapper>
                {renderIf(
                  !titleSkeleton,
                  () => (
                    <Title>{title}</Title>
                  ),
                  <TitleSkeleton />
                )}
              </TitleWrapper>
            ),
            <TitlePlaceholder />
          )}
          <DismissButton {...closeProps}>
            <DismissIcon />
          </DismissButton>
        </Header>
      ))}
      {children}
    </MuiDialog>
  )
}

Dialog.defaultProps = {
  showHeader: true
}

Dialog.propTypes = {
  /**
   * See https://material-ui.com/api/dialog/ for all spreadable props
   */

  /**
   * The string to be used for the title.
   */
  title: PropTypes.string,

  /**
   * A boolean that denotes whether to show the title as a skeleton or not.
   */
  titleSkeleton: PropTypes.bool,

  /**
   * An href that, if provided, is passed to the close button (and the close
   * button is turned into a link).
   */
  closeHref: PropTypes.string,

  /**
   * The callback for the close button - only used if closeHref is not supplied.
   */
  onClose: PropTypes.func
}

Dialog.displayName = 'Dialog'

export default memo(Dialog)
