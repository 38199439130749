import React, { memo } from 'react'
import times from 'lodash/times'
import {
  Wrapper,
  StatsList,
  StatListItem,
  StatLabelSkeleton,
  StatMetricSkeleton
} from './styles'

const AdStatsListSkeleton = memo(() => {
  return (
    <Wrapper>
      <StatsList>
        {times(5, i => (
          <StatListItem key={i} divider={true}>
            <StatLabelSkeleton />
            <StatMetricSkeleton />
          </StatListItem>
        ))}
      </StatsList>
    </Wrapper>
  )
})
AdStatsListSkeleton.displayName = 'AdStatsListSkeleton'

export default AdStatsListSkeleton
