var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Typography from '../Typography';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n"])));
export var CenterColumn = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-grow: 1;\n"])));
export var Title = styled(Typography).attrs(function () {
  return {
    variant: 'h3',
    color: 'textDark'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));