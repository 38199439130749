import { API_VERSION, API_URL } from './constants';
import { useRouteMatch } from 'react-router-dom';
import get from 'lodash/get';
import { useGet } from '../fetch';
export function getCustomReportManifestURL(accountId, dashboardId) {
  return "".concat(API_URL, "/v").concat(API_VERSION, "/custom/").concat(accountId, "/report/").concat(dashboardId);
}
export function useCustomReport(dashboardId) {
  var match = useRouteMatch();
  var accountId = get(match, 'params.accountId');
  var url = getCustomReportManifestURL(accountId, dashboardId);
  return useGet(url, 'useCustomReport');
}
export default useCustomReport;