export function createMetaPayload(eventType, eventPayload) {
  return {
    analytics: {
      eventType: eventType,
      eventPayload: eventPayload
    }
  };
}
export function createEventDefinition(category, name) {
  return {
    category: category,
    event: name
  };
}