var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { appBarZIndexSelector } from '@signal/theme/selectors';
import ContentBase from '../Content';
import { HEADER_CONTENT_HEIGHT } from './../constants';
export var StaticPositionedHeader = styled.header(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  height: ", ";\n"])), HEADER_CONTENT_HEIGHT);
export var StickyPositionedHeader = styled(StaticPositionedHeader)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: sticky;\n  top: 0;\n  z-index: ", ";\n\n  @media print {\n    position: absolute;\n  }\n"])), appBarZIndexSelector);
export var Content = styled(ContentBase)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: 100%;\n"])));