var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import isNumber from 'lodash/isNumber';
import { getColorPrimarySelector, getPxToRemSelector } from '@signal/theme';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  height: ", ";\n  min-height: ", ";\n  width: ", ";\n  min-width: ", ";\n  background-color: ", ";\n  border: solid 2px\n    ", ";\n"])), function (_ref) {
  var height = _ref.height,
      theme = _ref.theme;
  return isNumber(height) ? getPxToRemSelector(height)(theme) : height;
}, function (_ref2) {
  var height = _ref2.height,
      theme = _ref2.theme;
  return isNumber(height) ? getPxToRemSelector(height)(theme) : height;
}, function (_ref3) {
  var width = _ref3.width,
      theme = _ref3.theme;
  return isNumber(width) ? getPxToRemSelector(width)(theme) : width;
}, function (_ref4) {
  var height = _ref4.height,
      theme = _ref4.theme;
  return isNumber(height) ? getPxToRemSelector(height)(theme) : height;
}, function (_ref5) {
  var theme = _ref5.theme,
      background = _ref5.background;
  return background || getColorPrimarySelector('light')(theme);
}, function (_ref6) {
  var theme = _ref6.theme,
      foreground = _ref6.foreground;
  return foreground || getColorPrimarySelector('dark')(theme);
});
export var Icon = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), function (_ref7) {
  var theme = _ref7.theme,
      foreground = _ref7.foreground;
  return foreground || getColorPrimarySelector('dark')(theme);
});