import add from 'lodash/add';
import { createThemeSelector, createVariableThemeSelectorCreator } from './utils';
export var constantsSelector = createThemeSelector(function (theme) {
  return theme.constants;
});
export var getConstantSelector = createVariableThemeSelectorCreator(function (name) {
  return createThemeSelector(constantsSelector, function (constants) {
    return constants[name];
  });
});
export var bottomNavigationHeightSelector = getConstantSelector('BOTTOM_NAVIGATION_HEIGHT');
export var bottomNavigationHeightLandscapeSelector = getConstantSelector('BOTTOM_NAVIGATION_HEIGHT_LANDSCAPE');
export var maxPageWidthSelector = getConstantSelector('MAX_PAGE_WIDTH');
/* NavigationBar */

export var navigationBarBorderWidthSelector = getConstantSelector('NAVIGATION_BAR_BORDER_WIDTH');
export var navigationBarHeightSelector = getConstantSelector('NAVIGATION_BAR_HEIGHT');
export var navigationBarHeightTabletSelector = getConstantSelector('NAVIGATION_BAR_HEIGHT_TABLET');
export var navigationBarHeightLandscapeSelector = getConstantSelector('NAVIGATION_BAR_HEIGHT_LANDSCAPE');
export var navigationBarOuterHeightSelector = createThemeSelector(navigationBarHeightSelector, navigationBarBorderWidthSelector, add);
export var navigationBarOuterHeightTabletSelector = createThemeSelector(navigationBarHeightTabletSelector, navigationBarBorderWidthSelector, add);
export var navigationBarOuterHeightLandscapeSelector = createThemeSelector(navigationBarHeightLandscapeSelector, navigationBarBorderWidthSelector, add);
/* ContentSectionHeading */

export var contentSectionHeadingTitleTypographyVariantSelector = getConstantSelector('CONTENT_SECTION_HEADING_TITLE_TYPOGRAPHY_VARIANT');
export var contentSectionHeadingTitleSpacingBottomSelector = getConstantSelector('CONTENT_SECTION_HEADING_TITLE_SPACING_BOTTOM');
/* Button Spacing */

export var buttonPaddingHorizontalSelector = getConstantSelector('BUTTON_PADDING_HORIZONTAL');
export var buttonPaddingVerticalSelector = getConstantSelector('BUTTON_PADDING_VERTICAL');