import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Toastr from '@signal/components/Toastr'
import { userIdSelector } from '@signal/core/user'
import testableLazy from '@signal/test-utils/testableLazy'
import renderIf from '@signal/utils/renderIf'
import Suspense from '@signal/utils/Suspense'
import { getLazyMap } from '../../../lazy-map'
import ReportErrorBoundary from './ErrorBoundary'
import ReportSkeleton from './Skeleton'

const Report = testableLazy(getLazyMap)('report')

export function ReportLoadable(props) {
  const { pathname, search } = useLocation()
  const userId = useSelector(userIdSelector)
  return (
    <Suspense fallback={<ReportSkeleton />}>
      {renderIf(
        userId, // TODO: if we fetch user data using SWR instead of redux-saga we can avoid this renderIf
        () => (
          <ReportErrorBoundary routeReportContext={{ pathname, search }}>
            <Toastr>
              <Report {...props} />
            </Toastr>
          </ReportErrorBoundary>
        ),
        () => (
          <ReportSkeleton />
        )
      )}
    </Suspense>
  )
}
