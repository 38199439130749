import { createSelector } from 'reselect';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { getColorGreySelector, colorWhiteSelector } from '@signal/theme'; // The legacy palette still uses old key names. They should be eventually
// removed in favor of the keys prefixed with `skeleton`

export var legacyPalette = {
  default: {
    primary: 'rgba(80, 80, 80, 0.2)',
    secondary: 'rgba(80, 80, 80, 0.5)'
  },
  inverse: {
    primary: 'rgba(255, 255, 255, 0.3)',
    secondary: 'rgba(255, 255, 255, 0.8)'
  }
};
export var createSkeletonSelector = function createSkeletonSelector(colorSelector, alpha) {
  return createSelector(colorSelector, function (color) {
    return fade(color, alpha);
  });
};
export var createSkeletonColorSelectors = function createSkeletonColorSelectors(_ref) {
  var colorSelector = _ref.colorSelector,
      alphaPrimary = _ref.alphaPrimary,
      alphaSecondary = _ref.alphaSecondary;
  return {
    skeletonDark: createSkeletonSelector(colorSelector, alphaPrimary),
    skeletonPrimary: createSkeletonSelector(colorSelector, alphaSecondary)
  };
};
export var palette = {
  default: createSkeletonColorSelectors({
    colorSelector: getColorGreySelector('dark'),
    alphaPrimary: 0.5,
    alphaSecondary: 0.2
  }),
  inverse: createSkeletonColorSelectors({
    colorSelector: colorWhiteSelector,
    alphaPrimary: 0.8,
    alphaSecondary: 0.3
  })
};