import styled from 'styled-components'
import {
  getSpacingSelector,
  getColorGreySelector
} from '@signal/theme/selectors'
import ConstrainedImage from '@signal/components/ConstrainedImage'
import DonutChart from '@signal/components/DonutChart'
import DonutChartSkeleton from '@signal/components/DonutChart/Skeleton'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import PerformanceDetailView from '@signal/components/PerformanceDetailView'

export const Wrapper = styled(PerformanceDetailView)`
  align-items: center;
  padding: 30px 0;
`

export const TitleSkeleton = styled(TypographySkeleton).attrs(() => ({
  variant: 'h3',
  width: 200,
  color: 'skeletonDark'
}))``

export const Subtitle = styled(Typography).attrs(() => ({
  variant: 'body2'
}))`
  margin-top: 5px;
`

export const SubtitleSkeleton = styled(
  Subtitle.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 150
}))``

export const Image = styled(ConstrainedImage)`
  width: 100%;
  height: auto;
  justify-self: center;
  width: 97px;
  margin-right: ${getSpacingSelector(5)}px;
`

export const MetricsWrapper = styled.div`
  display: flex;
  margin-top: ${getSpacingSelector(2)}px;
  flex-wrap: wrap;
`

export const EngagementScoreDonut = styled(DonutChart).attrs(() => ({
  size: 88,
  thickness: 2
}))`
  margin-left: ${getSpacingSelector(5)}px;
`
export const EngagementScoreDonutSkeleton = EngagementScoreDonut.withComponent(
  DonutChartSkeleton
)

export const MetricItem = styled(Typography).attrs(() => ({
  variant: 'body3',
  color: 'textSecondary',
  noWrap: true
}))`
  border-right: 1px solid ${getColorGreySelector(500)};
  padding-right: 10px;
  margin-right: 10px;

  &:last-child {
    border-right: none;
  }
`
export const MetricItemSkeleton = styled(
  MetricItem.withComponent(TypographySkeleton)
).attrs(() => ({
  width: 100,
  color: 'skeletonPrimary'
}))``
