import React, { memo } from 'react'
import UserInfo from '@signal/containers/UserInfo'
import { PAGE_ACCOUNTS } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import Header from '../../containers/EmptyHeader'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import AccountPicker from '@signal/containers/AccountPicker'
import ContextualPageHeader from '../../components/ContextualPageHeader'
import {
  PageContent,
  Wrapper,
  AccountPickerLabel,
  UserInfoWrapper
} from './styles'

export const AccountsPage = memo(props => {
  usePageTrack(PAGE_ACCOUNTS)

  return (
    <LayoutDefaultWithPageHeader
      navbarInWrapper
      navbar={Header}
      headerComponent={ContextualPageHeader}
      headerProps={{ crumbs: ['Login', 'Account Selection'] }}
      wrapperComponent={PageContent}
    >
      <Wrapper>
        <UserInfoWrapper>
          <UserInfo />
        </UserInfoWrapper>
        <AccountPickerLabel>Select An Account</AccountPickerLabel>
        <AccountPicker />
      </Wrapper>
    </LayoutDefaultWithPageHeader>
  )
})

AccountsPage.displayName = 'AccountsPage'

export default AccountsPage
