import isStorybook from '@signal/utils/env/isStorybook';
import isTest from '@signal/utils/env/isTest';
import useSWRWithCache from '../contextFetch/hooks';
import { replaceCacheURL } from '../contextFetch/utils';
import { API_VERSION, API_URL } from './constants';
import mockData from './mockData.json';
import { hydrateServiceMenuIcons } from './utils';
var DEFAULT_MENU_ICON_PROPS = {
  color: 'primary'
};
export function getMenuServiceURL(_ref) {
  var accountId = _ref.accountId;
  return "".concat(API_URL, "/v").concat(API_VERSION, "/items/").concat(accountId);
} // use this function to actually use the menu data from a React component w/suspense loading state

export function useMenuService(_ref2) {
  var accountId = _ref2.accountId,
      baseUrl = _ref2.baseUrl,
      _ref2$iconProps = _ref2.iconProps,
      iconProps = _ref2$iconProps === void 0 ? DEFAULT_MENU_ICON_PROPS : _ref2$iconProps,
      _ref2$useMockData = _ref2.useMockData,
      useMockData = _ref2$useMockData === void 0 ? isStorybook() || isTest() : _ref2$useMockData;
  var url = getMenuServiceURL({
    accountId: accountId
  });

  if (useMockData) {
    var mockDataForAccount = JSON.parse(JSON.stringify(mockData).replace(/:accountId/g, accountId));
    replaceCacheURL(url, '', mockDataForAccount);
  }

  var rawMenu = useSWRWithCache({
    url: url,
    context: 'useMenuService'
  });
  return rawMenu && hydrateServiceMenuIcons(rawMenu.items, iconProps, baseUrl);
}
export { default as MenuServiceLoader } from './Loader';
export default useMenuService;