import set from 'lodash/set';
import { parse } from 'query-string';
var AB_PREFIX = 'ab_';
var AB_PREFIX_LENGTH = AB_PREFIX.length;
/**
 * Attempts to exact ab-testing related parameters from the given query parameters
 * @param {Object} queryParams - the query parameters from the URL, which may contain a/b testing parameters
 * @return {Object} - all the query parameters related to a/b testing, with the ab prefix removed
 */

export function extractABTestParams(queryParams) {
  return Object.keys(queryParams).filter(function (paramCur) {
    return paramCur.startsWith(AB_PREFIX);
  }).reduce(function (params, keyCur) {
    return set(params, keyCur.substr(AB_PREFIX_LENGTH), queryParams[keyCur]);
  }, {});
}
export var AB_TEST_PARAMS = extractABTestParams(parse(window.location.search));
export default AB_TEST_PARAMS;