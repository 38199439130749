import styled from 'styled-components'
import {
  breakpointTabletSelector,
  breakpointMobileLandscapeSelector,
  navigationBarHeightSelector,
  navigationBarHeightTabletSelector,
  navigationBarHeightLandscapeSelector
} from '@signal/theme/selectors'
import Toolbar from '../../layouts/Toolbar'

export const FilterToolbar = styled(Toolbar)`
  top: ${navigationBarHeightSelector}px;

  ${breakpointTabletSelector} {
    top: ${navigationBarHeightTabletSelector}px;
  }

  ${breakpointMobileLandscapeSelector} {
    top: ${navigationBarHeightLandscapeSelector}px;
  }
`
