import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HelpIcon from '@material-ui/icons/Help'
import Section from '@signal/layouts/Section'
import Content from '@signal/layouts/Content'
import { PAGE_OFFERS_OVERVIEW, APP_OFFERS } from '@signal/analytics'
import {
  OFFERS_UNIVERSAL_CREATE,
  OFFERS_PERSONALIZED_CREATE
} from '@signal/routes'
import { hasActivateWriteSelector } from '@signal/core/auth'
import { OFFERS_ARTICLE_URL } from '@signal/core/helpdesk'
import { usePageTrack, useRoutePath } from '@signal/core-hooks'
import ExternalLink from '@signal/components/ExternalLink'
import Typography from '@signal/components/Typography'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import CreateAdButton from '../../../containers/CreateAdButton'
import UniversalOffersList from '../../../containers/UniversalOffersList'
import PersonalizedAdsList from '../../../containers/PersonalizedAdsList'
import { ActivateTour } from '../../../containers/OnboardingTour'
import { beginActivateTour } from '@signal/core/local'
import { TitleWrapper, HelpButton } from './styles'

export default function OffersOverview() {
  const universalOfferCreatePath = useRoutePath(OFFERS_UNIVERSAL_CREATE)
  const personalizedOfferCreatePath = useRoutePath(OFFERS_PERSONALIZED_CREATE)
  usePageTrack(PAGE_OFFERS_OVERVIEW, APP_OFFERS)
  const dispatch = useDispatch()
  const showTour = useCallback(() => {
    dispatch(beginActivateTour())
  }, [dispatch])

  // check features to see if the user has write access in order to create / edit offers
  const hasWriteAccess = useSelector(hasActivateWriteSelector)

  return (
    <Content component="main" animateIn>
      <ActivateTour />
      <Section>
        <ContentSectionHeading
          title={
            <TitleWrapper>
              <Typography variant="h3" color="textDark">
                Active Offers
              </Typography>
              <HelpButton
                color="primary"
                aria-label="upload picture"
                component="span"
                size="small"
                onClick={showTour}
              >
                <HelpIcon fontSize="small" color="primary" />
              </HelpButton>
            </TitleWrapper>
          }
          description={
            <Typography variant="subtitle2">
              Create and manage offers for your website. Offers are promotions
              that run on your website. Create an offer for all shoppers or run
              specific promotions for custom audiences.{' '}
              <ExternalLink to={OFFERS_ARTICLE_URL}>
                Learn more about how offers work
              </ExternalLink>
            </Typography>
          }
        />
      </Section>
      <Section>
        <UniversalOffersList
          title="Universal Offer"
          hasWriteAccess={hasWriteAccess}
          description="An offer that targets every shopper who is not in a custom audience. Only one offer can be active during scheduled dates."
          statuses={['active']}
          emptyMessage="There is no active universal offer."
          rightContent={
            hasWriteAccess ? (
              <CreateAdButton to={universalOfferCreatePath}>
                Create a universal offer
              </CreateAdButton>
            ) : null
          }
        />
      </Section>
      <Section>
        <PersonalizedAdsList
          hasWriteAccess={hasWriteAccess}
          description="Offers that target specific shoppers within a custom audience."
          title="Personalized Offers"
          statuses={['active']}
          emptyMessage="There are no active personalized offers."
          rightContent={
            hasWriteAccess ? (
              <CreateAdButton to={personalizedOfferCreatePath}>
                Create a personalized offer
              </CreateAdButton>
            ) : null
          }
        />
      </Section>
      <Section justify="center" />
    </Content>
  )
}
