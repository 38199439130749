function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import select from '@signal/utils/select';
import { OFFERS_PERSONALIZED_EDIT } from '@signal/routes';
import { createRouteParamsSelector, adsPathSelector } from '../router';
import { REDUCER_KEY, AD_LIST_REDUCERS, AD_DETAILS_REDUCERS, AD_PHOTOS_REDUCERS, ACCOUNT_AD_PHOTOS_REDUCERS, NON_REQUEST_REDUCERS, UNIVERSAL_OFFERS_LIST_REDUCER, SHOW_AD_CREATED, PRIOR_AD } from './constants';
export var rootSelector = select(REDUCER_KEY);
var ENABLE_MOCK_DATA = false;

var mockListReducer = function mockListReducer() {
  return {
    isLoading: false,
    isLoaded: true,
    data: [{
      id: '1',
      outputName: 'Test Ad 1',
      status: 'active',
      startDate: '2019-01-10T20:12:54.588Z',
      endDate: '2019-01-25T20:12:54.588Z',
      updatedAt: '2019-01-14T20:12:54.588Z'
    }, {
      id: '2',
      outputName: 'Test Ad 2',
      status: 'active',
      startDate: '2019-02-01T20:12:54.588Z',
      endDate: '2019-02-12T20:12:54.588Z',
      updatedAt: '2019-01-17T20:12:54.588Z'
    }]
  };
};

export var adListSelector = ENABLE_MOCK_DATA ? mockListReducer : createSelector(rootSelector, select(AD_LIST_REDUCERS));
export var adListLoadingSelector = createSelector(adListSelector, select('isLoading'));
export var adListLoadedSelector = createSelector(adListSelector, select('isLoaded'));
export var adListDataSelector = createSelector(adListSelector, select('data'));
export var adDetailsSelector = createSelector(rootSelector, select(AD_DETAILS_REDUCERS));
export var adDetailsLoadedSelector = createSelector(adDetailsSelector, select('loaded'));
export var adDetailsLoadingSelector = createSelector(adDetailsSelector, select('loading'));
export var adDetailsDataSelector = createSelector(adDetailsSelector, select('data'));
export var adDetailsNameSelector = createSelector(adDetailsDataSelector, select('name'));
export var adDetailsRouteParamsSelector = createRouteParamsSelector(OFFERS_PERSONALIZED_EDIT);
export var adDetailsIdParamSelector = createSelector(adDetailsRouteParamsSelector, function (_, props) {
  return props && props.offerId;
}, function (_ref, propsOfferId) {
  var offerId = _ref.offerId;
  var id = propsOfferId || offerId;
  if (isNil(id) || id === 'create') return null;
  return id;
});
export var adPhotosSelector = createSelector(rootSelector, select(AD_PHOTOS_REDUCERS));
export var accountAdPhotosSelector = createSelector(rootSelector, select(ACCOUNT_AD_PHOTOS_REDUCERS));
export var adPhotosLoadingSelector = createSelector(adPhotosSelector, select('loading'));
export var adPhotosLoadedSelector = createSelector(adPhotosSelector, select('loaded'));
export var adPhotosDataSelector = createSelector(adPhotosSelector, select('data'));
export var accountAdPhotosDataSelector = createSelector(accountAdPhotosSelector, select('data'));
export var photoGalleryList = createSelector(adPhotosDataSelector, accountAdPhotosDataSelector, function () {
  var defaultPhotos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var accountPhotos = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  if (defaultPhotos === null) {
    defaultPhotos = [];
  }

  if (accountPhotos === null) {
    accountPhotos = [];
  }

  return [].concat(_toConsumableArray(accountPhotos), _toConsumableArray(defaultPhotos));
});
export var nonRequestSelector = createSelector(rootSelector, select(NON_REQUEST_REDUCERS));
export var adsBreadcrumbSelector = createSelector(adsPathSelector, function (to) {
  return {
    label: 'Offers',
    to: to
  };
});
export var priorAdSelector = createSelector(nonRequestSelector, select(PRIOR_AD));
export var showAdCreatedSelector = createSelector(nonRequestSelector, select(SHOW_AD_CREATED));
export var statsRootSelector = createSelector(rootSelector, select('stats'));
export var adStatsStateSelector = createSelector(function (state, props) {
  return get(props, 'id');
}, statsRootSelector, function (id, stats) {
  return get(stats, id, {});
});
export var adStatsLoadingSelector = createSelector(adStatsStateSelector, select('isLoading'));
export var adStatsSelector = createSelector(adStatsStateSelector, select('data', {}));
export var adStatsLoadedSelector = createSelector(adStatsStateSelector, select('isLoaded'));
export var universalOffersListSelector = createSelector(rootSelector, select(UNIVERSAL_OFFERS_LIST_REDUCER));
export var universalOffersListDataSelector = createSelector(universalOffersListSelector, select('data'));
export var universalOffersListIsLoadedSelector = createSelector(universalOffersListSelector, select('isLoaded'));
export var universalOffersListIsLoadingSelector = createSelector(universalOffersListSelector, select('isLoading'));