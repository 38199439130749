import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Icon from '@signal/components/Icons/Selected'
import styled from 'styled-components'
import {
  getColorTextSelector,
  getColorBackgroundSelector,
  getColorPrimarySelector,
  getSpacingSelector
} from '@signal/theme/selectors'

const Invalid = styled(Icon).attrs(() => ({
  size: 24
}))`
  background-color: ${getColorBackgroundSelector('paper')};
  border-radius: 50%;
  padding: ${getSpacingSelector(0.5)}px;
  margin-right: ${getSpacingSelector()}px;
  color: ${getColorTextSelector()};
  margin-bottom: 0.35em;
`

const Valid = styled(Invalid)`
  background-color: ${getColorPrimarySelector()};
`

export const FormControlValidationIcon = ({ valid }) => {
  return valid ? <Valid /> : <Invalid />
}

FormControlValidationIcon.propTypes = {
  valid: PropTypes.bool
}

FormControlValidationIcon.displayName = 'FormControlValidationIcon'

FormControlValidationIcon.defaultProps = {
  valid: false
}

export default memo(FormControlValidationIcon)
