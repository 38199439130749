import styled from 'styled-components'
import Typography from '@signal/components/Typography'
import SkeletonTypography from '@signal/components/Skeleton/Typography'
import { getColorGreySelector, getSpacingSelector } from '@signal/theme'

export const Title = styled(Typography).attrs(() => ({
  variant: 'body1',
  color: 'textDark'
}))`
  margin-bottom: ${getSpacingSelector(2)}px;
`
export const TitleSkeleton = styled(
  Title.withComponent(SkeletonTypography)
).attrs(() => ({
  color: 'skeletonDark',
  width: 150
}))``

export const Label = styled(Typography).attrs(() => ({
  variant: 'body4',
  color: 'textPrimary'
}))``

export const LabelSkeleton = styled(
  Label.withComponent(SkeletonTypography)
).attrs(() => ({
  color: 'skeletonPrimary',
  width: 80
}))``

export const Content = styled(Typography).attrs(() => ({
  variant: 'subtitle2',
  color: 'textPrimary'
}))``

export const ContentSkeleton = styled(
  Content.withComponent(SkeletonTypography)
).attrs(() => ({
  color: 'skeletonPrimary',
  width: 200
}))``

export const Group = styled.div`
  border-bottom: 1px solid ${getColorGreySelector(300)};
  padding-bottom: ${getSpacingSelector(4)}px;
  margin-bottom: ${getSpacingSelector(4)}px;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

export const Definition = styled.div`
  margin-bottom: ${getSpacingSelector(2)}px;
  &:last-child {
    margin-bottom: 0;
  }
`
