import times from 'lodash/times';
import { decomposeColor, recomposeColor } from '@material-ui/core/styles/colorManipulator';
/**
 * this function inverts the given color
 * @param {string} - color - the color string to invert, in RGB format (#rrggbb, rgb(r,g,b) or rgba(r,g,b,a)
 * @return {string} - the inverted color, as an rgb() or rgba() string
 */

export function invertColor(color) {
  var invertedColor = decomposeColor(color);

  if (invertedColor.type.includes('rgb')) {
    times(3).forEach(function (i) {
      invertedColor.values[i] = 255 - invertedColor.values[i];
    });
  }

  return recomposeColor(invertedColor);
}
export default invertColor;