import styled from 'styled-components'
import Content from '@signal/layouts/Content'
import {
  getColorBackgroundSelector,
  getSpacingSelector
} from '@signal/theme/selectors'

export const Wrapper = styled(Content)`
  background: ${getColorBackgroundSelector('paper')};
  padding: ${getSpacingSelector(5)}px 0;
`
