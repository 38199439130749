import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import {
  paletteDividerSelector,
  getColorBackgroundSelector
} from '@signal/theme/selectors'
import { constrainToMaxPageWidth, clearfix } from '@signal/theme/mixins'
import BreadcrumbsBase from '@signal/components/Breadcrumbs'
import BreadcrumbSkeleton from '@signal/components/Breadcrumbs/Skeleton'
import {
  breadcrumbHeightSelector,
  breadcrumbBorderWidthSelector
} from '../../theme/selectors'

export const ContentWrapper = styled(Paper).attrs(() => ({
  elevation: 1,
  square: true
}))`
  ${clearfix}
`

export const ConstrainWidth = styled.div`
  ${constrainToMaxPageWidth}
`

export const BreadcrumbsWrapper = styled.div`
  background: ${getColorBackgroundSelector('paper')};
  border-bottom: ${breadcrumbBorderWidthSelector}px solid
    ${paletteDividerSelector};
`

export const BreadcrumbsContent = styled.div`
  ${constrainToMaxPageWidth}
  height: ${breadcrumbHeightSelector}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Breadcrumbs = styled(BreadcrumbsBase)`
  display: flex;
  align-items: center;
`
export const BreadcrumbsSkeleton = Breadcrumbs.withComponent(BreadcrumbSkeleton)
