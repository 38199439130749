var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n"])));
export var Svg = styled.svg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n"])));