import React, { memo } from 'react'
import { PAGE_SHOPPERS_BUCKET, APP_ATTRIBUTION } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import Header from '../../containers/AttributionHeader'
import MostActiveShoppers from '../../containers/MostActiveShoppers'
import ShoppersBucketPageHeader from '../../containers/ShoppersBucketPageHeader'
import { PageWrapper } from '../styles'

export const PerformanceShoppersBucketPage = memo(props => {
  usePageTrack(PAGE_SHOPPERS_BUCKET, APP_ATTRIBUTION)

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      headerComponent={ShoppersBucketPageHeader}
      match={props.match}
    >
      <PageWrapper>
        <MostActiveShoppers {...props} noCounLimit />
      </PageWrapper>
    </LayoutDefaultWithPageHeader>
  )
})

PerformanceShoppersBucketPage.displayName = 'PerformanceShoppersBucketPage'

export default PerformanceShoppersBucketPage
