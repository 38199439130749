import { createSelector } from 'reselect'
import {
  shoppersBucketBreadcrumbLabelSelector,
  activeBucketDaysFromSaleLabelSelector
} from '@signal/core/shoppersBucket/selectors'
import { shoppersBreadcrumbSelector } from '@signal/core/shoppers/selectors'

export const crumbsSelector = createSelector(
  shoppersBreadcrumbSelector,
  shoppersBucketBreadcrumbLabelSelector,
  (shopperCrumb, bucketLabel) => [shopperCrumb, bucketLabel]
)

export const bucketMetricSelector = createSelector(
  activeBucketDaysFromSaleLabelSelector,
  value => ({
    value,
    format: 'String',
    label: 'Days from Sale'
  })
)
