import React from 'react'
import { SkeletonWrapper, SkeletonDescription } from './styles'

export default function AdConfigurationWidgetSkeleton() {
  return (
    <SkeletonWrapper>
      <SkeletonDescription>
        Select an Audience and an offer template to preview and configure your
        offer.
      </SkeletonDescription>
    </SkeletonWrapper>
  )
}
