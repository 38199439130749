import React from 'react'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import Button from '@signal/components/Button'
import ChannelsDrilldownTableSkeleton from '../../components/ChannelsDrilldownTable/Skeleton'
import { Wrapper, Footer } from './styles'

export const OverviewTopChannelsSkeleton = () => (
  <Wrapper>
    <ContentSectionHeading title="Top Channel Types" />
    <ChannelsDrilldownTableSkeleton showAvatar />
    <Footer>
      <Button variant="contained" color="primary" disabled>
        View Channels
      </Button>
    </Footer>
  </Wrapper>
)

export default OverviewTopChannelsSkeleton
