function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createAction } from 'redux-act';
import createRequestActions, { REQUEST_ACTION_TYPES } from '@signal/utils/createRequestActions';
import { AUDIENCE_AUDIENCES, AUDIENCE_SEGMENTS, AUDIENCE_DETAILS, AUDIENCE_SAVE, AUDIENCE_DELETE, AUDIENCE_RESET_FORM, INVOKE_AUDIENCE_CREATED, DISMISS_AUDIENCE_CREATED } from './constants';
import { transformAudienceFormData } from './utils';
/**
 * Actions
 */

export var audienceResetForm = createAction(AUDIENCE_RESET_FORM);
/**
 * Actions for the audiences API lifecycle.
 * @type {RequestActions}
 */

export var audienceSaveActions = createRequestActions(AUDIENCE_SAVE, _defineProperty({}, REQUEST_ACTION_TYPES.LOAD, function (defineActionReducers) {
  return defineActionReducers(function (_ref) {
    var id = _ref.id,
        audience = _ref.audience;
    return {
      id: id,
      audience: transformAudienceFormData(audience)
    };
  });
}));
var audienceSaveLoad = audienceSaveActions.load,
    audienceSaveRequest = audienceSaveActions.request,
    audienceSaveSuccess = audienceSaveActions.success,
    audienceSaveFailure = audienceSaveActions.failure;
export { audienceSaveLoad, audienceSaveRequest, audienceSaveSuccess, audienceSaveFailure };
/**
 * Actions for the audiences API lifecycle.
 * @type {RequestActions}
 */

export var audienceSegmentsActions = createRequestActions(AUDIENCE_SEGMENTS, _defineProperty({}, REQUEST_ACTION_TYPES.LOAD, function (defineActionReducers) {
  return defineActionReducers(function (_ref2) {
    var id = _ref2.id,
        audience = _ref2.audience;
    return {
      id: id,
      audience: transformAudienceFormData(audience)
    };
  });
}));
var audienceSegmentsLoad = audienceSegmentsActions.load,
    audienceSegmentsRequest = audienceSegmentsActions.request,
    audienceSegmentsSuccess = audienceSegmentsActions.success,
    audienceSegmentsFailure = audienceSegmentsActions.failure;
export { audienceSegmentsLoad, audienceSegmentsRequest, audienceSegmentsSuccess, audienceSegmentsFailure };
/**
 * Actions for the audiences API lifecycle.
 * @type {RequestActions}
 */

export var audiencesActions = createRequestActions(AUDIENCE_AUDIENCES);
var audiencesLoad = audiencesActions.load,
    audiencesRequest = audiencesActions.request,
    audiencesSuccess = audiencesActions.success,
    audiencesFailure = audiencesActions.failure;
export { audiencesLoad, audiencesRequest, audiencesSuccess, audiencesFailure };
export var audiencesDeleteActions = createRequestActions(AUDIENCE_DELETE);
var audiencesDeleteLoad = audiencesDeleteActions.load,
    audiencesDeleteRequest = audiencesDeleteActions.request,
    audiencesDeleteSuccess = audiencesDeleteActions.success,
    audiencesDeleteFailure = audiencesDeleteActions.failure;
export { audiencesDeleteLoad, audiencesDeleteRequest, audiencesDeleteSuccess, audiencesDeleteFailure };
/**
 * Actions for the audience details API lifecycle.
 * @type {RequestActions}
 */

export var audienceDetailsActions = createRequestActions(AUDIENCE_DETAILS);
var audienceDetailsLoad = audienceDetailsActions.load,
    audienceDetailsRequest = audienceDetailsActions.request,
    audienceDetailsSuccess = audienceDetailsActions.success,
    audienceDetailsFailure = audienceDetailsActions.failure;
export { audienceDetailsLoad, audienceDetailsRequest, audienceDetailsSuccess, audienceDetailsFailure };
/**
 * Actions for managing Audience created dialog
 */

export var invokeAudienceCreatedModal = createAction(INVOKE_AUDIENCE_CREATED);
export var dismissAudienceCreatedModal = createAction(DISMISS_AUDIENCE_CREATED);