var _SHOPPER_FOCUS_TYPE_C, _SHOPPER_FOCUS_TYPE_C2, _SHOPPER_FOCUS_TYPE_L;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { COLOR_PRIMARY, COLOR_PRIMARY_LIGHT, COLOR_PRIMARY_DARK } from '@signal/theme';
import { getColorPrimarySelector } from '@signal/theme/selectors';
import reduce from 'lodash/reduce';
import { BROAD_FOCUS, MODEL_FOCUS, VIN_FOCUS } from '../root-constants'; // Order is important

export var FOCUS_TYPES = [BROAD_FOCUS, MODEL_FOCUS, VIN_FOCUS];
export var SHOPPER_FOCUS_TYPE_COLORS = (_SHOPPER_FOCUS_TYPE_C = {}, _defineProperty(_SHOPPER_FOCUS_TYPE_C, BROAD_FOCUS, COLOR_PRIMARY_DARK), _defineProperty(_SHOPPER_FOCUS_TYPE_C, MODEL_FOCUS, COLOR_PRIMARY), _defineProperty(_SHOPPER_FOCUS_TYPE_C, VIN_FOCUS, COLOR_PRIMARY_LIGHT), _SHOPPER_FOCUS_TYPE_C);
export var SHOPPER_FOCUS_TYPE_COLOR_SELECTORS = (_SHOPPER_FOCUS_TYPE_C2 = {}, _defineProperty(_SHOPPER_FOCUS_TYPE_C2, BROAD_FOCUS, getColorPrimarySelector('dark')), _defineProperty(_SHOPPER_FOCUS_TYPE_C2, MODEL_FOCUS, getColorPrimarySelector('main')), _defineProperty(_SHOPPER_FOCUS_TYPE_C2, VIN_FOCUS, getColorPrimarySelector('light')), _SHOPPER_FOCUS_TYPE_C2);
export var getShopperFocusTypeColors = function getShopperFocusTypeColors(theme) {
  return reduce(SHOPPER_FOCUS_TYPE_COLOR_SELECTORS, function (shopperColorSet, colorSelector, shopperType) {
    return _objectSpread(_objectSpread({}, shopperColorSet), {}, _defineProperty({}, shopperType, colorSelector(theme)));
  }, {});
};
export var SHOPPER_FOCUS_TYPE_LABELS = (_SHOPPER_FOCUS_TYPE_L = {}, _defineProperty(_SHOPPER_FOCUS_TYPE_L, BROAD_FOCUS, 'Broad Focus'), _defineProperty(_SHOPPER_FOCUS_TYPE_L, MODEL_FOCUS, 'Model Focus'), _defineProperty(_SHOPPER_FOCUS_TYPE_L, VIN_FOCUS, 'VIN Focus'), _SHOPPER_FOCUS_TYPE_L);
export var DEFAULT_TIME_RANGE_LABEL = 'Last 30 days';