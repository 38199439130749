import React, { Fragment } from 'react'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import PathToSaleSkeleton from '../../components/PathToSale/Skeleton'
import { TITLE, DESCRIPTION } from './constants'

export const ShopperDetailsActivitySkeleton = () => (
  <Fragment>
    <ContentSectionHeading title={TITLE} description={DESCRIPTION} />
    <PathToSaleSkeleton />
  </Fragment>
)

export default ShopperDetailsActivitySkeleton
