import PropTypes from 'prop-types';
import componentPropType from '@signal/prop-types/component';
export var menuListItemsPropType = PropTypes.arrayOf(PropTypes.shape({
  title: PropTypes.string,
  name: PropTypes.string,
  icon: componentPropType,
  defaultDividers: PropTypes.bool,
  divider: PropTypes.bool,
  link: PropTypes.string,
  TextComponent: componentPropType,
  subMenuItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    name: PropTypes.string,
    link: PropTypes.string
  }))
}));