import React from 'react'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import Button from '@signal/components/Button'
import ShoppersBucketsDetailsSkeleton from '../../components/ShoppersBucketsDetails/Skeleton'
import { Wrapper, Footer, TimestampSkeleton } from './styles'

export const OverviewShopperActivitySkeleton = () => (
  <Wrapper>
    <ContentSectionHeading title="Shopper Activity" />
    <ShoppersBucketsDetailsSkeleton />
    <Footer>
      <TimestampSkeleton />
      <Button variant="contained" color="primary" disabled>
        View Shoppers
      </Button>
    </Footer>
  </Wrapper>
)

export default OverviewShopperActivitySkeleton
