import React from 'react'
import { Route } from 'react-router-dom'
import Section from '@signal/layouts/Section'
import Content from '@signal/layouts/Content'
import { OFFERS_UNIVERSAL_STATS } from '@signal/routes'
import { OFFERS_UNIVERSAL_CREATE } from '@signal/routes'
import { hasActivateWriteSelector } from '@signal/core/auth'
import { useSelector } from 'react-redux'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import Typography from '@signal/components/Typography'
import CreateAdButton from '../../../containers/CreateAdButton'
import UniversalOffersList from '../../../containers/UniversalOffersList'
import { PAGE_OFFERS_UNIVERSAL, APP_OFFERS } from '@signal/analytics'
import { usePageTrack, useRoutePath } from '@signal/core-hooks'
import UniversalOfferStatsModal from '../../../containers/UniversalOfferStatsModal'

export default function UniversalOffers() {
  const universalOfferCreatePath = useRoutePath(OFFERS_UNIVERSAL_CREATE)

  usePageTrack(PAGE_OFFERS_UNIVERSAL, APP_OFFERS)
  // check features to see if the user has write access in order to create / edit offers
  const hasWriteAccess = useSelector(hasActivateWriteSelector)

  return (
    <Content component="main" animateIn>
      <Section>
        <ContentSectionHeading
          title={
            <Typography variant="h3" color="textDark">
              Universal Offers
            </Typography>
          }
          rightContent={
            hasWriteAccess ? (
              <CreateAdButton to={universalOfferCreatePath}>
                Create a universal offer
              </CreateAdButton>
            ) : (
              undefined
            )
          }
        />
      </Section>
      <Section>
        <UniversalOffersList
          description="The active universal offer, if one exists, will be displayed below. Only one offer can be active during scheduled dates."
          title="Active"
          statuses={['active']}
          emptyMessage="There is no active universal offer."
          hasWriteAccess={hasWriteAccess}
        />
      </Section>
      <Section>
        <UniversalOffersList
          title="Inactive"
          description="Offers that are either scheduled for the future or have already run and are now expired."
          statuses={['completed', 'scheduled']}
          emptyMessage="There are no inactive personalized offers."
          limit={3}
          hasWriteAccess={hasWriteAccess}
        />
      </Section>
      <Route
        exact
        path={OFFERS_UNIVERSAL_STATS.path}
        component={UniversalOfferStatsModal}
      />
    </Content>
  )
}
