// relative URLs must start with a forward slash and then may contain any valid character afterwards
var RELATIVE_URL_REGEX = /\/[A-Za-z0-9\-._~:/?#[\]%@!$&'()*+,;=]*/;
/**
 * this function returns whether the given URL is a relative URL
 * @param {String} url - the string to check to determine if it is a relative URL
 * @return {Boolean} - true if the given URL is a relative URL
 */

export default function isRelativeUrl(url) {
  var matches = url.match(RELATIVE_URL_REGEX);
  return !!matches && matches[0] === url;
}