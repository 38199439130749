var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getSpacingSelector } from '@signal/theme/selectors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-row-gap: ", "px;\n"])), getSpacingSelector(2));
export var ChartWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  height: 22px;\n  border-radius: 2px;\n  overflow: hidden;\n"])));