import React from 'react'
import { PrintIcon } from './styles'
import IconButton from '@signal/components/IconButton'

export const ReportPrinterSkeleton = function ReportPrinterSkeleton() {
  return (
    <IconButton>
      <PrintIcon disabled={true} />
    </IconButton>
  )
}

export default ReportPrinterSkeleton
