import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Link from '@signal/components/Link'
import { activeAccountIdSelector } from '@signal/core/auth'
import Typography from '@signal/components/Typography'
import { tabsSelector, activeTabIndexSelector } from './selectors'
import { Tabs, Tab } from './styles'

export function MainNavTabs({ accountId, tabs, activeTabIndex }) {
  /**
   * There is a possibility that the accountId will not be defined. If that is
   * the case, return the standard path to be able to create keys properly,
   * and disable the navigation bar until the account is set to active.
   */
  const renderTab = ({ to, label }, i) => {
    const isCurrentRoute = i === activeTabIndex
    return (
      <Tab
        key={label}
        component={Link}
        to={to}
        disabled={!accountId}
        label={
          <Typography
            variant="body2"
            color={isCurrentRoute ? 'textDark' : 'textSecondary'}
          >
            {label}
          </Typography>
        }
      />
    )
  }

  return (
    <Tabs
      value={activeTabIndex}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
    >
      {tabs.map(renderTab)}
    </Tabs>
  )
}

MainNavTabs.propTypes = {
  accountId: PropTypes.string,
  activeTabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    .isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      label: PropTypes.string.isRequired
    })
  )
}

MainNavTabs.defaultProps = {
  tabs: []
}

const mapStateToProps = createStructuredSelector({
  accountId: activeAccountIdSelector,
  tabs: tabsSelector,
  activeTabIndex: activeTabIndexSelector
})

export default connect(mapStateToProps)(MainNavTabs)
