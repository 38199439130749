import styled from 'styled-components'
import { createSelector } from 'reselect'
import {
  getSpacingSelector,
  getColorBackgroundSelector,
  getColorGreySelector,
  buttonPaddingHorizontalSelector
} from '@signal/theme/selectors'
import Typography from '@signal/components/Typography'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import TimelineEvent from '@signal/components/TimelineEvent'
import TimelineEventSkeleton from '@signal/components/TimelineEvent/Skeleton'

const wrapperSpacingSelector = createSelector(
  props => props.featured,
  getSpacingSelector(2),
  (isFeatured, spacing) => {
    if (!isFeatured) return
    return `
      padding: ${spacing}px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    `
  }
)

export const Wrapper = styled.div`
  display: flex;
  ${wrapperSpacingSelector}
`

export const Content = styled.div`
  display: flex;
`

export const Timestamp = styled(Typography).attrs(() => ({
  variant: 'body2',
  color: 'textSecondary'
}))`
  width: 110px;
`
export const TimestampSkeleton = styled(TypographySkeleton).attrs(() => ({
  variant: 'body2',
  color: 'skeletonPrimary',
  width: 60
}))`
  margin-right: 50px;
`

export const Icon = styled.div`
  margin-right: ${getSpacingSelector(2)}px;
  color: ${getColorGreySelector('dark')};
  display: inline-flex;
`
export const IconSkeleton = styled(Icon)`
  width: 24px;
  height: 24px;
  background: ${getColorBackgroundSelector()};
  border-radius: 50%;
`

export const Details = styled.div`
  > * {
    margin: ${getSpacingSelector(2)}px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Title = styled(Typography).attrs(() => ({
  variant: 'body1'
}))`
  margin-left: ${buttonPaddingHorizontalSelector}px;
`
export const TitleSkeleton = styled(
  Title.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonDark',
  width: 100
}))``

export const Event = styled(TimelineEvent)``

export const EventSkeleton = Event.withComponent(TimelineEventSkeleton)
