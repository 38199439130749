export var generateGuid = function generateGuid() {
  var retGUID = '';

  for (var charOn = 0; charOn < 32; charOn++) {
    if ([8, 12, 16, 20].indexOf(charOn) >= 0) {
      retGUID += '-';
    }

    var valCur = Math.floor(Math.random() * 16);
    retGUID += String.fromCharCode(valCur < 10 ? 48 + valCur : 87 + valCur);
  }

  return retGUID;
};
export default generateGuid;