var _templateObject;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import MuiOutlinedInput from '@material-ui/core/OutlinedInput';
import MuiSelect from '@material-ui/core/Select';
import MuiMenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { getSpacingSelector } from '@signal/theme/selectors';
var SELECT_ICON_WIDTH = 24;
var denseVerticalSpacingSelector = getSpacingSelector(1.5);
var horizontalSpacingSelector = getSpacingSelector(3);

var outlinedInputStyles = function outlinedInputStyles(theme) {
  var denseVerticalPadding = denseVerticalSpacingSelector(theme);
  var paddingLeft = horizontalSpacingSelector(theme); // 2x padding on the right to account for the arrow icon

  var paddingRight = paddingLeft * 2;
  return {
    input: {
      paddingLeft: paddingLeft,
      // adjust for the width of the arrow icon
      paddingRight: paddingRight
    },
    inputMarginDense: {
      paddingTop: denseVerticalPadding,
      paddingBottom: denseVerticalPadding
    }
  };
};

export var OutlinedInput = withStyles(outlinedInputStyles)(MuiOutlinedInput);

var selectStyles = function selectStyles(theme) {
  var horizontalSpacing = horizontalSpacingSelector(theme); // the select icon is 24px wide, but the arrow svg is only 10px wide, so we
  // subtract 7 from the horizontal spacing value

  var spacingAdjustment = SELECT_ICON_WIDTH / 2;
  return {
    icon: {
      right: horizontalSpacing - spacingAdjustment
    }
  };
};

export var Select = withStyles(selectStyles)(MuiSelect);
export var MenuItem = styled(MuiMenuItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-left: ", "px;\n  padding-right: ", "px;\n"])), horizontalSpacingSelector, horizontalSpacingSelector);