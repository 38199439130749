import React from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import mutuallyExclusiveProps from '@signal/prop-types/mutuallyExclusiveProps'
import renderIf from '@signal/utils/renderIf'
import DrilldownTableHeaderSkeleton from '../DrilldownTableHeader/Skeleton'
import TableListView from '../TableListView'
import {
  DetailsSkeleton,
  SecondaryValueSkeleton,
  PrimaryValueSkeleton
} from './styles'

export class DrilldownTableSkeleton extends React.Component {
  static propTypes = {
    count: PropTypes.number,
    countSecondaryValues: PropTypes.number,
    getColumnInfo: PropTypes.func,
    disableHeader: PropTypes.bool,
    showLevel: PropTypes.bool,
    showPrimaryValue: PropTypes.bool,
    ...mutuallyExclusiveProps({
      showAvatar: PropTypes.bool,
      showImage: PropTypes.bool
    })
  }

  static defaultProps = {
    count: 3,
    countSecondaryValues: 3,
    disableHeader: false,
    showLevel: true,
    showPrimaryValue: true,
    /**
     * Default function used by the `DrilldownTableSkeleton` to get `columnInfo`.
     * @param {Object} detailsColumn - skeleton "Details" column info
     * @param {Object} primaryColumn - skeleton "Primary" column info
     * @param {Object[]} secondaryColumns - skeleton "Secondary" columns info list
     * @returns {Object[]} - skeleton `columnInfo` for `TableListView`
     */
    getColumnInfo: (detailsColumn, primaryColumn, secondaryColumns) =>
      [detailsColumn, secondaryColumns, primaryColumn]
        // filter out any undefined/null base columns
        .filter(Boolean)
        .reduce((columnInfo, baseColumn) => columnInfo.concat(baseColumn), [])
  }

  get columnInfo() {
    const {
      countSecondaryValues,
      showLevel,
      showPrimaryValue,
      showAvatar,
      showImage,
      getColumnInfo
    } = this.props

    const skeletonDetailsColumn = {
      key: 'details',
      component: DetailsSkeleton,
      headerProps: {
        width: 50
      },
      hideLevel: !showLevel,
      showAvatar,
      showImage,
      mapProps: ({ showAvatar, showImage }) => ({ showAvatar, showImage })
    }

    let skeletonPrimaryColumn
    if (showPrimaryValue) {
      skeletonPrimaryColumn = {
        key: 'primaryValue',
        component: PrimaryValueSkeleton,
        headerProps: {
          width: 100,
          align: 'right'
        }
      }
    }

    let skeletonSecondaryColumns = []
    if (countSecondaryValues) {
      skeletonSecondaryColumns = times(countSecondaryValues, i => ({
        key: `secondaryValue${i}`,
        component: SecondaryValueSkeleton,
        headerProps: {
          width: 70
        }
      }))
    }

    return getColumnInfo(
      skeletonDetailsColumn,
      skeletonPrimaryColumn,
      skeletonSecondaryColumns
    )
  }

  render() {
    const { count, disableHeader, ...rest } = this.props

    const rows = times(count, i => ({ key: i }))

    const headerCellRenderer = ({ hideLevel, headerProps }) =>
      renderIf(!hideLevel, () => (
        <DrilldownTableHeaderSkeleton {...headerProps} />
      ))

    const cellRenderer = (
      row,
      { component: Component, key, mapProps = () => null, ...rest }
    ) => <Component {...row} key={key} {...mapProps(rest)} />

    return (
      <TableListView
        columnInfo={this.columnInfo}
        rows={rows}
        renderHeaderCell={disableHeader ? null : headerCellRenderer}
        renderRowCell={cellRenderer}
        {...rest}
      />
    )
  }
}

export default DrilldownTableSkeleton
