import React from 'react'
import ProgressiveImage from '@signal/components/ProgressiveImage'
import {
  Layout,
  RightContent,
  ImageSkeleton,
  SalePriceTitleSkeleton,
  SalePriceMetricSkeleton,
  TitleSkeleton,
  SubtitleSkeleton
} from './styles'

export function ChannelsVinSummarySkeleton() {
  return (
    <Layout
      title={<TitleSkeleton width={150} />}
      leftContent={
        <ProgressiveImage>
          {(src, loading) => <ImageSkeleton src={src} alt="loading" />}
        </ProgressiveImage>
      }
      rightContent={
        <RightContent>
          <SalePriceTitleSkeleton width={100} />
          <SalePriceMetricSkeleton />
        </RightContent>
      }
    >
      <SubtitleSkeleton width={100} />
    </Layout>
  )
}

export default ChannelsVinSummarySkeleton
