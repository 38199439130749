import React, { memo } from 'react'
import FormControlTextFieldSkeleton from '@signal/components/FormControlTextField/Skeleton'
import ValidationIcon from '../FormControlValidationIcon'
import { Wrapper as FormControlBaseWrapper } from '@signal/components/FormControl/styles'
import {
  Wrapper,
  FormControlWrapper,
  AddSegmentIcon,
  AddSegmentButton
} from './styles'

const AudienceDetailsFormSkeleton = () => (
  <Wrapper>
    <FormControlWrapper>
      <FormControlBaseWrapper>
        <FormControlTextFieldSkeleton
          leftAdornment={<ValidationIcon />}
          label="Give your audience a name"
          labelPlacement="top"
          placeholder="Enter a name for your audience"
          fullWidth
          margin="dense"
        />
      </FormControlBaseWrapper>
    </FormControlWrapper>
    <AddSegmentButton
      name="audience-detail-form-add-segment-button-skeleton"
      disabled
    >
      <AddSegmentIcon />
      Add a segment
    </AddSegmentButton>
  </Wrapper>
)

export default memo(AudienceDetailsFormSkeleton)
