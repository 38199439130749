import React from 'react'
import { Route } from 'react-router-dom'
import Section from '@signal/layouts/Section'
import Content from '@signal/layouts/Content'
import { OFFERS_PERSONALIZED_STATS } from '@signal/routes'
import { PAGE_OFFERS_PERSONALIZED, APP_OFFERS } from '@signal/analytics'
import { usePageTrack, useRoutePath } from '@signal/core-hooks'
import { OFFERS_PERSONALIZED_CREATE } from '@signal/routes'
import ContentSectionHeading from '@signal/components/ContentSectionHeading'
import Typography from '@signal/components/Typography'
import { hasActivateWriteSelector } from '@signal/core/auth'
import { useSelector } from 'react-redux'
import CreateAdButton from '../../../containers/CreateAdButton'
import PersonalizedAdsList from '../../../containers/PersonalizedAdsList'
import PersonalizedOfferStatsModal from '../../../containers/PersonalizedOfferStatsModal'

export default function PersonalizedOffers() {
  const personalizedOfferCreatePath = useRoutePath(OFFERS_PERSONALIZED_CREATE)
  usePageTrack(PAGE_OFFERS_PERSONALIZED, APP_OFFERS)
  // check features to see if the user has write access in order to create / edit offers
  const hasWriteAccess = useSelector(hasActivateWriteSelector)

  return (
    <Content component="main" animateIn>
      <Section>
        <ContentSectionHeading
          title={
            <Typography variant="h3" color="textDark">
              Personalized Offers
            </Typography>
          }
          rightContent={
            hasWriteAccess ? (
              <CreateAdButton to={personalizedOfferCreatePath}>
                Create a personalized offer
              </CreateAdButton>
            ) : null
          }
        />
      </Section>
      <Section>
        <PersonalizedAdsList
          description="Offers that are currently active."
          title="Active"
          statuses={['active']}
          emptyMessage="There are no active personalized offers."
          hasWriteAccess={hasWriteAccess}
        />
      </Section>
      <Section>
        <PersonalizedAdsList
          title="Inactive"
          description="Offers that are either scheduled for the future or have already run and are now expired."
          statuses={['completed', 'scheduled']}
          emptyMessage="There are no inactive personalized offers."
          limit={3}
          hasWriteAccess={hasWriteAccess}
        />
      </Section>
      <Route
        exact
        path={OFFERS_PERSONALIZED_STATS.path}
        component={PersonalizedOfferStatsModal}
      />
    </Content>
  )
}
