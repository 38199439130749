var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

import styled from 'styled-components';
import { createSelector } from 'reselect';
import isString from 'lodash/isString';
import map from 'lodash/map';
import kebabCase from 'lodash/kebabCase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { colorSelectors } from '../../Typography';
import { palette } from '../styles';
var LAST_ROW_WIDTH = 0.85; // 85%

function getPadding(_ref) {
  var theme = _ref.theme,
      variant = _ref.variant;
  var _theme$typography$var = theme.typography[variant],
      lineHeight = _theme$typography$var.lineHeight,
      fontSize = _theme$typography$var.fontSize;
  return "".concat((parseFloat(lineHeight) - parseFloat(fontSize)) / 2, "rem");
}

function getWidth(_ref2) {
  var last = _ref2.last,
      fill = _ref2.fill,
      width = _ref2.width;
  var w = '100%';
  if (fill || width) w = fill ? '100%' : width;
  var value = w;
  var unit = 'px';

  if (isString(w)) {
    // If width is a string, separate the value from the unit
    var _w$match = w.match(/[a-zA-Z%]+|[0-9.]+/g),
        _w$match2 = _slicedToArray(_w$match, 2),
        strValue = _w$match2[0],
        strUnit = _w$match2[1];

    value = Number(strValue);
    unit = strUnit;
  } // For last rows, multiply value by 85%


  return "".concat(last ? value * LAST_ROW_WIDTH : value).concat(unit);
}

function getMaxWidth(_ref3) {
  var last = _ref3.last;
  var value = last ? LAST_ROW_WIDTH * 100 : 100;
  return "".concat(value, "%");
}

function getHeight(_ref4) {
  var theme = _ref4.theme,
      variant = _ref4.variant,
      fill = _ref4.fill,
      height = _ref4.height;
  var heightStr; // If height is a string, it's good to go... if it's a number, we need to add
  // the px unit to the end so it's valid for CSS

  if (height) heightStr = isString(height) ? height : "".concat(height, "px");
  if (fill || heightStr) return fill ? '100%' : heightStr;
  return theme.typography[variant].fontSize;
}

function selectColorSelector(_ref5) {
  var inverse = _ref5.inverse,
      color = _ref5.color;
  if (color.startsWith('skeleton')) return inverse ? palette.inverse[color] : palette.default[color]; // Non-"skeleton" colors are rendered with a slight alpha transparency to make
  // them look slightly more subdued. Without the transparency many of the
  // colors, for example "success" and "error" are way too bright and stick out
  // like a sore thumb when used with the skeleton.

  return createSelector(colorSelectors[color], function (color) {
    return fade(color, 0.65);
  });
}

export var backgroundColorSelector = createSelector(selectColorSelector, function (props) {
  return props.theme;
}, function (skeletonColorSelector, theme) {
  return skeletonColorSelector(theme);
});
var alignmentSelector = createSelector(function (props) {
  return props.align;
}, function (align) {
  if (!align) return;
  if (align === 'center') return 'margin: 0 auto;';
  return "float: ".concat(align, ";");
});
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: ", ";\n  ", "\n"])), getWidth, alignmentSelector);
export var Skeleton = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  /* Use padding instead of margin so that vertical margins aren't collapsed. */\n  padding: ", " 0;\n  height: ", ";\n  max-width: ", ";\n  box-sizing: content-box;\n  /* Using a :before selector to avoid creating a new child DOM element. */\n  &:before {\n    content: ' ';\n    background: ", ";\n    height: ", ";\n    max-width: 100%;\n    display: block;\n    border-radius: ", ";\n    /* Loop through style object and convert to CSS. */\n    ", "\n  }\n  /* Don't pass component props down to the div component, only className. */\n"])), getPadding, getHeight, getMaxWidth, backgroundColorSelector, getHeight, function (_ref6) {
  var theme = _ref6.theme,
      variant = _ref6.variant;
  return theme.typography[variant].fontSize;
}, function (props) {
  return map(props.styles, function (val, key) {
    return "".concat(kebabCase(key), ": ").concat(val, ";");
  });
});