import createRequestActions from '@signal/utils/createRequestActions';
export var FETCH_PERFORMANCE_DATA = 'PERFORMANCE/DATA';
export var FETCH_INVENTORY_PERFORMANCE_DATA = 'PERFORMANCE/INVENTORY_DATA';
/**
 * Channel Performance
 */

export var performanceDataActions = createRequestActions(FETCH_PERFORMANCE_DATA);
var performanceDataLoad = performanceDataActions.load,
    performanceDataRequest = performanceDataActions.request,
    performanceDataSuccess = performanceDataActions.success,
    performanceDataFailure = performanceDataActions.failure;
export { performanceDataLoad, performanceDataRequest, performanceDataSuccess, performanceDataFailure };
/**
 * Inventory Performance
 */

export var inventoryPerformanceDataActions = createRequestActions(FETCH_INVENTORY_PERFORMANCE_DATA);
var inventoryPerformanceDataLoad = inventoryPerformanceDataActions.load,
    inventoryPerformanceDataRequest = inventoryPerformanceDataActions.request,
    inventoryPerformanceDataSuccess = inventoryPerformanceDataActions.success,
    inventoryPerformanceDataFailure = inventoryPerformanceDataActions.failure;
export { inventoryPerformanceDataLoad, inventoryPerformanceDataRequest, inventoryPerformanceDataSuccess, inventoryPerformanceDataFailure };