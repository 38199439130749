import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { readCookie, setCookie } from '../cookies';
var DEFAULT_API_DOMAIN = 'https://apix.purecars.com';
var QA_API_DOMAIN = 'https://apix-qa.purecars.com';
var API_DOMAIN_COOKIE = 'signal-dev-apix-domain';
var APPS_QA_HOSTNAME = 'apps-qa.purecars.com';
/**
 * gets the API domain to use (defaults to https://apix.purecars.com), but can be overridden through cookies
 * @param {String} middleProxy - the middle part of the apix URL, which is currently reverse proxied and not truly
 *   in existence on developer machines.  If an override is an object mapping these middle proxies we will use it
 * @return {String} - the API domain to use
 */

export function getAPIDomain() {
  var middleProxy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var defaultApiDomain = DEFAULT_API_DOMAIN; // handle the legacy meridian case to default to apix-qa on apps-qa.

  if (APPS_QA_HOSTNAME === window.location.hostname) {
    defaultApiDomain = QA_API_DOMAIN;
  }

  var apiRoot = isObject(window.env) && window.env.apiRoot || defaultApiDomain;

  if (!apiRoot.includes('://')) {
    apiRoot = "https://".concat(apiRoot);
  }

  var override = readCookie(API_DOMAIN_COOKIE);

  if (override) {
    var ret = null;

    try {
      // attempt to parse, in case this override sets middle proxies
      override = JSON.parse(override);
    } catch (e) {
      ret = "".concat(override).concat(middleProxy);
    }

    if (ret === null) {
      ret = override[middleProxy] || "".concat(override.default || apiRoot).concat(middleProxy);
    }

    if (middleProxy) {
      console.log('override mapped API at', middleProxy, 'to', ret);
    } else {
      console.log('override mapped API to', ret);
    }

    console.log('use window.SignalDevAPIDomain.reset() to remove the override.');
    return ret;
  }

  return "".concat(apiRoot).concat(middleProxy);
}
/**
 * applies the debug API override cookie to environment base URLs
 * @param {String} envKey - the key in process.env in which the base URL is found
 * @return {String} - the base URL to use, with appropriate API domain
 */

export function applyAPIDomainToEnvBase(envKey) {
  var envBase = process.env[envKey];
  return isString(envBase) ? envBase.replace(DEFAULT_API_DOMAIN, getAPIDomain()) : envBase;
}
/**
 * developers can use this to reset their API domain override
 */

function resetSignalDevAPIDomain() {
  setCookie({
    name: API_DOMAIN_COOKIE,
    value: ''
  });
}
/**
 * developers can use this to set their API domain override
 * @param {String|Object} details - the details to set for override.
 *   This can either be a string (the URL to use for all apix requests)
 *   or it can be an object indicating which middle proxies should be handled by which URLs)
 *
 *   Examples:
 *
 *   setSignalDevAPIDomain('https://localhost:4999')
 *
 *   setSignalDevAPIDomain({
 *     '/menu/service': 'https://localhost:4999',
 *     default: 'https://apix-qa.purecars.com'
 *   })
 *
 *   Note that if you use the object form for the details, then the URL that will be hit for a given middle proxy key
 *   will not include that middle proxy segment of the URL.
 *
 *   For example,
 *
 *   setSignalDevAPIDomain({
 *     '/menu/service': 'https://localhost:4999',
 *     default: 'https://apix-qa.purecars.com'
 *   })
 *
 *   the above function will direct requests
 *   from https://apix.purecars.com/menu/service/v1/{accountId} to https://localhost:4999/v1/{accountId}
 *     (no middle proxy segment in URL because there was a specific override for this middle proxy key)
 *   from https://apix.purecars.com/reports/dashboard/v1/foo to https://apix-qa.purecars.com/reports/dashboard/v1/foo
 *     (middle proxy /reports/dashboard was included in URL because this went to the default override)
 */


function setSignalDevAPIDomain(details) {
  setCookie({
    name: API_DOMAIN_COOKIE,
    value: isString(details) ? details : JSON.stringify(details)
  });
} // expose these functions to the console for developer use


window.SignalDevAPIDomain = {
  reset: resetSignalDevAPIDomain,
  setTo: setSignalDevAPIDomain
};
export default getAPIDomain;