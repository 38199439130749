function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import storage from 'local-storage-fallback';
import { LOCAL_STORAGE_SIGNAL_DATA } from '../auth/constants';
export function getRecentlyViewedAccountsFromStorage() {
  try {
    var signalStorage = storage.getItem(LOCAL_STORAGE_SIGNAL_DATA);
    return signalStorage && JSON.parse(signalStorage).recentlyViewedAccounts || [];
  } catch (err) {
    console.warn('Unable to get recently viewed accounts from localStorage! Account object was malformed.');
    return [];
  }
}
export function setRecentlyViewedAccountsInStorage(recentlyViewedAccounts) {
  var signalStorage = storage.getItem(LOCAL_STORAGE_SIGNAL_DATA) || '{}';

  try {
    storage.setItem(LOCAL_STORAGE_SIGNAL_DATA, JSON.stringify(_objectSpread(_objectSpread({}, JSON.parse(signalStorage)), {}, {
      recentlyViewedAccounts: recentlyViewedAccounts
    })));
  } catch (err) {
    storage.setItem(LOCAL_STORAGE_SIGNAL_DATA, JSON.stringify({
      recentlyViewedAccounts: recentlyViewedAccounts
    }));
  }
}