import React from 'react'
import ShopperBucketDetailsSkeleton from '@signal/components/ShopperBucketDetails/Skeleton'
import ShopperBucketIcon from '@signal/components/ShopperBucketIcon'
import { BUCKETS } from '@signal/core/shoppersBucket/constants'
import { Wrapper } from './styles'

export default function ShoppersBucketsDetailsSkeleton() {
  return (
    <Wrapper>
      {BUCKETS.map(bucket => (
        <ShopperBucketDetailsSkeleton
          key={bucket}
          icon={<ShopperBucketIcon bucket={bucket} />}
        />
      ))}
    </Wrapper>
  )
}
