import {
  ACCOUNTS,
  ACTIVATE_REPORTS,
  AUDIENCES,
  AUDIENCE_DETAIL,
  AUDIENCE_CREATE,
  REPORTS,
  SHARED_REPORTS,
  INDUSTRY_REPORTS,
  LOCAL_SEARCH_REPORTS,
  HOME,
  OFFERS,
  LOGOUT,
  PERFORMANCE,
  PERFORMANCE_CHANNELS,
  PERFORMANCE_CHANNELS_DETAIL,
  PERFORMANCE_CHANNELS_VIN,
  PERFORMANCE_INVENTORY,
  PERFORMANCE_INVENTORY_DETAIL,
  PERFORMANCE_INVENTORY_VIN,
  PERFORMANCE_SHOPPERS,
  PERFORMANCE_SHOPPERS_BUCKET,
  MANAGE_SPEND,
  NOT_FOUND,
  SHOPPER_DETAIL,
  UNAUTHORIZED,
  VALUE_INTELLIGENCE_REPORTS
} from '@signal/routes'
import ActiveAccountRequiredRoute from '@signal/containers/ActiveAccountRequiredRoute'
import AuthenticatedRoute from '@signal/containers/AuthenticatedRoute'

// Views
// import Home from '../views/Home'
import Home from '../containers/Home'
import { DashboardView } from '../views/dashboard'
import { IndustryReportsView } from '../views/industryReports'
import { GenericReportsView } from '../views/genericReports'
import Offers from '../views/offers'

// Pages
import NotFound from '../pages/NotFound'
import UnauthorizedPage from '../pages/Unauthorized'
import LogOut from '../pages/LogOut'
import Overview from '../pages/Overview'
import ManageSpend from '../pages/ManageSpend'
import ChannelsOverview from '../pages/ChannelsOverview'
import ChannelsDetail from '../pages/ChannelsDetail'
import ChannelsVin from '../pages/ChannelsVin'
import InventoryOverview from '../pages/InventoryOverview'
import InventoryDrilldowns from '../pages/InventoryDrilldowns'
import InventoryVin from '../pages/InventoryVin'
import PerformanceShoppers from '../pages/PerformanceShoppers'
import PerformanceShoppersBucket from '../pages/PerformanceShoppersBucket'
import ShoppersDetail from '../pages/ShopperDetail'
import AccountsPage from '../pages/Accounts'
import AudiencesPage from '../pages/Audiences'
import AudienceCreatePage from '../pages/AudienceCreate'
import AudienceDetailPage from '../pages/AudienceDetail'

// Containers

export default {
  [HOME]: {
    component: Home,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [MANAGE_SPEND]: {
    component: ManageSpend,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [LOGOUT]: {
    component: LogOut,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE]: {
    component: Overview,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE_CHANNELS]: {
    component: ChannelsOverview,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE_CHANNELS_DETAIL]: {
    component: ChannelsDetail,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE_CHANNELS_VIN]: {
    component: ChannelsVin,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE_INVENTORY]: {
    component: InventoryOverview,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE_INVENTORY_DETAIL]: {
    component: InventoryDrilldowns,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE_INVENTORY_VIN]: {
    component: InventoryVin,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE_SHOPPERS]: {
    component: PerformanceShoppers,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [PERFORMANCE_SHOPPERS_BUCKET]: {
    component: PerformanceShoppersBucket,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [SHOPPER_DETAIL]: {
    component: ShoppersDetail,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [OFFERS]: {
    component: Offers,
    routeComponent: ActiveAccountRequiredRoute
  },
  [AUDIENCES]: {
    component: AudiencesPage,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [AUDIENCE_CREATE]: {
    component: AudienceCreatePage,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [AUDIENCE_DETAIL]: {
    component: AudienceDetailPage,
    routeComponent: ActiveAccountRequiredRoute,
    exact: true
  },
  [ACCOUNTS]: {
    component: AccountsPage,
    routeComponent: AuthenticatedRoute,
    exact: true
  },
  [REPORTS]: {
    component: DashboardView,
    routeComponent: ActiveAccountRequiredRoute
  },
  [SHARED_REPORTS]: {
    component: DashboardView,
    routeComponent: ActiveAccountRequiredRoute
  },
  [INDUSTRY_REPORTS]: {
    component: IndustryReportsView,
    routeComponent: ActiveAccountRequiredRoute
  },
  [ACTIVATE_REPORTS]: {
    component: GenericReportsView,
    routeComponent: ActiveAccountRequiredRoute
  },
  [VALUE_INTELLIGENCE_REPORTS]: {
    component: GenericReportsView,
    routeComponent: ActiveAccountRequiredRoute
  },
  [LOCAL_SEARCH_REPORTS]: {
    component: GenericReportsView,
    routeComponent: ActiveAccountRequiredRoute
  },
  // IMPORTANT: add the not found route as the last (fallback) route
  [NOT_FOUND]: {
    component: NotFound,
    routeComponent: ActiveAccountRequiredRoute
  },
  [UNAUTHORIZED]: {
    component: UnauthorizedPage,
    routeComponent: AuthenticatedRoute
  }
}
