var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getSpacingSelector, paletteDividerSelector } from '@signal/theme/selectors';
import Typography from '../Typography';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));\n  grid-column-gap: ", "px;\n  grid-row-gap: ", "px;\n"])), getSpacingSelector(2.5), getSpacingSelector(2.5));
export var Section = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: ", "px ", "px;\n  border: 1px solid ", ";\n  border-radius: 5px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  & > :first-child {\n    margin-bottom: ", "px;\n  }\n"])), getSpacingSelector(1.5), getSpacingSelector(2), paletteDividerSelector, getSpacingSelector());
export var Metric = styled(Typography).attrs(function () {
  return {
    variant: 'h3',
    color: 'textDark'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Label = styled(Typography).attrs(function () {
  return {
    variant: 'body2',
    color: 'textSecondary'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));