import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import ExpansionPanel from '@signal/components/ExpansionPanel'
import ContextualPageHeader from '../ContextualPageHeader'
import { Image, Description } from './styles'

export const addImgParams = (src, params) => {
  const qs = queryString.stringify(params)
  return `${src}?${qs}`
}

export class PerformanceSummaryPanel extends PureComponent {
  static propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    image: PropTypes.shape({
      srcSet: PropTypes.arrayOf(
        PropTypes.shape({
          dpr: PropTypes.number.isRequired,
          src: PropTypes.string.isRequired
        })
      ),
      alt: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired
    }).isRequired,
    isExpandable: PropTypes.bool
  }

  state = {
    expanded: false
  }

  static getDerivedStateFromProps(props, state) {
    const { expanded, isExpandable } = props
    if (isExpandable && expanded !== state.expanded) {
      return { expanded }
    }
    return null
  }

  render() {
    const { title, description, image, ...rest } = this.props
    const { expanded } = this.state
    const { srcSet: srcSetProp, ...imgProps } = image
    const baseImgParams = { w: imgProps.width, auto: 'format' }
    const lowRes = srcSetProp.find(({ dpr }) => dpr === 1)
    const src = addImgParams(lowRes.src, baseImgParams)
    const srcSet = srcSetProp.map(({ dpr, src }) => {
      const imgParams = dpr > 1 ? { ...baseImgParams, dpr } : baseImgParams
      return `${addImgParams(src, imgParams)} ${dpr}x`
    })
    return (
      <ContextualPageHeader>
        <ExpansionPanel {...rest} title={title} expanded={expanded}>
          <Description>{description}</Description>
          <Image src={src} srcSet={srcSet.join(',')} {...imgProps} />
        </ExpansionPanel>
      </ContextualPageHeader>
    )
  }
}

export default PerformanceSummaryPanel
