import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { decomposeColor } from '@material-ui/core/styles/colorManipulator'
import { getColorPrimarySelector } from '@signal/theme/selectors'

const gridlineStyles = {
  stroke: 'rgb(158, 158, 158)',
  strokeWidth: '1px',
  strokeDasharray: '1,6'
}

export default () => {
  const theme = useTheme()
  const primary = decomposeColor(getColorPrimarySelector()(theme))
  const primaryGradientStart = `rgba(${primary.values[0]}, ${primary.values[1]}, ${primary.values[2]}, 0.5)`
  const primaryGradientEnd = `rgba(${primary.values[0]}, ${primary.values[1]}, ${primary.values[2]}, 0.15)`
  return (
    <svg
      className="rv-xy-plot__inner"
      viewBox="0 0 1303 375"
      preserveAspectRatio="none"
      style={{ display: 'block', width: '100%', height: '100%' }}
    >
      <defs className="rv-gradient-defs">
        <linearGradient id="gradient-2" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={primaryGradientStart} />
          <stop offset="100%" stopColor={primaryGradientEnd} />
        </linearGradient>
      </defs>
      <g transform="translate(64,10)" className="rv-xy-plot__grid-lines">
        <line
          y1="65"
          y2="65"
          x1="0"
          x2="1243"
          className="rv-xy-plot__grid-lines__line"
          style={gridlineStyles}
        />
        <line
          y1="130"
          y2="130"
          x1="0"
          x2="1243"
          className="rv-xy-plot__grid-lines__line"
          style={gridlineStyles}
        />
        <line
          y1="195"
          y2="195"
          x1="0"
          x2="1243"
          className="rv-xy-plot__grid-lines__line"
          style={gridlineStyles}
        />
        <line
          y1="260"
          y2="260"
          x1="0"
          x2="1243"
          className="rv-xy-plot__grid-lines__line"
          style={gridlineStyles}
        />
      </g>
      <path
        d="M0,325C82.86666666666667,315.26948689377053,165.73333333333335,305.5389737875411,248.60000000000002,266.6169213626234C331.4666666666667,227.69486893770562,414.33333333333337,0,497.20000000000005,0C580.0666666666667,0,662.9333333333333,240.54242279528813,745.8,240.54242279528813C828.6666666666666,240.54242279528813,911.5333333333334,206.63005412289078,994.4000000000001,206.63005412289078C1077.2666666666667,206.63005412289078,1160.1333333333334,231.66985036612544,1243,256.7096466093601L1243,325C1160.1333333333334,325,1077.2666666666667,325,994.4000000000001,325C911.5333333333334,325,828.6666666666666,325,745.8,325C662.9333333333333,325,580.0666666666667,325,497.20000000000005,325C414.33333333333337,325,331.4666666666667,325,248.60000000000002,325C165.73333333333335,325,82.86666666666667,325,0,325Z"
        className="rv-xy-plot__series rv-xy-plot__series--line "
        transform="translate(60,10)"
        style={{ opacity: 1, stroke: 'none', fill: 'url(#gradient-2)' }}
      />
      <g
        transform="translate(60,335)"
        className="rv-xy-plot__axis rv-xy-plot__axis--horizontal "
      >
        <line
          x1="0"
          x2="1243"
          y1="0"
          y2="0"
          className="rv-xy-plot__axis__line"
          style={gridlineStyles}
        />
        <g transform="translate(0, 0)" className="rv-xy-plot__axis__ticks">
          <g transform="translate(0, 0)" className="rv-xy-plot__axis__tick">
            <line
              x1="0"
              x2="0"
              y1="0"
              y2="0"
              className="rv-xy-plot__axis__tick__line"
              style={gridlineStyles}
            />
          </g>
          <g
            transform="translate(248.60000000000002, 0)"
            className="rv-xy-plot__axis__tick"
          >
            <line
              x1="0"
              x2="0"
              y1="0"
              y2="0"
              className="rv-xy-plot__axis__tick__line"
              style={gridlineStyles}
            />
          </g>
          <g
            transform="translate(497.20000000000005, 0)"
            className="rv-xy-plot__axis__tick"
          >
            <line
              x1="0"
              x2="0"
              y1="0"
              y2="0"
              className="rv-xy-plot__axis__tick__line"
              style={gridlineStyles}
            />
          </g>
          <g transform="translate(745.8, 0)" className="rv-xy-plot__axis__tick">
            <line
              x1="0"
              x2="0"
              y1="0"
              y2="0"
              className="rv-xy-plot__axis__tick__line"
              style={gridlineStyles}
            />
          </g>
          <g
            transform="translate(994.4000000000001, 0)"
            className="rv-xy-plot__axis__tick"
          >
            <line
              x1="0"
              x2="0"
              y1="0"
              y2="0"
              className="rv-xy-plot__axis__tick__line"
              style={gridlineStyles}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
