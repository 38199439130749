function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { createTracker } from '@purecarslabs/redux-segment';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import isError from 'lodash/isError';
import omit from 'lodash/omit';
import createNormalizrMiddleware from '@signal/utils/createNormalizrMiddleware';
import getAPIDomain from '@signal/utils/env/apiDomain';
import isServer from '@signal/utils/env/isServer';
import isSnapshot from '@signal/utils/env/isSnapshot';
import isTest from '@signal/utils/env/isTest';
import isProduction from '@signal/utils/env/isProduction';
import getRuntimeEnvironment from '@signal/utils/env/getRuntimeEnvironment';
import { initialize as initializeErrorReporting, createMiddleware as createErrorReportingMiddleware, captureException, LEVELS, TAGS } from '@signal/utils/errorReporting';
import { AuthError, FetchError } from '@signal/utils/errors';
import { isPageTransitioningSelector } from './app';
import createReducer from './createReducer';
/*
 * localStorage does not exist in snapshot or test mode (not in JSDOM)
 * we can't load this package at all unless we have localStorage
 */

var reduxLocalStorageSimple = null;

if (!isSnapshot() && !isTest()) {
  reduxLocalStorageSimple = require('redux-localstorage-simple');
}

export default function configureStore() {
  var createHistory = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : createBrowserHistory; // if running on dev server, add "Development" to the app name

  var appNameParts = ['Signal', !isProduction() && 'Development', "v".concat(process.env.REACT_APP_VERSION)]; // name used in the Redux Devtools Extension

  var appName = appNameParts.filter(Boolean).join(' - '); // List of options supported by the Redux Devtools Extension:
  // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options

  var composeEnhancers = composeWithDevTools({
    name: appName,
    trace: true,
    traceLimit: 50,
    serialize: {
      // serializes errors as strings in redux devtools
      replacer: function serializeErrors(key, value) {
        // only serialize `Error` objects
        if (!isError(value)) return value;
        var name = value.name,
            message = value.message;
        return "".concat(name, ": ").concat(message);
      }
    }
  });
  var history = createHistory();
  var sagaMiddleware = createSagaMiddleware({
    onError: function onError(error) {
      // We don't need to report auth errors, as they are expected when session expires, etc.
      if (error instanceof AuthError) return; // Fetch errors are to be expected in small volumes, but a rise in these errors may alert us to
      // an issue, log with `error` severity level.

      if (error instanceof FetchError) {
        captureException(error, {
          level: LEVELS.ERROR,
          tags: _defineProperty({}, TAGS.CATEGORY, 'Fetch Data')
        });
        return;
      }

      captureException(error, {
        level: LEVELS.FATAL,
        tags: _defineProperty({}, TAGS.CATEGORY, 'Saga')
      });
    }
  });
  var normalizrMiddleware = createNormalizrMiddleware(); // Create an enhanced history that syncs navigation events with the store
  // Build the middleware for intercepting and dispatching navigation actions

  var routerMiddleware = createRouterMiddleware(history);
  var middleware = [sagaMiddleware, routerMiddleware, normalizrMiddleware];
  var initialState;

  if (reduxLocalStorageSimple) {
    var localStatesAndNamespace = {
      states: ['local'],
      namespace: 'signal-local'
    };
    middleware.push(reduxLocalStorageSimple.save(localStatesAndNamespace));

    try {
      initialState = reduxLocalStorageSimple.load(localStatesAndNamespace);
    } catch (err) {}
  }
  /**
   * @NOTE - We must disable the analytics tracking middleware on the server as
   * it only works client-side.
   */


  if (!isServer() && !isTest()) {
    /**
     * @todo Solution for analytics environments
     * @body Currently, events/page views that happen in local and staging environments record to the same place as the production site, which will obviously cause data biases when we go live. Let's find a good solution for this.
     */
    // Analytics tracking middleware
    var trackerMiddleware = createTracker({
      skipDefaultMapping: true
    });
    middleware.push(trackerMiddleware);
    initializeErrorReporting({
      dsn: 'https://b59921e9773f42bda9fc77bce9a49e00@sentry.io/1140897',
      release: process.env.REACT_APP_VERSION,
      environment: getRuntimeEnvironment()
    });
    var errorReportingMiddleware = createErrorReportingMiddleware({
      stateTransformer: function stateTransformer(state) {
        return omit(state, ['auth', 'user.accounts', 'user.users']);
      }
    });
    middleware.push(errorReportingMiddleware); // Azure Application Insights

    if (isProduction()) {
      var reactPlugin = new ReactPlugin();
      var appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: '78f165e9-18d9-4da5-a982-bc6e9e0ceb3c',
          extensions: [reactPlugin],
          extensionConfig: _defineProperty({}, reactPlugin.identifier, {
            history: history
          }),
          disableFetchTracking: false,
          enableCorsCorrelation: true,
          correlationHeaderDomains: [getAPIDomain().replace(/.*\/\//, '')]
        }
      });
      appInsights.loadAppInsights();
      appInsights.addTelemetryInitializer(function (envelope) {
        envelope.tags['ai.cloud.role'] = 'Signal Web';
      });
    }
  }

  var store = createStore(createReducer(null, history), initialState, composeEnhancers(applyMiddleware.apply(void 0, middleware), batchedSubscribe(createBatchNotifier()))); // prevent views from updating while page is transitioning, as well as do some
  // minor batching with requestAnimationFrame

  function createBatchNotifier() {
    var raf;
    return function batchNotify(notify) {
      /**
       * @NOTE - We must disable store subscriber notification batching on the
       * server because there are no page transitions and requestAnimationFrame
       * is unavailable.
       */
      if (isServer()) return notify();
      var isTransitioning = isPageTransitioningSelector(store.getState()); // only notify subscribers when route is not transitioning

      if (!isTransitioning) {
        // batch updates with requestAnimationFrame
        if (raf) cancelAnimationFrame(raf);
        raf = requestAnimationFrame(function () {
          notify();
        });
      }
    };
  } // Extensions


  store.history = history;
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry

  store.injectedSagas = {}; // Saga registry
  // Make reducers hot reloadable, see http://mxs.is/googmo

  /* istanbul ignore next */

  if (module.hot) {
    module.hot.accept('./createReducer', function () {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}