import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiTableCell from '@material-ui/core/TableCell'
import MuiTableRow from '@material-ui/core/TableRow'
import MoneyOff from '@material-ui/icons/MoneyOff'
import AvatarIcon from '@signal/components/AvatarIcon'
import AvatarIconSkeleton from '@signal/components/AvatarIcon/Skeleton'
import EmptyScreenBase from '@signal/components/EmptyScreen'
import { Icon as IconBase } from '@signal/components/EmptyScreen/styles'
import TypographySkeleton from '@signal/components/Skeleton/Typography'
import Typography from '@signal/components/Typography'
import {
  colorWhiteSelector,
  getSpacingSelector,
  getColorGreySelector
} from '@signal/theme/selectors'

export const EmptySpendIcon = IconBase.withComponent(MoneyOff)

export const TableCell = styled(MuiTableCell)`
  /**
   * Use "&&" to increase specificity, ensuring that all padding is stripped
   * from cells; row height is fixed and cell content should just be aligned
   * vertically in the center.
   */
  && {
    padding: 0;
  }
`

export const TableCellFixed = styled(TableCell)`
  width: 25%;
  min-width: 150px;
`

export const TableRow = styled(MuiTableRow)`
  height: 64px;
  /* removes the bottom border from last table row */
  &:last-of-type:not(:first-of-type) > * {
    border-bottom: none;
  }
`

export const TableHeaderCell = styled(TableCell)`
  background-color: ${colorWhiteSelector};
  z-index: 3;
  border-bottom: none;

  position: sticky;
  top: 0;

  /**
   * The CSS hack below is used to create a border below the sticky headers.
   * Using "position: sticky" breaks the standard border for some reason.
   */
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid ${getColorGreySelector('light')};
  }
`

export const TableHeaderCellFixed = styled(TableHeaderCell)`
  width: 150px;
`

export const TableHeaderCellText = styled(Typography).attrs(() => ({
  variant: 'body1'
}))``

export const RepeatMonthlyCell = styled(FormControlLabel)`
  margin: 0;
`

export const ChannelAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Avatar = styled(AvatarIcon).attrs(() => ({
  size: 36
}))`
  margin-right: ${getSpacingSelector(2)}px;
`

export const AvatarSkeleton = Avatar.withComponent(AvatarIconSkeleton)

export const AvatarTitle = styled(Typography).attrs(() => ({
  variant: 'h6',
  color: 'textDark',
  noWrap: true
}))``

export const AvatarTitleSkeleton = styled(
  AvatarTitle.withComponent(TypographySkeleton)
).attrs(() => ({
  color: 'skeletonPrimary'
}))``

export const EmptyScreen = styled(EmptyScreenBase)`
  background-color: transparent;
  height: auto;
  padding: ${getSpacingSelector(5)}px;
`
