function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import select from '@signal/utils/select';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { REDUCER_KEY, DATE_RANGE, SUMMARY_EXPANDED, RECENTLY_VIEWED_ACCOUNTS_KEY, SHOW_ONBOARDING_TOUR, SHOW_ACTIVATE_TOUR, CHANNELS_FILTER_TOOLBAR } from './constants';
export var rootSelector = select(REDUCER_KEY);
export var summaryExpandedRootSelector = createSelector(rootSelector, select(SUMMARY_EXPANDED));
export var createSummaryExpandedSelector = function createSummaryExpandedSelector(key) {
  return createSelector(summaryExpandedRootSelector, select(key));
};
export var dateRangeSelector = createSelector(rootSelector, select(DATE_RANGE, initialState[DATE_RANGE]));
export var recentlyViewedAccountIdsSelector = createSelector(rootSelector, select(RECENTLY_VIEWED_ACCOUNTS_KEY, initialState[RECENTLY_VIEWED_ACCOUNTS_KEY]));
export var hasRecentlyViewedAccountsSelector = createSelector(recentlyViewedAccountIdsSelector, function (ids) {
  return ids.length > 1;
});
export var showOnboardingTourSelector = createSelector(rootSelector, select(SHOW_ONBOARDING_TOUR));
export var showActivateTourSelector = createSelector(rootSelector, select(SHOW_ACTIVATE_TOUR));
export var selectChannelsFilters = createSelector(rootSelector, select(CHANNELS_FILTER_TOOLBAR, initialState[CHANNELS_FILTER_TOOLBAR])); // functions to create simulated redux state for data obtained by context fetcher for use with selectors

export function cookRecentlyViewedAccountsForSelectors(_ref) {
  var _ref$recentAccounts = _ref.recentAccounts,
      recentAccounts = _ref$recentAccounts === void 0 ? [] : _ref$recentAccounts;
  return _defineProperty({}, REDUCER_KEY, _defineProperty({}, RECENTLY_VIEWED_ACCOUNTS_KEY, recentAccounts));
}