import React, { memo } from 'react'
import { PAGE_SHOPPER, APP_ATTRIBUTION } from '@signal/analytics'
import { usePageTrack } from '@signal/core-hooks'
import LayoutDefaultWithPageHeader from '../../layouts/DefaultWithPageHeader'
import Header from '../../containers/AttributionHeader'
import ShopperDetailsTimestamp from '../../containers/ShopperDetailsTimestamp'
import ShopperDetailsActivity from '../../containers/ShopperDetailsActivity'
import ShopperDetailHeader from '../../containers/ShopperDetailHeader'
import { Wrapper } from './styles'

export const ShopperDetailPage = memo(props => {
  usePageTrack(PAGE_SHOPPER, APP_ATTRIBUTION)

  return (
    <LayoutDefaultWithPageHeader
      navbar={Header}
      match={props.match}
      headerComponent={ShopperDetailHeader}
    >
      <Wrapper>
        <ShopperDetailsTimestamp {...props} />
        <ShopperDetailsActivity {...props} />
      </Wrapper>
    </LayoutDefaultWithPageHeader>
  )
})

ShopperDetailPage.displayName = 'ShopperDetailPage'

export default ShopperDetailPage
