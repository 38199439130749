import loadable from '@signal/hoc/loadable'
import combinedError from '@signal/hoc/combinedError'
import ErrorScreenApi from '@signal/containers/ErrorScreenApi'
import Skeleton from './Skeleton'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "inventory-vin-details-list" */ './component'),
  dataLoader: () =>
    import(
      /* webpackChunkName: "lib-inventory-vin" */ '@signal/core/inventoryVin'
    ),
  loading: Skeleton,
  error: combinedError(ErrorScreenApi)
})
