import loadable from '@signal/hoc/loadable'
import combinedError from '@signal/hoc/combinedError'
import ErrorScreenApi from '@signal/containers/ErrorScreenApi'
import Component from './Component'

export default loadable({
  loader: () =>
    import(/* webpackChunkName: "redux-crutch-loader" */ './Component'),
  dataLoader: {
    user: () => import(/* webpackChunkName: "lib-user" */ '@signal/core/user'),
    auth: () => import(/* webpackChunkName: "lib-auth" */ '@signal/core/auth')
  },
  loadData: (
    { user: { userDetailsLoader }, auth: { authLoader } },
    loaderInterface
  ) =>
    Promise.all([
      userDetailsLoader(loaderInterface),
      authLoader(loaderInterface)
    ]),
  loading: Component,
  error: combinedError(ErrorScreenApi)
})
