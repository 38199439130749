import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import get from 'lodash/get';
import format from 'date-fns/format';
import { mapFiltersToArray } from './utils';
import { API_VERSION, API_URL } from './constants';
import { usePost } from '../fetch';
export function getWidgetDataURL(_ref) {
  var accountId = _ref.accountId,
      dashboardRevisionId = _ref.dashboardRevisionId,
      dataSetId = _ref.dataSetId;
  return "".concat(API_URL, "/v").concat(API_VERSION, "/manifest/").concat(accountId, "/report/").concat(dashboardRevisionId, "/data/").concat(dataSetId);
}
export function getWidgetPostBody(_ref2) {
  var _ref2$filters = _ref2.filters,
      filters = _ref2$filters === void 0 ? [] : _ref2$filters,
      dateRange = _ref2.dateRange,
      comparisonDateRange = _ref2.comparisonDateRange;
  var comparisons = {};

  if (comparisonDateRange) {
    var startDate = comparisonDateRange.startDate,
        endDate = comparisonDateRange.endDate;
    comparisons["previous" //HACK: MRD-605 - While we only support 1-Comparison lets use a predicable name to make it easier on the backend.
    ] = {
      start: format(startDate, 'MM-dd-yyyy'),
      end: format(endDate, 'MM-dd-yyyy')
    };
  }

  return {
    startDate: format(dateRange.startDate, 'MM-dd-yyyy'),
    endDate: format(dateRange.endDate, 'MM-dd-yyyy'),
    filters: mapFiltersToArray(filters),
    comparisons: comparisons
  };
} // use this function to actually use widget data from a React component w/suspense loading state

export function useWidgetData(_ref3) {
  var dashboardRevisionId = _ref3.dashboardRevisionId,
      dataSetId = _ref3.dataSetId,
      filters = _ref3.filters,
      dateRange = _ref3.dateRange,
      comparisonDateRange = _ref3.comparisonDateRange,
      widgetId = _ref3.widgetId;
  var match = useRouteMatch();
  var accountId = get(match, 'params.accountId');
  var postBody = useMemo(function () {
    return getWidgetPostBody({
      filters: filters,
      dateRange: dateRange,
      comparisonDateRange: comparisonDateRange
    });
  }, [filters, dateRange, comparisonDateRange]);
  var url = getWidgetDataURL({
    accountId: accountId,
    dashboardRevisionId: dashboardRevisionId,
    dataSetId: dataSetId
  });
  return usePost(url, postBody, "useWidgetData ".concat(widgetId));
}
export default useWidgetData;