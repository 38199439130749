import styled from 'styled-components'
import LegendBase from '@signal/components/Legend'
import LegendSkeletonBase from '@signal/components/Legend/Skeleton'
import {
  getSpacingSelector,
  getColorPrimarySelector
} from '@signal/theme/selectors'

const hexToRgba = (hexCode, alpha) => {
  const hexValues = hexCode.substr(1)
  const r = parseInt(hexValues.substring(0, 2), 16)
  const g = parseInt(hexValues.substring(2, 4), 16)
  const b = parseInt(hexValues.substring(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const getChartGradients = theme => {
  const primary = getColorPrimarySelector('main')(theme)
  return {
    gradientStart: hexToRgba(primary, 0.5),
    gradientEnd: hexToRgba(primary, 0.15)
  }
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${getSpacingSelector(3)}px 0;
`
export const ChartWrapper = styled.div`
  width: 100%;
  height: 320px;
`

export const Legend = styled(LegendBase).attrs(() => ({
  labelProps: { variant: 'body2' },
  marginSpacing: 2
}))`
  padding: ${getSpacingSelector(2)}px 0;
`

export const LegendSkeleton = styled(LegendSkeletonBase).attrs(() => ({
  numOfSeries: 1,
  labelProps: { variant: 'body2' },
  marginSpacing: 2
}))`
  padding: ${getSpacingSelector(2)}px 0;
`
