import React from 'react'
import { Wrapper } from './styles'
import Content from '@signal/layouts/Content'
import ReportHeaderSkeleton from '../../../components/dashboard/ReportHeader/Skeleton'
import ReportLoadingState from '@signal/components/ReportLoadingState'

export default function ReportSkeleton() {
  return (
    <>
      <ReportHeaderSkeleton />
      <Content animateIn disableGutter constrainWidth={false}>
        <Wrapper>
          <ReportLoadingState />
        </Wrapper>
      </Content>
    </>
  )
}
