var _adTemplateActionRedu;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import endOfWeek from 'date-fns/endOfWeek';
import endOfMonth from 'date-fns/endOfMonth';
import formatISO from 'date-fns/formatISO';
import { CASH_OFFER_TEMPLATE_ID, CUSTOM_OFFER_TEMPLATE_ID, FULL_CUSTOM_OFFER_TEMPLATE_ID, ERROR_OVERLAPPING_TIME_WINDOW, CONTENT_ENUMS, TYPE_ENUMS } from './constants';

var commonReducer = function commonReducer(_ref, isUniversal) {
  var audience = _ref.audience,
      adName = _ref.adName,
      adTemplateId = _ref.adTemplateId,
      repeat = _ref.repeat,
      adParameters = _ref.adParameters;
  var startDate = adParameters.startDate,
      endDate = adParameters.endDate,
      IncludeTerms = adParameters.includeTerms,
      Terms = adParameters.termsMessage,
      _adParameters$photo = adParameters.photo,
      photo = _adParameters$photo === void 0 ? {} : _adParameters$photo,
      Vertical = adParameters.vertical,
      Horizontal = adParameters.horizontal,
      LeadsCategory = adParameters.leadsCategory;
  var DefaultPhoto = photo.imageCdnUrl,
      DefaultPhotoDescription = photo.description;
  var commonProperties = {
    type: 'nativeAd',
    name: adName,
    // hardcoded to `active` until we have a better story around `draft` mode
    status: 'active',
    adParameters: {
      IncludeTerms: IncludeTerms,
      Terms: Terms,
      DefaultPhoto: DefaultPhoto,
      DefaultPhotoDescription: DefaultPhotoDescription,
      Vertical: Vertical,
      Horizontal: Horizontal,
      LeadsCategory: LeadsCategory
    },
    nativeAdTemplateId: adTemplateId
  };

  if (repeat !== 'none') {
    commonProperties.repeat = repeat;
  } else {
    commonProperties.startDate = startDate.startOf('day').utc().format().toString();
    commonProperties.runUntil = endDate.startOf('day').utc().format().toString();
    commonProperties.repeat = 'none';
  }

  if (!isUniversal) {
    commonProperties.audienceIds = [audience.value];
  }

  return commonProperties;
};

export var adTemplateActionReducers = (_adTemplateActionRedu = {}, _defineProperty(_adTemplateActionRedu, CASH_OFFER_TEMPLATE_ID, function (offerDetails, isUniversal) {
  var commonProperties = commonReducer(offerDetails, isUniversal);
  return _objectSpread(_objectSpread({}, commonProperties), {}, {
    adParameters: _objectSpread(_objectSpread({}, commonProperties.adParameters), {}, {
      DollarsOff: offerDetails.adParameters.offerValue
    })
  });
}), _defineProperty(_adTemplateActionRedu, CUSTOM_OFFER_TEMPLATE_ID, function (offerDetails, isUniversal) {
  var commonProperties = commonReducer(offerDetails, isUniversal);
  return _objectSpread(_objectSpread({}, commonProperties), {}, {
    adParameters: _objectSpread(_objectSpread({}, commonProperties.adParameters), {}, {
      Title: offerDetails.adParameters.title
    })
  });
}), _defineProperty(_adTemplateActionRedu, FULL_CUSTOM_OFFER_TEMPLATE_ID, function (offerDetails, isUniversal) {
  var commonProperties = commonReducer(offerDetails, isUniversal);
  return _objectSpread(_objectSpread({}, commonProperties), {}, {
    adParameters: _objectSpread(_objectSpread({}, commonProperties.adParameters), {}, {
      Title: offerDetails.adParameters.title
    })
  });
}), _adTemplateActionRedu);

var formatDate = function formatDate(date) {
  return format(date, 'MM/dd/yyyy');
};

export var getFormErrors = function getFormErrors(_ref2) {
  var error = _ref2.error,
      errorDetails = _ref2.errorDetails;
  if (error !== ERROR_OVERLAPPING_TIME_WINDOW) return null;
  var errorText = '';
  var conflicting = errorDetails.conflicting;
  var conflictingName = conflicting.name;

  if (!conflicting.runUntil) {
    errorText = "".concat(conflictingName, " is scheduled as a repeating offer.");
  } else {
    var conflictingStartDate = parseISO(conflicting.startDate.split('T')[0]);
    var conflictingEndDate = parseISO(conflicting.runUntil.split('T')[0]);
    errorText = "".concat(conflictingName, " is scheduled to run from ").concat(formatDate(conflictingStartDate), " to ").concat(formatDate(conflictingEndDate), ".");
  }

  errorText += ' Only one universal offer can be active at a time. Disable or delete the existing offer.';
  return {
    adParameters: {
      startDate: errorText
    }
  };
};

function createEndDateFromRepeatValue(repeat) {
  var now = Date.now();

  switch (repeat) {
    case 'weekly':
      return endOfWeek(now);

    case 'monthly':
      return endOfMonth(now);

    default:
      return now;
  }
}

export function endDateDataReducer() {
  var offers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return offers.map(function addEndDate(offer) {
    if (offer.endDate) {
      return offer;
    }

    var endDate = formatISO(createEndDateFromRepeatValue(offer.repeat));
    return _objectSpread(_objectSpread({}, offer), {}, {
      endDate: endDate
    });
  });
}
export function adTemplateIdBy(_ref3) {
  var content = _ref3.content;

  switch (content) {
    case CONTENT_ENUMS.CASH:
      return CASH_OFFER_TEMPLATE_ID;

    case CONTENT_ENUMS.CUSTOM:
      return FULL_CUSTOM_OFFER_TEMPLATE_ID;

    case CONTENT_ENUMS.CUSTOM_COUNTDOWN:
      return CUSTOM_OFFER_TEMPLATE_ID;

    default:
      return null;
  }
}
export function adTemplateContentTypeBy(_ref4) {
  var adTemplateId = _ref4.adTemplateId;

  switch (adTemplateId) {
    case CASH_OFFER_TEMPLATE_ID:
      return {
        content: CONTENT_ENUMS.CASH,
        type: TYPE_ENUMS.TEASER
      };

    case CUSTOM_OFFER_TEMPLATE_ID:
      return {
        content: CONTENT_ENUMS.CUSTOM_COUNTDOWN,
        type: TYPE_ENUMS.TEASER
      };

    case FULL_CUSTOM_OFFER_TEMPLATE_ID:
      return {
        content: CONTENT_ENUMS.CUSTOM,
        type: TYPE_ENUMS.TEASER
      };

    default:
      return null;
  }
}