import React from 'react'
import {
  Wrapper,
  AttributionBarChartSkeleton,
  ChannelTableSkeleton,
  SectionHeading
} from './styles'

export const ChannelsVinAttributionSkeleton = () => (
  <Wrapper>
    <SectionHeading />
    <AttributionBarChartSkeleton />
    <ChannelTableSkeleton />
  </Wrapper>
)

export default ChannelsVinAttributionSkeleton
