import React from 'react'
import { Wrapper, AppearancesDataGridSkeleton, SectionHeading } from './styles'

export const ChannelsVinAppearancesSkeleton = () => (
  <Wrapper>
    <SectionHeading />
    <AppearancesDataGridSkeleton />
  </Wrapper>
)

export default ChannelsVinAppearancesSkeleton
