import styled from 'styled-components'
import DonutChart from '@signal/components/DonutChart'
import DonutChartSkeleton from '@signal/components/DonutChart/Skeleton'

export const ScoreChart = styled(DonutChart).attrs(() => ({
  size: 48
}))`
  float: right;
`

export const ScoreChartSkeleton = styled(
  ScoreChart.withComponent(DonutChartSkeleton)
).attrs(() => ({
  value: null
}))``
