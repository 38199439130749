function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

import { useState, useEffect, useRef } from 'react';
import useEventListener from '@use-it/event-listener';
import createGlobalState from './createGlobalState';

var usePersistedState = function usePersistedState(initialState, key, _ref) {
  var get = _ref.get,
      set = _ref.set;
  var globalState = useRef(null);

  var _useState = useState(function () {
    return get(key, initialState);
  }),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1]; // subscribe to `storage` change events


  useEventListener('storage', function (_ref2) {
    var k = _ref2.key,
        newValue = _ref2.newValue;

    if (k === key) {
      try {
        var newState = JSON.parse(newValue);

        if (state !== newState) {
          setState(newState);
        }
      } catch (err) {// gracefully fail on unexpected JSON parse error
      }
    }
  }); // only called on mount

  useEffect(function () {
    // register a listener that calls `setState` when another instance emits
    globalState.current = createGlobalState(key, setState, initialState);
    return function () {
      globalState.current.deregister();
    };
  }, [initialState, key]); // Only persist to storage if state changes.

  useEffect(function () {
    // persist to localStorage
    set(key, state); // inform all of the other instances in this tab

    globalState.current.emit(state);
  }, [key, set, state]);
  return [state, setState];
};

export default usePersistedState;