var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import Typography from '../Typography';
import { getSpacingSelector } from '@signal/theme/selectors';
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var TimelineWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-top: ", "px;\n"])), getSpacingSelector(4));
export var Title = styled(Typography).attrs(function () {
  return {
    variant: 'h6'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-bottom: ", "px;\n"])), getSpacingSelector(2));
export var Description = styled(Typography).attrs(function () {
  return {
    variant: 'subtitle2'
  };
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));