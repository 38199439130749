import React from 'react'
import {
  Heading,
  ChannelAvatarSkeleton,
  HeadingTitleSkeleton,
  SummaryTextSkeleton,
  ContributionBarChartSkeleton,
  ContributionTextSkeleton,
  MetricListSkeleton,
  Wrapper
} from './styles'

export const ChannelsVinContributionSkeleton = () => (
  <Wrapper>
    <Heading>
      <ChannelAvatarSkeleton />
      <HeadingTitleSkeleton />
    </Heading>
    <SummaryTextSkeleton />
    <ContributionTextSkeleton />
    <ContributionBarChartSkeleton />
    <MetricListSkeleton />
  </Wrapper>
)

export default ChannelsVinContributionSkeleton
