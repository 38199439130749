import { createThemeSelector, createVariableThemeSelectorCreator } from './utils';
export var breakpointsSelector = createThemeSelector(function (theme) {
  return theme.breakpoints;
});
export var getBreakpointUpSelector = createVariableThemeSelectorCreator(function (size) {
  return createThemeSelector(breakpointsSelector, function (breakpoints) {
    return breakpoints.up(size);
  });
});
export var getBreakpointDownSelector = createVariableThemeSelectorCreator(function (size) {
  return createThemeSelector(breakpointsSelector, function (breakpoints) {
    return breakpoints.down(size);
  });
});
export var getBreakpointCustomSelector = createVariableThemeSelectorCreator(function (name) {
  return createThemeSelector(breakpointsSelector, function (breakpoints) {
    return breakpoints.custom[name];
  });
});
export var breakpointTabletSelector = getBreakpointUpSelector('sm');
export var breakpointBelowTabletSelector = getBreakpointDownSelector('sm');
export var breakpointAboveTabletSelector = getBreakpointUpSelector('md');
export var breakpointMobileLandscapeSelector = getBreakpointCustomSelector('mobileLandscape');