import styled from 'styled-components'
import DataGrid from '@signal/components/DataGrid'
import DataGridSkeleton from '@signal/components/DataGrid/Skeleton'

export const ChannelsDetailDataGrid = styled(DataGrid)`
  grid-template-columns: repeat(auto-fit, minmax(100px, 220px));
  justify-content: space-between;
`

export const ChannelsDetailDataGridSkeleton = ChannelsDetailDataGrid.withComponent(
  DataGridSkeleton
)
