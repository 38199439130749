import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageName } from '@signal/analytics';
import { locationSelector } from '@signal/core/router';
import { userIdSelector } from '@signal/core/user';
/**
 * Tracks each page view from authenticated users.
 * @param {string} pageName - Name of the page you want to track
 */

export function usePageTrack(pageName, app) {
  var dispatch = useDispatch();
  var userId = useSelector(userIdSelector);
  var location = useSelector(locationSelector);
  useEffect(function () {
    // prevent page view tracking for unauthenticated users
    if (!userId) return;
    dispatch(setPageName(pageName, app));
  }, [dispatch, pageName, userId, location, app]);
}
export default usePageTrack;