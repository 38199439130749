var _templateObject, _templateObject2, _templateObject3;

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

import styled from 'styled-components';
import { getColorBackgroundSelector, getColorPrimarySelector } from '@signal/theme/selectors';
export var MarkerWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 0 20px;\n"])));
export var MarkerOuter = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  width: 20px;\n  height: 20px;\n  background-color: ", ";\n  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);\n  border-radius: 50%;\n  z-index: 1;\n  margin: 0 auto;\n"])), getColorBackgroundSelector());
export var MarkerInner = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 10px;\n  height: 10px;\n  background-color: ", ";\n  border-radius: 50%;\n  top: 5px;\n  left: 5px;\n  position: absolute;\n"])), getColorPrimarySelector());