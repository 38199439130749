import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import get from 'lodash/get'
import { SORT_ASCENDING, SORT_NONE } from '@signal/components/SortableTable'
import TableListView from '@signal/components/TableListView'
import ComponentPropType from '@signal/prop-types/component'
import DrilldownTableHeader from '../DrilldownTableHeader'
import { sortRows, reverseSortDirection } from './utils'

// TODO: doc props, isSortable on columnInfo defaults to true, key and label are req'd

export default class SortableTableListView extends PureComponent {
  static propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnInfo: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string,
        width: PropTypes.string,
        renderCell: PropTypes.func,
        /**
         * The component to be rendered in the column
         */
        component: ComponentPropType,
        /**
         *  The key of the property in the rows to sort by.
         */
        sortKey: PropTypes.string
      })
    ).isRequired,
    initialSortKey: PropTypes.string
  }

  state = {
    activeSortKey:
      this.props.initialSortKey ||
      get(
        this.props.columnInfo.find(
          ({ sortKey, isSortable }) => isSortable !== false && sortKey
        ),
        'sortKey',
        null
      ),
    sortDirection: SORT_ASCENDING,
    sortChanged: false,
    lastRows: null
  }

  static getDerivedStateFromProps(props, state) {
    const { rows, columnInfo } = props
    const { activeSortKey, sortDirection, lastRows, sortChanged } = state
    if (rows !== lastRows || sortChanged) {
      const sortColumn = columnInfo.find(
        ({ sortKey }) => sortKey === activeSortKey
      )
      const sortReverse = get(sortColumn, 'sortReverse', false)
      return {
        sortedRows: sortRows({
          rows,
          activeSortKey,
          sortDirection: sortReverse
            ? reverseSortDirection(sortDirection)
            : sortDirection
        }),
        lastRows: rows,
        sortChanged: false
      }
    }
    return null
  }

  createSortChangeHandler = sortKey => sortDirection => {
    this.setState({
      activeSortKey: sortKey,
      sortDirection,
      sortChanged: true
    })
  }

  renderHeaderCell = column => {
    const { label, sortKey, inverse, isSortable = true } = column
    const { activeSortKey, sortDirection } = this.state
    const { rows } = this.props
    const sortDirectionUse =
      activeSortKey === sortKey ? sortDirection : SORT_NONE
    return label == null ? null : (
      <DrilldownTableHeader
        sortDirection={sortDirectionUse}
        allowSort={isSortable && rows.length > 1 ? true : false}
        onSortChange={this.createSortChangeHandler(sortKey)}
        inverse={inverse}
      >
        {label}
      </DrilldownTableHeader>
    )
  }

  renderRowCell = (row, { renderCell, component: Component }) => {
    return Component ? <Component {...row} /> : renderCell(row)
  }

  render() {
    const { columnInfo, ...rest } = this.props
    const { sortedRows } = this.state
    return (
      <TableListView
        columnInfo={columnInfo}
        rows={sortedRows}
        renderHeaderCell={this.renderHeaderCell}
        renderRowCell={this.renderRowCell}
        {...omit(rest, 'rows')}
      />
    )
  }
}
